import api, { APIClient } from "../api";
import { CostCenterModel } from "../finOpsManagerService";

export interface CostCenterWithBudget {
    id: number;
    name: string;
    active: boolean;
    clientGroupId: number;
    budget: number;
    monthBudget: MonthBudget[];
}

export interface MonthBudget {
    id: number;
    costCenterId: number;
    value: number;
    month: string;
}

export interface FilterDashboard {
    clientGroupId?: number;
    accountId?: string;
    costCenterID?: number;
    contractID?: number;
    showChildrenCostCenters?: number;
    gtmId: number;
    reportType?: string;
    componentName?: string;
    productName?: string;
    serviceCode?: string;
    serviceName?: string;
    provedor?: string;
    user?: string;
    ano: number;
    mes: number;
    dia: number;
    periodo?: number;
}
interface CloudOsbillingConsolidatedAdminView {
    recurso: string;
    servico: string;
    centrodeCustoId: string;
    contratoId: string;
    provedor: string;
    valorBRL: number;
    ano: number;
    date: Date;
    mes: number;
    dia: number;
    centrodeCusto: string;
    contrato: string;
    resourceGroup: string;
    tags: string;
    categoria: string;
    subCategoria: string;
    metrica: string;
    unidade: string;
    accountId: string;
    costCenterAppliedRule: string;
    consumedQuantity: number;
}
export interface FinOpsPivotDataResult {
    Srv: string; // Corresponds to Servico
    Bdg: number; // Corresponds to Budget
    Pct: number; // Corresponds to Porcent_Valor
    Prv: string; // Corresponds to Provedor
    Dat: string; // Corresponds to Date, using string to represent DateTime
    Ano: number; // Corresponds to Ano
    Mes: number; // Corresponds to Mes
    Dia: number; // Corresponds to Dia
    Cdc: string; // Corresponds to CentrodeCusto
    Cid: string; // Corresponds to CentrodeCustoId
    Cto: string; // Corresponds to ContratoId
    Ctr: string; // Corresponds to Contrato
    Rsg: string; // Corresponds to ResourceGroup
    Rec: string; // Corresponds to Recurso
    Vbr: number; // Corresponds to ValorBRL
    Tag: string; // Corresponds to Tags
    Cat: string; // Corresponds to Categoria
    Sub: string; // Corresponds to SubCategoria
    Met: string; // Corresponds to Metrica
    Uni: string; // Corresponds to Unidade
    Acc: string; // Corresponds to AccountId
    Ccr: string; // Corresponds to CostCenterAppliedRule
    Csq: number; // Corresponds to ConsumedQuantity
    Cst: number; // Corresponds to Cost
    Mrg: number; // Corresponds to Margin
    Tax: number; // Corresponds to Tax
    Spt: boolean; // Corresponds to ShowPercent
    Smg: boolean; // Corresponds to ShowMargin
}
export class CostCenterService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    public title = 'Centro de Custo';
    public cardsItens = [
        { label: 'Gráfico', value: 0 },
        { label: 'Árvore', value: 1 },
        { label: 'Orçamento/consumo', value: 2 }
    ];


    async GetUrl() {
        const url = this.endpoint + `/api/CostCenterDashBoard/GetToken?refresh=${Math.random()}`;
        return api({
            method: 'get',
            url,

        });
    }

    OrderDataDetailsAnaliticoOld(responseAData) {
        const reverseMonthMapping = {};
        // Mapeamento dos meses
        const monthMapping = {
            1: "Janeiro",
            2: "Fevereiro",
            3: "Março",
            4: "Abril",
            5: "Maio",
            6: "Junho",
            7: "Julho",
            8: "Agosto",
            9: "Setembro",
            10: "Outubro",
            11: "Novembro",
            12: "Dezembro"
        };


        // let data = decodedObject;
        /*  data.map((item: any) => {
              item.valorBRL = Number(item.valorBRL);
              item.mes = monthMapping[item.mes] || item.mes;
          });*/
        if (responseAData.length > 0) {

            for (var i = 0; i < responseAData.length; i++) {
                //responseAData[i].Dat = convertDateString(responseAData[i].Dat);
                const date = responseAData[i].Ano * 10000 + responseAData[i].Mes * 100 + responseAData[i].Dia;
                responseAData[i].Mes = monthMapping[responseAData[i].Mes] || responseAData[i].Mes;
                responseAData[i].Dat = date.toString();
            }
        }
        return responseAData;
    }
    OrderDataDetailsAnaliticoReplaceMonthName(responseAData) {
        const monthMapping = {
            1: "Janeiro",
            2: "Fevereiro",
            3: "Março",
            4: "Abril",
            5: "Maio",
            6: "Junho",
            7: "Julho",
            8: "Agosto",
            9: "Setembro",
            10: "Outubro",
            11: "Novembro",
            12: "Dezembro"
        };

        for (let i = 0, len = responseAData.length; i < len; i++) {
            const item = responseAData[i];
            // Cache the numeric month value
            const monthNum = item.Mes;
            // Compute the date using the original numeric month
            item.Dat = (item.Ano * 10000 + monthNum * 100 + item.Dia).toString();
            // Replace the month with its mapped string, if available
            item.Mes = monthMapping[monthNum] || monthNum;
        }
        return responseAData;
    }

    OrderDataDetailsAnaliticoNew(responseAData) {
        const collator = new Intl.Collator(undefined, { numeric: true });
        return responseAData.sort((a, b) => collator.compare(a.Dat, b.Dat));
    }

    OrderDataDetailsAnalitico(responseAData) {
        if (responseAData.length > 0) {
            // Sort using direct numeric comparisons
            responseAData.sort((a, b) =>
                (a.Ano * 10000 + a.Mes * 100 + a.Dia) -
                (b.Ano * 10000 + b.Mes * 100 + b.Dia)
            );
        }

        return responseAData;
    }

    orderDataByYearParallel(data) {
        // Agrupa os dados por ano
        const gruposPorAno = data.reduce((acc, item) => {
            if (!acc[item.Ano]) {
                acc[item.Ano] = [];
            }
            acc[item.Ano].push(item);
            return acc;
        }, {});

        // Ordena cada grupo (aqui de forma síncrona, mas pode ser paralelizado)
        const anosOrdenados = Object.keys(gruposPorAno).sort((a, b) => Number(a) - Number(b));
        const dataOrdenada = anosOrdenados.reduce((acc, ano) => {
            const grupoOrdenado = gruposPorAno[ano].sort((a, b) =>
                (a.Ano * 10000 + a.Mes * 100 + a.Dia) - (b.Ano * 10000 + b.Mes * 100 + b.Dia)
            );
            return acc.concat(grupoOrdenado);
        }, []);

        return dataOrdenada;
    }

    async GetDataDetails(filter: FilterDashboard) {

        let url = `${process.env.REACT_APP_LCAPIDASH}`
        url = url + 'LiveCloudFinOpsDashboardAPI/api/cloudosbillingconsolidated/retrievelistdetail';
        // url = this.endpoint.replace('LiveCloudBFFWeb', '') + 'LiveCloudFinOpsDashboardAPI/api/cloudosbillingconsolidated/retrievelistdetail';
        const response = await api({
            method: 'post',
            url,
            data: filter

        });

        return this.OrderDataDetailsAnalitico(response.data);
    }

    async GetCostCenters(clientGroupId?: number, user?: string, token?: string) {
        let url = this.endpoint.replace('LiveCloudBFFWeb', '') + 'LiveCloudFinOpsDashboardAPI/api/sipcontract/retrievefromuserlist';

        let queryParameters = '?';
        if (clientGroupId != undefined && clientGroupId != null) {
            queryParameters += '&ClientGroupId=' + clientGroupId;
        }

        if (user != undefined && user != null) {
            queryParameters += '&User=' + user;
        }

        if (token != undefined && token != null) {
            queryParameters += '&token=' + token;
        }

        url = (url + queryParameters);
        return api({
            method: 'get',
            url
        });
    }

    async GetContracts(sipContractID?: number, clientGroupId?: number, user?: string, gtmid?: number, token?: string) {
        let url = this.endpoint.replace('LiveCloudBFFWeb', '') + 'LiveCloudFinOpsDashboardAPI/api/sipcontract/retrievefromuserlist';
        let queryParameters = '?';
        if (sipContractID != undefined && sipContractID != null) {
            queryParameters += 'SipContractID=' + sipContractID;
        }

        if (clientGroupId != undefined && clientGroupId != null) {
            queryParameters += '&clientGroupId=' + clientGroupId;
        }

        if (user != undefined && user != null) {
            queryParameters += '&userid=' + user;
        }

        if (gtmid != undefined && gtmid != null) {
            queryParameters += '&gtmid=' + gtmid;
        }

        if (token != undefined && token != null) {
            queryParameters += '&token=' + token;
        }

        url = (url + queryParameters);
        return api({
            method: 'get',
            url
        });
    }

    async GetTreeData() {
        const url = `${this.endpoint}/api/CostCenter/GetTreeData`
        return api({
            method: 'get',
            url,
        });
    }
    async GetTreeDataWithBudget() {
        const url = `${this.endpoint}/api/CostCenter/GetTreeDataWithBudget`
        return api({
            method: 'get',
            url,
        });
    }

    async GetCostCenterDetails(costCenterId: number) {
        const url = `${this.endpoint}/api/CostCenter/GetCostCenterDetails/${costCenterId}`
        return api({
            method: 'get',
            url,
        });
    }
    async GetCostCenterDetailsTEST(costCenterId: number) {
        const url = `${this.endpoint}/api/CostCenter/GetCostCenterDetails/${costCenterId}`
        return api({
            method: 'get',
            url,
        });
    }

    async UpdateTree(tree: any) {
        const url = `${this.endpoint}/api/CostCenter/SaveTree`
        const data = JSON.stringify(tree)

        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json'
            }
        });
    }

    async UpdateCostCenter(tree: any) {

        const url = `${this.endpoint}/api/CostCenter/Save`
        const data = JSON.stringify(tree)

        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json'
            }
        });
    }

    async UpdateCostCenterWithBudget(tree: any) {

        const url = `${this.endpoint}/api/CostCenter/SaveWithBudget`
        const data = JSON.stringify(tree)

        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json'
            }
        });
    }

    async GetCostCenterResources(costCenterId: number) {
        const url = `${this.endpoint}/api/CostCenterResource/GetCostCenterResourcePaginated/${costCenterId}?start=0&size=100000`
        return api({
            method: 'get',
            url,
        });
    }

    async GetCloudResource(costCenterId: number) {
        const url = `${this.endpoint}/api/CloudResource/RetrieveCloudResourcePaginated/${costCenterId}?start=0&size=100000`
        return api({
            method: 'get',
            url,
        });
    }

    async GetCostCenterUsers(costCenterId: number) {
        const url = `${this.endpoint}/api/CostCenterUser/RetrieveUsers?costCenterId=${costCenterId}`
        return api({
            method: 'get',
            url,
        });
    }

    async GetCostCenterAspNetUsers(costCenterId: number) {
        const url = `${this.endpoint}/api/CostCenterUser/RetrieveAspNetUsers?costCenterId=${costCenterId}`
        return api({
            method: 'get',
            url,
        });
    }

    async AddCostCenterUser(costCenterUser: any) {
        const url = `${this.endpoint}/api/CostCenterUser/Save`
        const data = JSON.stringify(costCenterUser)

        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json'
            }
        });
    }

    async RemoveCostCenterUser(id: number) {
        const url = `${this.endpoint}/api/CostCenterUser/Delete/${id}`

        return api({
            method: 'delete',
            url,
        });
    }

    async RemoveCostCenterUsers(ids: number[]) {
        const url = `${this.endpoint}/api/CostCenterUser/DeleteMany`

        return api({
            method: 'delete',
            url,
            data: ids,
        });
    }

    async AddCostCenterResource(costCenterResource: any) {
        const url = `${this.endpoint}/api/CostCenterResource/CostCenterResource/Save`
        const data = JSON.stringify(costCenterResource)

        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json-patch+json'
            }
        });
    }

    async RemoveCostCenterResource(id: number) {
        const url = `${this.endpoint}/api/CostCenterResource/DeleteCostCenterResource/CostCenterResource/${id}`

        return api({
            method: 'delete',
            url,
        });
    }

    async DeleteCostCenter(id: number) {
        const url = `${this.endpoint}/api/CostCenter/Delete/${id}`

        return api({
            method: 'delete',
            url,
        });
    }

    async RetrieveListByClientGroupAndUserId() {
        const url = `${this.endpoint}/api/CostCenter/RetrieveListByClientGroupAndUserId`
        return api({
            method: 'get',
            url,
        });
    }

    async GetContractsByGroupId(gtmId = '0') {
        const url = `${this.endpoint}/api/CostCenterDashBoard/RetrieveFromUserList?gtmId=${gtmId}`
        return api({
            method: 'get',
            url,
        });
    }

    //Budget
    async CreateOrUpdateBudget(costCenterId: number, value: number, month: string) {
        const url = `${this.endpoint}/api/CostCenter/CreateOrUpdateBudget?costCenterId=${costCenterId}&value=${value}&month=${month}`

        return api({
            method: 'post',
            url
        });
    }

    //Historico
    async ReadHistoricByCostCenter(costCenterId: number) {
        const url = `${this.endpoint}/api/CostCenter/ReadHistoricByCostCenter?costCenterId=${costCenterId}`

        return api({
            method: 'get',
            url
        });
    }
}