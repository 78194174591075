import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { useDispatch } from 'react-redux';
import LcNoData from '../../../../components/Generic/LcNoData';
import { defaultTooltipConfig } from '../../../../components/Generic/eChartsConfig';
import { GraphModel } from '../../../../services/patchmanager/patchManagerModel';

interface graphData {
    labels: Array<string>
    data: Array<number>
    colors: Array<string>
}
interface Props {
    isHome?: boolean
    isSecOps?: boolean
    title?: string
    qtsGroups?: number
    patchSummariesMissingSeverity?: any[];
}

const getColorsGraph = (SOName: string): string => {
    const color = {
        'Crítico': '#dc291e',
        'Importante': "#ffa400",
        'Moderado': "#f6de00",
        'Baixo': "#3ebb3f",
        'Não classificado': "#cccccf",
    };
    return color[SOName];
}
const buildGraphData = (data: GraphModel[]) => {
    if (!Array.isArray(data)) return { labels: [], data: [], colors: [] };
    return {
        labels: data.map(label => label.name),
        data: data.map(dados => dados.value),
        colors: data.map(cor => getColorsGraph(cor.name))
    }
}
const GetOptions = (graphDataLocal, props: Props) => {
    const option = {
        tooltip: {
            ...defaultTooltipConfig,
            trigger: 'axis',
            axisPointer: {
                type: 'shadow'
            }
        },
        title: {
            text: props.title,
            right: "0",
            top: "0",
            textStyle: {
                fontSize: 11,
                fontWeight: 'normal',
                color: '#8b90a3',
                fontFamily: 'Ubuntu',
            },
            backgroundColor: props.title ? '#ffffff90' : 'transparent',
            borderRadius: [10],
            padding: [4, 8, 4, 8],
            itemGap: 8
        },
        grid: {
            top: '15%',
            bottom: '0%',
            left: '0%',
            width: '100%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            data: graphDataLocal.labels,
            show: true,
            axisLabel: {
                interval: props.isHome ? 2 : 0,
                fontSize: (window.innerWidth > 1520 ? 10 : window.innerWidth > 1920 ? 14 : (props.qtsGroups && props.qtsGroups >= 4 ? 7 : 9))
            },
            animation: false // Desabilita animação no eixo X
        },
        yAxis: {
            type: 'value',
            show: true,
            axisLabel: {
                fontSize: props.qtsGroups && props.qtsGroups >= 4 ? 9 : 12
            },
            animation: false // Desabilita animação no eixo Y
        },
        series: [
            {
                data: graphDataLocal.data,
                itemStyle: {
                    color: (params) => graphDataLocal.colors[params.dataIndex],
                    borderRadius: [4, 4, 0, 0],
                    borderColor: '#fff',
                    borderWidth: 0,
                },
                type: 'bar',
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(58, 79, 135, 0.4)',
                    }
                },
            },
        ]
    };

    return option;
};
const MissingPatchesGraph: React.FC<Props> = (props) => {
    const [graphData, setGraphData] = useState<graphData>()
    const dispatch = useDispatch();

    useEffect(() => {
        const newGraphData = buildGraphData(props.patchSummariesMissingSeverity || []);
        if (JSON.stringify(newGraphData) !== JSON.stringify(graphData)) {
            setGraphData(newGraphData);
        }
    }, [props.patchSummariesMissingSeverity, graphData]);

    const handleFilterSystem = useCallback((event) => {
        let newName = event.name
        let payload = {
            SeverityPatch:newName
        } 
        dispatch({ type: 'SEVERITY_PATCH_FILTER', payload });
    },[dispatch])

    const memoizedOnEvents = useMemo(() => {
        if (props.isHome || props.isSecOps) return undefined;
        return {
            'click': (e) => handleFilterSystem(e)
        };
    }, [props.isHome, props.isSecOps, handleFilterSystem]);

    const options = useMemo(() => 
        graphData && GetOptions(graphData, props),
        [graphData, props]
    );
    return (
        <div style={{
            height: "100%",
            width: "100%",
        }}>
            {
                graphData ?
                    <ReactECharts
                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                    className='MissingPatchesGraph'
                    option={options}
                    style={{ width: '98%', height: '100%', minHeight: props.isHome || props.isSecOps ? '215px' : '310px', maxHeight: '55vh', marginBottom: props.isSecOps ? '10px' : '' }}
                    onEvents={memoizedOnEvents}
                    notMerge={true}
                    lazyUpdate={true}

                    />
                    :
                    <LcNoData size={props.isHome ? "small" : "default"} />
            }
        </div>
    );
}

export default MissingPatchesGraph;