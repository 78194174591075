import React, { useEffect } from 'react';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import { useQuery } from 'react-query';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import LcLoading from '../../../components/Generic/LcLoading';
import moment from 'moment';
import { PiFileLight } from 'react-icons/pi';
import { RiVirusFill } from 'react-icons/ri';
import { translateAttackSeverity } from './MostTargeTableCheckpoint';
import { StatusColor } from './AttacksChart';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

export const collumnMaliciousFile = [
    { field: 'file_name', headerName: 'Nome do Arquivo', width: "20%" },
    { field: 'serverity', headerName: 'Severidade', width: "10%", renderCell: (row: any) => {
        return  <div className='severityLabelDetails' style={{ padding: '0px 8px', backgroundColor: StatusColor(row.severity) }}>
        {translateAttackSeverity(row.severity)}
    </div>
    } },
    { field: 'user_name', headerName: 'Usuário', width: "20%", renderCell:(row: any) => {
        return row.user_name
    } },
    { field: 'src', headerName: 'Origem', width: "10%" },
    { field: 'action', headerName: 'Ação', width: "10%", renderCell: (row: any) => translateAction(row.action) },
    { field: 'confidence_level', headerName: 'Nível de confiança', width: "10%", renderCell: (row: any) => translateConfidenceLevel(row.confidence_level) },
    { field: 'verdict', headerName: 'Veredito', width: "10%", renderCell: (row: any) => translateClassification(row.verdict)},
    { field: 'time', headerName: 'Data', width: "12%", renderCell: (row: any) =>  moment(row.time).format('DD/MM/YYYY HH:mm:ss') },
]

export const translateConfidenceLevel = (status: string) => {
    const classficiation = {
        'High':'Alta',
        'Medium':'Média',
        'Low':'Baixa',
        'Critical':'Crítica',
    }
    return classficiation[status]
}
export const translateClassification = (classification: string) => {
    const classficiation = {
        'Malicious':'Maliciosa',
    }
    return classficiation[classification]
}
export const translateAction = (classification: string) => {
    const classficiation = {
        'Detect':'Detectado',
        "Prevent":'Prevenido',
        "Allow":'Permitido',

    }
    return classficiation[classification]
}

const MaliciousFileCard: React.FC = () => {
    const checkpointService = new EndpointDetectionResponseService({});
    const user = useSelector<RootState, UserState>(state => state.user);
    const dispatch = useDispatch();
    const period = useSelector((state: RootState) => state.xdrCheckpointResume.periodFilter);
    const loading = useSelector((state: RootState) => state.xdrCheckpointResume.loadingStates.loadingMaliciousFile);
    
    const { data: maliciousFileData, isLoading = true, refetch } = useQuery({
        queryKey: ["MaliciousFile",period, user.ClientGroupSelected, user.refreshFlag],
        queryFn: () => checkpointService.GetMaliciousFileAttacks(period),
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            dispatch({ type: 'SET_MALICIOUS_FILE_DATA', payload: response });
        },
        onError: (error) => {
            console.error('Error fetching Malicious File data:', error);
        }
    });
    useEffect(() => {
        dispatch({ type: 'SET_LOADING', payload: { key: 'loadingMaliciousFile', value: isLoading } });
    }, [dispatch, isLoading]);

    
    useEffect(() => {
        refetch();
    }, [user.ClientGroupSelected, user.refreshFlag, refetch]);

    return(
        <LcLoading loading={isLoading || loading}> 
            <IconValue 
                value={maliciousFileData ? maliciousFileData.recordCount : 0}
                icon={
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <PiFileLight  size={30} />
                    <RiVirusFill  size={20} style={{ position: 'absolute', bottom:-2 , zIndex: '100', left: 17, color: 'var(--emphasis)' }} />
                </div>}
                color='var(--emphasis)'
                description='Detectados'
            />

        </LcLoading>
    );
}

export default MaliciousFileCard;