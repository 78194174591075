import React, { useState, useEffect, useCallback } from 'react'
import Layout from '../../../components/Layout/Layout';
import { BackupGoldService } from '../../../services/backupGold/backupGoldService';
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';
import { FilterState, setFilterActive } from '../../../store/reducers/filterReducer';

import Moment from 'moment';
import LcLoading from '../../../components/Generic/LcLoading';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import HeyHoNewRequest from '../../../components/Generic/HeyHoNewRequest';
import { FormDataHeyho } from '../../../services/Heyho/heyhoModel';

const BackupGoldCofre: React.FC = (props) => {

    interface VaultsFilter {
        size: number,
        column: string,
        direction: string,
        CofreNumber: string,
        TaskName: string,
        startDate: string,
        endDate: string,
    }

    const emptyVaultsFilter = {
        size: 20,
        column: "",
        direction: "asc",
        CofreNumber: "",
        TaskName: "",
        startDate: "",
        endDate: ""
    }

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    const [modalVisibleHeyHoCall, setModalVisibleHeyHoCall] = useState<boolean>(false);
    const [vaults, setVaults] = useState<any[]>([]);
    const [filteredVaults, setFilteredVaults] = useState<any[]>([]);
    const [visibleVaults, setVisibleVaults] = useState<any[]>([]);
    //const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 20, term: "", column: "", direction: "asc" });

    const [filter, setFilter] = useState<VaultsFilter>(emptyVaultsFilter);


    const [formData, setFormData] = useState<FormDataHeyho>({ titulo: '', Descricao: '', gtmType: 0 });
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [notFoundMessage, setNotFoundMessage] = useState<string>('');
    const backupGoldService = new BackupGoldService(props);

    useEffect(() => {
        if (user.ClientGroupSelected) {
            setIsLoading(true)
            setFilteredVaults([])
            setVaults([]);
            setNotFoundMessage('')
            backupGoldService.GetVaults()
                .then(vaults => {
                    if (vaults.length == 0) {
                        setNotFoundMessage('Não foram encontrados dados')
                        setIsLoading(false)
                    } else {
                        setVaults(vaults);
                        setFilteredVaults(vaults);
                        setIsLoading(false)
                    }
                })
                .catch(error => {
                    setIsLoading(false)
                    console.error(error);
                });
        }
    }, [user.ClientGroupSelected, user.refreshFlag]);

    const restore = (row: any) => {
        setFormData({
            titulo: `Restaurar ${row.CofreNumber}`,
            Descricao: `Restaurar backup ${row.TaskName} de ${row.CofreNumber} do dia ${row.BackupDateTime}`,
            gtmType: 3
        });
        setModalVisibleHeyHoCall(true)
    }
    const columns = [
        { field: 'CofreNumber', headerName: 'Cofre', width: "50%" },
        { field: 'TaskName', headerName: 'Tarefa', width: "20%" },
        { field: 'BackupDateTime', headerName: 'Data do backup', width: "30%" }
    ];

    const functionsRow = [
        {
            label: 'Restaurar',
            func: (row: any) => restore(row)
        }
    ];
    const closeButton = {
        show: true,
        onClose: () => setModalVisibleHeyHoCall(false)
    };

    const onSortChange = (sortData) => {
        const { sort } = sortData;

        let sortedVaults = [...filteredVaults].sort((a, b) => {
            if (sort.column === 'BackupDateTime') {
                let dateA = Moment(a[sort.column], "DD/MM/YYYY HH:mm:ss").toDate().getTime();
                let dateB = Moment(b[sort.column], "DD/MM/YYYY HH:mm:ss").toDate().getTime();

                if (!isNaN(dateA) && !isNaN(dateB)) {
                    return sort.direction === 'asc' ? dateA - dateB : dateB - dateA;
                } else {
                    return 0;
                }
            } else {
                let valueA = a[sort.column] || '';
                let valueB = b[sort.column] || '';
                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return sort.direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                } else {
                    return 0;
                }
            }
        });

        setFilteredVaults(sortedVaults);
        setVisibleVaults(sortedVaults.slice(0, filter.size));
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleVaults(filteredVaults.slice(0, size))
        else {
            setVisibleVaults([...visibleVaults, ...filteredVaults.slice(visibleVaults.length, visibleVaults.length + 10)])
        }
    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const [filterVisible, setFilterVisible] = useState(false);
    const filterAdvanced = {
        fields: [
            { label: 'Cofre', name: 'CofreNumber', type: 'text' },
            { label: 'Tarefa', name: 'TaskName', type: 'text' },
            { label: 'Data do backup (intervalo)', name: 'BackupDateTime', type: 'spanDateTime' },
        ],
        onChange: (f: any, size: number) => {
            //setFilter({ ...filter, size: size, term: filter[0].value.toLowerCase() })

            setFilter({
                ...filter,
                CofreNumber: f[0].value,
                TaskName: f[1].value,
                startDate: f[2].value,
                endDate: f[3].value,
            });



        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }

    const card = [
        {
            id: 1,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (

                    <LcLoading loadingType='Helix' loading={isLoading}>
                        {
                            notFoundMessage != '' &&

                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'start', padding: '1rem', alignItems: 'center', marginTop: '0rem', marginLeft: '36%' }}>
                                <i className="lci lci-exclamation lci-2x text-warning" />
                                <span className="font-sm">Não há informações no período</span>
                            </div>
                        }
                        {
                            vaults.length > 0 &&

                            <LcInfiniteTable
                                loading={isLoading}
                                columns={columns}
                                functionsRow={functionsRow}
                                rows={visibleVaults}
                                filter={filterAdvanced}
                                size={filteredVaults.length}
                                loadMore={loadMore}
                                onSortChange={onSortChange}
                                density={pref.data?.density || "high"}
                                disableFilterModal
                            />
                        }

                        {/* <PPModal visible={modalVisibleHeyHoCall} title="Abertura de Chamado" onClose={() => setModalVisibleHeyHoCall(false)}>
                            <HeyhoCall formData={formData} closeButton={closeButton}></HeyhoCall>
                        </PPModal> */}
                    </LcLoading>
                )
            }

        }
    ]


    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={filterAdvanced.fields}
                onFilterChange={handleFilterChange}
            />
        );
    };

    const applyFilter = useCallback(() => {
        let isFilterActive = false;

        if (vaults.length > 0) {
            let { CofreNumber, TaskName, startDate, endDate } = filter;

            let filtered = vaults.filter((vault) => {
                let matchCofreNumber = CofreNumber ? vault.CofreNumber.toLowerCase().includes(CofreNumber.toLowerCase()) : true;
                let matchTaskName = TaskName ? vault.TaskName.toLowerCase().includes(TaskName.toLowerCase()) : true;
                let matchDate = true;

                let vaultDate = Moment(vault.BackupDateTime, "DD/MM/YYYY HH:mm:ss");
                let start = startDate ? Moment(startDate, "YYYY-MM-DD") : null;
                let end = endDate ? Moment(endDate, "YYYY-MM-DD").endOf('day') : null;

                // Considera a data de início se ela estiver preenchida
                if (start) {
                    matchDate = matchDate && vaultDate.isSameOrAfter(start);
                }

                // Considera a data de fim se ela estiver preenchida
                if (end) {
                    matchDate = matchDate && vaultDate.isSameOrBefore(end);
                }

                return matchCofreNumber && matchTaskName && matchDate;
            });

            isFilterActive = filtered.length !== vaults.length;
            setFilteredVaults(filtered);
            setVisibleVaults(filtered.slice(0, filter.size));
        }
        dispatch(setFilterActive(isFilterActive));
    }, [filter, vaults, dispatch]);


    useEffect(() => {
        applyFilter();
    }, [applyFilter]);

    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    const handleFilterChange = (updatedFilters) => {
        let newFilter = { ...filter };

        updatedFilters.forEach(f => {
            if (f.name === 'CofreNumber') {
                newFilter.CofreNumber = f.value ? f.value.toLowerCase() : '';
            } else if (f.name === 'TaskName') {
                newFilter.TaskName = f.value ? f.value.toLowerCase() : '';
            } else if (f.name === 'BackupDateTime') {
                if (f.value && typeof f.value === 'object' && 'startDate' in f.value && 'endDate' in f.value) {
                    newFilter.startDate = f.value.startDate;
                    newFilter.endDate = f.value.endDate;
                } else {
                    newFilter.startDate = '';
                    newFilter.endDate = '';
                }
            }
        });
        setFilter(newFilter);
    };


    return (
        <Layout
            pageTitle="Cofre/Restauração"
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }

            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: filteredVaults.length
            }}

        >

            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                }}
            />
            <HeyHoNewRequest formData={formData} needAtachmente openModal={modalVisibleHeyHoCall} onClose={() => setModalVisibleHeyHoCall(false)} />
        </Layout>
    );
};

export default BackupGoldCofre;