import React, { useEffect, useMemo, useState } from 'react';
import { PiCurrencyCircleDollar, PiPiggyBank } from 'react-icons/pi';
import LcLoading from '../../../Generic/LcLoading';
import IconValue from '../../Dashboard/IconValue';
import { useSelector, useDispatch } from 'react-redux';
import { FinOpsSavingPlanReserveService } from '../../../../services/finOps/FinOpsSavingPlanReserve/FinOpsSavingPlanReserve';
import { RootState } from '../../../../store';
import { CMSEnvironmenetState } from '../../../../store/reducers/CmsEnvironmentReducer';
import { UserState } from '../../../../store/reducers/userReducer';
import { SavingPlanReserveModel } from '../../../../services/finOps/FinOpsSavingPlanReserve/FinOpsSavingPlanReserveModel';
import { OnOffModel } from '../../../../services/cmsPremier/cmsPremierModel';
import { FaAws } from 'react-icons/fa';
import { SiMicrosoftazure } from 'react-icons/si';
import { Tooltip } from '@material-ui/core';
import moment from 'moment';
import { useQuery } from 'react-query';

interface SavingAndReserverProps {
    type: string
    setLoading?: (loading: boolean) => void
    withoutBallon?: boolean
    isHome?: boolean
}

interface QueryResult {
    savingsData: SavingPlanReserveModel[];
    reservasData: SavingPlanReserveModel[];
    savingsCard: { on: number; off: number };
    reservesCard: { on: number; off: number };
}
const translationsFilter =  {
    plan: {
        label: "Plano",
        type: "text",
    },
    alocation: {
        label: "Alocação",
        type: "multiSelect",
    },
    service: {
        label: "Serviço",
        type: "multiSelect",
    },
    scope: {
        label: "Escopo",
        type: "multiSelect",
        topDropdown: true
    },
    region: {
        label: "Região",
        type: "multiSelect",
        topDropdown: true
    },
    // expired_date: {
    //     label: "Expira em",
    //     type: "date",
    // },
};

const columns = [  
        {
            field: "plan", headerName: "Provedor/Plano", width: "28%", align: 'left', renderCell: (row: SavingPlanReserveModel) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', minWidth: '0' }}>
                        <Tooltip arrow title={row.provider === 'Azure' ? 'Microsoft Azure' : 'Amazon AWS'}>
                            <span style={{ minWidth: '20px', paddingLeft: '4px', display: 'flex', alignItems: 'center' }}>
                                {row.provider === 'Azure' ? <SiMicrosoftazure size={'14px'} color='#141B4D' /> : <FaAws size={'16px'} color='#141B4D' />}
                            </span>
                        </Tooltip>
                        <Tooltip arrow title={row.plan}>
                            <span
                                className='overflowEllipsis ml-4'
                            >
                                {row.plan}
                            </span>
                        </Tooltip>
                    </div>
                )
            }
        },
        { field: "type", headerName: "Tipo", width: "10%", align: 'left', },
        {
            field: "alocation", headerName: "Alocação", width: "10%", align: 'left',
            renderCell: (row: SavingPlanReserveModel) => {
                // Função para formatar adequadamente a alocação
                const formatAlocation = (value) => {
                    // Padrão para encontrar valores monetários com ou sem texto adicional
                    const pattern = /^(R\$)\s*(\d+[.,]?\d*)\s*(.*)$/;
                    const match = value?.match(pattern);

                    if (match) {
                        // Formata apenas a parte numérica mantendo qualquer texto adicional
                        const numberPart = parseFloat(match[2].replace(',', '.'));
                        const formattedNumber = numberPart.toLocaleString("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        });
                        return `${formattedNumber}${match[3]}`.trim(); // Reconstrói sem adicionar novamente o R$                        

                    }

                    // Retorna o valor original se não corresponder ao padrão esperado
                    return value;
                };

                const formattedAlocation = formatAlocation(row.alocation);
                return <Tooltip title={row.alocation} arrow><div className='overflowEllipsis'>{formattedAlocation}</div></Tooltip>;
            }
        },

        {
            field: "service", headerName: "Serviço", width: "12%", align: 'left',
            renderCell: (row: SavingPlanReserveModel) => <Tooltip title={row.service} arrow><div className='overflowEllipsis'>{row.service}</div></Tooltip>
        },
        {
            field: "scope", headerName: "Escopo", width: "12%", align: 'left',
            renderCell: (row: SavingPlanReserveModel) => <Tooltip title={row.scope} arrow><div className='overflowEllipsis'>{row.scope}</div></Tooltip>
        },
        {
            field: "region", headerName: "Região", width: "12%", align: 'left',
            renderCell: (row: SavingPlanReserveModel) => <Tooltip title={row.region} arrow><div className='overflowEllipsis'>{row.region}</div></Tooltip>
        },
        {
            field: "commitment", headerName: "Compromisso", width: "8%",
            renderCell: (row: SavingPlanReserveModel) => <Tooltip title={row.commitment} arrow><div className='overflowEllipsis'>{row.commitment}</div></Tooltip>
        },
        {
            field: "use", headerName: "Utilização", width: "8%", align: 'center',
            renderCell: (row: SavingPlanReserveModel) => `${(row.use)}%`
        },
        {
            field: "expired_date", headerName: "Expira em", width: "8%", align: 'left',
            renderCell: (row: SavingPlanReserveModel) => moment(row.expiredDate).year() === 1 ? "-" : moment(row.expiredDate).format('DD/MM/YYYY')
        },
    ]
export const SavingAndReserveInfoCard: React.FC<SavingAndReserverProps> = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const cmsEnvironment = useSelector<RootState, CMSEnvironmenetState>(state => state.cmsEnvironment);
    const dispatch = useDispatch();
    const {data,  isLoading } = useQuery<QueryResult>({
        queryKey: ['savingsAndReserves', user.ClientGroupSelected, user.refreshFlag],
        queryFn: async () => {
            const SavingsPlanReserveService = new FinOpsSavingPlanReserveService({});
            const response = await SavingsPlanReserveService.retrieveList();
            
            const savingsData: SavingPlanReserveModel[] = [];
            const reservasData: SavingPlanReserveModel[] = [];
            const resultSavings = { on: 0, off: 0 };
            const resultReserves = { on: 0, off: 0 };

            if (response && response.length > 0) {
                response.forEach(item => {
                    if (item.type.includes("Savings Plan")) {
                        savingsData.push(item);
                        if (item.status.includes("Ativo")) {
                            resultSavings.on += 1;
                        } else {
                            resultSavings.off += 1;
                        }
                    } else if (item.type.includes("Reserva")) {
                        reservasData.push(item);
                        if (item.status.includes("Ativo")) {
                            resultReserves.on += 1;
                        } else {
                            resultReserves.off += 1;
                        }
                    }
                });
            }

            return {
                savingsData,
                reservasData,
                savingsCard: resultSavings,
                reservesCard: resultReserves
            };
        },
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10, 
        retry: 3,
        onError: (error) => {
            console.error("Error:", error);
        },
    });

    useEffect(() =>{
        if(cmsEnvironment.enviromentFilter === props.type  && !isLoading) {
            let payload = {
                enviromentFilter: props.type,
                columns: columns,
                rowsList: props.type === 'Reservas' ? data?.reservasData : data?.savingsData,
                rowsListFiltered: props.type === 'Reservas' ? data?.reservasData : data?.savingsData,
                funcitonGeneric: undefined,
                translationFilter: translationsFilter
            }
            dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
            props.setLoading && props.setLoading(false)
        }
    } ,[cmsEnvironment.enviromentFilter, isLoading, data]) 

    const handleClickEnviroment = () => {
        let payload = {
            enviromentFilter: props.type,
            columns: columns,
            rowsList: props.type === 'Reservas' ? data?.reservasData : data?.savingsData,
            rowsListFiltered: props.type === 'Reservas' ? data?.reservasData : data?.savingsData,
            funcitonGeneric: undefined,
            translationFilter: translationsFilter
        }
        dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
        props.setLoading && props.setLoading(false)

    } 


    return(
        <LcLoading loading={isLoading}  >
            <IconValue 
                icon={props.type === 'Reservas' ?<PiCurrencyCircleDollar size={32}  /> :<PiPiggyBank size={28} /> }
                value={props.type === 'Reservas' 
                    ? `${((data?.reservesCard?.on ?? 0) + (data?.reservesCard?.off ?? 0))}` 
                    : `${((data?.savingsCard?.on ?? 0) + (data?.savingsCard?.off ?? 0))}`
                }
                color={props.type === 'Reservas' ?"#DA43F2" : "#00DBFF"}
                onClick={props.withoutBallon ? undefined : handleClickEnviroment}
                balonSelected={(cmsEnvironment.enviromentFilter === props.type) && !props.withoutBallon}
                description={props.isHome ? props.type : 'Ativos'}
            />
        </LcLoading>
    );
}
