import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
//CSS
import './index.css';
//Service
import { TechEAEASService, SKUs, LicsModel, License, User } from '../../../services/EASubscriptionService/techEAEASService';
//Components
import Layout from '../../../components/Layout/Layout';
import LcCheckList from '../../../components/Form/LcCheckList';
import LcCheckBox from '../../../components/Form/LcCheckBox';
import { PPModal } from 'processor-plataform-ui'
import { SelectField } from '../../../components/Form/Input';
import LcIconLink from '../../../components/Generic/LcIconLink';
import FilterPanel from '../../../components/Data/FilterPanel';
import { FilterField } from '../../../components/Data/FilterPanel/FilterField';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import LcLoading from '../../../components/Generic/LcLoading';
import { PiArrowsDownUpLight, PiFunnelLight } from 'react-icons/pi';
import LcNoData from '../../../components/Generic/LcNoData';

const SubscriptionBycountryReport: React.FC = (props) => {

    let instance = new TechEAEASService(props);

    const user = useSelector<RootState, UserState>(state => state.user);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
    const [data, setData] = useState<License[]>([]);
    const [dataAux, setDataAux] = useState<License[]>([]);

    const [itemsA, setItemsA] = useState<{ value: string, selected: boolean }[]>([]);
    const [itemsB, setItemsB] = useState<{ value: string, selected: boolean }[]>([]);
    const [itemsC, setItemsC] = useState<{ value: string, selected: boolean }[]>([]);
    const [filtered, setFiltered] = useState<boolean>(false);

    //Modal Filter
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    //Users
    const [users, setUsers] = useState<Array<User>>([]);
    const [modalVisibleUsers, setModalVisibleUsers] = useState<boolean>(false);

    //SoftwaresPorUsuário
    const [softwaresConsumidos, setSoftwaresConsumidos] = useState<Array<SKUs>>([]);
    const [modalVisibleSoftwaresConsumidos, setModalVisibleSoftwaresConsumidos] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<any | undefined>(undefined);
    const [selectedCountry, setSelectedCountry] = useState<any>();
    const [savedFilters, setSavedFilters] = useState<{ [key: string]: any }>({});
    //Order
    const [order, setOrder] = useState(["country", "license", "costCenter"]);
    const [newOrder, setNewOrder] = useState(["country", "license", "costCenter"]);
    const [modalOrderVisible, setModalOrderVisible] = useState(false);

    const getData = () => {
        setData([]);
        setLoading(true);
        user && instance.GetSaasLicenseByCountryTotalView()
            .then(response => {
                setData(response.data);
                setDataAux(response.data)
            })
            .catch(console.warn)
            .then(() => {
                setLoading(false);
            });
    }

    useEffect(getData, [user.ClientGroupSelected, user.refreshFlag]);

    //Disctinc function
    useEffect(() => {
        let _itemsA: { value: string, selected: boolean }[] = [];
        let _itemsB: { value: string, selected: boolean }[] = [];
        let _itemsC: { value: string, selected: boolean }[] = [];

        data.map(license => {
            if (_itemsA.findIndex(c => c.value == license[order[0]]) == -1) {
                _itemsA.push({ value: license[order[0]], selected: true });
            }
            if (_itemsB.findIndex(c => c.value == license[order[1]]) == -1) {
                _itemsB.push({ value: license[order[1]], selected: true });
            }
            if (_itemsC.findIndex(c => c.value == license[order[2]]) == -1) {
                _itemsC.push({ value: license[order[2]], selected: true });
            }
        })

        setItemsA(_itemsA.sort((a, b) => a.value > b.value ? 1 : -1));
        setItemsB(_itemsB.sort((a, b) => a.value > b.value ? 1 : -1));
        setItemsC(_itemsC.sort((a, b) => a.value > b.value ? 1 : -1));
    }, [data]);

    //Carrega os usuários no click do número
    const getUsers = (clientId: string, costcenter: string, country: string, sku: string) => {
        setLoadingUsers(true);
        instance.GetSaasLicenseByCountryTotalViewByUsers(costcenter, country, sku)
            .then(response => {
                setUsers(response.data.sort((a: any, b: any) => (a.userName > b.userName) ? 1 : -1));
                setModalVisibleUsers(true);
            })
            .catch(console.warn)
            .then(() => {
                setLoadingUsers(false);
            })
    }

    //carrega os SKUs no click do usuário
    const getSKUsByUser = (clientGroupId: string, userId: string) => {
        instance.GetSubcribeSKUClientGroup(userId)
            .then(response => {

                let sorted = response.data.sort((a: any, b: any) => (a.description > b.description) ? 1 : -1);

                setSoftwaresConsumidos(sorted);
                setModalVisibleSoftwaresConsumidos(true);
            })
            .catch(console.warn)
    }

    //Modal de softwares utilizados
    const closeModalSoftwares = () => {
        setSelectedUser(undefined);
        setModalVisibleSoftwaresConsumidos(false);
    }
    const handleIsLicensed = (id: number) => {
        const updated = softwaresConsumidos.map((obj, index) =>
            index == id ? { ...obj, isLicensed: !obj.isLicensed } : obj);

        setSoftwaresConsumidos(updated);
    }

    //Salva softwares usados editados
    const saveSoftwaresConsumidos = () => {

        let licsModel: LicsModel = {
            userId: selectedUser.userGraphId,
            nomeUsuario: selectedUser.userName,
            licencas: softwaresConsumidos as any,
            currentLocation: selectedCountry
        }

        instance.PostSKUsByUser(licsModel).then(response => {
            console.debug(response);
        })
    }

    const textToLabel = (text: string) => {
        switch (text) {
            case "country":
                return "País"
            case "license":
                return "Softwares"
            case "costCenter":
                return "Centro de Custo"
            default:
                break;
        }
    }

    const cards: Card[] =[
        {
            id: 1,
            type: 'Custom',
            cssClass: 'transparetBackground',
            centeredContent: false,
            hideHeader: true,
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                <LcLoading loading={loading} loadingType='Helix'>
                    {
                        data.length > 0 ?
                        <div className="lc-pivot-segments" >
                            {
                                itemsA && itemsB && itemsC &&
                                itemsA.map((c, ci) => {
                                    return (
                                        c.selected &&
                                        <div key={ci} className="lc-segment primary">

                                            <span className="title">{c.value}</span>
                                            {
                                                itemsB.map((s, si) => {
                                                    return (

                                                        s.selected &&
                                                        data.filter(l => l[order[0]] == c.value && l[order[1]] == s.value).length > 0 &&

                                                        <div key={si} className="secondary">

                                                            <div className="left">
                                                                <span className="title">{s.value}</span>
                                                                {(() => {
                                                                    const firstCC = data.filter(l => l[order[0]] == c.value && l[order[1]] == s.value)[0];
                                                                    return firstCC ? (<span className={`info ${data.filter(l => l[order[0]] == c.value && l[order[1]] == s.value).reduce((sum, current) => sum + current.totalCC, 0) > firstCC.totalHired ? 'text-danger' : ''}`}>{`Utilizando ${data.filter(l => l[order[0]] == c.value && l[order[1]] == s.value).reduce((sum, current) => sum + current.totalCC, 0)} de ${firstCC.totalHired}`}</span>) : <span className="info">Não há licenças</span>
                                                                })()}
                                                            </div>
                                                            <div className="tertiary scrollable-v">
                                                                <div className="list">
                                                                    {
                                                                        data
                                                                            .filter(l => l[order[0]] == c.value && l[order[1]] == s.value && itemsC.findIndex(cc => cc.selected && cc.value == l[order[2]]) != -1)
                                                                            .map((lf, ilf) => {
                                                                                return (
                                                                                    <div className="item" key={ilf}>
                                                                                        <span>{lf[order[2]]}</span>
                                                                                        <span className="link-text" onClick={() => { getUsers(lf.clientGroupId, lf.costCenter, lf.country, lf.skuPartNumber) }} >{lf.totalCC}</span>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    )

                                })
                            }
                        </div >
                        :
                        <LcNoData label='Não há dados para serem exibidos no momento.' />
                    }
                </LcLoading>
                )
            }
        }
    ]
    const translationsCCFilter = {
        country: {
            label: "Pais",
            type: "multiSelect"
        },
        license: {
            label: "Softwares",
            type: "multiSelect",
        },
        costCenter: {
            label: "Centro de custo",
            type: "multiSelect",
        },
    };
    const filterForCC = () => {
        return (
            <div className="right-sidepanel-filters">
            <FilterPanel
                filter={Object.keys(translationsCCFilter).map(key => ({
                    label: translationsCCFilter[key].label,
                    name: key,
                    type: translationsCCFilter[key].type as FilterField['type'],
                    values: translationsCCFilter[key].values,
                }))}
                data={dataAux}
                onFilteredData={(filteredData) =>{setFiltered(true); setData(filteredData as License[])}}
                translations={translationsCCFilter}
                clearFilters={() => { setFiltered(false); setData(dataAux as License[]) }}
                savedFilters={savedFilters}
                onSaveFilters={setSavedFilters}
            />
            </div>
        )
    }
    
    return (
        <Layout
            pageTitle="Softwares por centro de custo"
            // loading={loading}
            functionsGeneric={
                data.length > 0 ?
                    [
                        { icon: <PiArrowsDownUpLight />, tooltip: "Ordem", onClick: () => setModalOrderVisible(!modalOrderVisible) },
                        // { icon: <PiArrowsClockwiseLight />, tooltip: "Atualizar", onClick: getData },
                        { icon: <PiFunnelLight />, tooltip: "Filtros", onClick: () => setModalVisible(!modalVisible), color: filtered ? 'var(--color-secondary-lime)' : '', }
                    ] : undefined
            }
        >
            <LCDashboard 
                cards={cards}  
                cssClassPanel='scrollable-v'
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: true,
                    show: modalVisible,
                    content: () => filterForCC()
                }} 
            
            />

            <PPModal size='Medium' title="Ordem" visible={modalOrderVisible}
                onClose={setModalOrderVisible}
                functions={[
                    {
                        label: "Salvar", onClick: () => {
                            setModalOrderVisible(false);
                            setOrder(newOrder);
                            getData();
                        },
                        context: "info"
                    },
                    {
                        label: "Fechar",
                        onClick: () => {
                            setModalOrderVisible(false);
                            setNewOrder(order);
                        },
                        context: "grey"
                    }
                ]}
            >
                {
                    newOrder.map((o, i) => {
                        return <div key={i} className='row between mb-5'>
                            <span>{textToLabel(o)}</span>
                            <div className='row'>
                                <LcIconLink disabled={loading || i == 0} icon='ppi ppi-arrow-up'
                                    onClick={() => { setNewOrder([newOrder[i == 1 ? 1 : 0], newOrder[i == 0 ? 1 : i == 1 ? 0 : 2], newOrder[i == 2 ? 1 : 2],]) }}
                                />
                                <LcIconLink disabled={loading || i == 2} icon='ppi ppi-arrow-down'
                                    onClick={() => { setNewOrder([newOrder[i == 0 ? 1 : 0], newOrder[i == 0 ? 0 : i == 1 ? 2 : 1], newOrder[i == 1 ? 1 : 2],]) }}
                                />
                            </div>
                        </div>
                    })
                }
            </PPModal>
            {/* <PPModal title="Filtro" visible={modalVisible} onClose={() => setModalVisible(false)}>
                <PPExpand title={textToLabel(order[0])} marginBotton="15" expanded={true}>
                    {
                        itemsA.length > 0 &&
                        <LcCheckList list={itemsA} toggle={true} onChange={setItemsA} search={true} searchPlaceHolder="(Procure por países)" title="Pais" />
                    }
                </PPExpand>
                <PPExpand title={textToLabel(order[1])} marginBotton="15" expanded={false}>
                    {
                        itemsB.length > 0 &&
                        <LcCheckList list={itemsB} toggle={true} onChange={setItemsB} search={true} searchPlaceHolder="(Procure por softwares)" title="Software" />
                    }
                </PPExpand>
                <PPExpand title={textToLabel(order[2])} expanded={false}>
                    {
                        itemsC.length > 0 &&
                        <LcCheckList list={itemsC} toggle={true} onChange={setItemsC} search={true} searchPlaceHolder="(Procure por centros de custo)" title="Centro de Custo" />
                    }
                </PPExpand>
            </PPModal> */}
            <PPModal title="Usuários" visible={modalVisibleUsers} onClose={() => { setModalVisibleUsers(false) }} >
                {
                    modalVisibleUsers &&
                    users.map((u, ui) => {
                        return (
                            <div key={ui} className="lctaglink" onClick={() => { getSKUsByUser(u.clientGroupId, u.userGraphId); setSelectedUser(u); }}>
                                <div className="text-link">
                                    <span>&nbsp;{u.userName}</span>
                                </div>
                            </div>
                        )
                    })
                }
            </PPModal>
            <PPModal title="Softwares utilizados" size="large" visible={modalVisibleSoftwaresConsumidos} onClose={() => closeModalSoftwares()}>
                {
                    selectedUser &&
                    <>
                        <h4>{selectedUser.userName}</h4>

                        <SelectField label="País" name="pais" value={selectedUser && selectedUser.country} onChange={setSelectedCountry} options={itemsA.map(country => { return { value: country.value, name: country.value } })} />

                        <div className="scrollable-v" style={{ height: 'calc(100vh - 19.5rem)' }}>
                            {
                                softwaresConsumidos &&
                                softwaresConsumidos.map((sc, sci) => {
                                    return (
                                        <div className="lctaglink" key={sci} onClick={() => { handleIsLicensed(sci) }}>
                                            <div>
                                                <LcCheckBox name="islicensed" checked={sc.isLicensed} />
                                                <span className="text-link">&nbsp;{sc.description}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                            <button className="lc-button bg-info" style={{ marginRight: '.5rem' }} onClick={() => setModalVisibleSoftwaresConsumidos(false)}>Fechar</button>
                            <button className="lc-button bg-info" onClick={() => { saveSoftwaresConsumidos() }}>Salvar</button>
                        </div>
                    </>
                }

            </PPModal>
        </Layout >
    );
}

export default SubscriptionBycountryReport;