import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { SavingPlanReserveModel } from '../../../../services/finOps/FinOpsSavingPlanReserve/FinOpsSavingPlanReserveModel';
import moment from 'moment';
import './index.css';

interface Props {
    data: SavingPlanReserveModel
}

const UsedVsNotUsedChart: React.FC<Props> = (props) => {

    const [usedChart, setUsedChart] = useState<string[]>([]);
    const [notusedChart, setNotUsedChart] = useState<string[]>([]);
    const [dateChart, setDateChart] = useState<string[]>([]);


    useEffect(() => {
        let usedData: any[] = props.data.resouces.map(i => ((i.quantityUsed / (i.quantityUsed + i.quantityNotUsed)) * 100).toFixed(1));
        let notusedData: any[] = props.data.resouces.map(i => (i.quantityNotUsed / ((i.quantityNotUsed + i.quantityUsed)) * 100).toFixed(1));
        let dataChart: any[] = props.data.resouces.map(i => formatarData(new Date(i.purchaseDateTime)))
            .sort((a, b) => (moment(a, "DD/MMM") as any) - (moment(b, "DD/MMM") as any))

        if (props.data.provider === 'AWS') {
            usedData = props.data.dailyUsage ? props.data.dailyUsage.map(i => i.usagePercentage) : [];
            notusedData = props.data.dailyUsage ? props.data.dailyUsage.map(i => 100 - i.usagePercentage) : [];
            dataChart = props.data.dailyUsage ? props.data.dailyUsage.map(i => formatarData(new Date(i.usageStart)))
                .sort((a, b) => (moment(a, "DD/MMM") as any) - (moment(b, "DD/MMM") as any)) : []
        }

        setUsedChart(usedData)
        setNotUsedChart(notusedData)
        setDateChart(dataChart)
    }, [props.data])

    function formatarData(data) {
        const meses = [
            'jan', 'fev', 'mar', 'abr', 'mai', 'jun',
            'jul', 'ago', 'set', 'out', 'nov', 'dez'
        ];

        const dia = ('0' + data.getDate()).slice(-2); // Adiciona zero à esquerda e pega os dois últimos dígitos
        const mesAbreviado = meses[data.getMonth()];

        return `${dia}/${mesAbreviado}`;
    }

    const optionsChart = {
        backgroundColor: '#ffffff',
        tooltip: {
            backgroundColor: '#f1f5fb',
            borderWidth: 0,
            textStyle: {
                fontSize: 11,
                fontFamily: 'Ubuntu',
                color: '#2B304F',
            },
            trigger: 'axis',
        },
        grid: {
            top: 15,
            left: 10,
            right: 5,
            bottom: 40,
            containLabel: true
        },
        width: '90%',
        height: '60%',
        toolbox: {
            feature: {
                saveAsImage: false,
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: dateChart,
            axisLabel: {
                fontSize: '11px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
        },
        yAxis: {
            type: 'value',
            max: 100,
            axisLabel: {
                fontSize: '11px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                formatter: '{value}%',
            },
        },
        series: [
            {
                name: 'Utilizado',
                type: 'line',
                data: usedChart,
                smooth: false,
                itemStyle: {
                    color: '#3ebb3f'
                },
                symbol: 'circle',
                symbolSize: 6,
                clip: true,
            }
        ],
        dataZoom: [
            {
                type: 'inside',
                start: 70,
                end: 100,
            },
            {
                type: 'slider',
                start: 70,
                end: 100,
                height: 20,
                bottom: 35,
                handleSize: '80%',
            },
        ],
        graphic: [
            {
                type: 'text',
                left: 'center',
                bottom: 2,
                style: {
                    text: 'Utilize o scroll do mouse para ampliar ou reduzir o zoom. Arraste a barra de zoom acima para ajustar\r\no período visualizado ou clique e arraste no gráfico para navegar entre os dados disponíveis.',
                    font: '12px Ubuntu',
                    fill: '#666',
                },
            },
        ],
    };


    return (
        <div>
            <h4>Percentual utilizado </h4>
            <ReactECharts
                opts={{ renderer: 'svg', locale: 'PT-br' }}
                style={{ maxHeight: '35vh' }}
                option={optionsChart}
            />
        </div>
    )
}

export default UsedVsNotUsedChart;