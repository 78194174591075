import React from 'react';
import { PiMonitorLight } from 'react-icons/pi';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import { LiveWatchService } from '../../../services/LiveWatchServiceGroupService';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

export const LWResourcesCardInfo: React.FC = () => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const service = new LiveWatchService({});
    const { data: hostsResume, isLoading: loadingHostsResume } = useQuery({
        queryKey: ['hostsResume',  user.ClientGroupSelected, user.refreshFlag],
        queryFn: () => service.GetHostsResume(),
        refetchOnWindowFocus: false,

        keepPreviousData: true,
        onError: (error) => {
            console.error('Error fetching hosts resume:', error);
        }
    });

    return (
        <LcLoading loading={loadingHostsResume}>
        {
            hostsResume ?
                <IconValue
                    icon={<PiMonitorLight size={28} />}
                    value={`${hostsResume.enabled} de ${hostsResume.total}`}
                    color='white'
                    description='Monitorados / Total'
                    descriptionColor='white'
                />
                :
                <LcNoData />
        }
    </LcLoading>
    );
}
