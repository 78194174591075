import { PPFormInput } from 'processor-plataform-ui/dist/components/PPForm2';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LcIconLink from '../LcIconLink';
import { PPModal, PPForm2 } from 'processor-plataform-ui';
import { useSnackbar } from 'notistack';
import Attachment from '../../../pages/Heyho/Attachment';
import { HeyHoCallService } from '../../../services/Heyho/heyHoCallService';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import moment from 'moment';
import { HeyHoService } from '../../../services/Heyho/heyHoService';
import HHComponentAI from './HHComponentAI';
import { FormDataHeyho } from '../../../services/Heyho/heyhoModel';

interface Props {
    openModal: boolean;
    formData: FormDataHeyho
    needAtachmente?: boolean;
    enebleIA?: boolean
    onClose: Function
    submittingRequest?: Function
    header?: string,

}

const HeyHoNewRequest: React.FC<Props> = (props) => {
    const ServiceHeyho:HeyHoService  = new HeyHoService(props);
    const user = useSelector<RootState, UserState>(state => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const { enqueueSnackbar } = useSnackbar();
    const [anexos, setAnexos] = useState<File[]>([]);
    const dispatch = useDispatch();
    const [GTMTreeDefault, setGTMTreeDefault] = useState<any>('');
    const [submitting, setSubmitting] = useState(false);
    const [sugestaoExp, setSugestaoExp] = useState<boolean>(false);
    const [updateField, setUpdateField] = useState<boolean>(false);
    const emptyRecordMessage = {
        detail: false,
        edit: false,
        add: false,
        record: ServiceHeyho.emptyRecord
    };
    const [newRequest, setNewRequest] = useState(emptyRecordMessage);
    useEffect(() => {
        setLoading(true);
        const ServiceHeyho:HeyHoService  = new HeyHoService({});
        ServiceHeyho.RetrieveContractsAndGTM();
        if (props.formData.gtmType) {
            let gtmType: any = props.formData?.gtmType;
            const service = new HeyHoCallService({});
            service.RetrieveDefaultChamadoTreeItem(gtmType)
            .then(response => {
                setGTMTreeDefault(response.data);
                setLoading(false);
            })
            .catch(error => {
                    console.error(error);
                    setLoading(false);
                });
        }

        if (props.formData !== null) {
            setNewRequest(prevRequest => ({
                ...prevRequest,
                record: {
                    ...prevRequest.record,
                    titulo: props.formData.titulo,
                    Descricao: props.formData.Descricao
                }
            }));
            setLoading(false);
        }
    }, [props.formData]);


    const fieldsForm: PPFormInput[] = [
        {
            name: 'titulo',
            type: 'text',
            title: 'Assunto',
            required: true,
            value: props.formData.titulo ? props.formData.titulo : ''
        },
        {
            name: 'Descricao',
            type: 'textarea',
            title: 'Descrição detalhada',
            required: true,
            rows: sugestaoExp ? 3 : 7,
            value: props.formData.Descricao ? props.formData.Descricao: ''
        },
    ]

    const onChangeForm = (event) => {
        setNewRequest(prevRequest => ({
            ...prevRequest,
            record: {
                ...prevRequest.record,
                titulo: event.titulo,
                Descricao: event.Descricao
            }
        }));
        props.formData.Descricao = event.Descricao
        props.formData.titulo = event.titulo
        setUpdateField(true)
    }

    async function saveTicket(
        ServiceHeyho: any,
        setNewRequest: React.Dispatch<React.SetStateAction<{ detail: boolean; edit: boolean; add: boolean; record: any; }>>,
        emptyRecordMessage: { detail: boolean; edit: boolean; add: boolean; record: any; },
    ) {
        if (newRequest.record) {
            if (!newRequest.record.Descricao ||  /^\s*$/.test(newRequest.record.Descricao)) {
                enqueueSnackbar(`Erro ao criar o chamado é obrigatorio o preenchimento do campo "Descrição detalhada"`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                })
                return;
            }
            if (!newRequest.record.titulo ||  /^\s*$/.test(newRequest.record.Descricao)) {
                enqueueSnackbar(`Erro ao criar o chamado é obrigatorio o preenchimento do campo "Assunto"`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                })
                return;
            }
        }
        let idSolicitation;
        let idUserRequester
        setLoading(true);
        try {
            if(props.formData.gtmType && GTMTreeDefault !== '') {
                var values = GTMTreeDefault ? GTMTreeDefault?.value.split('_') : null;
                let agendamento = {
                    id: 0,
                    titulo: props.formData.titulo,
                    descricao: props.formData.Descricao,
                    contrato: '0',
                    dataInicio: moment().format('YYYY-MM-DD HH:mm:ss'),
                    duracao: null,
                    idCliente: null,
                    idContratoProduto: null,
                    idUsuario: user.UserId,
                    tipoTarefa: null,
                    situacao: null,
                    dataCriacao: moment().format('YYYY-MM-DD HH:mm:ss'),
                    idSource: null,
                    dataAtualizacao: moment().format('YYYY-MM-DD HH:mm:ss'),
                    status: null,
                    clientGroupId: user.ClientGroupSelected,
                    clientGroup: null,
                    aspNetUsers: null,
                    validationMenssage: null,
                    idService: values ? values[1] : null,
                    idProcess: values ? values[2] : null,
                }
                const service = new HeyHoCallService({});
                await service.Criar(agendamento,[])
                .then((response: any) => {
                        idSolicitation = response
                        enqueueSnackbar(
                            <div style={{ display: 'flex' }}>
                                Chamado {response} criado com sucesso.
                                <LcIconLink
                                    icon={"ppi ppi-duplicate"}
                                    size={'small'}
                                    tooltip={'Copiar número de chamado'}
                                    tooltipPosition={'inline-left'}
                                    onClick={() => navigator.clipboard.writeText(response.toString())}
                                />
                            </div>
                            , {
                                variant: 'success',
                                preventDuplicate: true,
                                persist: false,
                            });
                    setLoading(false);
                }).catch((err: any) => {
                    enqueueSnackbar(`Um erro ocorreu ao criar chamado.`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                    console.error(err);
                    setLoading(false);
                }).finally(() => {
                    setNewRequest({ ...emptyRecordMessage });
                    setLoading(false);
                    dispatch({ type: 'MENU_NEW_REQUEST', payload: false });
                    props.onClose(false);
                    props.submittingRequest && props.submittingRequest(false);

                });
            } else {
                await ServiceHeyho.Save(newRequest.record)
                .then(async (response) => {
                    idSolicitation = response.data.id
                    idUserRequester = response.data.idUserRequester
                    enqueueSnackbar(
                        <div style={{ display: 'flex' }}>
                            Chamado {response.data.id} criado com sucesso.
                            <LcIconLink
                                icon={"ppi ppi-duplicate"}
                                size={'small'}
                                tooltip={'Copiar número de chamado'}
                                tooltipPosition={'inline-left'}
                                onClick={() => navigator.clipboard.writeText(response.data.id.toString())}
                            />
                        </div>
                        , {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        });

                })
                .catch((error) => {
                    enqueueSnackbar(`Erro na criação do chamado.`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                    console.error(error);
                })
                .finally(() => {
                    setNewRequest({ ...emptyRecordMessage });
                    setLoading(false);
                    setUpdateField(false);
                    dispatch({ type: 'MENU_NEW_REQUEST', payload: false });
                    props.onClose(false);
                    props.submittingRequest && props.submittingRequest(false);

                })
            }

        } catch (e) {
            console.error(e);
            setLoading(false);
        }
        await handleFile(idSolicitation, idUserRequester)

    }

    const handleFile = async (id: number, idUserRequester: number) => {
        if (anexos.length > 0) {
            await ServiceHeyho.sendFile(anexos, id,idUserRequester,props.formData.Descricao)
            .then((response2) => {
                if (response2.data === 201) {
                    enqueueSnackbar(`Anexo para o ticket ${id} criado com sucesso.`, {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    });
                }
                else if (response2.data === 400) {
                    enqueueSnackbar(`Erro ao anexar arquivo.`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                }
            })
            .catch((error) => {
                enqueueSnackbar(`Erro ao anexar arquivo.`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                });
                console.warn(error);
            }).finally(() => {
                setAnexos([])
                dispatch({ type: 'MENU_NEW_REQUEST', payload: false });
                setLoading(false);
            })
        }
    }
    const handleSubmit = () => {
        setSubmitting(true);
        saveTicket(ServiceHeyho, setNewRequest, emptyRecordMessage)
            .then(result => {
            })
            .catch(console.warn)
            .then(() => {
                setSubmitting(false);
                props.onClose(false);
            })
    }

    const removeFile = (fileName: string) => {
        const files_ = anexos && anexos.filter(item => item.name !== fileName);
        setAnexos(files_);
    };

    const handleFiles = (files_: any[]) => {
        let _newRequest = newRequest.record;
        _newRequest.Anexo = [...anexos, ...files_];
        setNewRequest({ ...newRequest, record: _newRequest })
        setAnexos([...anexos, ...files_]);
    }

    return(
        <PPModal
            visible={props.openModal}
            onClose={() => {
                props.onClose(false);
                setNewRequest({ ...emptyRecordMessage });
                dispatch({ type: 'MENU_NEW_REQUEST', payload: false });

            }}
            size={props.enebleIA ? 'large' : ''}
            title={props.header ? props.header : 'Novo chamado'}
            loading={loading}
        >
            <div className='newRequestModal scrollable-v'>
                <PPForm2
                    fields={fieldsForm}
                    returnType="object"
                    onChange={onChangeForm}
                    onSubmit={handleSubmit}
                    submitting={submitting}
                    updateFieldsOnChangeOrigin={updateField}
                    functions={
                        {
                            submitButton: {
                                hide: true,
                                label: 'Ok',
                            },
                        }
                    }
                >

                {
                    props.enebleIA &&
                    <HHComponentAI 
                        originalText={props.formData.Descricao} 
                        visible={sugestaoExp} 
                        handleVisible={() => setSugestaoExp(!sugestaoExp)} 
                        setText={(e) =>  { onChangeForm({titulo: props.formData.titulo, Descricao: e})} }
                        setUpdateField={(e) => setUpdateField(e)}
                        formData={props.formData}
                        onChangeForm={(e) => onChangeForm(e)}
                    />
                }
                {
                    props.needAtachmente &&
                    <Attachment
                        anexos={anexos}
                        removeFile={(e) => removeFile(e)}
                        setAnexos={handleFiles}
                        visible={true}
                    />
                }
                <div className="w-100p space-between">
                    <button id='form_novochamado_abrirchamado_button' className='pp-button bg-info' onClick={() =>    saveTicket(ServiceHeyho, setNewRequest, emptyRecordMessage) }>Abrir chamado</button>
                    <button id='form_novochamado_cancelar_button' className='pp-button bg-gray' onClick={() => {  props.onClose(false); setNewRequest({ ...emptyRecordMessage });dispatch({ type: 'MENU_NEW_REQUEST', payload: false }); setUpdateField(false); setSugestaoExp(false);} }>Cancelar</button>

                </div>
                </PPForm2>
            </div>
        </PPModal>
    );
}

export default HeyHoNewRequest;
