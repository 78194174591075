import React, { useState, useEffect, useRef } from 'react';
import { Button, Box, Typography, List, ListItem, Paper, Card, CardContent, Popover } from '@mui/material';
import { FaCommentDots, FaRegCopy } from 'react-icons/fa';
import { Markdown } from 'react-showdown';
import 'eventsource-polyfill';
import IcebreakerQuestions from '../IcebreakerQuestion/IcebreakerQuestions';
import Base64ImageGallery from '../Base64ImageGallery';
import MessageService from '../../../../services/AIx/messages/MessageService';
import ThreadService from '../../../../services/AIx/threads/ThreadService';
import ExpandingTextarea from '../ExpandingTextArea';
import './AIxChatComponent.css';
import { IconButton } from '@material-ui/core';
import moment from 'moment';
import LcLoading from '../../../../components/Generic/LcLoading';
import { MessageResponse, Thread } from '../../../../services/AIx/assistantsTypes';
import { PiChatDotsLight, PiChatTeardropDots, PiChatTeardropDotsLight, PiCopyLight } from 'react-icons/pi';
import { RiChatNewLine } from "react-icons/ri";
import { BsCopy } from 'react-icons/bs';
import { useSnackbar } from 'notistack';
import LcIconLink from '../../../../components/Generic/LcIconLink';

interface ThreadDialogProps {
    assistantId: string;
    assistantName: string;
    eventChat?: (event: any) => void;
    questions?: string[];
}

const AIxChatComponent: React.FC<ThreadDialogProps> = ({
    assistantId,
    assistantName,
    eventChat,
    questions
}) => {
    const [message, setMessage] = useState<string>('');
    const [resetThread, setResetThread] = useState<number>(0);
    const [runRequest, setRunRequest] = useState<number>(0);
    const [messages, setMessages] = useState<MessageResponse[]>([]);
    const [messagesMap, setMessagesMap] = useState<{ [id: string]: MessageResponse }>({});
    const [threadId, setThreadId] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingStream, setLoadingStream] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { enqueueSnackbar } = useSnackbar();
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const endOfMessagesRef = useRef<HTMLDivElement>(null);

    const createOrFetchThread = async () => {
        setLoading(true);
        setMessagesMap({});
        if (!assistantId) return;
        const threadService = new ThreadService({});

        try {
            const storedThreadId = localStorage.getItem(`threadId_${assistantId}`);
            let threadIdToUse = storedThreadId;
            if (threadIdToUse && threadIdToUse.length > 0) {
                setRunRequest(0);
                const messageService = new MessageService({});
                const allMessages = await messageService.listMessagesAIX(threadIdToUse);
                if (allMessages) {
                    setMessagesMap(allMessages.reduce(
                        (map, message) => {
                            map[message.id] = message;
                            return map;
                        },
                        {} as { [id: string]: MessageResponse }
                    ))
                    setMessages(allMessages);
                }
                setThreadId(threadIdToUse);
            }
            else {
                const newThread = {} as Thread;
                const createdThread = await threadService.createThread(newThread);
                if (createdThread && createdThread.id) {
                    setThreadId(createdThread.id);
                    threadIdToUse = createdThread.id;
                    localStorage.setItem(`threadId_${assistantId}`, createdThread.id);
                }
            }
        } catch (error) {
            console.error('Error creating or fetching thread:', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (resetThread && resetThread > 0) {
            localStorage.removeItem(`threadId_${assistantId}`);
            setMessages([] as MessageResponse[]);
            createOrFetchThread();
        }
    }, [resetThread])

    useEffect(() => {
        if (assistantId) {
            createOrFetchThread();
        }
    }, [assistantId,]);

    const [retryCount, setRetryCount] = useState(0);
    const maxRetries = 0; // Maximum number of retry attempts
    const retryDelay = 1500; // Delay between retries in milliseconds
    console.log()
    useEffect(() => {
        if (runRequest === 0) return;

        const baseUrl = process.env.REACT_APP_AIX_LLM_API;
        const LiveCloud_AuthMS = sessionStorage.getItem('LiveCloud_AuthMS');
        const LiveCloud_AuthLC = sessionStorage.getItem('LiveCloud_AuthLC');

        setRetryCount(0); // zerar tentativas de retry, se você ainda usa essa lógica

        // Monta a URL de streaming
        const url = `${baseUrl}/api/threads/stream/${assistantId}/thread/${threadId}?Authorization=${LiveCloud_AuthMS}&AuthorizationPlatform=${LiveCloud_AuthLC}&Platform=1`;
        console.log("Streaming usando fetch/ReadableStream:", url);

        // Controlador para cancelar a requisição se o componente desmontar
        const controller = new AbortController();
        const { signal } = controller;

        // Função principal que abre o stream
        const connectToStream = () => {
 
            setLoadingStream(true);
            fetch(url, {
                headers: {
                    Authorization: `Bearer ${LiveCloud_AuthMS}`,
                    AuthorizationPlatform: `Bearer ${LiveCloud_AuthLC}`,
                    Platform: `1`,
                    PathRoute: window.location.pathname,
                    'Access-Control-Allow-Origin': '*',
                },
                signal
            })
                .then((response) => {
                    try { 
                        if (!response.ok) {
                            setLoadingStream(false);
                            throw new Error("Resposta de rede não OK");
                        }
                        setLoadingStream(true);

                        const reader = response.body?.getReader();
                        const decoder = new TextDecoder();
                        let buffer = '';

                        if (!reader) {
                            console.error('Não foi possível obter o reader do response.body.');
                            setLoadingStream(false);
                            return;
                        }

                        const processStream = () => {
                            reader.read().then(({ done, value }) => {
                                if (done) {
                                    console.log('Stream encerrado pelo servidor ou finalizado.');
                                    setLoadingStream(false);
                                    return;
                                }
                                //
                                // Decodifica o chunk atual
                                buffer += decoder.decode(value, { stream: true });
                                console.log('buffer')
                                console.log(buffer)
                                // Separa por quebras de linha simples (\n).
                                // Cada linha SSE geralmente começa com "data:", "id:", "event:", etc.
                                const lines = buffer.split('\n');
                                console.log('lines')
                                console.log(lines)
                                // Mantemos a última linha (que pode estar incompleta) no buffer
                                buffer = lines.pop() || '';

                                // Processa cada linha completa
                                for (const line of lines) {
                                    const trimmedLine = line.trim();
                                    // Ignora linhas vazias
                                    if (!trimmedLine) continue;

                                    // SSE oficial: somente a linha que inicia com "data:" contém dados de fato
                                    if (trimmedLine.startsWith('data:')) {
                                        // Remove o prefixo "data:"
                                        let dataStr = trimmedLine.substring(5).trim();
                                        console.log(" let dataStr = trimmedLine.substring(5).trim();")
                                        console.log(dataStr)
                                        dataStr = dataStr.substring(5).trim();
                                        // Tenta fazer parse do JSON somente do que vier depois de "data:"
                                        try {
                                            const data = JSON.parse(dataStr);

                                            // Agora tratamos as mensagens conforme seu "object"
                                            if (data.object === 'thread.message') {
                                                if (data.status === "completed") {
                                                    setLoadingStream(false);
                                                }
                                                const completeMessage: MessageResponse = data;
                                                messagesMap[completeMessage.id] = completeMessage;
                                                setMessages(Object.values(messagesMap));

                                            } else if (data.object === 'thread.message.delta') {
                                                const delta = data.delta;
                                                const messageId = data.id;

                                                if (!messagesMap[messageId]) {
                                                    messagesMap[messageId] = {
                                                        id: messageId,
                                                        object: 'message',
                                                        created_at: data.created_at,
                                                        assistant_id: data.assistant_id,
                                                        thread_id: data.thread_id,
                                                        run_id: data.run_id,
                                                        role: data.role,
                                                        content: [],
                                                        attachments: [],
                                                        metadata: {}
                                                    };
                                                }

                                                const contentPart = delta.content[0]?.text?.value || '-';

                                                // Se o chunk contiver [DONE], sinaliza fim do streaming
                                                if (contentPart.includes('[DONE]')) {
                                                    setLoadingStream(false);
                                                }

                                                messagesMap[messageId].content.push({
                                                    type: 'text',
                                                    text: {
                                                        value: contentPart,
                                                        annotations: delta.content[0]?.text?.annotations || []
                                                    }
                                                });

                                                setMessages((prevMessages) => {
                                                    const existingMessageIndex = prevMessages.findIndex((msg) => msg.id === messageId);
                                                    if (existingMessageIndex !== -1) {
                                                        const updatedMessages = [...prevMessages];
                                                        updatedMessages[existingMessageIndex] = messagesMap[messageId];
                                                        return updatedMessages;
                                                    } else {
                                                        return [...prevMessages, messagesMap[messageId]];
                                                    }
                                                });
                                            }
                                        } catch (error) {
                                            console.error('Erro ao fazer parse dos dados do stream (provavelmente chunk parcial ou formato inesperado):', error);
                                            // Se quiser debugar, pode logar o dataStr também:
                                            // console.error('Conteúdo que falhou no parse:', dataStr);
                                        }
                                    } // Fim do if (line.startsWith('data:'))
                                }

                                // Continua lendo o stream
                                processStream();
                            })
                                .catch((err) => {
                                    console.error('Erro ao ler o stream:', err);
                                   // setLoadingStream(false);
                                });
                        };

                        processStream();
                    } catch (e) {
                        console.log(e)
                    }
                })
                .catch((error) => {
                    console.error('Erro no fetch streaming:', error);
                    setLoadingStream(false);
                });
        };

        connectToStream();

        // Cleanup ao desmontar ou refazer o efeito
        return () => {
            controller.abort();
            setLoadingStream(false);
            setLoading(false);
            setMessage('');
        };
    }, [runRequest]);


    useEffect(() => {
        if (endOfMessagesRef.current) {
            endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages]);

    const handleSendMessage = async () => {
        if (!assistantId || !message.trim() || !threadId) { return };
        setLoadingStream(true);
        try {
            const newMessage: MessageResponse = {
                id: `msg_${Date.now()}`,
                object: 'message',
                created_at: Math.floor(Date.now() / 1000),
                assistant_id: assistantId,
                thread_id: threadId,
                run_id: `run_${Date.now()}`,
                role: 'user',
                content: [{ type: 'text', text: { value: message, annotations: [] } }],
                attachments: [],
                metadata: {},
            };

            messagesMap[newMessage.id] = newMessage;
            setMessages((prevMessages) => [...prevMessages, newMessage]);

            const messageService = new MessageService({});
            await messageService.createChat(threadId, assistantId, {
                role: 'user',
                content: message,
            });

            // Limpa o campo de texto após o envio
            setMessage(''); // Isso deve limpar o textarea
            console.log(message);
        } catch (error) {
            console.error('Failed to send message:', error);
        } finally {
            setRunRequest(Math.random());
            setLoading(false);
        }
    };

    const handleSuggestionClick = (suggestedMessage: string) => {
        setMessage(suggestedMessage);
        setAnchorEl(null);
        if (textareaRef.current) {
            textareaRef.current.focus(); // Foca no textarea após selecionar uma pergunta
        }
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const callResetThread = (event: React.MouseEvent<HTMLElement>) => {
        setResetThread((resetThread + 1));
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const successMessage = async (action: string, time: number = 0) => {
        setTimeout(() => {
            enqueueSnackbar(`${action}`, {
                variant: 'success',
                preventDuplicate: true,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            })
        }, time);
    }

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(
            () => successMessage("Texto copiado com sucesso!", 400),
            (err) => console.error('Falha ao copiar o texto: ', err)
        );
    };


    const handleQuestionClick = (selectedQuestion: string) => {
        handleSuggestionClick(selectedQuestion);
    };

    const removeFileCitations = (text: string, annotations: any[]) => {
        let cleanedText = text;
        annotations.forEach((annotation) => {
            if (annotation.type === 'file_citation') {
                cleanedText = cleanedText.replace(annotation.text, '');
            }
        });
        return cleanedText;
    };

    return (
        <Box className="chat-container">
            <div className='list-container'>
                <LcLoading loading={loading}>
                    <List className="message-list scrollable-v">
                        {messages && messages.length > 0 ? (
                            messages.map((msg, index) => (
                                <ListItem key={index} className={`message-item ${msg.role}`}>
                                    <Paper elevation={msg.role === 'user' ? 2 : 1}
                                        className={`message-paper ${msg.role}`} >
                                        {msg.role !== 'user' && (
                                            <Box className="message-header">
                                                <Typography variant="subtitle2" className="assistant-name">
                                                    {assistantName || 'Assistant'}
                                                </Typography>
                                                <div className="message-header-right">
                                                    <Typography variant="caption" className="message-timestamp">
                                                        {msg.created_at && moment(new Date(msg.created_at * 1000)).format("DD/MM/yy HH:mm")}
                                                    </Typography>
                                                    <LcIconLink
                                                        onClick={() =>
                                                            copyToClipboard(msg.content
                                                                    .map((c) => c.text && c.text.value)
                                                                    .join(''))
                                                        }
                                                        size="small"
                                                        className="copy-icon"
                                                        tooltip='Copiar texto'
                                                        tooltipPosition='inline-left'
                                                        icon={<BsCopy fontSize="small" />}
                                                    >

                                                    </LcIconLink>
                                                </div>
                                            </Box>
                                        )}
                                        <Typography component="div" variant="body1" className="message-content">
                                            <Markdown
                                                markdown={
                                                    msg.content &&
                                                    msg.content
                                                        .map((c) => {
                                                            const textValue = c.text?.value || '';
                                                            return c.text?.annotations
                                                                ? removeFileCitations(textValue, c.text.annotations)
                                                                : textValue;
                                                        })
                                                        .join('')
                                                }
                                            />
                                        </Typography>
                                        {/*{msg.attachments && (*/}
                                        {/*    <Base64ImageGallery*/}
                                        {/*        key={index}*/}
                                        {/*        message={msg}*/}
                                        {/*        imageEvent={(eventDATA) => {*/}
                                        {/*            eventChat && eventChat(eventDATA);*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*)}*/}
                                        {msg.content && (
                                            <Base64ImageGallery
                                                key={index}
                                                message={msg}
                                                imageEvent={(eventDATA) => {
                                                    eventChat && eventChat(eventDATA);
                                                }}
                                            />
                                        )}
                                        {/*{msg.outputs && (*/}
                                        {/*    <Base64ImageGallery*/}
                                        {/*        key={index}*/}
                                        {/*        message={msg}*/}
                                        {/*        imageEvent={(eventDATA) => {*/}
                                        {/*            eventChat && eventChat(eventDATA);*/}
                                        {/*        }}*/}
                                        {/*    />*/}
                                        {/*)}*/}
                                    </Paper>
                                </ListItem>
                            ))
                        ) : (
                            <Box className="suggestions-container">
                                <Box className="suggestions-grid">
                                    {questions && questions.map((suggestion, index) => (
                                        <Card
                                            key={index}
                                            onClick={() => handleSuggestionClick(suggestion)}
                                            className="suggestion-card scrollable-v"
                                        >
                                            <CardContent>
                                                {suggestion}
                                            </CardContent>
                                        </Card>
                                    ))}
                                </Box>
                            </Box>
                        )}
                        <div ref={endOfMessagesRef} />
                    </List>
                </LcLoading>
            </div>
            {!loading && <LcLoading loading={loadingStream}>
                <Box className="input-container">
                    <div style={{ display: 'flex' }}>
                        <Button
                            style={{ textTransform: 'none', marginLeft: '10px' }}
                            onClick={handlePopoverOpen}
                            startIcon={<PiChatTeardropDotsLight />}
                            className={anchorEl ? 'darkgrafittibutton' : 'greybutton'}
                        > Mais prompts...
                        </Button>

                        <div>
                            <Button
                                className={anchorEl ? 'darkgrafittibutton' : 'greybutton'}
                                style={{ textTransform: 'none', margin: '0px', marginLeft: '10px' }}
                                onClick={callResetThread}
                                startIcon={<RiChatNewLine className='resetThread' />}
                            >Nova conversa
                            </Button></div>
                    </div>
                    <Popover
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        {questions ?
                            <IcebreakerQuestions questions={questions} onQuestionClick={handleQuestionClick} />
                            : <></>
                        }
                    </Popover>
                </Box>
                <Box className="textarea-container-parent">
                    <ExpandingTextarea
                        value={message}
                        setValue={setMessage}
                        onSend={handleSendMessage}
                        ref={textareaRef}
                    />
                </Box>
            </LcLoading>}
        </Box>
    );
};

export default AIxChatComponent;