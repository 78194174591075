import React, { useEffect } from 'react';
import styles from './IcebreakerQuestions.module.css';
import IcebreakerQuestion from './IcebreakerQuestion';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { PiCaretLeftLight, PiCaretRightLight } from 'react-icons/pi';

interface IcebreakerQuestionsProps {
    questions: string[];
    onQuestionClick: (question: string) => void;
}

const IcebreakerQuestions: React.FC<IcebreakerQuestionsProps> = ({ questions, onQuestionClick }) => {
    const scrollContainer = (direction: 'left' | 'right') => {
        const container = document.getElementById('questionsContainer');
        if (container) {
            const scrollAmount = direction === 'left' ? -150 : 150;
            container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };

    useEffect(() => {
        const container = document.getElementById('questionsContainer');

        if (container) {
            const handleWheel = (event: WheelEvent) => {
                event.preventDefault();
                container.scrollBy({
                    left: event.deltaY < 0 ? -150 : 150,
                });
            };

            container.addEventListener('wheel', handleWheel); 
            return () => {
                container.removeEventListener('wheel', handleWheel);
            };
        }
    }, []);


    return (
        <section className={styles.icebreakerquestionscontainer}>
            <div className={styles.questionsWrapper}>
                <PiCaretLeftLight
                    size={16}
                    className={styles.icon}
                    onClick={() => scrollContainer('left')}
                />
                <div id="questionsContainer" className={styles.questionsContainer}>
                    {questions.map((question, index) => (
                        <IcebreakerQuestion key={index} question={question} onClick={() => onQuestionClick(question)} />
                    ))}
                </div>
                <PiCaretRightLight
                    size={16}
                    className={styles.icon}
                    onClick={() => scrollContainer('right')}
                />
            </div>
            <div className={styles.decorativeArrow}>
            </div>
        </section>
    );
};

export default IcebreakerQuestions;
