import React, { useEffect, useState } from 'react';
import './index.css'
import { HostsAvailability, HostsResume, Incident, LiveWatchService, liveWatchSeverityDic, liveWatchStatusDic } from '../../../services/LiveWatchServiceGroupService';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import Layout from '../../../components/Layout/Layout';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import PPDropDown from '../../../components/PPDropDown';
import DoughNutEChart from '../../../components/Data/Dashboard/DoughnutEchart';
import MessageService from '../../../services/AIx/messages/MessageService';
import AIxChatComponent from '../../../components/aix/ChatClient/AIxChatComponent';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import { LWResourcesCardInfo } from './LWResourcesCardInfo';
import LWRiskIndex from './LWRiskIndex';
import { SecCardProps } from '../../../components/Data/Dashboard/HomeDashboardCard/SecCard';
import { shouldShowLiveWatchCard } from '../../endpointDetectionResponse/XDRCheckpointResume';
import { setFilterActive } from '../../../store/reducers/filterReducer';

interface Props extends SecCardProps {
    isHome: boolean;
    isSecOpsPage: boolean
    secOpsQtdGroups: number;
}

interface FilterData {
    size: number;
    hostname: string;
    trigger_name: string,
    severity: number,
    direction: string;
}

const incidentTypes = [
    { label: 'Categoria', value: 'CAT' },
    { label: 'Severidade', value: 'SEV' },
]

export const incidentPeriods = [
    { label: 'Semana atual', value: 'CURR_WEEK' },
    { label: 'Semana anterior', value: 'PREV_WEEK' },
]
// Formata data em DD/MM/YYYY - HH:MM
const formatAsDateDashTime = (dateString: string): string => {
    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    return `${day}/${month}/${year} - ${hours}:${minutes}`;
}

const renderSeverityCell = (row: any) =>
    <div className='severity-container' style={{ backgroundColor: row.severity ? liveWatchSeverityDic[row.severity][1] : liveWatchSeverityDic[0][1] }}>
        <span style={{ color: row.severity ? liveWatchSeverityDic[row.severity][2] : liveWatchSeverityDic[0][2] }}>
            {row.severity ? liveWatchSeverityDic[row.severity][0] : liveWatchSeverityDic[0][0]}
        </span>
    </div>

const renderHostnameCell = (row: any) => <span className='overflowEllipsis'>{row.hostname}</span>

const renderTriggerNameCell = (row: any) => <span className='overflowEllipsis'>{row.trigger_name}</span>

const renderStatusCell = (row: any) => <span style={{ color: liveWatchStatusDic[row.status][1] }}>{liveWatchStatusDic[row.status][0]}</span>

const questions = [
    "Qual recurso teve o maior percentual de consumo?",
    "Quantas automações tenho em meu ambiente?",
    "Foi detectado algum alerta de consumo?",
    "Quais VMs estão desligadas agora?",
    "Qual a média de uso dos meus recursos?",
    "Quais os principais indicadores?"
];
const LiveWatchSummary: React.FC<Props> = (props) => {

    const service = new LiveWatchService({});
    const messageService = new MessageService({});
    const user = useSelector<RootState, UserState>(state => state.user);
    const dispatch = useDispatch();

    // Incidentes NÃO resolvidos - dados
    const [loadingActiveIncidents, setLoadingActiveIncidents] = useState<boolean>(false);
    const [activeIncidents, setActiveIncidents] = useState<Incident[]>([]);
    const [filteredActiveIncidents, setFilteredActiveIncidents] = useState<Incident[]>([]);
    const [activeIncidentsPanelIsFocused, setActiveIncidentsPanelIsFocused] = useState<boolean>(false);

    // #region Recursos - dados
    const [loadingHostsResume, setLoadingHostsResume] = useState<boolean>(false);
    const [hostsResume, setHostsResume] = useState<HostsResume>();

    const loadHostsResume = () => {
        const service = new LiveWatchService({});
        setLoadingHostsResume(true);
        service.GetHostsResume()
            .then(setHostsResume)
            .catch(console.error)
            .finally(() => setLoadingHostsResume(false));
    }
    useEffect(loadHostsResume, [user.ClientGroupSelected, user.refreshFlag]);
    // #endregion

    // #region Sugestões da IA - dados
    const [loadingAIData, setLoadingAIData] = useState<boolean>(false);
    const [loadingAIAssistant, setLoadingAIAssistant] = useState<boolean>(false);
    const [assistantId, setAssistantId] = useState<string>("");
    const [messageLLMAIx, setMessageLLMAIx] = useState<string[]>([]);
    const [AIXAgentVisible, setAIXAgentVisible] = useState<boolean>(true);

    const getAssitantData = async () => {
        setAssistantId("");
        if (user && user.ClientGroupSelected) {
            setLoadingAIData(true);
            setLoadingAIAssistant(true);
            var assistantid = await messageService.getAssistantId("4", user.ClientGroupSelected?.toString(), "1");
            setAssistantId(assistantid);
            setLoadingAIAssistant(false);

            var Message = await messageService.fast_assistant_Inquery(assistantid,
                "Faça cinco recomendações de prioridade baseado nos arquivos anexados, " +
                "no máximo 3 linhas cada, " +
                "duas recomendações devem ser sobre hosts específicos com severidade entre 4 e 5, " +
                "remova citações do texto resultante, " +
                "inicie cada recomendação com um hífen (-)."
            );

            const topics = Message &&
                Message.messages &&
                Message.messages.length > 0 &&
                Message.messages[0].content &&
                Message.messages[0].content.length > 0 &&
                Message.messages[0].content[0].text &&
                Message.messages[0].content[0].text.value &&
                Message.messages[0].content[0].text.value.split("\n\n- ");

            if (topics && topics.length > 0) {
                topics[0] = topics[0].replace(/^- /, '');
                setMessageLLMAIx(topics);
            } else
                setMessageLLMAIx([]);

            setLoadingAIData(false);
        }
    }
    useEffect(() => { getAssitantData() }, [user.refreshFlag, user.ClientGroupSelected]);
    // #endregion

    // #region Indice de risco - dados
    const [loadingHostsAvailability, setLoadingHostsAvailability] = useState<boolean>(false);
    const [hostsAvailability, setHostsAvailability] = useState<HostsAvailability[]>([]);
    const [filteredHostsAvailability, setFilteredHostsAvailability] = useState<HostsAvailability[]>([]);
    const [riskPercent, setRiskPercent] = useState<number>(0);
    const [hostsAvailabilityPanelIsFocused, setHostsAvailabilityPanelIsFocused] = useState<boolean>(false);
    const [incidentPeriod, setIncidentPeriod] = useState<{ label: string, value: string }>(incidentPeriods[0]);
    const [selectedPeriodLabel, setSelectedPeriodLabel] = useState<string>();

    const loadActiveIncidents = () => {
        setLoadingActiveIncidents(true);
        const service = new LiveWatchService({});
        service.GetActiveIncidents()
            .then((response) => {
                const sortedIncidents = response
                    .map(item => ({
                        ...item,
                        hostgroupNames: item.hostgroups.map(group => group.name).join(", "),
                    }))
                    .sort((a, b) =>
                        b.severity !== a.severity ? b.severity - a.severity :
                            new Date(b.started_at).getTime() - new Date(a.started_at).getTime()
                    );
                setActiveIncidents(sortedIncidents);
                setFilteredActiveIncidents(sortedIncidents);
            })
            .catch(console.error)
            .finally(() => setLoadingActiveIncidents(false));
    }
    useEffect(loadActiveIncidents, [user.ClientGroupSelected, user.refreshFlag]);
    // #endregion

    // #region NÃO resolvidos por categoria/severidade - dados
    const [groupedActiveIncidents, setGroupedActiveIncidents] = useState<{ name: string, value: number }[]>([]);
    const [hostgroupActiveIncidents, setHostgroupActiveIncidents] = useState<{ name: string, value: number }[]>([]);
    const [severityActiveIncidents, setSeverityActiveIncidents] = useState<{ name: string, value: number }[]>([]);
    const [loadingGroupedActiveIncidents, setLoadingGroupedActiveIncidents] = useState<boolean>(false);
    const [incidentType, setIncidentType] = useState<{ label: string, value: string }>(props.isHome || props.isSecOpsPage ? incidentTypes[1] : incidentTypes[0]);

    const loadGroupedActiveIncidents = () => {
        setLoadingGroupedActiveIncidents(true);
        service.GetGroupedActiveIncidents()
            .then((response) => {
                const hostgroups = Object.entries(response.hostgroups).map(([name, value]) => ({ name, value }))
                setHostgroupActiveIncidents(hostgroups);

                const severities = Object.entries(response.severities)
                    .filter(([name, value]) => Number(name) > 1)
                    .map(([name, value]) => ({ name: liveWatchSeverityDic[Number(name)][0], value }))
                setSeverityActiveIncidents(severities);

                if (props.isHome || props.isSecOpsPage)
                    setGroupedActiveIncidents(severities);
                else
                    setGroupedActiveIncidents(hostgroups);
            })
            .catch(console.error)
            .finally(() => setLoadingGroupedActiveIncidents(false));
    }
    useEffect(loadGroupedActiveIncidents, [user.ClientGroupSelected, user.refreshFlag]);

    const filterGroupedActiveIncidentsByType = (type: string) => {
        if (type === 'CAT') setGroupedActiveIncidents(hostgroupActiveIncidents);
        if (type === 'SEV') setGroupedActiveIncidents(severityActiveIncidents);
    }

    useEffect(() => filterGroupedActiveIncidentsByType(incidentType.value), [incidentType]);
    // #endregion

    const dashboardCards: Card[] = [
        {// 1. Recursos
            id: 1,
            type: "Custom",
            showFocusButton: false,
            hideHeader: false,
            bgColor: "var(--color-primary-purple)",
            title: () => <div style={{ color: "white" }} className="lc-title ellipsis">Recursos</div>,
            position: { col: 1, ColSpan: 4, row: 1, RowSpan: 1 },
            customContentRender: () => <LWResourcesCardInfo />
        },
        {// 2. Índice de Risco
            id: 2,
            type: "Custom",
            showFocusButton: riskPercent > 0,
            hideHeader: false,
            infoContent: () => !hostsAvailabilityPanelIsFocused && 'Incidentes da semana',
            title: () =>
                <div className='title-with-selector-container'>
                    <span>{hostsAvailabilityPanelIsFocused ? `Eventos da semana ${selectedPeriodLabel}` : 'Índice de risco'}</span>
                    {
                        hostsAvailabilityPanelIsFocused &&
                        <PPDropDown
                            options={incidentPeriods}
                            value={incidentPeriod}
                            defaultValue={incidentPeriods[0]}
                            onChange={setIncidentPeriod}
                            width='161px'
                        />
                    }
                </div>,
            position: { col: 5, ColSpan: 4, row: 1, RowSpan: 1 },
            customContentRender: (focused?: boolean) => {
                setHostsAvailabilityPanelIsFocused(focused !== undefined && focused === true);
                const isPanelFocused = focused !== undefined && focused === true;
                if (isPanelFocused) setFilteredDataIndex(0);
                return (
                    <LWRiskIndex
                        isPanelFocused={isPanelFocused}
                        incidentPeriod={incidentPeriod}
                        setSelectedPeriodLabel={setSelectedPeriodLabel}
                        newFilteredRows={filteredHostsAvailability}
                        setHostsAvailability={setHostsAvailability}
                        setRiskPercent={setRiskPercent}
                    />
                )
            },
        },
        {// 3. Sugestões da IA
            id: 3,
            type: "Custom",
            showFocusButton: true,
            hideHeader: false,
            title: () => 'Sugestões da IA',
            position: { col: 9, ColSpan: 4, row: 1, RowSpan: 1 },
            customContentRender: (focused?: boolean) =>
                <LcLoading loading={loadingAIData}>
                    {
                        messageLLMAIx.length > 0 ?
                            focused ?
                                messageLLMAIx.map((item, index) =>
                                    <div className='row '>
                                        <i className='ppi ppi-lightning-bolt' style={{ fontSize: '12px' }}></i>
                                        <p className='suggestionAIText' key={index}>{item}</p>
                                    </div>
                                ) : (
                                    <div className='row '>
                                        <i className='ppi ppi-lightning-bolt' style={{ fontSize: '12px' }}></i>
                                        <p className='suggestionAIText'>{messageLLMAIx[0]}</p>
                                    </div>
                                )
                            : <LcNoData />
                    }
                </LcLoading>,
        },
        {// 4. Incidentes NÃO resolvidos
            id: 4,
            type: "Custom",
            showFocusButton: true,
            hideHeader: false,
            centeredContent: false,
            title: () => 'Incidentes NÃO resolvidos',
            position: { col: 1, ColSpan: 8, row: 2, RowSpan: 4, hidden: hostsAvailabilityPanelIsFocused },
            customContentRender: (focused?: boolean) => {
                const panelIsFocused = focused !== undefined && focused === true;
                setActiveIncidentsPanelIsFocused(panelIsFocused);
                if (panelIsFocused) setFilteredDataIndex(1);
                const renderDateCell = (row: any) => formatAsDateDashTime(row.started_at);

                const renderTicketCell = (row: any) => <span style={{ marginRight: '5px' }}>{row.ticket === 'No ticket available.' ? '--' : row.ticket}</span>;
                const renderHostgroupsCell = (row: Incident) => (
                    <span className='overflowEllipsis'>
                        {row.hostgroups.length > 1 ? row.hostgroups.map(hg => hg.name).join(', ') : row.hostgroups[0].name}
                    </span>
                );
                const tableFields = [
                    { field: 'started_at', headerName: 'Data', width: 300, renderCell: renderDateCell },
                    { field: 'hostname', headerName: 'Recurso', width: 800, renderCell: renderHostnameCell },
                    { field: 'severity', headerName: 'Severidade', width: 200, renderCell: renderSeverityCell, align: 'center' }
                ];
                const focusedTableFields = [
                    { field: 'started_at', headerName: 'Data', width: 400, renderCell: renderDateCell },
                    { field: 'hostname', headerName: 'Recurso', width: 800, renderCell: renderHostnameCell },
                    { field: 'hostgroupNames', headerName: 'Grupo', width: 300, renderCell: renderHostgroupsCell },
                    { field: 'trigger_name', headerName: 'Sensor', width: 600, renderCell: renderTriggerNameCell },
                    { field: 'severity', headerName: 'Severidade', width: 400, renderCell: renderSeverityCell, align: 'center' },
                    { field: 'ticket', headerName: 'Ticket', width: 200, renderCell: renderTicketCell, align: 'left' }
                ];
                return (
                    <LcLoading loading={loadingActiveIncidents}>
                        {
                            activeIncidents && filteredActiveIncidents && activeIncidents.length > 0 && filteredActiveIncidents.length > 0 ?
                                <LcInfiniteTable
                                    rows={filteredActiveIncidents}
                                    columns={focused ? focusedTableFields : tableFields}
                                    size={filteredActiveIncidents.length}
                                    hidePagination
                                /> : <LcNoData />
                        }
                    </LcLoading>
                )
            },
        },
        {// 5. NÃO resolvidos por categoria/severidade
            id: 5,
            type: "Custom",
            showFocusButton: false,
            hideHeader: false,
            title: () =>
                <div className='title-with-selector-container'>
                    <span>
                        {props.isHome || props.isSecOpsPage ? "Incidentes NÃO resolvidos" : "NÃO resolvidos por:"}
                    </span>
                    {
                        !props.isHome && !props.isSecOpsPage &&
                        <PPDropDown
                            options={incidentTypes}
                            value={incidentType}
                            defaultValue={incidentTypes[0]}
                            onChange={setIncidentType}
                            width='161px'
                        />
                    }
                </div>,
            position: { col: 9, ColSpan: 4, row: 2, RowSpan: 4, hidden: hostsAvailabilityPanelIsFocused },
            customContentRender: () => {
                const delayShow = (props.isHome || props.isSecOpsPage) && loadingHostsAvailability;
                const totalData = groupedActiveIncidents ? groupedActiveIncidents.reduce((acc, item) => acc + item.value, 0) : 0;
                const chartData = groupedActiveIncidents ?
                    incidentType.value === 'SEV' ?
                        groupedActiveIncidents.map(item => {
                            const severityDicEntry = liveWatchSeverityDic.find(s => s[0] === item.name);
                            const color = severityDicEntry ? severityDicEntry[1] : 'var(--icon-disabled)';
                            return { ...item, itemStyle: { color } }
                        })
                        : [...groupedActiveIncidents]
                    : []
                const categoryChart = (chartData ? <DoughNutEChart
                    ArrayData={chartData}
                    showPercent={false}
                    decimals={0}
                    totalNumber={totalData}
                    legendOrientation={pinnedAIPanel ? 'horizontal' : 'vertical'}
                /> : <LcNoData />);
                const severityChart = (chartData ? <DoughNutEChart
                    ArrayData={chartData}
                    showPercent={false}
                    decimals={0}
                    totalNumber={totalData}
                    legendOrientation={pinnedAIPanel ? 'horizontal' : 'vertical'}
                /> : <LcNoData />);
                return (
                    <LcLoading loading={delayShow && loadingGroupedActiveIncidents}>
                        {
                            props.isHome || props.isSecOpsPage ?
                                <div style={{ height: '-webkit-fill-available' }} >{severityChart}</div>
                                :
                                <>
                                    {incidentType.value === 'CAT' && categoryChart}
                                    {incidentType.value === 'SEV' && severityChart}
                                </>
                        }
                    </LcLoading>
                )
            },
        },
    ];

    // Função para ajustar propriedades dinamicamente
    const adjustCardProperties = (cards: any[]) => {
        return cards.map((card) => {
            // Ajuste específico para o cartão de ID 2
            if (card.id === 2) {
                card.showFocusButton = !(props.isHome || props.isSecOpsPage) && riskPercent > 0;
            }
            // Ajuste de posições com base no contexto
            if (props.isHome) {
                switch (card.id) {
                    case 1:
                        card.position = { col: 1, ColSpan: 1, row: 1, RowSpan: 1 }; // home
                        break;
                    case 2:
                        card.position = { col: 1, ColSpan: 1, row: 2, RowSpan: 1, hidden: !shouldShowLiveWatchCard(props) }; // home
                        break;
                    case 5:
                        card.position = { col: 1, ColSpan: 1, row: (!shouldShowLiveWatchCard(props) ? 2 : 3), RowSpan: 3 }; // home
                        break;
                }
            } else if (props.isSecOpsPage) {
                switch (card.id) {
                    case 1:
                        card.position = { col: 1, ColSpan: 1, row: 1, RowSpan: 1 }; // SecOps
                        break;
                    case 2:
                        card.position = { col: 1, ColSpan: 1, row: 2, RowSpan: 1 }; // SecOps
                        break;
                    case 5:
                        card.position = { col: 1, ColSpan: 1, row: 3, RowSpan: 3 }; // SecOps
                        break;
                }
            }
            return card;
        });
    };

    // Selecionar e ajustar cartões para exibição
    const filteredDashboardCards = props.isHome || props.isSecOpsPage
        ? adjustCardProperties(dashboardCards.filter((card) => card.id != null && [1, 2, 5].includes(card.id)))
        : adjustCardProperties(dashboardCards);

    // #region Filtro
    const defaultFilterValue = { size: 20, hostname: "", trigger_name: "", severity: -1, direction: "asc" };
    const [pinnedAIPanel, setPinnedAIPanel] = useState<boolean>(true);
    const [filterData, setFilterData] = useState<FilterData>(defaultFilterValue);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [showFilterButton, setShowFilterButton] = useState<boolean>(false);
    const [filteredDataIndex, setFilteredDataIndex] = useState<number>(0);

    useEffect(() => setShowFilterButton(hostsAvailabilityPanelIsFocused || activeIncidentsPanelIsFocused),
        [hostsAvailabilityPanelIsFocused, activeIncidentsPanelIsFocused]);

    useEffect(() => {
        setFilterData(defaultFilterValue);
        dispatch(setFilterActive(false));
    }, [filteredDataIndex]);

    const hostAvailabilityFilterStructure = {
        fields: [
            { label: 'Recurso', name: 'hostname', type: 'select', options: [...new Set(hostsAvailability.map(i => i.hostname))].map(i => ({ label: i, value: i })) },
            { label: 'Sensor', name: 'trigger_name', type: 'select', options: [...new Set(hostsAvailability.map(i => i.trigger_name))].map(i => ({ label: i, value: i })) },
            { label: 'Severidade', name: 'severity', type: 'select', options: liveWatchSeverityDic.map((item, index) => ({ label: item[0], value: index })).slice(-2) },
        ],
        onChange: (_filter: any, size: number) => setFilterData({
            ...filterData, size: size,
            hostname: _filter[0].value.toLowerCase(),
            trigger_name: _filter[1].value.toLowerCase()
        }),
        visible: showFilter,
        onChangeVisibility: () => setShowFilter(!showFilter)
    }

    const activeIncidentsFilterStructure = {
        fields: [
            { label: 'Recurso', name: 'hostname', type: 'select', options: [...new Set(activeIncidents.map(i => i.hostname))].map(i => ({ label: i, value: i })) },
            { label: 'Sensor', name: 'trigger_name', type: 'select', options: [...new Set(activeIncidents.map(i => i.trigger_name))].map(i => ({ label: i, value: i })) },
            { label: 'Severidade', name: 'severity', type: 'select', options: liveWatchSeverityDic.map((item, index) => ({ label: item[0], value: index })).slice(-2) },
        ],
        onChange: (_filter: any, size: number) => setFilterData({
            ...filterData, size: size,
            hostname: _filter[0].value.toLowerCase(),
            trigger_name: _filter[1].value.toLowerCase()
        }),
        visible: showFilter,
        onChangeVisibility: () => setShowFilter(!showFilter)
    }

    const filterStructure = [hostAvailabilityFilterStructure, activeIncidentsFilterStructure];

    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={filterStructure[filteredDataIndex].fields}
                onFilterChange={(updatedFilters) => {
                    const newFilter = { ...filterData };
                    updatedFilters.forEach(f => newFilter[f.name] = f.value);
                    setFilterData(newFilter);
                }}
            />
        );
    };
    useEffect(() => {
        const applyFilter = () => {
            const filterAction = (item: any) => {
                const mustFilterHostname = ![defaultFilterValue.hostname, undefined].includes(filterData.hostname) && filterData.hostname.length > 0
                const mustFilterTriggerName = ![defaultFilterValue.trigger_name, undefined].includes(filterData.trigger_name) && filterData.trigger_name.length > 0
                const mustFilterSeverity = ![defaultFilterValue.severity, undefined, ''].includes(filterData.severity)

                if (mustFilterHostname || mustFilterTriggerName || mustFilterSeverity) {
                    const matchesHostname = mustFilterHostname ? item.hostname.toLowerCase().includes(filterData.hostname.toLowerCase()) : true;
                    const matchesTriggerName = mustFilterTriggerName ? item.trigger_name.toLowerCase().includes(filterData.trigger_name.toLowerCase()) : true;
                    const matchesSeverity = mustFilterSeverity ? item.severity === filterData.severity : true;
                    return matchesHostname && matchesTriggerName && matchesSeverity;
                }

                return true;
            };

            if (filteredDataIndex === 0) {
                const filtered = hostsAvailability.filter(filterAction);
                setFilteredHostsAvailability(filtered);
            }

            if (filteredDataIndex === 1) {
                const filtered = activeIncidents.filter(filterAction);
                setFilteredActiveIncidents(filtered);
            }

        }
        applyFilter();
    }, [
        activeIncidents, defaultFilterValue.hostname, defaultFilterValue.severity,
        defaultFilterValue.trigger_name, filterData.hostname, filterData.severity,
        filterData.trigger_name, filteredDataIndex, hostsAvailability
    ]);

    const gridFilter = {
        toggleVisibility: () => {
            setShowFilter(!showFilter)
        },
        size: [filteredHostsAvailability.length, filteredActiveIncidents.length][filteredDataIndex]
    }

    const dashboardRightPanelFilter = {
        title: 'Filtros',
        pinned: false,
        show: showFilter,
        content: filterSystem
    }
    //#endregion Filtro

    //#region Assistente IA
    const layoutFunctions = [{
        icon: (
            <LcIconLink
                icon='ppi ppi-lightning-bolt'
                id='aiLCChamados'
                size='small'
                degrade
                tooltip="LiveWatch AI"
                tooltipPosition="right"
            />
        ),
        onClick: () => !loadingAIAssistant && setAIXAgentVisible(!AIXAgentVisible),
        tooltip: "LiveWatch AI"
    }]

    const dashboardRightPanelAI = {
        title: 'LiveCloud AI',
        titleClass: 'ai-gradient-text',
        pinned: true,
        hiddenPin: false,
        show: AIXAgentVisible,
        focusable: true,
        togglePinned: () => setPinnedAIPanel(!pinnedAIPanel),
        content: () => (
            <LcLoading loading={loadingAIData || loadingAIAssistant}>
                <div style={{ alignSelf: 'center' }}>
                    <AIxChatComponent questions={questions} eventChat={(_event) => { }} assistantId={assistantId} assistantName={"LiveWatch"} />
                </div>
            </LcLoading>
        ),
    }
    //#endregion Assistente IA

    return (props.isHome || props.isSecOpsPage) ? (
        <LCDashboard
            isHomeType
            cssClass={props.secOpsQtdGroups === 1 ? '' : shouldShowLiveWatchCard(props) ? 'LiveWatchHomeWithXDR' : 'LiveWatchHome'}
            grid={shouldShowLiveWatchCard(props) ? [3, 1] : props.isHome ? [2, 1] : [4, 1]}
            cards={filteredDashboardCards}
        />
    ) : (
        <Layout
            pageTitle="Resumo"
            functionsGeneric={showFilterButton ? undefined : layoutFunctions}
            gridFilter={showFilterButton ? gridFilter : undefined}
        >
            <LCDashboard
                cards={filteredDashboardCards}
                rightSidePanel={showFilterButton ? dashboardRightPanelFilter : dashboardRightPanelAI}
            />
        </Layout>
    );
};

export default LiveWatchSummary;
