import React, { FC, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'


import Layout from '../../../components/Layout/Layout'
import { FinopsRecommendationsService, Info_Items_Options, Organized_Recommendations_Infos } from '../../../services/finOps/finOpsRecommendations'
import { getRecomendationsInfos } from '../recomendations/Columns'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store'
import { UserState } from '../../../store/reducers/userReducer'
import { getDivider } from '../../../services/toolsService'
import IconValue from '../../../components/Layout/Dashboard/IconValue'
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard'
import LcLoading from '../../../components/Generic/LcLoading';
import styles from '../../gtmConfiguration/index.module.css';
import { PiArrowSquareOutLight, PiCurrencyCircleDollarLight, PiPiggyBankLight, PiPresentationChartLight, PiShieldCheckLight } from 'react-icons/pi'
import HeyHoNewRequest from '../../../components/Generic/HeyHoNewRequest'


export const RecommendationsInsights: FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const history = useHistory();
    const recomendationService = new FinopsRecommendationsService(props);
    const [isLoading, setIsLoading] = useState(true);
    const [allDataInfos, setAllDataInfos] = useState<any>();
    const [greaterSaving, setGreaterSaving] = useState<string>("");
    const [monthSavingDivideBy, setMonthSavingDivideBy] = useState(1);
    const [yearSavingDivideBy, setYearSavingDivideBy] = useState(1);
    const [totalConsumptionDivideBy, setTotalConsumptionDivideBy] = useState(1);
    const [modalRequestOpen, setModalRequestOpen] = useState<boolean>(false);

    useEffect(() => {
        getDashboard();
    }, [user.ClientGroupSelected])

    useEffect(() => {
        if (allDataInfos) {
            setMonthSavingDivideBy(getDivider(allDataInfos.total_saving));
            setYearSavingDivideBy(getDivider(allDataInfos.total_saving * 12));
            setTotalConsumptionDivideBy(getDivider(allDataInfos.total_consumption));
        }

    }, [allDataInfos])

    const getDashboard = async () => {
        setIsLoading(true);

        await recomendationService.GetRecommendationsDashboard()
            .then((response) => {
                setAllDataInfos(response);
            })
            .catch((error) => {
                console.warn(error);
            })
            .then(() => {
            })

        await recomendationService.GetAllRecommendationsInfos()
            .then((response) => {
                let greaterSaving: Organized_Recommendations_Infos = response.sort((element1, element2) => { return element2.saving - element1.saving })[0]
                let info: Info_Items_Options = {
                    optimization_name: greaterSaving.optimization_name,
                    infos: greaterSaving.organizations_Infos,
                    items: [],
                    options: greaterSaving.optimizations_Options
                }
                let optmizationInfos = getRecomendationsInfos(info);
                setGreaterSaving(optmizationInfos.definition);
            })
            .catch((error) => {
                console.warn(error);
                setIsLoading(false)
            })

        setIsLoading(false)
    }

    const cards = 
    [
        {
            type: 'Custom', position: { ColSpan: 6, RowSpan: 1, row: 1, col: 1 },
            ticketButtonLabel: "Ir para otimizações ",
            ticketButton: () => <PiArrowSquareOutLight onClick={() => { history.push("/FinOps/AdvancedRecommendations/cost") }} />,
            showFocusButton: false,
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Otimizações de custo</div>
                </div>
            ),
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={isLoading}>
                     { 
                       allDataInfos ?
                        <IconValue
                            onClick={() => { history.push("/FinOps/AdvancedRecommendations/cost") }}
                            value={allDataInfos.cost_recomendations_count}
                            formatValue={true}
                            color='var(--color-secondary-green-light)'
                            icon={<PiCurrencyCircleDollarLight size={32}/>}
                        />
                        :
                        <p className={styles.errorMessage}> Não conseguimos localizar informações sobre o consumo.</p>
                    }
                    </LcLoading>
                )
            }
        },
        {
            type: 'Custom', position: { ColSpan: 6, RowSpan: 1, row: 1, col: 7 },
            ticketButtonLabel: "Ir para segurança ",
            showFocusButton: false,
            ticketButton: () => <PiArrowSquareOutLight onClick={() => { history.push("/FinOps/AdvancedRecommendations/security") }} />,
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Recomendações de segurança</div>
                </div>
            ),
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={isLoading}>
                        {
                            allDataInfos ?
                            <IconValue
                            // onClick={() => { history.push("/FinOps/AdvancedRecommendations/security") }}
                            value={allDataInfos.security_recomendations_count}
                            formatValue={true}
                            color='var(--color-primary-pink)'
                            icon={<PiShieldCheckLight size={32}/>}
                            />
                            :
                            <p className={styles.errorMessage}> Não conseguimos localizar informações sobre o consumo.</p>
                        }
                    </LcLoading>
                )
        }
        },
        {
            type: 'Custom', position: { ColSpan: 4, RowSpan: 2, row: 2, col: 1 },
            ticketButtonLabel: "Ir para resumo ",
            ticketButton: () => <PiArrowSquareOutLight onClick={() => { history.push("/FinOps/resume") }} />,
            showFocusButton: false,
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Total consumido este mês</div>
                </div>
            ),
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={isLoading}>
                         {
                            allDataInfos ?
                            <IconValue
                                description={totalConsumptionDivideBy == 1 ? 'reais' : totalConsumptionDivideBy == 1000 ? 'mil reais' : 'mi reais'}
                                value={allDataInfos.total_consumption / totalConsumptionDivideBy}
                                maximumFractionDigits={1}
                                formatValue={true}
                                icon={<PiPresentationChartLight size={32}/>}
                                color='var(--color-primary-purple)'

                            /> 
                            :
                            <p className={styles.errorMessage}> Não conseguimos localizar informações sobre o consumo.</p>
                         }
                    </LcLoading>
                )
            }
        },
        {
            type: 'Custom', position: { ColSpan: 4, RowSpan: 2, row: 2, col: 5 },
            ticketButtonLabel: "Ir para otimizações ",
            ticketButton: () => <PiArrowSquareOutLight onClick={() => { history.push("/FinOps/AdvancedRecommendations/cost") }} />,
            showFocusButton: false,
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Economia estimada possível em 12 meses</div>
                </div>
            ),
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={isLoading}>
                         {
                            allDataInfos ?
                            <IconValue
                                description={yearSavingDivideBy == 1 ? 'reais' : yearSavingDivideBy == 1000 ? 'mil reais' : 'mi reais'}
                                value={(allDataInfos.total_saving * 12) / yearSavingDivideBy}
                                maximumFractionDigits={1}
                                formatValue={true}
                                icon={<PiPiggyBankLight size={32} />}
                                color='var(--color-secondary-green-dark)'

                            />
                            :
                            <p className={styles.errorMessage}> Não conseguimos localizar informações sobre o consumo.</p>
                         }
                    </LcLoading>
                )
            }
        },
        {
            type: 'Custom', position: { ColSpan: 4, RowSpan: 2, row: 2, col: 9 },
            ticketButtonLabel: "Ir para otimizações ",
            ticketButton: () => <PiArrowSquareOutLight onClick={() => { history.push("/FinOps/AdvancedRecommendations/cost") }} />,
            showFocusButton: false,
            title: () => (
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Economia estimada possível mensal</div>
                </div>
            ),
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={isLoading}>
                        {
                            allDataInfos ?
                            <IconValue
                                description={monthSavingDivideBy == 1 ? 'reais' : monthSavingDivideBy == 1000 ? 'mil reais' : 'mi reais'}
                                value={allDataInfos.total_saving / monthSavingDivideBy}
                                maximumFractionDigits={1}
                                formatValue={true}
                                icon={<PiPiggyBankLight size={32} />}
                                color='var(--color-primary-magenta)'                                

                            />
                            :
                            <p className={styles.errorMessage}> Não conseguimos localizar informações sobre o consumo.</p>
                        }
                    </LcLoading>
                )
            }
        },
        {
            type: 'Custom', position: { ColSpan: 12, RowSpan: 1, row: 4, col: 1 },
            showFocusButton: false,
            title: () => 'Destaque de economia mensal',
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={isLoading}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '16px',
                            marginBottom: '32px',
                            fontSize: '14px',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center'
                        }}>
                            {/* Destaque de economia possível:&nbsp;<br /> */}
                            {greaterSaving}<br />
                            <a className='link-text' onClick={() => setModalRequestOpen(true)}>
                                Abra um chamado para sua efetivação
                            </a>
                        </div>
                    </LcLoading>

                )
            }
        },
    ]

    return (
        <Layout pageTitle='Insights'>
            <LCDashboard cards={cards} />
            <HeyHoNewRequest openModal={modalRequestOpen} formData={{
                titulo: '',
                Descricao: ''
            }} onClose={() => setModalRequestOpen(false)} />
        </Layout>
    )
}