import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom'
//Redux
import { useSelector, useDispatch } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
//Componentes
import Layout from '../../../components/Layout/Layout';
import LcLoading from '../../../components/Generic/LcLoading';
import LcCheckList from '../../../components/Form/LcCheckList';
import LcCheckBox from '../../../components/Form/LcCheckBox';
import { SelectField } from '../../../components/Form/Input';
import Flags from '../../../components/Data/Flags';
import { PiCurrencyCircleDollar, PiFunnelLight, PiUser } from 'react-icons/pi';
import SideModal from '../../../components/Layout/SideModal/SideModal';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcAccordion from '../../../components/Generic/LcAccordion';
//Serviços
import { LicsModel, TechEAEASService, SKUs, User } from '../../../services/EASubscriptionService/techEAEASService';
import { NotificationCenterService } from "../../../services/notificationCenter/notificationService";
import { VariableSizeList as List } from 'react-window';
import { PPIconLink, PPModal } from 'processor-plataform-ui';
import LcNoData from '../../../components/Generic/LcNoData';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import FilterPanel from '../../../components/Data/FilterPanel';
import { FilterField } from '../../../components/Data/FilterPanel/FilterField';

const FormatNumber = (value: number) => {
    //Carrega a formatação decimal de acordo com a lingua do browser
    return new Intl.NumberFormat(navigator.language, { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
}

const removeDuplicates = (data) => {
    return data.reduce((acc, current) => {
        const x = acc.find(item => item.license == current.license && item.country == current.country);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
};

const removeDuplicateswithCC = (data) => {
    return data.reduce((acc, current) => {
        const x = acc.find(item => item.license == current.license && item.country == current.country && item.costCenter == current.costCenter);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
};

interface RowProps {
    data: any;
    index: number;
    isScrolling?: boolean;
    style: any;
    softwares: { value: string, selected: boolean }[];
    getUsers: (clientId: string, costcenter: string, country: string, sku: string) => void;
    FormatNumber: (value: number) => string;
    sortData: (data: any[], config: any) => any[];
}

const Row: React.FC<RowProps> = React.memo(({ data, index, isScrolling, style, softwares, getUsers, FormatNumber, sortData }) => {

    const cc = data.array[index];
    const country = data.countryL;

    if (!cc) {
        return <div key={index} style={style}> </div>
    }

    const selectedSoftwaresSet = new Set(softwares.filter(n => n.selected).map(n => n.value));

    const _licenses = sortData(
        data.currentData.filter(o =>
            o.country.toUpperCase() == country.value &&
            o.costCenter == cc.value &&
            selectedSoftwaresSet.has(o.license)
        ),
        data.sortConfig
    );

    let totalAmount = 0;
    let totalValue = 0;

    _licenses.forEach(license => {
        totalAmount += license.totalCC;
        totalValue += license.totalCC * license.unitPrice;
    });

    return (
        <div key={index} style={style}>
            {
                _licenses.map((license, lIndex) => (
                    <div key={lIndex} className="p-3 row-ea font-4x">
                        <div>{license.license}</div>
                        <div className="text-right">
                            {license.totalCC > 0 ? (
                                <span className="link-text" onClick={() => getUsers(license.clientGroupId, license.costCenter, license.country, license.skuPartNumber)}>
                                    {license.totalCC}
                                </span>
                            ) : (
                                <span>{license.totalCC}</span>
                            )}
                        </div>
                        <div className="text-right">{FormatNumber(license.unitPrice)}</div>
                        <div className="text-right">{FormatNumber(license.totalCC * license.unitPrice)}</div>
                    </div>
                ))
            }
        </div>
    );
});

const EABudget: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const dispatch = useDispatch();

    const notification = new NotificationCenterService(props);
    let saaSGroupService = new TechEAEASService(props);

    const [loading, setLoading] = useState<boolean>(false);
    const [lcLoading, setLcLoading] = useState(true);


    const [dataList, setDataList] = useState<any[]>([]);
    const [dataListAux, setDataListAux] = useState<any[]>([]);

    const [countries, setCountries] = useState<{ value: string, selected: boolean }[]>([]);
    const [softwares, setSoftwares] = useState<{ value: string, selected: boolean }[]>([]);
    const [costCenters, setCostCenters] = useState<{ value: string, selected: boolean }[]>([]);

    const [modalVisibleUsers, setModalVisibleUsers] = useState<boolean>(false);
    const [users, setUsers] = useState<Array<User>>([]);
    const [softwaresConsumidos, setSoftwaresConsumidos] = useState<Array<SKUs>>([]);
    const [modalVisibleSoftwaresConsumidos, setModalVisibleSoftwaresConsumidos] = useState<boolean>(false);
    const [selectedUser, setSelectedUser] = useState<any | undefined>(undefined);
    const [selectedCountry, setSelectedCountry] = useState<any>();
    const [hasData, setHasData] = useState<boolean | null>(null);
    const [filterVisible, setFilterVisible] = useState<boolean>(false);
    const [requestCount, setRequestCount] = useState(0);

    const [savedFilters, setSavedFilters] = useState<{ [key: string]: any }>({});
    const countryOptions = useMemo(() => countries.map(country => ({ value: country.value, name: country.value })), [countries]);
    const [filtered, setFiltered] = useState<boolean>(false)

    const sortedUsers = useMemo(() => {
        return users.sort((a, b) => {
            let nameA = a.userName.toUpperCase();
            let nameB = b.userName.toUpperCase();
            return nameA < nameB ? -1 : nameA > nameB ? 1 : 0;
        });
    }, [users]);


    const [sortConfigs, setSortConfigs] = useState<{ [key: string]: SortConfig }>({});

    type SortDirection = 'ascending' | 'descending';

    type SortConfig = {
        key: string | null;
        direction: SortDirection;
    };

    function sortData(data, config) {
        if (!config || !config.key) return data;

        const sortedData = [...data].sort((a, b) => {
            if (a[config.key] < b[config.key]) return -1;
            if (a[config.key] > b[config.key]) return 1;
            return 0;
        });

        if (config.direction == 'descending') {
            sortedData.reverse();
        }

        return sortedData;
    }

    // useEffect(() => {
    //     // Forçar uma nova renderização ou qualquer outra lógica após a atualização de sortConfigs
    // }, [sortConfigs]);


    const loadData = () => {
        setLoading(true);
        setLcLoading(false);


        if (locationXC.pathname === '/eaBudgetsByLicenseAndUser') {
            setRequestCount(prev => prev + 1); // Incrementa o contador

            user && saaSGroupService.GetSaasLicenseByCountryTotalViewbyUser()
                .then(response => {
                    const uniqueData = removeDuplicates(response.data);
                    setDataList(uniqueData);
                    setDataListAux(uniqueData);
                    setLcLoading(false);
                    setHasData(uniqueData.length > 0);
                })
                .catch(error => {
                    console.log("API call failed:", error);
                    console.warn(error);
                })
                .finally(() => {
                    setRequestCount(prev => prev - 1); // Diminui o contador
                });
        }

        if (locationXC.pathname === '/eaBudgetsByLicense') {
            setRequestCount(prev => prev + 1); // Incrementa o contador

            user && saaSGroupService.GetSaasLicenseByCountryTotalView()
                .then(response => {
                    const uniqueData = removeDuplicateswithCC(response.data);
                    setDataList(uniqueData);
                    setDataListAux(uniqueData);
                    setLcLoading(false);
                    setHasData(uniqueData.length > 0);
                })
                .catch(error => {
                    console.log("API call failed:", error);
                    console.warn(error);
                })
                .finally(() => {
                    setRequestCount(prev => prev - 1); // Diminui o contador
                });
        }
    }


    useEffect(() => {
        console.log("Request count:", requestCount);
        if (requestCount === 0) {
            setLoading(false);
        }
    }, [requestCount]);


    const getItemSize = ({ data, index }) => {
        const cc = data?.array?.[index];
        const country = data?.countryL;

        if (!cc) {
            return 130;
        }

        const licenseCount = dataList?.reduce((count, o) => {
            const softwareMatch = softwares.some(n => n.value === o.license && n.selected);
            if (o.country.toUpperCase() == country.value && o.costCenter === cc.value && softwareMatch) {
                count++;
            }
            return count;
        }, 0);

        return licenseCount * 75;
    }

    // const handleCloseModalVisibleUsers = useCallback(() => {
    //     setModalVisibleUsers(false);
    // }, []);

    // const handleFilterVisible = useCallback(() => {
    //     setFilterVisible(!filterVisible);
    // }, []);

    useEffect(() => {
        let msg = {
            subject: "Aviso",
            content: "Todos valores e contextos aqui são estimativos e sujeitos a confirmação conforme politica vigente do detentor dos direitos das tecnologias listadas",
            context: "info"
        };
        notification.PostInterfaceNotification(dispatch, msg);
    }, [dataList])
    const locationXC = useLocation();

    //Disctinc function
    useEffect(() => {
        const countrySet = new Set<string>();
        const softwareSet = new Set<string>();
        const costCenterSet = new Set<string>();

        dataList?.forEach(license => {
            countrySet.add(license.country.toUpperCase());
            softwareSet.add(license.license);
            costCenterSet.add(license.costCenter);
        });

        setCountries([...countrySet].map(country => ({ value: country.toUpperCase(), selected: true })));
        setSoftwares([...softwareSet].map(software => ({ value: software, selected: true })));
        setCostCenters([...costCenterSet].map(costCenter => ({ value: costCenter, selected: true })));
    }, [dataList]);

    useEffect(loadData, [user.ClientGroupSelected, user.refreshFlag, locationXC.pathname]);


    //Carrega os usuários no click do número
    const getUsers = useCallback((clientId, costcenter, country, sku) => {
        saaSGroupService.GetSaasLicenseByCountryTotalViewByUsers(costcenter, country, sku).then(response => {
            setUsers(response.data);
            setModalVisibleUsers(true);
        }).catch((i) => { });
    }, [saaSGroupService]);

    //carrega os SKUs no click do usuário
    const getSKUsByUser = (clientGroupId: string, userId: string) => {
        saaSGroupService.GetSubcribeSKUClientGroup(userId).then(response => {

            let sorted = response.data.sort((a: any, b: any) => (a.description > b.description) ? 1 : -1);

            setSoftwaresConsumidos(sorted);
            setModalVisibleSoftwaresConsumidos(true);
        })
    }

    //Modal de softwares utilizados
    const closeModalSoftwares = () => {
        setSelectedUser(undefined);
        setModalVisibleSoftwaresConsumidos(false);
    }

    const handleIsLicensed = (id: number) => {
        const updated = softwaresConsumidos.map((obj, index) =>
            index == id ? { ...obj, isLicensed: !obj.isLicensed } : obj);

        setSoftwaresConsumidos(updated);
    }

    const handleGetSKUsByUser = useCallback((clientGroupId, userGraphId, user) => {
        getSKUsByUser(clientGroupId, userGraphId);
        setSelectedUser(user);
    }, [getSKUsByUser, setSelectedUser]);

    const handleCloseModalSoftwares = useCallback(() => {
        closeModalSoftwares();
    }, [closeModalSoftwares]);

    //Salva softwares usados editados
    const saveSoftwaresConsumidos = () => {

        let licsModel: LicsModel = {
            userId: selectedUser.userGraphId,
            nomeUsuario: selectedUser.userName,
            licencas: softwaresConsumidos as any,
            currentLocation: selectedCountry
        }

        saaSGroupService.PostSKUsByUser(licsModel).then(response => {
            console.log(response);
        })
    }
    const translationsBudgetFilter = {
        country: {
            label: "Pais",
            type: "multiSelect"
        },
        license: {
            label: "Softwares",
            type: "multiSelect",
        },
        costCenter: {
            label: "Centro de custo",
            type: "multiSelect",
        },
    };

    const filterForBudget = () => {
        return (
            <div className="right-sidepanel-filters">
            <FilterPanel
                filter={Object.keys(translationsBudgetFilter).map(key => ({
                    label: translationsBudgetFilter[key].label,
                    name: key,
                    type: translationsBudgetFilter[key].type as FilterField['type'],
                    values: translationsBudgetFilter[key].values,
                }))}
                data={dataListAux}
                onFilteredData={(filteredData) =>{setFiltered(true); setDataList(filteredData) }}
                translations={translationsBudgetFilter}
                clearFilters={() => { setFiltered(false);setDataList(dataListAux) }}
                savedFilters={savedFilters}
                onSaveFilters={setSavedFilters}
            />
            </div>
        )
    }

    
    const card:Card[] = [ 
        {
            id: 1,
            type: 'Custom',
            cssClass: 'transparetBackground',
            centeredContent: false,
            hideHeader: true,
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={loading} loadingType='Helix'>
                    {
                        !loading && hasData === false &&
                        //<div className="fullspace">
                        //    <div className="centralized-row">
                        //        <i className="font-7x lci lci-exclamation text-warning mr-3"></i>
                        //        <span className="text-center text-primary font-5x">
                        //            Não há dados para gerar o orçamento.
                        //        </span>
                        //    </div>
                        //</div>
                        <LcNoData label="Não há dados a serem exibidos no momento." />
                    }
    
                    <div className="cards-ea">
                        {
                            softwares && countries.length > 0 &&
                            countries
                                .filter(country => country.selected && dataList?.find(p => p.country.toUpperCase() == country.value && p.totalUsed > 0))
                                .map((country, cI) => {
                                    return (
                                        <div key={cI} className="card">
                                            <h4 className="title-country"><Flags countryCode={country.value} /> {country.value}</h4>
                                            <div className="scrollable-v">
                                                {
                                                    softwares.length > 0 && costCenters
                                                        .filter(o => o.selected && dataList?.find(p => p.country == country.value
                                                            && p.costCenter == o.value && p.totalUsed > 0 && softwares.filter(n => n.value == p.license && n.selected).length > 0))
                                                        .map((cc, ccIndex) => {
                                                            const currentData = sortData(
                                                                dataList?.filter(item => item.country.toUpperCase() == country.value && item.costCenter == cc.value),
                                                                sortConfigs[`${cI}-${ccIndex}`]
                                                            );
    
                                                            // Calcule totalAmount e totalValue aqui
                                                            let totalAmount = 0;
                                                            let totalValue = 0;
    
                                                            currentData.forEach(license => {
                                                                totalAmount += license.totalCC;
                                                                totalValue += license.totalCC * license.unitPrice;
                                                            });
    
                                                            return (
                                                                <React.Fragment key={ccIndex}>
                                                                    <h5 className="subtitle-cc">Centro de Custo: <b>{cc.value}</b></h5>
                                                                    <div className="row-ea cab-ea mt-4">
                                                                        {['license', 'totalCC', 'unitPrice', 'totalValue'].map((key, kIndex) => (
                                                                            <div
                                                                                key={kIndex}
                                                                                className={`link ${key != 'license' ? 'text-right' : ''}`}
                                                                                onClick={() => {
                                                                                    const configKey = `${cI}-${ccIndex}`;
                                                                                    const currentConfig = sortConfigs[configKey] || { key: null, direction: 'ascending' };
                                                                                    currentConfig.key = key;
                                                                                    currentConfig.direction = currentConfig.direction == 'ascending' ? 'descending' : 'ascending';
                                                                                    setSortConfigs({ ...sortConfigs, [configKey]: currentConfig });
                                                                                }}
                                                                            >
                                                                                {key == 'license' ? 'Produto' : key == 'totalCC' ? 'Qtd' : key == 'unitPrice' ? 'Unit' : 'Total'}
                                                                                <span className="text-warning">
                                                                                    {sortConfigs[`${cI}-${ccIndex}`] && sortConfigs[`${cI}-${ccIndex}`].key == key && (sortConfigs[`${cI}-${ccIndex}`].direction == 'ascending' ? '↑' : '↓')}
                                                                                </span>
                                                                            </div>
                                                                        ))}
                                                                    </div>
    
                                                                    <List key={cI}
                                                                        height={130}
                                                                        itemCount={1}
                                                                        itemSize={getItemSize}
                                                                        itemData={{ array: [cc], countryL: country, currentData: currentData }}
                                                                        className='scrollable-v'
                                                                    >
                                                                        {props => <Row {...props} softwares={softwares} getUsers={getUsers} FormatNumber={FormatNumber} sortData={sortData} />}
                                                                    </List>
                                                                    <div className="p-3 font-4x row-ea row-total">
                                                                        <div>Total:</div>
                                                                        <div className="text-right cell-total-users" title="Usuários"><PiUser title="Usuários" size='16px' className="icon-ea" color="#0580ce" /> {totalAmount}</div>
                                                                        <div className="text-right"> </div>
                                                                        <div className="text-right cell-total-users" title="Total"><PiCurrencyCircleDollar size='16px' title="Total" className="icon-ea" color="#0580ce" />{FormatNumber(totalValue)}</div>
                                                                    </div>
                                                                </React.Fragment>
                                                            );
                                                        })
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div>
                </LcLoading>
                )
            }
        }
    ]
    
    return (
        <Layout
            //loading={loading}
            pageTitle="Orçamento"
            row={
                <>
                    <LcIconLink
                        icon={<PiFunnelLight/>}
                        tooltip="Filtro"
                        onClick={() => setFilterVisible(!filterVisible)}
                        color={filtered ? 'var(--color-secondary-lime)' : ''}
                    />
                </>
            }
        >
            <LCDashboard 
                cards={card}
                cssClassPanel='scrollable-v'
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: true,
                    show: filterVisible,
                    content: () => filterForBudget()
                }} 
            />
            {/* {
                filterVisible &&
                <SideModal header="Filtro" onClose={setFilterVisible} visible={filterVisible}>
                    <div className="form">
                        <LcAccordion title="Países" visible={true}>
                            {
                                countries.length > 0 &&
                                <LcCheckList list={countries} toggle onChange={setCountries}
                                    search={true} searchPlaceHolder="(Procure por países)" title="Pais" />
                            }
                        </LcAccordion>
                        <LcAccordion title="Softwares" visible={false}>
                            {
                                softwares.length > 0 &&
                                <LcCheckList list={softwares} toggle onChange={setSoftwares}
                                    search={true} searchPlaceHolder="(Procure por softwares)" title="Software" />
                            }
                        </LcAccordion>
                        <LcAccordion title="Centros de Custo" visible={false}>
                            {
                                costCenters.length > 0 &&
                                <LcCheckList list={costCenters} toggle
                                    onChange={setCostCenters} search={true}
                                    searchPlaceHolder="(Procure por centros de custo)" title="Centro de Custo" />
                            }
                        </LcAccordion>
                    </div>
                </SideModal>
            } */}
            {
                modalVisibleUsers &&
                <PPModal title="Usuários" visible={modalVisibleUsers}
                    onClose={() => setModalVisibleUsers(false)} >
                    {
                        modalVisibleUsers &&
                        sortedUsers.map((u, ui) => {
                            return (
                                <div key={ui} className="lctaglink" onClick={() => handleGetSKUsByUser(u.clientGroupId, u.userGraphId, u)}>
                                    <div className="text-link">
                                        <span>&nbsp;{u.userName}</span>
                                    </div>
                                </div>
                            )
                        })
                    }
                </PPModal>
            }
            {
                modalVisibleSoftwaresConsumidos &&
                <PPModal title="Softwares utilizados" visible={modalVisibleSoftwaresConsumidos}
                    onClose={handleCloseModalSoftwares}>
                    {
                        selectedUser &&
                        <>
                            <h4>{selectedUser.userName}</h4>
                            <SelectField label="País" name="pais" value={selectedUser && selectedUser.country} onChange={setSelectedCountry}
                                options={countryOptions}></SelectField>
                            <div className="scrollable-v" style={{ height: 'calc(100vh - 19.5rem)' }}>
                                {
                                    softwaresConsumidos &&
                                    softwaresConsumidos.map((sc, sci) => {
                                        return (
                                            <div className="lctaglink" key={sci} onClick={() => { handleIsLicensed(sci) }}>
                                                <div>
                                                    <LcCheckBox name="islicensed" checked={sc.isLicensed} />
                                                    <span className="text-link">&nbsp;{sc.description}</span>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
                                <button className="lc-button bg-info" style={{ marginRight: '.5rem' }} onClick={() => setModalVisibleSoftwaresConsumidos(false)}>Fechar</button>
                                <button className="lc-button bg-info" onClick={() => { saveSoftwaresConsumidos() }}>Salvar</button>
                            </div>
                        </>
                    }
                </PPModal>
            }
        </Layout >
    );
}

export default EABudget;