import { useEffect, useState } from "react";
import LcLoading from "../../../../components/Generic/LcLoading";
import ReactECharts from 'echarts-for-react';
import * as echarts from 'echarts';
import LcNoData from "../../../../components/Generic/LcNoData";

export interface DetailsChartDataset {
	value: number;
	unit: string;
	time: string;
}
interface Props {
	loading: boolean,
	dataset: DetailsChartDataset[],
	hostname: string;
	isSubcard?: boolean,
}

const LiveWatchPerformanceDetailsChart: React.FC<Props> = (props) => {

	const { loading, dataset, hostname, isSubcard } = props;

	const [chartOptions, setChartOptions] = useState<object>();
	const renderSubcard = isSubcard !== undefined && isSubcard === true;

	const generateChartOptions = () => {
		const formatValueWithUnit = (value: number, unit: string): string => {
			const suffixed = {
				'bytes': `${value} B`,
				'bytes per second': `${value} bps`,
				'percentage': `${value} %`,
				'': `${value}`,
			}[unit];
			return suffixed ? suffixed : `${value}`;
		};

		if (dataset.length > 1) {
			// const dataset = items.map((item) => ({ value: parseFloat(item.value), unit: pivot ? pivot['item.units'] : '' }));
			// const xAxisData = items.map((item) => item.clock);
			const xAxisData = dataset.map((item) => item.time);
			const yAxisData = dataset.map(item => item.value);
			const seriesData = xAxisData.map((time, index) => [time, yAxisData[index]]);

			// Calcula os limites dinâmicos do eixo Y sem casas decimais
			const minValue = Math.min(...yAxisData);
			const maxValue = Math.max(...yAxisData);
			const rangeMargin = Math.ceil((maxValue - minValue) * 0); // Margem de x%, arredondada para cima

			const yAxisMinValue = Math.floor(minValue - rangeMargin) >= 0 ? Math.floor(minValue - rangeMargin) : 0; // Garante que não seja negativo
			const yAxisMaxValue = Math.ceil(maxValue + rangeMargin);

			setChartOptions({
				title: {
					text: hostname ? hostname : '',
					top: 'top',
					textStyle: {
						fontFamily: 'Ubuntu',
						fontSize: 16,
						fontWeight: 'bold',
						color: '#333',
					},
				},
				xAxis: {
					type: 'time',
					boundaryGap: false,
					axisLabel: {
						formatter: (value: number) => {
							const date = new Date(value);
							return date.toLocaleTimeString('pt-BR', {
								hour: '2-digit',
								minute: '2-digit',
								hour12: false,
							});
						},
					},
					axisTick: { alignWithLabel: true },
					splitLine: { show: true },
					maxInterval: 3600000,
				},
				yAxis: {
					type: 'value',
					axisLabel: {
						formatter: (value: number, index: number) => {
							const correspondingData = dataset[index] || { unit: '' };
							return formatValueWithUnit(value, correspondingData.unit);
						},
					},
					min: yAxisMinValue, // Valor mínimo ajustado dinamicamente
					max: yAxisMaxValue, // Valor máximo ajustado dinamicamente
				},
				series: [
					{
						data: seriesData,
						type: 'line',
						smooth: true,
						lineStyle: { color: '#9B8FF7', width: 2 },
						itemStyle: { color: '#9B8FF7' },
						symbol: 'none', // Remove os pontos visíveis
						areaStyle: {
							opacity: 0.8,
							color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
								{ offset: 0, color: '#9B8FF7' }, // Cor no topo
								{ offset: 1, color: 'rgba(155, 143, 247, 0)' }, // Transparente na base
							]),
						},
						clip: true, // Mantém o corte dentro da área de zoom para evitar problemas visuais
						z: 2, // Garante que o preenchimento da área esteja acima do grid
					},
				],
				grid: {
					left: '3%',
					right: '3%',
					bottom: '20%',
					containLabel: true,
				},
				tooltip: {
					backgroundColor: '#f1f5fb',
					borderWidth: 0,
					textStyle: {
						fontSize: 11,
						fontFamily: 'Ubuntu',
						color: '#2B304F',
					},
					trigger: 'axis',
					formatter: (params: any) => {
						return params
							.map((point: any) => {
								const index = point.dataIndex;
								const data = dataset[index];
								const formattedTime = new Date(point.axisValue).toLocaleTimeString('pt-BR', {
									hour: '2-digit',
									minute: '2-digit',
									second: '2-digit',
									hour12: false,
								});

								// Mapeamento de unidades para abreviações
								const unitMapping: { [key: string]: string } = {
									percentage: '%',
									milliseconds: 'ms',
									bytes: 'B',
									'bytes per second': 'B/s',
								};

								const unit = unitMapping[data.unit] || data.unit || ''; // Obtém a unidade abreviada

								// Formata o texto da tooltip
								return `
							<span style="display: inline-flex; align-items: center; font-size: 12px;">
								🕒 <b>${formattedTime}</b>
							</span><br/>
							<span>
								${Number(data.value).toFixed(2)} ${unit}
							</span>
							`;
							})
							.join('<br/>');
					},
				},


				dataZoom: [
					{
						type: 'inside',
						start: 70,
						end: 100,
					},
					{
						type: 'slider',
						start: 70,
						end: 100,
						height: 30, // Aumenta o tamanho da barra de zoom
						bottom: 60, // Move a barra para cima, liberando espaço para o texto
						handleSize: '100%', // Aumenta o tamanho do controle de deslizamento
					},
				],
				toolbox: {
					left: 'right',
					itemSize: 18,
					itemGap: 20, // Define o espaçamento entre os ícones (padrão é 10)
					top: 0,
					feature: {
						dataZoom: {
							title: {
								zoom: 'Redefinir zoom', // Personaliza a tooltip do botão de zoom
								back: '',
							},
							icon: {
								back: 'blank' // Remove o ícone de voltar
							},
						},
						restore: {
							title: 'Restaurar', // Personaliza a tooltip do botão de restaurar
						},
					},
				},
				graphic: [
					{
						type: 'text',
						left: 'center',
						bottom: 20, // Posiciona o texto abaixo da barra de zoom
						style: {
							text: 'Utilize o scroll do mouse para ampliar ou reduzir o zoom. Arraste a barra de zoom acima para ajustar o período visualizado ou clique e arraste no gráfico para navegar entre os dados disponíveis.',
							font: '12px Ubuntu',
							fill: '#666',
						},
					},
				],
			});
		} else setChartOptions(undefined);
	};

	useEffect(generateChartOptions, [dataset])

	return (
		<LcLoading loading={loading} label='Carregando gráfico...'>
			{chartOptions ? <ReactECharts option={chartOptions} style={{ height: renderSubcard ? '55vh' : '90vh' }} /> : <LcNoData size='large' />}
		</LcLoading>
	)
}

export default LiveWatchPerformanceDetailsChart;