import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { BackupSaasServiceV2, CloudAllyResponseTask, CloudAllyResponseTaskData, CloudAllyResponseTaskData_WithEntities, CloudAllyResponseUserStatus, CloudAllyUserStatusDetail, CloudAllyUserStatusDetail_DataGrid, EntityDetail, TypeResponse } from '../../../services/backupSaas/backupSaasServiceV2';
import styles from "./index.module.css";
import Moment from 'moment';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';

import LcLoading from '../../../components/Generic/LcLoading';
import LCFlexContainer from '../../../components/Layout/LCFlexContainer';
import LCFlexItem from '../../../components/Layout/LCFlexItem';


import exchangeLogo from '../icons/exchange-logo.svg'
import sharepointLogo from '../icons/sharepoint-logo.svg'
import teamsLogo from '../icons/teams-logo.svg'
import onedriveLogo from '../icons/onedrive-logo.svg'

import SideModal from '../../../components/Layout/SideModal/SideModal';
import IconValueCardRow from '../../../components/Layout/Dashboard/IconValueCardRow';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import HeyhoCall from '../../../components/Generic/HeyhoCall/HeyhoCall';
import LcNoData from '../../../components/Generic/LcNoData';
import HeyHoNewRequest from '../../../components/Generic/HeyHoNewRequest';

interface DoughnutChartValue {
    title: string;
    labels: string[];
    values: number[]
}

const BackupSaasHistorico: React.FC = (props) => {

    const templateColumn = [
        {
            id: 1, from: 'MS365', column: [
                {
                    field: 'source', headerName: 'Solução', width: "17%",
                    renderCell: (row: any) => <> {service.GetFromForSource(row.source)} </>
                },
                { field: 'fullName', headerName: 'Mailbox', width: "30%" },
                { field: 'mail', headerName: 'Email', width: "40%" },
                {
                    field: 'backupStatus', headerName: 'Status', width: "7%", sort: false, filterable: false, overflow: "visible",
                    renderCell: (row: any) => {
                        const icon = service.GetFromForStatusIcon(row.backupStatus[0].status as string)
                        const statusLabel = service.GetFromForStatus(row.backupStatus[0].status as string)
                        const color = service.GetFromForStatusColor(row.backupStatus[0].status as string)
                        return (
                            <LcIconLink size="small" icon={icon || ''} color={color} tooltip={statusLabel} notALink />
                        )
                    }
                },
                {
                    field: 'lastBackupAttemptDate', headerName: 'Último backup', width: "12%",
                    renderCell: (row: any) => {
                        if (row.lastBackupAttemptDate != null) {
                            let date = new Date(row.lastBackupAttemptDate)
                            const labelDate = service.FormatDate(date as Date)
                            return <>{labelDate}</>
                        }
                        else return <>Nunca</>
                    }
                },
            ]
        },
        {
            id: 2, from: 'SHAREPOINT_MULTI', column: [
                {
                    field: 'source', headerName: 'Solução', width: "15%",
                    renderCell: (row: any) => <> {service.GetFromForSource(row.source)} </>
                },
                { field: 'mail', headerName: 'Link base do Sharepoint', width: "65%" },
                {
                    field: 'backupStatus', headerName: 'Status', width: "10%", sort: false, filterable: false, overflow: "visible",
                    renderCell: (row: any) => {
                        const icon = service.GetFromForStatusIcon(row.backupStatus[0].status as string)
                        const statusLabel = service.GetFromForStatus(row.backupStatus[0].status as string)
                        const color = service.GetFromForStatusColor(row.backupStatus[0].status as string)
                        return (
                            <LcIconLink size="small" icon={icon || ''} color={color} tooltip={statusLabel} notALink />
                        )
                    }
                },
                {
                    field: 'lastBackupAttemptDate', headerName: 'Último backup', width: "10%",
                    renderCell: (row: any) => {
                        if (row.lastBackupAttemptDate != null) {
                            let date = new Date(row.lastBackupAttemptDate)
                            let labelDate = service.FormatDate(date as Date)
                            return <> {labelDate} </>
                        }
                        else return <>Nunca</>
                    }
                },
            ]
        },
        {
            id: 3, from: 'ONEDRIVE', column: [
                {
                    field: 'source', headerName: 'Solução', width: "15%",
                    renderCell: (row: any) => <> {service.GetFromForSource(row.source)} </>
                },
                { field: 'mail', headerName: 'Link do OneDrive', width: "65%" },
                {
                    field: 'backupStatus', headerName: 'Status', width: "10%", sort: false, filterable: false, overflow: "visible",
                    renderCell: (row: any) => {
                        const icon = service.GetFromForStatusIcon(row.backupStatus[0].status as string)
                        const statusLabel = service.GetFromForStatus(row.backupStatus[0].status as string)
                        const color = service.GetFromForStatusColor(row.backupStatus[0].status as string)
                        return (
                            <LcIconLink size="small" icon={icon || ''} color={color} tooltip={statusLabel} notALink />
                        )
                    }
                },
                {
                    field: 'lastBackupAttemptDate', headerName: 'Último backup', width: "10%",
                    renderCell: (row: any) => {
                        if (row.lastBackupAttemptDate != null) {
                            let date = new Date(row.lastBackupAttemptDate)
                            let labelDate = service.FormatDate(date as Date)
                            return <> {labelDate} </>
                        }
                        else return <>Nunca</>
                    }
                },
            ]
        },
        {
            id: 4, from: 'MS365GROUPS', column: [
                {
                    field: 'source', headerName: 'Solução', width: "15%",
                    renderCell: (row: any) => <> {service.GetFromForSource(row.source)} </>
                },
                { field: 'mail', headerName: 'Nome do Grupo', width: "65%" },
                {
                    field: 'backupStatus', headerName: 'Status', width: "10%", sort: false, filterable: false, overflow: "visible",
                    renderCell: (row: any) => {
                        const icon = service.GetFromForStatusIcon(row.backupStatus[0].status as string)
                        const statusLabel = service.GetFromForStatus(row.backupStatus[0].status as string)
                        const color = service.GetFromForStatusColor(row.backupStatus[0].status as string)
                        return (
                            <LcIconLink size="small" icon={icon || ''} color={color} tooltip={statusLabel} notALink />
                        )
                    }
                },
                {
                    field: 'lastBackupAttemptDate', headerName: 'Último backup', width: "10%",
                    renderCell: (row: any) => {
                        if (row.lastBackupAttemptDate != null) {
                            let date = new Date(row.lastBackupAttemptDate)
                            let labelDate = service.FormatDate(date as Date)
                            return <> {labelDate} </>
                        }
                        else return <>Nunca</>
                    }
                },
            ]
        }
    ]

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const service = new BackupSaasServiceV2(props);
    const [page, setPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(12)
    const [rows, setRows] = useState<CloudAllyUserStatusDetail_DataGrid[]>([]);
    const [columns, setColumns] = useState<any>(templateColumn[0].column);
    const [tasks, setTasks] = useState<CloudAllyResponseTaskData[]>([]);
    const [taskChart, setTaskChart] = useState<DoughnutChartValue>({ title: "", labels: [], values: [] });
    const [filteredRows, setFilteredRows] = useState<CloudAllyUserStatusDetail_DataGrid[]>([]);
    const [visibleRows, setVisibleRows] = useState<any[]>([]);
    const [menu, setMenu] = useState<any[]>([]);
    const [notFoundCredential, setNotFoundCredential] = useState<string>('')
    const [refreshColumn, setRefreshColumn] = useState<boolean>(true);
    const [initialSource, setInitialSource] = useState<string>('');
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string, source?: string }>({ size: 20, term: "", column: "", direction: "asc" });
    const [_source, set_source] = useState<any>();

    const [selected, setSelected] = useState<any>();
    const [sideModalRestaurarVisible, SetSideModalRestaurarVisible] = useState<boolean>(false);

    const [filterSolution, setFilterSolution] = useState<string>("MS365");

    useEffect(() => {
        filterBySource(filterSolution);
    }, [filterSolution])

    const GetImageBySourceName = (sourceName) => {
        let iconName = service.GetPathIcon(sourceName)
        switch (iconName) {
            case "exchange-logo":
                return (
                    <img src={exchangeLogo} height={36} width={36} />
                )
                break;

            case "onedrive-logo":
                return (
                    <img src={onedriveLogo} height={36} width={36} />
                )
                break;

            case "sharepoint-logo":
                return (
                    <img src={sharepointLogo} height={36} width={36} />
                )
                break;

            case "teams-logo":
                return (
                    <img src={teamsLogo} height={36} width={36} />
                )
                break;

            default:
                return (
                    <img src='/' height={36} width={36} />
                )
                break;
        }
    }

    useEffect(() => {
        setIsLoading(true)
        setRows([]);
        // setFilteredRows([]);
        let taskIds: string[] = []
        let source = ''
        service.GetTasks(page, pageSize).then(result => {
            const response: CloudAllyResponseTask = result.data;
            if (initialSource == '') {
                if (response.data) {
                    let firstSourceName = response.data[0].source.toString()
                    set_source(response.data[0].source.toString());
                    source = firstSourceName
                    setInitialSource(firstSourceName)
                    let _c = templateColumn.find(c => c.from == firstSourceName);
                    setColumns(_c?.column)
                }
            }

            if (service.IsNullReturn(response, TypeResponse.CloudAllyResponseTask)) {
                setNotFoundCredential('Credencial não encontrada, revise suas assinaturas e tente novamente.')
                setIsLoading(false)
                return;
            } else {
                setNotFoundCredential('')
                response.data && setTasks(response.data)
                response.data && response.data.map((e: CloudAllyResponseTaskData) => {
                    taskIds.push(e.id)
                })
            }
        })

        service.GetUserStatus(page, 1000).then(UserStatusResponse => {
            let arrayRows: CloudAllyUserStatusDetail_DataGrid[] = [];
            const responseStatus: CloudAllyResponseUserStatus = UserStatusResponse.data;

            if (UserStatusResponse.status == 200) {
                if (service.IsNullReturn(responseStatus, TypeResponse.CloudAllyResponseUserStatus)) {
                    setNotFoundCredential('Credencial não encontrada, revise suas assinaturas e tente novamente.')
                    setIsLoading(false)
                    return;
                }


                taskIds.map((taskId: string, i: number) => {
                    service.GetUserDetails(taskId)
                        .then(UserDetailsResponse => {

                            const responseDetail: CloudAllyResponseTaskData_WithEntities = UserDetailsResponse.data;
                            responseStatus.data && responseStatus.data.map((userDetail: CloudAllyUserStatusDetail, index: number) => {
                                let selectedRow = responseDetail.entities.find((x: EntityDetail) => x.entity == userDetail.entityName)
                                if (selectedRow) {
                                    arrayRows.push(
                                        {
                                            ...userDetail,
                                            fullName: selectedRow?.fullName,
                                            mail: selectedRow?.entity,
                                            sizeLabel: service.bytesToSize(userDetail.size),
                                            id: index
                                        }
                                    )
                                }
                            })
                            setRows(arrayRows)
                            let filteredInitialRows = arrayRows.filter(x => x.source == source)
                            setFilteredRows(filteredInitialRows)
                            setVisibleRows((filteredInitialRows.length > filter.size) ? filteredInitialRows.slice(0, filter.size) : filteredInitialRows);
                            setIsLoading(false)
                        })
                })

                let opt: { name: string, value: string }[] = []
                responseStatus.data && responseStatus.data.map((element: CloudAllyUserStatusDetail) => {
                    let m = opt.find(x => x.value == element.source)
                    if (!m) {
                        const _name = service.GetFromForSource(element.source)
                        opt.push(
                            {
                                name: _name,
                                value: element.source
                            }
                        )
                    }
                })
                setMenu(opt)
            }
        })
        filterBySource(filterSolution);
    }, [user.ClientGroupSelected, user.refreshFlag])

    useEffect(() => {
        if (tasks.length == 0) return;
        const dataChart: DoughnutChartValue = {
            title: "Quantidade de usuários por solução",
            labels: [],
            values: []
        };

        tasks.map((element) => {
            dataChart.labels.push(service.GetFromForSource(element.source))
            dataChart.values.push(parseInt(element.numOfEntities))
        })
        setTaskChart(dataChart)
    }, [tasks])

    // useEffect(() => {
    //     setRefreshColumn(true)
    //     setTimeout(() => {
    //         setRefreshColumn(false)
    //     }, 400);
    // }, [columns])

    useEffect(() => {
        if (rows.length == 0) return;

        let filteredInitialRows = filter.source ? rows.filter(x => x.source == filter.source) : rows.filter(x => x.source == _source)

        var filtered = filteredInitialRows.filter((p: any) =>
            p.source?.toLowerCase().indexOf(filter.term) > -1 ||
            p.fullName?.toLowerCase().indexOf(filter.term) > -1 ||
            p.entityName?.toLowerCase().indexOf(filter.term) > -1 ||
            p.mail.toLowerCase().indexOf(filter.term) > -1
        )

        if (filtered.length > 1) filtered = filtered.sort((a: any, b: any) => {
            if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
            else return (a[filter.column] > b[filter.column]) ? -1 : 1;
        });

        setFilteredRows(filtered);
        setVisibleRows((filtered.length > filter.size) ? filtered.slice(0, filter.size) : filtered);
    }, [filter]);


    const filterBySource = (source: string) => {
        setRefreshColumn(true)
        setInitialSource(source)
        setFilter({ ...filter, source: source, term: '' })
        if (rows.filter(x => x.source == source).length > 0) {
            let _c = templateColumn.find(c => c.from == source);
            setColumns(_c?.column)
        }
        setRefreshColumn(false)
    }

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleRows(filteredRows.slice(0, size))
        else setVisibleRows([...visibleRows, ...filteredRows.slice(visibleRows.length, visibleRows.length + 15)])
    };

    const functionsRow = [
        {
            label: 'Restaurar',
            disabled: (row: any) => { return !row.lastBackupAttemptDate },
            func: (row: any) => {
                setSelected(row);
                SetSideModalRestaurarVisible(true)
            }

        }
    ]

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }
    const [filterVisible, setFilterVisible] = useState(false);
    const filterAdvanced = {
        fields: [
            { label: 'Pesquisar', name: 'search', type: 'text' }
        ],
        onChange: (_filter: any, size: number) => {
            setFilter({ ...filter, size: size, term: _filter[0].value.toLowerCase() })
        },
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    };

    const periodSelection = {
        periods: [
            {
                label: 'Microsoft Exchange', text: 'Microsoft Exchange',
                fields: [
                    { name: "MS365", value: "MS365" }
                ],
                default: true
            },
            {
                label: 'Microsoft OneDrive', text: 'Microsoft OneDrive',
                fields: [
                    { name: "ONEDRIVE", value: "ONEDRIVE" }
                ],
                default: false
            },
            {
                label: 'Microsoft SharePoint', text: 'Microsoft SharePoint',
                fields: [
                    { name: "SHAREPOINT_MULTI", value: "SHAREPOINT_MULTI" }
                ],
                default: false
            },
            {
                label: 'Microsoft Teams', text: 'Microsoft Teams',
                fields: [
                    { name: "MS365GROUPS", value: "MS365GROUPS" }
                ],
                default: false
            },
        ],
        customPeriodType: 'microsoft',
        onChange: (e) => { console.log(e); setFilterSolution(e.fields[0].value) }
    }

    return (
        <Layout
            pageTitle="Histórico"
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            periodSelection={periodSelection}
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: filteredRows.length
            }}
            cardType
        >
            <HeyHoNewRequest
                openModal={sideModalRestaurarVisible}
                formData={{
                    titulo: 'Restauração de Backup',
                    Descricao: `Origem:  ${selected && selected.source}\nCaminho: ${selected && selected.mail}\n >>>>>Informar a pasta, arquivo ou assunto do e-mail a ser restaurado<<<<<`,
                    gtmType: 3,
                }}
                onClose={() => SetSideModalRestaurarVisible(false)}
                needAtachmente
                header="Restaurar backup"
            />
            {/* <SideModal visible={sideModalRestaurarVisible} header="Restaurar backup" onClose={() => SetSideModalRestaurarVisible(false)}>
                <HeyhoCall formData={
                    {
                        title: 'Restauração de Backup',
                        description: `Origem:  ${selected && selected.source}\nCaminho: ${selected && selected.mail}\n >>>>>Informar a pasta, arquivo ou assunto do e-mail a ser restaurado<<<<<`,
                        gtmType: 3,
                    }
                }>
                </HeyhoCall>
            </SideModal> */}

            <LcLoading loading={isLoading} label="Carregando informações, aguarde..">
                {notFoundCredential === '' ?
                    <>
                        {/* <LCFlexContainer spacing={1}>
                            {tasks.length > 0 &&
                                tasks.map((e: any, index) => {
                                    const cardSize = (12 / tasks.length)
                                    const name = service.GetFromForSource(e.source);
                                    return (
                                        <LCFlexItem key={index} xs={12} sm={cardSize} md={cardSize}>
                                            <IconValueCardRow title={name} onClick={() => { filterBySource(e.source) }} color='transparent' withoutValue imageIcon={GetImageBySourceName(e.source)} />
                                            <div className={`${initialSource == e.source ? styles.active : ''}`}></div>
                                        </LCFlexItem>
                                    )
                                })
                            }
                        </LCFlexContainer> */}

                        {/* <LcLoading loading={refreshColumn} label="Carregando tabela"> */}
                        <LcInfiniteTable
                            loading={refreshColumn}
                            columns={columns}
                            rows={filteredRows}
                            filter={filterAdvanced}
                            size={filteredRows.length}
                            loadMore={loadMore}
                            onSortChange={onSortChange}
                            functionsRow={functionsRow}
                            density={pref.data?.density || "high"}
                        />
                        {/* </LcLoading> */}
                    </>
                    :
                    <LcNoData label={notFoundCredential} />
                }
            </LcLoading>
        </Layout>
    );
};

export default BackupSaasHistorico;