import React from 'react';

interface CostSummaryProps {
    valueChange: 'up' | 'down' | 'same';
    changeDiff: number;
    changePerc: string;
    desiredInstanceWeekCost: number;
}

export const CostSummary: React.FC<CostSummaryProps> = ({ valueChange, changeDiff, changePerc, desiredInstanceWeekCost }) => (
    <div id ='resize-review-costsummary' className='resize-review-costsummary'>
        <span>Novo custo semanal estimado</span><br />
        <span className='resize-review-costsummary-value'>
            R$ {desiredInstanceWeekCost.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </span><br />
        {valueChange === 'up' && <span id ='resize-review-costsummary-up'>
            aumento de R${changeDiff.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} ({changePerc}%)
        </span>}
        {valueChange === 'down' && <span id ='resize-review-costsummary-down'>
            redução de R${changeDiff.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })} ({changePerc}%)
        </span>}
        {valueChange === 'same' && <span id ='resize-review-costsummary-same'>sem alteração no custo.</span>}
    </div>
);
