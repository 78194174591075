import React, { useState } from 'react';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import SubscriptionComercial from './SubscriptionComercial';
import { SuggestionsSubscriptionModel } from '../../../services/saas/SaasModel';
import { LCTechService } from '../../../services/LCTech/LCTechService';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

interface SubscritionChartModel {
    pinnedFilterPanel: boolean
    subiscriptionsLabel: boolean
}

const getSubscriptionSuggestions = (subscription: any) => {
    return subscription.map(subs => {
        return {
            displayName: subs.displayName,
            percentege: (((subs.prepaidUnid - subs.consumedUnits) / subs.prepaidUnid) * 100).toFixed(1).toString() + "%",
            prepaidUnid: subs.prepaidUnid,
            consumedUnits: subs.consumedUnits,
        }
    })
}

export const SubscritionChart: React.FC<SubscritionChartModel> = ({pinnedFilterPanel, subiscriptionsLabel}) => {
    const [subscriptionSuggestions, setSubscriptionSuggestions] = useState<SuggestionsSubscriptionModel[]>([])
    const user = useSelector<RootState, UserState>(state => state.user);
    const lcTechService = new LCTechService({});

    const { data: subiscriptionData , isLoading :optionLicensesContratadasLoading} = useQuery({
        queryKey: ['subscriptionData', user.refreshFlag, user.ClientGroupSelected],
        queryFn:async () => await lcTechService.Retrieve(1),
        refetchOnWindowFocus: false,
        onSuccess(response: any) {
            setSubscriptionSuggestions(getSubscriptionSuggestions(response.subscriptions.filter(subs => subs.consumedUnits < subs.prepaidUnid)))
        }, onError: (error) => {
            console.error('Error fetching  domains data:', error);
        }
    })
    console.log(subiscriptionData)
    return (
        <LcLoading loading={optionLicensesContratadasLoading}>
        {
            subiscriptionData && subiscriptionData.subscriptions.length > 0 ?
                <>
                    <div style={{ height: '100%' }} className={`w-100p iconValueInsights ${subiscriptionsLabel ? '' : 'show'}`}>
                        <SubscriptionComercial subscriptionData={subiscriptionData} isHome={false} />
                    </div>
                    <div className={`row subscriptionInsights ${subiscriptionsLabel ? 'show' : ''} ${pinnedFilterPanel ? 'fixedAI' : ''} scrollable-v`}>
                        {
                            subscriptionSuggestions && subscriptionSuggestions.length > 0 &&
                            subscriptionSuggestions.map(subs => {
                                return (
                                    <div className='row' style={{width: 'auto'}}>
                                        <i className='ppi ppi-lightning-bolt' style={{ fontSize: '20px' }}></i>
                                        <p className='ml-5' 
                                        style={{
                                            flexWrap: 'wrap',
                                            display: 'flex',
                                        }}>
                                            <strong>{subs.displayName}</strong> 
                                            tem {subs.percentege} das atribuições disponiveis {`(${subs.consumedUnits} de ${subs.prepaidUnid} estão em uso)`}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </>
                :
                <LcNoData />
        }
    </LcLoading>
    );
}
