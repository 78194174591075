import { PPFormInput } from "processor-plataform-ui/dist/components/PPForm2";
import api, { APIClient } from "./api";
import { FieldGroup, FormFields, Option } from "../components/Form/PPFormModal";
import { DomainInfoModel } from "./saas/SaasModel";
import { ReactNode } from "react";
import React from 'react';
import { VendorService } from "./vendorService";
import { SaaSSubscriptionCostCenterParameters, TechEAEASService } from "./EASubscriptionService/techEAEASService";
export interface SaaSUser {
    login?: string;
    id?: string,
    objectid?: string,
    givenName?: string;
    surname?: string;
    signature?: string;
    alias: string;
    domain?: string;
    department?: string;
    jobTitle?: string;
    state?: string;
    streetAddress?: string;
    postalCode?: string;
    country?: string;
    city?: string;
    passwordProfile_Password?: string;
    passwordProfile_ForceChangePasswordNextSignIn?: boolean;
    passwordProfile: {
        Password?: string;
        ForceChangePasswordNextSignIn?: boolean;
    }
    userPrincipalName?: string;
    mail?: string;
    displayName?: string;
    usageLocation?: string;
    accountEnabled?: boolean;
    mailNickname?: string
}
export interface SaaSLicencesModel {
    skuId: string;
    description: string;
    isChecked: string;
    isLicensed: boolean;
}

export interface SaaSLicencasViewModel {
    userId: string;
    nomeUsuario: string;
    licencas: SaaSLicencesModel[];
    currentLocation: string;
}
interface FilterGridDefault {
    start: number;
    size: number;
    filter: string;
    ordered: string;
    direction: string;
    login: string;
    dirSyncEnabled: number;
    mailboxType: number;
    name: string;
}

interface State {
    data: {
        EnumDomains: any[];
        EnumVendorCredentials: any[];
        filterGridDefault: FilterGridDefault;
        template: any;
    };
}
export class SaasUserService extends APIClient  {
    private _isMounted: boolean = false;
    state: State = {
        data: {
            EnumDomains: [] as any[],
            EnumVendorCredentials: [] as any[],
            filterGridDefault: {
                start: 0,
                size: 25,
                filter: '',
                ordered: 'Name',
                direction: 'asc',
                login: '',
                dirSyncEnabled: 2,
                mailboxType: -1,
                name: ''
            },
            template: {},
        }
    };

    setState(newState: Partial<State>) {
        if (this._isMounted) {
            this.state = { ...this.state, ...newState };
        }
    }

    constructor(props: any) {
        super(props);
        this._isMounted = true;
    }

    destroy() {
        this._isMounted = false;
    }
    
    emptyUser: SaaSUser = {
        givenName: '',
        signature: '',
        alias: '',
        domain: '',
        surname: '',
        department: '',
        jobTitle: '',
        state: '',
        streetAddress: '',
        postalCode: '',
        country: '',
        city: '',
        passwordProfile_Password: '',
        passwordProfile_ForceChangePasswordNextSignIn: false,
        passwordProfile: {
            Password: '',
            ForceChangePasswordNextSignIn: false,
        },
        userPrincipalName: '',
        mail: '',
        displayName: '',
        usageLocation: 'BR',
        accountEnabled: true,
        mailNickname: '',
    }

    setTemplate(templateParm: any) {
        this.setState({
            data: {
                ...this.state.data,
                template: templateParm
            }
        });
    }

    public getTemplate() {
        if (!this.state.data.EnumDomains) {
            alert("É obrigatorio ter dominios cadastrados, revisar o cadastro da assinatura Microsoft Graph.");
        }
        if (this.state.data.EnumDomains.length == 0) {
            alert("É obrigatorio ter dominios cadastrados, revisar o cadastro da assinatura Microsoft Graph.");
        }
        return {
            ...{
                title: 'givenName',
                Fields: [{
                    edit: false,
                    row: 1, col: 1, colspan: 1, rowspan: 1,
                    name: 'signature',
                    type: 'select',
                    title: 'Assinatura',
                    required: {
                        required: 'Assinatura é obrigatório',
                        value: true
                    },
                    disabled: false,
                    options: this.state.data.EnumVendorCredentials != null ? this.state.data.EnumVendorCredentials : []
                }, {
                    edit: true,
                    row: 1, col: 1, colspan: 1, rowspan: 1,
                    name: 'givenName',
                    type: 'text',
                    title: 'Nome',
                    required: {
                        required: 'Nome é obrigatório',
                        value: true,
                        pattern: {
                            value: /^(.{1,100})$/,
                            message: 'Nome deve ter no máximo 100 caracteres'
                        }
                    },
                    disabled: false
                }, {
                    edit: true,
                    row: 1, col: 1, colspan: 1, rowspan: 1,
                    name: 'surname',
                    type: 'text',
                    title: 'Sobrenome',
                    required: {
                        required: 'Sobrenome é obrigatório',
                        value: true,
                        pattern: {
                            value: /^(.{1,100})$/,
                            message: 'Sobrenome deve ter no máximo 100 caracteres'
                        }
                    },
                    disabled: false
                }, {
                    edit: true,
                    row: 2, col: 1, colspan: 1, rowspan: 1,
                    name: 'alias',
                    type: 'text',
                    title: 'Alias',
                    icon: "lci lci-at-symbol",
                    required: {
                        required: 'Alias é obrigatório',
                        value: true,
                        pattern: {
                            value: /^\S+$/,
                            message: 'Espaços em branco não serão aceitos no alias'
                        }
                    },
                    disabled: false
                }, {
                    edit: false,
                    row: 2, col: 2, colspan: 1, rowspan: 1,
                    name: 'domain',
                    type: 'select',
                    title: 'Dominio',
                    required: {
                        required: 'Dominio é obrigatório',
                        value: true,
                        pattern: {
                            value: /^(.{1,100})$/,
                            message: 'Dominio deve ter no máximo 100 caracteres'
                        }
                    },
                    disabled: false,
                    options: this.state.data.EnumDomains
                }, {
                    edit: true,
                    row: 2, col: 1, colspan: 1, rowspan: 2,
                    name: 'department',
                    type: 'text',
                    title: 'Departamento',
                    required: {
                        required: 'Departamento é obrigatório',
                        value: true,
                        pattern: {
                            value: /^(.{1,100})$/,
                            message: 'Departamento deve ter no máximo 100 caracteres'
                        }
                    },
                    disabled: false
                }, {
                    edit: true,
                    row: 3, col: 2, colspan: 1, rowspan: 2,
                    name: 'jobTitle',
                    type: 'text',
                    title: 'Cargo',
                    required: {
                        required: 'Cargo é obrigatório',
                        value: true,
                        pattern: {
                            value: /^(.{1,75})$/,
                            message: 'Cargo deve ter no máximo 75 caracteres'
                        }
                    },
                    disabled: false
                }, {
                    edit: true,
                    row: 3, col: 2, colspan: 1, rowspan: 2,
                    name: 'streetAddress',
                    type: 'text',
                    title: 'Endereço',
                    required: {
                        required: 'Endereço é obrigatório',
                        value: true,
                        pattern: {
                            value: /^(.{1,100})$/,
                            message: 'Endereço deve ter no máximo 100 caracteres'
                        }
                    },
                    disabled: false
                }, {
                    edit: true,
                    row: 3, col: 1, colspan: 1, rowspan: 2,
                    name: 'postalCode',
                    type: 'text',
                    title: 'CEP',
                    required: {
                        required: 'CEP é obrigatório',
                        value: true,
                        pattern: {
                            value: /^(.{1,30})$/,
                            message: 'CEP deve ter no máximo 30 caracteres'
                        }
                    },
                    disabled: false
                }, {
                    edit: true,
                    row: 3, col: 2, colspan: 1, rowspan: 2,
                    name: 'country',
                    type: 'text',
                    title: 'País',
                    required: {
                        required: 'País é obrigatório',
                        value: true,
                        pattern: {
                            value: /^(.{1,50})$/,
                            message: 'País deve ter no máximo 50 caracteres'
                        }
                    },
                    disabled: false
                }, {
                    edit: true,
                    row: 7, col: 2, colspan: 1, rowspan: 2,
                    name: 'state',
                    type: 'text',
                    title: 'Estado',
                    required: {
                        required: 'Estado é obrigatório',
                        value: true,
                        pattern: {
                            value: /^(.{1,50})$/,
                            message: 'Estado deve ter no máximo 50 caracteres'
                        }
                    },
                    disabled: false
                }, {
                    edit: true,
                    row: 8, col: 1, colspan: 1, rowspan: 2,
                    name: 'city',
                    type: 'text',
                    title: 'Cidade',
                    required: {
                        required: 'Cidade é obrigatório',
                        value: true,
                        pattern: {
                            value: /^(.{1,50})$/,
                            message: 'Cidade deve ter no máximo 50 caracteres'
                        }

                    },
                    disabled: false
                }, {
                    edit: true,
                    row: 8, col: 2, colspan: 1, rowspan: 2,
                    name: 'usageLocation',
                    type: 'select',
                    position: 'top',
                    title: 'Local de utilização',
                    required: {
                        required: 'Local de utilização é obrigatório',
                        value: true,
                    },
                    disabled: false,
                    options: this.options != null ? this.options : []
                }, {
                    edit: false,
                    row: 10, col: 1, colspan: 1, rowspan: 2,
                    name: 'passwordProfile_Password',
                    type: 'password',
                    title: 'Senha',
                    required: {
                        required: 'Senha é obrigatório',
                        value: true,
                        pattern: {
                            value: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                            message: 'A senha deve conter caracteres especiais, caixa alta e baixa, números e ter mais que 8 caracteres.',
                        },
                        validateSS: (value: any) => {
                            let changePassword: string = value;

                            var hasUppercase = /[A-Z]/;
                            if (!hasUppercase.test(changePassword)) {

                                return false;
                            }

                            var hasNumber = /\d/;
                            if (!hasNumber.test(changePassword)) {

                                return false;
                            }

                            var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
                            if (!format.test(changePassword)) {

                                return false;
                            }

                            if (changePassword.length < 8) {
                                return false;
                            }
                            return true;
                        }
                    },
                    disabled: false
                }, {
                    edit: false,
                    row: 10, col: 2, colspan: 1, rowspan: 2,
                    name: 'passwordProfile_ForceChangePasswordNextSignIn',
                    type: 'checkbox',
                    title: 'Forçar a troca de senha no próximo login',
                    required: {
                        value: false,
                    },
                    disabled: false
                }]
            }
        };
    }

    public getTemplate2 = (user?: SaaSUser) => {
        let newForm: PPFormInput[] = [
            {
                hidden: user != undefined,
                title: "Assinatura",
                name: 'signature',
                type: 'dropdown',
                options: this.state.data.EnumVendorCredentials.length > 0 ? this.state.data.EnumVendorCredentials : [],
                openTrigger: 'click',
                required: user == undefined,
                width: '50%',
                value: this.state.data.EnumVendorCredentials.length > 0 ? this.state.data.EnumVendorCredentials[0].value : undefined,
            },
            {
                title: 'Nome',
                name: 'givenName',
                type: 'text',
                required: true,
                width: '50%',
                regex: {
                    exp: /^(.{1,100})$/,
                    help: 'Nome deve ter no máximo 100 caracteres'
                },
                value: user?.givenName ?? undefined
            },
            {
                title: 'Sobrenome',
                name: 'surname',
                type: 'text',
                required: true,
                width: '50%',
                regex: {
                    exp: /^(.{1,100})$/,
                    help: 'Sobrenome deve ter no máximo 100 caracteres'
                },
                value: user?.surname ?? undefined
            },
            {
                title: 'Alias',
                name: 'alias',
                type: 'text',
                required: true,
                width: '50%',
                regex: {
                    exp: /^\S+$/,
                    help: 'Espaços em branco não serão aceitos no alias'
                },
                value: user?.alias ?? undefined
            },
            {
                hidden: user != undefined,
                title: 'Domínio',
                name: 'domain',
                type: 'dropdown',
                options: this.state.data.EnumDomains.length > 0 ? this.state.data.EnumDomains : [],
                required: user == undefined,
                width: '50%',
                regex: {
                    exp: /^(.{1,100})$/,
                    help: 'Domínio deve ter no máximo 100 caracteres'
                },
                value: this.state.data.EnumDomains.length > 0 ? this.state.data.EnumDomains[0].value : undefined
            },
            {
                title: 'Departamento',
                name: 'department',
                type: 'text',
                required: true,
                width: '50%',
                regex: {
                    exp: /^(.{1,100})$/,
                    help: 'Departamento deve ter no máximo 100 caracteres'
                },
                value: user?.department ?? undefined
            },
            {
                title: 'Cargo',
                name: 'jobTitle',
                type: 'text',
                required: true,
                width: '50%',
                regex: {
                    exp: /^(.{1,75})$/,
                    help: 'Cargo deve ter no máximo 75 caracteres'
                },
                value: user?.jobTitle ?? undefined
            },
            {
                title: 'Endereço',
                name: 'streetAddress',
                type: 'text',
                required: true,
                width: '50%',
                regex: {
                    exp: /^(.{1,100})$/,
                    help: 'Endereço deve ter no máximo 100 caracteres'
                },
                value: user?.streetAddress ?? undefined
            },
            {
                title: 'CEP',
                name: 'postalCode',
                type: 'text',
                required: true,
                width: '50%',
                regex: {
                    exp: /^(.{1,30})$/,
                    help: 'CEP deve ter no máximo 30 caracteres'
                },
                value: user?.postalCode ?? undefined
            },
            {
                title: 'País',
                name: 'country',
                type: 'text',
                required: true,
                width: '50%',
                regex: {
                    exp: /^(.{1,50})$/,
                    help: 'País deve ter no máximo 50 caracteres'
                },
                value: user?.country ?? undefined
            },
            {
                title: 'Estado',
                name: 'state',
                type: 'text',
                required: true,
                width: '50%',
                regex: {
                    exp: /^(.{1,50})$/,
                    help: 'Estado deve ter no máximo 50 caracteres'
                },
                value: user?.state ?? undefined
            },
            {
                title: 'Cidade',
                name: 'city',
                type: 'text',
                required: true,
                width: '50%',
                regex: {
                    exp: /^(.{1,50})$/,
                    help: 'Cidade deve ter no máximo 50 caracteres'
                },
                value: user?.city ?? undefined
            },
            {
                title: 'Local de utilização',
                name: 'usageLocation',
                type: 'dropdown',
                options: this.options != null ? this.options : [],
                required: true,
                width: '50%',
                value: user?.usageLocation ?? undefined
            },
            {
                hidden: user != undefined,
                title: 'Senha',
                name: 'passwordProfile_Password',
                type: 'password',
                required: user == undefined,
                width: '50%',
                regex: {
                    exp: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                    help: 'A senha deve conter caracteres especiais, caixa alta e baixa, números e ter mais que 8 caracteres.'
                }
            },
            {
                hidden: user != undefined,
                title: 'Forçar a troca de senha no próximo login',
                name: 'passwordProfile_ForceChangePasswordNextSignIn',
                type: 'dropdown',
                options: [
                    { value: 1, label: "Não" },
                    { value: 2, label: "Sim" },
                ],
                required: user == undefined,
                width: '50%',
            },
        ]
        return newForm;
    }

    public getUserFormFieldsData = (edit?: boolean): FormFields => {

        var arrayForm = {
            id: "saasusers",
            fieldgroups: [
                {
                    label: "Dados Pessoais",
                    accordion: true,
                    fields: [
                        {
                            type: "text",
                            name: 'givenName',
                            label: 'Nome',
                            required: edit ? false : true,
                            hidden: false,
                            disabled: false,
                            validation: {
                                regex: /^(.{1,100})$/,
                                regexMsg: "Nome deve ter no máximo 100 caracteres"
                            },
                            tooltip: ""
                        },
                        {
                            type: "text",
                            name: 'surname',
                            label: 'Sobrenome',
                            required: edit ? false : true,
                            hidden: false,
                            disabled: false,
                            validation: {
                                regex: /^(.{1,100})$/,
                                regexMsg: "Sobrenome deve ter no máximo 100 caracteres"
                            },
                            tooltip: ""
                        },
                        {
                            type: "text",
                            name: 'alias',
                            label: 'Alias',
                            required: true,
                            hidden: false,
                            disabled: false,
                            validation: {
                                regex: /^\S+$/,
                                regexMsg: "Espaços em branco não serão aceitos no alias"
                            },
                            tooltip: "",
                        },
                        {
                            type: "text",
                            name: "department",
                            label: "Departamento",
                            required: false,
                            hidden: false,
                            disabled: false,
                            tooltip: "",
                            validation: {
                                regex: /^(.{0,100})$/,
                                regexMsg: "Departamento deve ter no máximo 100 caracteres"
                            },
                        },
                        {
                            type: "text",
                            name: "jobTitle",
                            label: "Cargo",
                            required: false,
                            hidden: false,
                            disabled: false,
                            tooltip: "",
                            validation: {
                                regex: /^(.{0,75})$/,
                                regexMsg: "Cargo deve ter no máximo 75 caracteres"
                            },
                        }
                    ]
                },
                {
                    label: "Endereço (opcional)",
                    accordion: true,
                    fields: [
                        {
                            type: "CEP",
                            name: "postalCode",
                            label: "CEP",
                            required: false,
                            hidden: false,
                            disabled: false,
                            tooltip: "",
                            validation: {
                                regex: /^(.{0,8})$/,
                                regexMsg: "CEP possui um máximo de 8 dígitos"
                            },
                        },
                        {
                            type: "text",
                            name: "streetAddress",
                            label: "Endereço",
                            required: false,
                            hidden: false,
                            disabled: false,
                            addressFieldFromCEP: "logradouro",
                            tooltip: "",
                            validation: {
                                regex: /^(.{0,100})$/,
                                regexMsg: "Endereço deve ter no máximo 100 caracteres"
                            },
                        },
                        {
                            type: "text",
                            name: "city",
                            label: "Cidade",
                            required: false,
                            hidden: false,
                            disabled: false,
                            addressFieldFromCEP: "localidade",
                            tooltip: "",
                            validation: {
                                regex: /^(.{0,50})$/,
                                regexMsg: ""
                            },
                        },
                        {
                            type: "text",
                            name: "state",
                            label: "Estado",
                            required: false,
                            hidden: false,
                            disabled: false,
                            addressFieldFromCEP: "uf",
                            tooltip: "",
                            validation: {
                                regex: /^(.{0,50})$/,
                                regexMsg: ""
                            },
                        },
                        {
                            type: "text",
                            name: "country",
                            label: "País",
                            required: false,
                            hidden: false,
                            disabled: false,
                            tooltip: "",
                            validation: {
                                regex: /^(.{0,50})$/,
                                regexMsg: "País deve ter no máximo 50 caracteres"
                            },
                        }
                    ]
                },
                {
                    label: "Conta",
                    accordion: true,
                    fields: [
                        {
                            type: "dropdown",
                            name: 'signature',
                            label: 'Assinatura',
                            required: edit ? false : true,
                            hidden: !edit ? false : true,
                            disabled: !edit ? false : true,
                            tooltip: "",
                            options: this.state.data.EnumVendorCredentials,

                            blockEdit: true
                        },
                        {
                            type: "dropdown",
                            name: "domain",
                            label: "Domínio",
                            required: edit ? false : true,
                            hidden: !edit ? false : true,
                            disabled: !edit ? false : true,
                            options: this.state.data.EnumDomains,
                            tooltip: "",
                            validation: {
                                regex: /^(.{1,100})$/,
                                regexMsg: "Domínio deve ter no máximo 100 caracteres"
                            },
                            blockEdit: true,
                            handleMenuOpen: async () => { return await this.LoadRetrieveDomain(); }
                        },
                        {
                            type: "password",
                            name: 'passwordProfile_Password',
                            label: 'Senha',
                            required: edit ? false : true,
                            hidden: !edit ? false : true,
                            disabled: !edit ? false : true,
                            validation: {
                                regex: /(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
                                regexMsg: "A senha deve conter caracteres especiais, caixa alta e baixa, números e ter mais que 8 caracteres."
                            },
                            tooltip: "",
                            blockEdit: true
                        },
                        {
                            type: "dropdown",
                            name: 'usageLocation',
                            label: 'Local de utilização',
                            position: 'top',
                            required: edit ? false : true,
                            hidden: !edit ? false : true,
                            disabled: false,
                            tooltip: "",
                            options: this.options as Option[],

                        },
                        {
                            type: "checkbox",
                            name: 'passwordProfile_ForceChangePasswordNextSignIn',
                            label: 'Forçar a troca de senha no próximo login',
                            required: false,
                            hidden: false,
                            disabled: false,
                            tooltip: "",

                            blockEdit: true
                        },
                    ]
                }
            ] as FieldGroup[]
        }

        if (edit) {
            arrayForm.fieldgroups = arrayForm.fieldgroups.filter(v => v.label != "Conta") as FieldGroup[];
        }

        return arrayForm;
    }

    public getWizardEAFormFieldsData = (edit?: boolean): FormFields => {
        /*  { name: 'ApplicationId', data: '', readOnly: false },
                    { name: 'ApplicationSecret', data: '', readOnly: false },
                    { name: 'Domain', data: '', readOnly: false },
                    { name: 'User', data: '', readOnly: false },
                    { name: 'Password', data: '', readOnly: false },
                    { name: 'ExpireDate', data: '', readOnly: false } */
        const serviceInstance = new VendorService(this.props);
        let saaSEAService = new TechEAEASService(this.props);
        var arrayForm = {
            id: "HubSoftwares",
            fieldgroups: [
                {
                    label: "Acesso as assinaturas Office",
                    accordion: true,
                    description: (StepData): ReactNode => {
                        return "<ul> <li>Arquivo de texto com a saída do comando Get-MsolAccountSku.</li> <li>Criar Service Principal com as permissões abaixo <a href='https://learn.microsoft.com/en-us/azure/active-directory/develop/quickstart-register-app'> (https://learn.microsoft.com/en-us/azure/active-directory/develop/quickstart-register-app)</a>.</li> <li>Microsoft Graph <a href='https://learn.microsoft.com/en-us/azure/active-directory/develop/quickstart-configure-app-access-web-apis#application-permission-to-microsoft-graph'> (https://learn.microsoft.com/en-us/azure/active-directory/develop/quickstart-configure-app-access-web-apis#application-permission-to-microsoft-graph)</a>.</li> <ul> <li>Directory.Read.All</li> <li>User.Read.All</li> </ul> </ul>";
                    },
                    onStepSave: async (StepData) => {
                        debugger;
                        var recordToSave = { ...serviceInstance.emptyRecord };
                        recordToSave.active = true;
                        recordToSave.cloudOSProviderId = 3;
                        recordToSave.vendorCredentialItem && recordToSave.vendorCredentialItem.push({
                            alias: "Wizard License",
                            id: 0,
                            vendorCredentialId: 0,
                            active: true,
                            keysSecret: JSON.stringify({
                                'ApplicationId': StepData["ApplicationId"],
                                'ApplicationSecret': StepData["ApplicationSecret"],
                                'Domain': "",
                                'User': "",
                                'Password': "",
                                'ExpireDate': "",
                            }),
                            secret: JSON.stringify({
                                'ApplicationId': StepData["ApplicationId"],
                                'ApplicationSecret': StepData["ApplicationSecret"],
                                'Domain': "",
                                'User': "",
                                'Password': "",
                                'ExpireDate': "",
                            }),
                            isDirty: false
                        });

                        try {
                            debugger;
                            return await serviceInstance.Create(recordToSave);

                        } catch (error: any) {

                            // Using `console.log` to log the error; consider a more robust logging approach for production
                            console.log(JSON.stringify(error));

                            // Checking if the error is an AxiosError and has a toJSON method
                            if (error && error.toJSON) {
                                console.log(error.toJSON());
                            }

                            console.log(error);

                            // Ensure the error handling logic is correct, especially how you extract and reformat the error message
                            if (error.response && error.response.data) {
                                const firstLine = error.response.data.split('\r\n')[0];
                                const message = firstLine.replace('System.Exception: ', '');
                                throw new Error(message); // Use `Error` instead of `Exception` which is not a standard JavaScript/TypeScript error type
                            } else {
                                throw new Error('An unexpected error occurred');
                            }
                        }
                    },
                    onStepValidation: async (StepData) => {
                        try {
                            debugger;
                            var result = await serviceInstance.CheckStatusForVendorCredential(3);
                            debugger;
                            return {
                                skipStep: result.length > 0,
                                validationPassed: false,
                                stepData: {},
                                log: result[0].logSync
                            };
                        } catch (e) {
                            return {
                                skipStep: true,
                                validationPassed: false,
                                stepData: {},
                                log: 'Falha ao tentar comunicar com o servidor'
                            };
                        }
                    },
                    fields: [
                        {
                            type: "password",
                            name: 'ApplicationId',
                            hidden: false,
                            disabled: false,
                            required: true,
                            label: 'Identificador do aplicativo',
                            tooltip: "",

                            regex: {
                                exp: /^(?:\{{0,1}(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}{0,1})$/,
                                help: 'Identificador do aplicativo deve ser um GUID.'
                            },
                        },
                        {
                            type: "password",
                            name: 'ApplicationSecret',
                            hidden: false,
                            disabled: false,
                            required: true,
                            label: 'Segredo do aplicativo',
                            tooltip: "",

                        },
                    ]
                },
                {
                    label: "Centro de Custo",
                    accordion: true,
                    description: (StepData): ReactNode => {
                        return "<ul> <li>Preencher com o campo no Active Directory que será utilizado como centro de custo - obrigatoriamente algum campo que não esteja em uso (OfficeLocation, country, state, etc...).</li> <li>Como usar o Centro Administrativo do Active Directory (ADAC):</li> <ul> <li>Abra o ADAC: Pressione a tecla Windows + R, digite dsac.exe e pressione Enter para abrir o Centro Administrativo do Active Directory.</li> <li>Encontre o Usuário: Use a caixa de pesquisa ou navegue pela estrutura do AD para encontrar a conta do usuário de seu interesse.</li> <li>Visualize as Propriedades: Dê um duplo clique no usuário para abrir suas propriedades. Procure pelo atributo que contém as informações do centro de custo. Ele pode estar listado em uma das abas, dependendo de como seu AD está organizado.</li> </ul> </ul> ";
                    },
                    onStepValidation: async (StepData) => {
                        try {
                            debugger;
                            var result = await saaSEAService.QuerySaaSSubscriptionCostCenterParameters();
                            if (result && result.length > 0) {
                                return {
                                    skipStep: result && result.length > 0,
                                    validationPassed: false,
                                    stepData: { 'CostCenterParameter': result[0].customField },
                                    log: ''
                                };
                            }
                            return {
                                skipStep: false,
                                validationPassed: true,
                                stepData: null,
                                log: ''
                            };
                        } catch (e) {
                            return "";
                        }
                    },
                    onSave: async (StepData) => {
                        if (!StepData)
                            return;

                        if (!StepData["CostCenterParameter"]) {
                            return;
                        }

                        var dataToSend = {
                            clientGroupId: 0,
                            vendorCredentialId: 0,
                            cloudOsProviderId: 0,
                            allowsImportLicenses: 0,
                            allowsImportCustomField: 0,
                            customField: StepData["CostCenterParameter"]
                        } as SaaSSubscriptionCostCenterParameters;

                        var result = await saaSEAService.SaveSaaSSubscriptionCostCenterParameters(dataToSend);
                    },
                    fields: [{
                        type: "text",
                        name: "CostCenterParameter",
                        label: "Centro de Custo",
                        required: true,
                        hidden: false,
                        disabled: false,
                        tooltip: "",
                        width: 400
                    }]
                }
            ] as unknown as FieldGroup[]
        }

        return arrayForm;
    }

    async LoadRetrieveDomain() { 
        this.setState({
            data: {
                ...this.state.data,
                EnumDomains: []
            }
        });

        var domains = await this.RetrieveDomains();

        if (domains?.data) {
            this.setState({
                data: {
                    ...this.state.data,
                    EnumDomains: domains.data
                }
            });
            return domains.data;
        }

        return domains.data;
    }

    async Initialize() {
        try {
            this.setState({
                data: {
                    ...this.state.data,
                    filterGridDefault: { 
                        start: 0, 
                        size: 15, 
                        filter: '', 
                        ordered: 'Name', 
                        direction: 'asc', 
                        dirSyncEnabled: 2, 
                        mailboxType: -1, 
                        login: '', 
                        name: '' 
                    }
                }
            });
            await Promise.all([this.LoadRetrieveDomain(), this.RetrieveVendorCredentials()]);
        } catch (e) {
            console.error(e);
        }
    }

    SetUsageLocation(usageLocation: string) {
        this.options = this.options.map(location => {
            return ({ ...location, selected: usageLocation == location.value ? true : false })
        });
    }

    getVendorCredentials() {
        let optionVendor: any[] = []

        this.state.data.EnumVendorCredentials && this.state.data.EnumVendorCredentials.forEach((element: any) => {
            if (element) {
                optionVendor.push(
                    { value: `${element.value}`, name: `${element.label}` },
                )
            }
        });
        return optionVendor;
    }

    getDomains() {
        let optDomain: any[] = [];
        this.state.data.EnumDomains && this.state.data.EnumDomains.map((e: any) => {
            optDomain.push(
                { value: e.value, name: e.label }
            )
        })
        return optDomain;
    }

    async RetrieveVendorCredentials() {
        const url = `${this.endpoint}/api/SaaSGrupos/GetVendorCredentials`;
        const stateData = { ...this.state };
        stateData.data.EnumVendorCredentials = [];
        this.setState({
            data: {
                ...this.state.data,
                EnumVendorCredentials: []
            }
        });
        try {
            const response = await api({
                method: 'get',
                url
            });
    
            if (response?.data) {
                this.setState({
                    data: {
                        ...this.state.data,
                        EnumVendorCredentials: response.data
                    }
                });
            }
        } catch (error) {
            console.error('Erro ao recuperar credenciais:', error);
        }
    }

    getFilter() {
        return this.state.data.filterGridDefault;
    }

    async UserServiceGet(filterDataFilter: any) {
        let filterData = filterDataFilter;
        if (filterDataFilter == null || filterDataFilter == undefined) {
            filterData = this.state.data.filterGridDefault;
        }
        const params = {
            start: filterData.start,
            size: 50,
            ordered: filterData.ordered,
            direction: filterData.direction,
            filter: filterData.filter,
            source: '',
            vendorPartNumber: '',
            hasSubscriptions: 0,
            AccountEnabled: 0,
            dirSyncEnabled: filterData.dirSyncEnabled,
            mailboxType: filterData.mailboxType,
            name: filterData.name
        };
        console.log(filterData);
        const url = this.endpoint + `/api/SaaSMembros/GetUsers`;

        return await api({
            method: 'get',
            url, params
        }).then(response => {
            response.data.userCollection = response.data.userCollection.map((user: any) => {
                return ({ ...user, id: user.objectId })
            });
            return response;
        });
    }

    async UserServiceGetUsersExcel() {

        const url = this.endpoint + `/api/SaaSMembros/GetUsersExcel`;

        return await api({
            method: 'get',
            url
        })
    }

    async GetUserServices() {

        const url = this.endpoint + `/api/SaaSMembros/GetUsersCSV`;
        var resultUsers = await api({
            method: 'get',
            url
        })

        const originalData = resultUsers && resultUsers.data;
        const expandedDataLocal: any[] = [];
        originalData.forEach(user => {
            try {
                if (!user.displayName.includes("Inativo") && !user.displayName.includes("Desligado")) {
                    const domain = user.userPrincipalName.split('@')[1]
                    if (user.licences) {
                        const skusArray = user.skuPartNumbers.split(',');
                        const skuPartPrepaidUnitsArray = user.skuPartPrepaidUnits.split(',');
                        const lncsArray = user.licences.split(',');
                        lncsArray.forEach((licence, index) => {
                            expandedDataLocal.push({
                                disName: user.displayName.trim(),
                                userPrincipalName: user.userPrincipalName,
                                jobTitle: user.jobTitle,
                                lncs: licence.trim(),
                                sku: skusArray[index].trim(),
                                avali: Number(skuPartPrepaidUnitsArray[index].trim()),
                                count: Number(1),
                                domain: domain,
                                statusUser: user.accountEnabled ? "Ativo" : "Desativado"
                            });
                        });
                    } else {
                        // Adicionando uma entrada com licenças em branco
                        expandedDataLocal.push({
                            disName: user.displayName,
                            userPrincipalName: user.userPrincipalName,
                            jobTitle: user.jobTitle,
                            lncs: "Nenhuma",
                            sku: "Nenhuma",
                            count: Number(0),
                            avali: Number(0),
                            domain: domain,
                            statusUser: user.accountEnabled ? "Ativo" : "Desativado"
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }
        });
        // Use map to extract the userPrincipalName from each object
        const userPrincipalNames = expandedDataLocal.map(item => item.userPrincipalName);

        // Create a set from the userPrincipalNames array to ensure uniqueness
        const uniqueUserPrincipalNames = new Set(userPrincipalNames);

        // Now you have a set of unique userPrincipalNames

        // If you need to work with the set as an array later, you can convert it back to an array
        const uniqueUserPrincipalNamesArray = Array.from(uniqueUserPrincipalNames);

        var domains = await this.getTopDomainsByUser(originalData);
        var softwares = await this.contarLicencasDistintas(expandedDataLocal);
        var userCount = uniqueUserPrincipalNamesArray.length;

        return { domains: domains, pivot: expandedDataLocal, softwares: softwares, userCount: userCount };
    }

    async getTopDomainsByUser(array: any): Promise<DomainInfoModel> {
        let usuarios = array.filter(user => !user.displayName.includes("Inativo") && !user.displayName.includes("Desligado"))
        let dominios = {};
        let totalUsuarios = usuarios.length;

        usuarios.forEach(usuario => {
            let dominio = usuario.userPrincipalName.split('@')[1];
            if (dominios[dominio]) {
                dominios[dominio]++;
            } else {
                dominios[dominio] = 1;
            }
        });

        let dominioMaisComum = "";
        let quantidadeMaisComum = 0;
        for (let dominio in dominios) {
            if (dominios[dominio] > quantidadeMaisComum) {
                dominioMaisComum = dominio;
                quantidadeMaisComum = dominios[dominio];
            }
        }

        let porcentagemDeUso = (quantidadeMaisComum / totalUsuarios) * 100;

        return {
            mostComumDomain: dominioMaisComum,
            porcentege: porcentagemDeUso.toFixed(2) + "%",
            quantity: quantidadeMaisComum
        };
    }
    contarLicencasDistintas(array) {
        let lncsDistintas = new Set();
        array.forEach(item => {
            lncsDistintas.add(item.lncs);
        });
        return lncsDistintas.size;
    }
    async GetGroupsByUser(userEmail: string) {
        const url = this.endpoint + `/api/SaaSGrupos/GetGroupsByUser?userEmail=${userEmail}`;
        return await api({
            method: 'get',
            url
        })
    }

    async GetGroupsByClientGroup() {
        const url = this.endpoint + `/api/SaaSGrupos/GetGroupsByClientGroupId`;
        return await api({
            method: 'get',
            url
        })
    }

    CreateUserGroupLinks(items: any[]) {
        const url = `${this.endpoint}/api/SaaSMembros/CreateLinks`;
        var response = api({
            method: 'post',
            url,
            data: items,
            withCredentials: false,
        });
        return response;
    }

    async GetUserDetail(userId: string) {
        const url = this.endpoint + `/api/SaaSMembros/GetUserDetail?userId=${userId}`;
        return api({
            method: 'get',
            url,
        })
    }

    async GetUserMemberOf(userId: string) {
        const url = this.endpoint + `/api/SaaSMembros/GetUserMemberOf?userId=${userId}`;
        return api({
            method: 'get',
            url,
        })
    }

    async GetPermissions(userEmail: string) {
        const url = this.endpoint + `/api/SaaSMembros/GetPermissions`;
        let param = {
            UserEmail: userEmail
        };
        const data = JSON.stringify(param);
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async CheckMailBox(userEmail: string) {
        const url = this.endpoint + `/api/SaaSMembros/CheckMailbox`;
        let param = {
            UserEmail: userEmail
        };
        const data = JSON.stringify(param);
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    ConvertMailBox(isShared: boolean, userEmail: string) {
        const url = this.endpoint + `/api/SaaSMembros/ConvertMailbox?isShared=${isShared}`;
        let param = {
            UserEmail: userEmail
        };
        const data = JSON.stringify(param);
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async AddPermissoesSendMailBox(userEmail: string, userAccess: string) {
        const url = this.endpoint + `/api/SaaSMembros/AddPermissoesSendMailBox`;
        let param = {
            User: userEmail,
            UserAccess: userAccess
        };
        const data = JSON.stringify(param);
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async RemovePermissoesSendMailBox(userEmail: string, userAccess: string) {
        const url = this.endpoint + `/api/SaaSMembros/RemovePermissoesSendMailBox`;
        let param = {
            User: userEmail,
            UserAccess: userAccess
        };
        const data = JSON.stringify(param);
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async AddPermissoesMailBox(userEmail: string, userAccess: string) {
        const url = this.endpoint + `/api/SaaSMembros/AddPermissoesMailBox`;
        let param = {
            User: userEmail,
            UserAccess: userAccess
        };
        const data = JSON.stringify(param);
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async RemovePermissoesMailBox(userEmail: string, userAccess: string) {
        const url = this.endpoint + `/api/SaaSMembros/RemovePermissoesMailBox`;
        let param = {
            User: userEmail,
            UserAccess: userAccess
        };
        const data = JSON.stringify(param);
        return api({
            method: 'post',
            url,
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    async UserServiceGetDetail() {
        const url = `${this.endpoint}/api/SaaSMembros/GetUsersAllInfo`;

        return api({
            method: 'get',
            url
        })
    }

    async DeleteUserById(idUser: string) {
        const url = `${this.endpoint}/api/SaaSMembros/DeleteUser?userId=${idUser}`

        return api({
            method: 'delete',
            url
        })
    }

    async UpdateUser(_user: any) {
        let _userO = { ..._user }

        delete _userO["@odata.context"];
        delete _userO["@odata.type"];
        delete _userO.responseHeaders;
        _userO.userPrincipalName = `${_user.alias}@${_user.domain}`;
        _userO.mail = `${_user.alias}@${_user.domain}`;
        _userO.mailNickname = `${_user.alias}`;
        _userO.displayName = `${_user.givenName} ${_user.surname}`;
        const url = `${this.endpoint}/api/SaaSMembros/UpdateUser`
        const data = JSON.stringify(_userO)
        return api({
            method: 'put',
            url,
            data: data, headers: {
                'Content-Type': 'application/json'
            }
        })

    }

    GetUnusedLicenses(days: number) {
        const url = `${this.endpoint}/api/UnusedLicense/GetUnusedLicenseByClientGroupId?days=${days}`;
        return api({
            method: 'get',
            url
        })

    }

    async GetGroup(clientGroupId: number) {
        const url = `${this.endpoint}/api/GroupService/ClientGroup/${clientGroupId}`

        return api({
            method: 'get',
            url
        })
    }

    async ValidatePassword(password: string) {
        const url = `${this.endpoint}/api/UserService/PasswordValidate?password=${password}`


        return api({
            method: 'post',
            url
        })
    }

    ChangePassword(model: any) {
        const url = `${this.endpoint}/api/SaaSMembros/UserSaaSChangePassword`
        return api({
            method: 'post',
            url,
            data: model,
            headers: {
                'Content-Type': 'application/json'
            }
        })
    }

    GetSubcribeSKU(idUser: string) {
        const url = `${this.endpoint}/api/SubscribeSKU/GetSubscribeByUserId?userId=${idUser}`;
        return api({
            method: 'get',
            url
        })
    }

    SendLicence(_data: SaaSLicencasViewModel) {
        try {
            console.log('SendLicence')
            const url = this.endpoint + '/api/SubscribeSKU/SendLicense';
            console.log(url)
            var data = JSON.stringify(_data);
            console.log(data)
            return api({
                method: 'post',
                url,
                data,
                headers: {
                    'Content-Type': 'application/json'
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    async CreateUserSaaS(_user: SaaSUser, _vendorCredentialItem: any) {
        _user.passwordProfile = {
            Password: _user.passwordProfile_Password,
            ForceChangePasswordNextSignIn: _user.passwordProfile_ForceChangePasswordNextSignIn
        };
        _user.userPrincipalName = `${_user.alias}@${_user.domain}`;
        _user.mail = `${_user.alias}@${_user.domain}`;
        _user.mailNickname = `${_user.alias}`;
        _user.displayName = `${_user.givenName} ${_user.surname}`;
        const url = `${this.endpoint}/api/SaaSMembros/CreateUser`;
        let userData = {
            VendorId: _vendorCredentialItem,
            User: _user,
        };

        var data = JSON.stringify(userData);
        console.log(data)
        return api({
            method: 'post',
            url,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    async CreateSaaSUser(_user: SaaSUser) {
        _user.passwordProfile = {
            Password: _user.passwordProfile_Password,
            ForceChangePasswordNextSignIn: _user.passwordProfile_ForceChangePasswordNextSignIn
        };
        _user.userPrincipalName = `${_user.alias}@${_user.domain}`;
        _user.mail = `${_user.alias}@${_user.domain}`;
        _user.mailNickname = `${_user.alias}`;
        _user.displayName = `${_user.givenName} ${_user.surname}`;
        const url = `${this.endpoint}/api/SaaSMembros/CreateUser`;
        let userData = {
            VendorId: _user.signature,
            User: _user,
        };
        var data = JSON.stringify(userData);

        return api({
            method: 'post',
            url,
            data,
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }

    async GetVendorCredential() {
        const url = this.endpoint + '/api/VendorCredential/GetVendorCredentialStatusbyClientGroupId';
        return api({
            method: 'get',
            url
        })
    }

    async RetrieveDomains() {
        const url = this.endpoint + '/api/LCTech/GetDomains';
        return api({
            method: 'get',
            url
        })
    }

    ActionsBaseFunctions(createAction: any, CloseActions: any) {

        let arrayActions = [
            {
                id: "6064bde7-0f50-4603-aba3-eae7e3860eff",
                title: 'Salvar',
                tooltip: 'Salvar',
                icon: 'ppi ppi-check',
                func: (item: any) => {
                    createAction(item);
                },
                disabled: false,
                skipvalidation: false
            },
            {
                id: "39347269-436f-42ab-b36e-7be92fae83f2",
                title: 'Cancelar',
                tooltip: 'Cancelar',
                icon: 'ppi ppi-arrow-left',
                func: () => {
                    if (CloseActions) {
                        CloseActions();
                    }
                },
                disabled: false,
                skipvalidation: true
            }
        ];

        return arrayActions;
    }

    options = [
        { value: "BR", label: "Brazil" },
        { value: "AF", label: "Afghanistan" },
        { value: "AL", label: "Albania" },
        { value: "DZ", label: "Algeria" },
        { value: "AR", label: "Argentina" },
        { value: "AM", label: "Armenia" },
        { value: "AU", label: "Australia" },
        { value: "AT", label: "Austria" },
        { value: "AZ", label: "Azerbaijan" },
        { value: "BH", label: "Bahrain" },
        { value: "BD", label: "Bangladesh" },
        { value: "BY", label: "Belarus" },
        { value: "BE", label: "Belgium" },
        { value: "BZ", label: "Belize" },
        { value: "BT", label: "Bhutan" },
        { value: "VE", label: "Bolivarian Republic of Venezuela" },
        { value: "BO", label: "Bolivia" },
        { value: "BA", label: "Bosnia and Herzegovina" },
        { value: "BW", label: "Botswana" },
        { value: "BN", label: "Brunei Darussalam" },
        { value: "BG", label: "Bulgaria" },
        { value: "KH", label: "Cambodia" },
        { value: "CM", label: "Cameroon" },
        { value: "CA", label: "Canada" },
        { value: "029", label: "Caribbean" },
        { value: "CL", label: "Chile" },
        { value: "CO", label: "Colombia" },
        { value: "CD", label: "Congo (DRC)" },
        { value: "CR", label: "Costa Rica" },
        { value: "CI", label: "Côte d’Ivoire" },
        { value: "HR", label: "Croatia" },
        { value: "CU", label: "Cuba" },
        { value: "CZ", label: "Czech Republic" },
        { value: "DK", label: "Denmark" },
        { value: "DJ", label: "Djibouti" },
        { value: "DO", label: "Dominican Republic" },
        { value: "EC", label: "Ecuador" },
        { value: "EG", label: "Egypt" },
        { value: "SV", label: "El Salvador" },
        { value: "ER", label: "Eritrea" },
        { value: "EE", label: "Estonia" },
        { value: "ET", label: "Ethiopia" },
        { value: "FO", label: "Faroe Islands" },
        { value: "FI", label: "Finland" },
        { value: "FR", label: "France" },
        { value: "GE", label: "Georgia" },
        { value: "DE", label: "Germany" },
        { value: "GR", label: "Greece" },
        { value: "GL", label: "Greenland" },
        { value: "GT", label: "Guatemala" },
        { value: "HT", label: "Haiti" },
        { value: "HN", label: "Honduras" },
        { value: "HK", label: "Hong Kong S.A.R." },
        { value: "HU", label: "Hungary" },
        { value: "IS", label: "Iceland" },
        { value: "IN", label: "India" },
        { value: "ID", label: "Indonesia" },
        { value: "IR", label: "Iran" },
        { value: "IQ", label: "Iraq" },
        { value: "IE", label: "Ireland" },
        { value: "PK", label: "Islamic Republic of Pakistan" },
        { value: "IL", label: "Israel" },
        { value: "IT", label: "Italy" },
        { value: "JM", label: "Jamaica" },
        { value: "JP", label: "Japan" },
        { value: "JO", label: "Jordan" },
        { value: "KZ", label: "Kazakhstan" },
        { value: "KE", label: "Kenya" },
        { value: "KR", label: "Korea" },
        { value: "KW", label: "Kuwait" },
        { value: "KG", label: "Kyrgyzstan" },
        { value: "LA", label: "Lao P.D.R." },
        { value: "419", label: "Latin America" },
        { value: "LV", label: "Latvia" },
        { value: "LB", label: "Lebanon" },
        { value: "LY", label: "Libya" },
        { value: "LI", label: "Liechtenstein" },
        { value: "LT", label: "Lithuania" },
        { value: "LU", label: "Luxembourg" },
        { value: "MO", label: "Macao S.A.R." },
        { value: "MY", label: "Malaysia" },
        { value: "MV", label: "Maldives" },
        { value: "ML", label: "Mali" },
        { value: "MT", label: "Malta" },
        { value: "MX", label: "Mexico" },
        { value: "MD", label: "Moldova" },
        { value: "MN", label: "Mongolia" },
        { value: "ME", label: "Montenegro" },
        { value: "MA", label: "Morocco" },
        { value: "MM", label: "Myanmar" },
        { value: "NP", label: "Nepal" },
        { value: "NL", label: "Netherlands" },
        { value: "NZ", label: "New Zealand" },
        { value: "NI", label: "Nicaragua" },
        { value: "NG", label: "Nigeria" },
        { value: "MK", label: "North Macedonia" },
        { value: "NO", label: "Norway" },
        { value: "OM", label: "Oman" },
        { value: "PA", label: "Panama" },
        { value: "PY", label: "Paraguay" },
        { value: "CN", label: "People's Republic of China" },
        { value: "PE", label: "Peru" },
        { value: "PH", label: "Philippines" },
        { value: "PL", label: "Poland" },
        { value: "PT", label: "Portugal" },
        { value: "MC", label: "Principality of Monaco" },
        { value: "PR", label: "Puerto Rico" },
        { value: "QA", label: "Qatar" },
        { value: "RE", label: "Réunion" },
        { value: "RO", label: "Romania" },
        { value: "RU", label: "Russia" },
        { value: "RW", label: "Rwanda" },
        { value: "SA", label: "Saudi Arabia" },
        { value: "SN", label: "Senegal" },
        { value: "RS", label: "Serbia" },
        { value: "SG", label: "Singapore" },
        { value: "SK", label: "Slovakia" },
        { value: "SI", label: "Slovenia" },
        { value: "SO", label: "Somalia" },
        { value: "ZA", label: "South Africa" },
        { value: "ES", label: "Spain" },
        { value: "LK", label: "Sri Lanka" },
        { value: "SE", label: "Sweden" },
        { value: "CH", label: "Switzerland" },
        { value: "SY", label: "Syria" },
        { value: "TW", label: "Taiwan" },
        { value: "TJ", label: "Tajikistan" },
        { value: "TH", label: "Thailand" },
        { value: "TT", label: "Trinidad and Tobago" },
        { value: "TN", label: "Tunisia" },
        { value: "TR", label: "Turkey" },
        { value: "TM", label: "Turkmenistan" },
        { value: "AE", label: "U.A.E." },
        { value: "UA", label: "Ukraine" },
        { value: "GB", label: "United Kingdom" },
        { value: "US", label: "United States" },
        { value: "UY", label: "Uruguay" },
        { value: "UZ", label: "Uzbekistan" },
        { value: "VN", label: "Vietnam" },
        { value: "001", label: "World" },
        { value: "YE", label: "Yemen" },
        { value: "ZW", label: "Zimbabwe" }
    ]

}