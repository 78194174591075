import React, { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom'
import Layout from "../../../components/Layout/Layout";
import { LCTechService } from "../../../services/LCTech/LCTechService";
// import { makeStyles, Theme } from "@material-ui/core/styles";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../store";
import LcLoading from "../../../components/Generic/LcLoading";
import { TechEAEASService } from "../../../services/EASubscriptionService/techEAEASService";
import Moment from 'moment';

//Img Fluxo
import { SaasService } from "../../../services/saas/saasService";
import ImgFluxoEAEAS from "../../../assets/images/Fluxos/eaeas.png";
import ImgFluxoCSP from "../../../assets/images/Fluxos/csp.png";
import { useSnackbar } from 'notistack';

import exportFromJSON from "export-from-json";
import { MenuState } from "../../../store/reducers/menuReducer";
import PivotTableWebix from "../../../components/webix";
import { PiDownloadLight, PiEyeLight, PiEyeSlashLight, PiInfoLight } from "react-icons/pi";
import { useQuery } from "react-query";
import { UserState } from "../../../store/reducers/userReducer";

const pivotCheckFilters = () => {
    if (localStorage !== null) {
        for (let i = 0; i < localStorage.length; i++) {
            let key: string = localStorage.key(i) || '';
            let value = localStorage.getItem(key) && JSON.parse(localStorage.getItem(key) || '');
            if (key.match('CSP/LCTechUsage')) {
                return value
            }
        }
    }
}
function sCdateToJsDate(cSDate: string): Date {
    if (!cSDate) {
        return new Date();
    }

    const date = new Date(Date.parse(cSDate));

    if (isNaN(date.getTime())) {
        throw new Error('Invalid date format');
    }

    return date;
}

function translateKey(key: string): string {
    const translations: { [key: string]: string } = {
        "customerName": "Empresa",
        "entitlementName": "Assinatura",
        "resourceGroupName": "Grupo do Recurso",
        "resourceName": "Recurso",
        "meterCategory": "Categoria",
        "meterSubCategory": "SubCategoria",
        "tag": "Tags",
        "unitOfMeasure": "Unidade",
        "currencyCode": "Moeda",
        "meterName": "Nome do Medidor",
        "year": "Ano Consumo",
        "month": "Mês Consumo",
        "day": "Dia Consumo",
        "quantityUsed": "Quantidade",
        "totalCost": "Total"
    };

    return translations[key] || key;
}

const LCTechUsage: React.FC = (props) => {
    const ServiceInstance = new LCTechService(props);
    const { enqueueSnackbar } = useSnackbar();
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const dispatch = useDispatch();
    const history = useHistory();
    const local = document.location.pathname;
    const user = useSelector<RootState, UserState>(state => state.user);
    const userPeriodSelected = user.PeriodSelection ? user.PeriodSelection[local] : undefined;
    const [consumoToUpper, setConsumoToUpper] = useState(true);
    const [showFluxoEAEAS, setShowFluxoEAEAS] = useState<boolean>(false);
    const [month, setMonth] = useState<string>(userPeriodSelected ? userPeriodSelected.fields[0].value.split('-')[1] : ((new Date()).getMonth() + 1).toString());
    const [year, setYear] = useState<string>(userPeriodSelected ? userPeriodSelected.fields[0].value.split('-')[0] : (new Date()).getFullYear().toString());
    const [footer, setFooter] = useState<string>('-');
    const [resourceUsagePivotRecords, setResourceUsagePivotRecords] = useState<any[]>([]);
    const optionsDataX = { year: 'numeric', month: '2-digit', day: '2-digit', hour: "2-digit", minute: '2-digit' };
    const [showFluxoCSP, setShowFluxoCSP] = useState<boolean>(false);

    const fetchUsageData = async () => {
        const ServiceInstance = new LCTechService({});
        const response = await ServiceInstance.RetrieveUsage(1, consumoToUpper, month, year);
        return response.data;
    };

    const getPreviousDays = (targetDay, targetMonth, targetYear, daysBack) => {
        const previousDays: any[] = [];
        let currentDate = new Date(targetYear, targetMonth - 1, targetDay); // Criar data inicial (ajustando mês, pois o Date usa 0-11)

        for (let i = 0; i < daysBack; i++) {
            currentDate.setDate(currentDate.getDate() - 1);
            previousDays.push({
                day: currentDate.getDate(),
                month: currentDate.getMonth() + 1,
                year: currentDate.getFullYear()
            });
        }

        return previousDays;
    }


    const { isLoading } = useQuery(
        ['retrieveUsage', true, month, year, consumoToUpper],
        fetchUsageData,
        {
            refetchOnWindowFocus: false,
            retry: 1,
            onSuccess: (data) => {
                if (data) {
                    try {
                        if (data.resourceUsageRecords?.length > 0) {
                            const today = new Date();
                            const currentDay = today.getDate();

                            // Calcular D-2
                            const d2Date = new Date(today);
                            d2Date.setDate(today.getDate() - 1);
                            const d2Day = d2Date.getDate();
                            const d2Month = d2Date.getMonth() + 1;
                            const d2Year = d2Date.getFullYear();

                            const resourcePivot = data.resourceUsageRecords.map((resourceUsage) => ({
                                ...resourceUsage,
                                month: sCdateToJsDate(resourceUsage.usageDate).getMonth() + 1,
                                day: sCdateToJsDate(resourceUsage.usageDate).getDate(),
                                year: sCdateToJsDate(resourceUsage.usageDate).getFullYear(),
                                quantityUsed: parseInt(resourceUsage.quantityUsed),
                            }));

                            let filteredRecords = resourcePivot;
                            if (currentDay === 1 || currentDay === 2) {
                                const daysToCheck = getPreviousDays(currentDay === 1 ? d2Day : (d2Day - 1), d2Month, d2Year, 90);

                                filteredRecords = resourcePivot.filter(record =>
                                    daysToCheck.some(({ day, month, year }) =>
                                        record.day === day &&
                                        record.month === month &&
                                        record.year === year
                                    )
                                );
                            }

                            const sortedRecords = filteredRecords.sort((a: { year: number; month: number; day: number | undefined; }, b: { year: number; month: number; day: number | undefined; }) =>
                                new Date(a.year, a.month - 1, a.day).getTime() - new Date(b.year, b.month - 1, b.day).getTime()
                            );

                            setResourceUsagePivotRecords(sortedRecords);
                        }
                        const footerMessage = buildFooterMessage(data.lastModifiedDate, optionsDataX);
                        setFooter(footerMessage);
                    } catch (e) {
                        console.error('Erro ao processar os dados de sucesso:', e);
                    }
                }
            }, onError: (erro) => {
                console.error('Erro ao processar os dados de erro:', erro);
            }
        }
    );

    const buildFooterMessage = (lastModifiedDate: string, optionsDataX: object) => {
        const lastModified = new Date(lastModifiedDate);
        const firstDay = new Date(lastModified.getFullYear(), lastModified.getMonth(), 1);

        return `\n\nPeríodo de apuração: ${firstDay.toLocaleDateString('pt-BR', optionsDataX)} a ${lastModified.toLocaleDateString('pt-BR', optionsDataX)}. 
        Valores estimados, sujeitos a validação e políticas de contabilização do vendor. 
        Sync ${lastModified.toLocaleDateString('pt-BR', optionsDataX)}`;
    };

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },

        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    // let formato = { minimumFractionDigits: 2, maximumFractionDigits: 2 };


    // const columnsResourceUsageRecords = [
    //     {
    //         field: "entitlementName", headerName: "Assinatura", width: "15%", align: "left", overflow: "auto"
    //     },
    //     {
    //         field: "resourceGroupName", headerName: "Grupo do Recurso", width: "15%", align: "left",
    //     },
    //     {
    //         field: "resourceName", headerName: "Recurso", width: "25%", align: "left", fontSize: "small",
    //         renderCell: (row: any) => (
    //             <div style={{ wordBreak: "break-word" }}>{row.resourceName}</div>
    //         )
    //     },
    //     {
    //         field: "totalCost", headerName: "Total", width: "10%", overflow: "auto",
    //         align: "right",
    //         renderCell: (row: any) => (
    //             <div>{(row.totalCost && row.totalCost.toLocaleString('pt-BR', formato))} </div>
    //         ),
    //     },
    //     {
    //         field: 'tags', headerName: 'Tags ', width: "25%", overflow: "auto", fontSize: "small",
    //         renderCell: (row: any) => {
    //             return <div style={{ wordBreak: "break-word" }}>{row.tags}</div>;
    //         }
    //     }
    // ];

    // useEffect(() => {
    //     // setLoading(true);
    //     RetrieveList();
    // }, [user.ClientGroupSelected, user.refreshFlag]);


    // #region Verifica se há licença EA/EAS
    useEffect(() => {
        if (menu && menu.selectedItem && menu.selectedItem.id === "cedcb443-a23e-4545-904b-5d1061e718aa") {
            const instance = new TechEAEASService({});
            instance.GetSaasLicenseByCountryTotalView()
                .then((response) => {
                    setShowFluxoEAEAS(response.data.length === 0);
                })
                .catch((error) => {
                    setShowFluxoEAEAS(true);
                });
        }
    }, [menu]);
    // #endregion

    // #region Verifica se há licença EA/EAS

    useEffect(() => {
        if (menu && menu.selectedItem && menu.selectedItem.id === "67aa56e4-ad63-44b0-aa27-d2d2a8665a63") {
            const saasService = new SaasService({});
            saasService
                .GetbyClientGroupIdAndTileId("13")
                .then((response) => {
                    let asGTMCSP = response.data;
                    setShowFluxoCSP(!asGTMCSP);
                })
                .catch((error) => {
                    console.warn(error);
                });
        }

        // if (menu && menu.selectedItem && menu.selectedItem.id === "F07FD64E-3766-4551-B70A-811390EEED96") setIsSaaS(true);
    }, [menu]);
    //#endregion

    const exportToCSV = () => {
        enqueueSnackbar(`Gerando arquivo`, {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        });

        const filters = pivotCheckFilters();
        const data = resourceUsagePivotRecords.map((record) => {
            const item: any = {};
            filters.rows.forEach((row: string) => {
                if (record.hasOwnProperty(row)) {
                    item[translateKey(row)] = record[row];
                }
            });
            filters.columns.forEach((column: string) => {
                if (record.hasOwnProperty(column)) {
                    item[translateKey(column)] = record[column];
                }
            });
            filters.values.forEach((column: any) => {
                if (record.hasOwnProperty(column.name)) {
                    item[translateKey(column.name)] = record[column.name];
                }
            });
            return item;
        });
        const fileName = 'LCTechCSPUsage';
        const exportType = 'csv';
        const withBOM = true;
        const fields = [...filters.rows.map(translateKey), ...filters.columns.map(translateKey), ...filters.values.map(val => translateKey(val.name))];
        exportFromJSON({
            data: data,
            fields: fields,
            fileName: fileName,
            exportType: exportType,
            withBOM: withBOM,
            delimiter: ';',
        });

        enqueueSnackbar(`Arquivo gerado com sucesso`, {
            variant: 'success',
            preventDuplicate: true,
            persist: false,
        });
    };

    return (
        <Layout
            periodSelection={periodSelection}
            pageTitle={ServiceInstance.title}
            pivot
            functionsGeneric={[{
                icon: <PiInfoLight />,
                tooltip: () => {
                    return <div className="">
                        {
                            (footer)
                            && < div style={{ width: '90%', height: '25px', borderRadius: '5px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span style={{ color: 'var(--text-default)', fontSize: '8pt' }}>
                                    {footer}
                                </span>
                            </div>
                        }
                    </div>
                },
                onClick: () => null
            },
            { icon: <PiDownloadLight />, onClick: () => exportToCSV(), tooltip: "Exportar para .csv" },
            { icon: consumoToUpper ? <PiEyeLight /> : <PiEyeSlashLight />, onClick: () => setConsumoToUpper(!consumoToUpper), tooltip: consumoToUpper ? "Visualizar padrão Microsoft" : "Não visualizar padrão Microsoft" },
            ]}
        >
            <LcLoading loading={isLoading}  >
                {showFluxoEAEAS ? (
                    <div className="lc-segment" style={{ width: '100%' }}>
                        <div className="body" style={{ width: '100%' }}>
                            Para o funcionamento completo da solução é necessário que todos os
                            passos do fluxo abaixo estejam corretamente executados, no
                            momento, não foi possível acessar todas as informações, por favor,
                            revise os passos abaixo. Caso precise de ajuda abra uma
                            requisição, clicando {" "}
                            <p
                                className="link text-link"
                                onClick={() => {
                                    history.push("/Chamados");
                                    dispatch({ type: 'MENU_NEW_REQUEST', payload: true })
                                }}
                            >
                                aqui
                            </p>
                            , e teremos o prazer em atender.
                            <img src={ImgFluxoEAEAS} width="100%" alt="Procedimento" />
                        </div>
                    </div>
                ) : showFluxoCSP ?
                    (
                        <div className="lc-segment">
                            <div className="body">
                                Para o funcionamento completo da solução é necessário que todos os
                                passos do fluxo abaixo estejam corretamente executados, no
                                momento, não foi possível acessar todas as informações, por favor,
                                revise os passos abaixo. Caso precise de ajuda abra uma
                                requisição, clicando{" "}
                                <span className="link text-link"
                                    onClick={() => {
                                        history.push("/Chamados");
                                        dispatch({ type: 'MENU_NEW_REQUEST', payload: true })
                                    }}
                                >
                                    aqui
                                </span>
                                , e teremos o prazer em atender.
                                <img src={ImgFluxoCSP} width="100%" alt="Procedimento" />
                            </div>
                        </div>
                    )
                    :
                    (<div style={{ minWidth: '100%' }}>
                        {(resourceUsagePivotRecords != null && resourceUsagePivotRecords.length > 0) &&
                            <PivotTableWebix
                                style={{ minWidth: '100%' }}
                                idView={month}
                                key={month}
                                data={resourceUsagePivotRecords}
                                fields={[
                                    { id: "customerName", value: "Empresa", type: "text" },
                                    { id: "entitlementName", value: "Assinatura", type: "text" },
                                    { id: "resourceGroupName", value: "Grupo do Recurso", type: "text" },
                                    { id: "resourceName", value: "Recurso", type: "text" },
                                    { id: "customerName", value: "Empresa", type: "text" },
                                    { id: "meterCategory", value: "Categoria", type: "text" },
                                    { id: "meterSubCategory", value: "SubCategoria", type: "text" },
                                    { id: "tag", value: "Tags", type: "text" },
                                    { id: "unitOfMeasure", value: "Unidade", type: "text" },
                                    { id: "currencyCode", value: "Moeda", type: "text" },
                                    { id: "meterName", value: "Nome do Medidor", type: "text" },
                                    { id: "year", value: "Ano", type: "text" },
                                    { id: "month", value: "Mês", type: "text" },
                                    { id: "day", value: "Dia", type: "number" },
                                    { id: "quantityUsed", value: "Quantidade", type: "number" },
                                    { id: "totalCost", value: "Total", type: "number" },
                                ]}
                                structure={{
                                    rows: ["customerName", "entitlementName", "resourceGroupName", "currencyCode"],
                                    columns: ['year', 'month'],
                                    values: [{ name: "totalCost", operation: ["sum"] }]
                                }}
                            />
                        }
                    </div>
                    )}
            </LcLoading>
        </Layout >
    );
};

export default LCTechUsage;
