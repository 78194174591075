import { PPDropMenu } from 'processor-plataform-ui';
import React, { useState } from 'react';
import LcIconLink from '../LcIconLink';
import { AiOutlineSound } from 'react-icons/ai';
import { BsArrowsAngleContract, BsArrows, BsType } from 'react-icons/bs';
import { PiMagicWand } from 'react-icons/pi';
import HHContentAI from '../../../pages/Heyho/HHContentAI';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { FormDataHeyho } from '../../../services/Heyho/heyhoModel';

interface HHComponentAIProps {
    originalText: string;
    visible: boolean;
    handleVisible: Function;
    setText: Function;
    setUpdateField(e: boolean): void;
    formData: FormDataHeyho
    onChangeForm(e: any): void;
}
const changeToneOpts = {
    icon: <AiOutlineSound />,
    text: 'Mudar tom',
    subMenu: [
        {
            text: 'Comunicativo',
            value: 6
        },
        {
            text: 'Bem-humorado',
            value: 7
        },
        {
            text: 'Entusiasmado',
            value: 8
        },
        {
            text: 'Informativo',
            value: 9
        },
        {
            text: 'Profissional',
            value: 10
        },
        {
            text: 'Inteligente',
            value: 11
        },
        {
            text: 'Confiável',
            value: 12
        },
    ],
    value: 4
}
const AIOpts = [
    {
        icon: <PiMagicWand />,
        text: 'Elaborar',
        value: 1
    },
    {
        icon: <BsArrowsAngleContract style={{ transform: ' rotate(45deg)' }} />,
        text: 'Reduzir',
        value: 2
    },
    {
        icon: <BsArrows />,
        text: 'Expandir',
        value: 3
    },
    {
        icon: <BsType />,
        text: 'Corrigir ortografia',
        value: 5
    },
    {
        icon: <AiOutlineSound />,
        text: 'Mudar tom ',
        submenu: true,
        value: 4
    }
    
]

const HHComponentAI: React.FC<HHComponentAIProps> = (props) => {
    const [sugestaoExp, setSugestaoExp] = useState<boolean>(false);
    const [openAIMenu, setOpenAIMenu] = useState<boolean>(false);
    const [AiSelected, setAiSelected] = useState<number>(0);
    const [AiSelectedMenu, setAiSelectedMenu] = useState<number>(0);
    const user = useSelector<RootState, UserState>(state => state.user);
    return (
        <>
        <div className='AiMenu' style={{marginTop: '-145px'}}>
            <LcIconLink
                icon='ppi ppi-lightning-bolt'
                id='aiLCChamados'
                degrade
                pulse
                onClick={() => {
                    setOpenAIMenu(!openAIMenu)
                    props.setUpdateField(true)
                }}
                tooltip={!props.formData.Descricao ?  
                    "Digite algo para habilitar o Assistente de AI" : "Assistente de AI"
                }
                tooltipPosition='right'
                disabled={!props.formData.Descricao}
                />
            {
            openAIMenu &&
            <PPDropMenu
                subItems={
                    AiSelectedMenu === 4 &&
                    changeToneOpts.subMenu.map(sub => {
                        return (
                            <div className={'item AiMenuItem'} onClick={(e) => {
                                    setSugestaoExp(true);
                                    setAiSelectedMenu(sub.value)
                                    setAiSelected(sub.value)
                                    setOpenAIMenu(false);
                            }}>
                                <div className="row item">
                                    <span style={{ fontSize: '12px' }}>{sub.text}</span>
                                </div>
                            </div>
                        )
                    })
                }
                items={
                <>
                    {
                    AIOpts.map(Ai => {
                            return (
                                <div className={'item AiMenuItem'} onClick={(e) => {
                                    setAiSelectedMenu(Ai.value)
                                    if(Ai.value !== 4) {
                                        setAiSelected(Ai.value)
                                        setSugestaoExp(true); 
                                        setOpenAIMenu(false); 
                                    }else {
                                        AiSelectedMenu === 4 && setAiSelectedMenu(0)
                                    }
                                    }}>
                                    <div className="row item ">
                                        {Ai.icon}
                                        <span style={{ fontSize: '12px' }}>{Ai.text}</span>
                                    </div>
                                    { Ai.submenu && <i className="ppi ppi-chevron-left" />}
                                </div>
                            )
                        })
                    }
                    </>
                }
                onLeave={() => {setOpenAIMenu(!openAIMenu); setAiSelectedMenu(0)}}
                    position='right'
            />
            }
        </div>
        <div className='w-100p' style={{marginTop: '-10px'}}>
            <HHContentAI 
                originalText={props.formData.Descricao} 
                visible={sugestaoExp} 
                handleVisible={() => setSugestaoExp(!sugestaoExp)} 
                setText={(e) =>  { props.onChangeForm({title: props.formData.titulo, Descricao: e})} }
                user={user} 
                instructionType={AiSelected}
            />
        </div>
    </>
    )
}

export default HHComponentAI;