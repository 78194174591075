import moment from 'moment';
import PPModal from 'processor-plataform-ui/dist/components/PPModal';
import React, { useState } from 'react';
import { CostCenterService } from '../../services/costCenter/costCenterService';
import { formatCurrency } from '../../services/currency';
import LcTable from '../../components/Data/LcTable';
import { useQuery } from 'react-query';

interface BudgetHistoryModalProps {
    budgetHistoricModalV: boolean;
    setBudgetHistoricModalV: (open: boolean) => void;
    ccSelected: any;
    setSaveMode: (value: number) => void;
    setCCSelected: (value: any) => void;
}

export const BudgetHistoryModal: React.FC<BudgetHistoryModalProps> = (props) => {
    const { data: budgetHistoric = [], isLoading: loadingHistoric } = useQuery({
        queryKey: ['budgetHistoric', props.ccSelected?.costCenterId],
        queryFn: async () => {
            const service = new CostCenterService({});
            const response = await service.ReadHistoricByCostCenter(props.ccSelected?.costCenterId);
            return response.data
                .sort((a, b) => { return a.when > b.when ? -1 : 1; })
                .sort((a, b) => { return a.month > b.month ? 1 : -1; });
        },
        refetchOnWindowFocus: false,
        enabled: !!props.ccSelected?.costCenterId,

    });

    return (
        <PPModal
        visible={props.budgetHistoricModalV}
        onClose={() => {
            props.setCCSelected(null);
            props.setBudgetHistoricModalV(false)
            props.setSaveMode(1)
        }}
        title={`Histórico do orçamento ${props.ccSelected?.name || 'x'}`}
        size='large'
        loading={loadingHistoric}
    >
        {
            budgetHistoric.length > 0 ?
                <LcTable
                    data={budgetHistoric}
                    columns={[
                        {
                            field: 'month', headerName: 'Mês', width: '20%',
                            renderCell: (row: any) => {
                                return moment(row.month + '-01').format("MMM/YYYY");
                            }
                        },
                        {
                            field: 'oldValue', headerName: 'Valor antigo', width: '20%',
                            renderCell: (row: any) => {
                                return row.oldValue != null ? formatCurrency(row.oldValue) : 'ND';
                            }
                        },
                        {
                            field: 'newValue', headerName: 'Valor novo', width: '20%',
                            renderCell: (row: any) => {
                                return row.newValue != null ? formatCurrency(row.newValue) : 'ND';
                            }
                        },
                        { field: 'who', headerName: 'Responsável', width: '20%' },
                        {
                            field: 'when', headerName: 'Data', width: '20%',
                            renderCell: (row: any) => {
                                return moment(row.when).add('hours', -3).format("DD/MM/YYYY HH:MM:SS");
                            }
                        },
                    ]}
                />
                :
                <div className='centralized row'>
                    <i className="lci lci-exclamation lci-2x text-warning" />
                    <span className="font-sm">Não há histórico para este centro de custo</span>
                </div>
        }
    </PPModal>

    );
}
