import React, { useCallback, useEffect, useState } from 'react';
import { BackupSilverService } from '../../../services/backupSilver/backupSilverService';

//Layout Padrão
import Layout from '../../../components/Layout/Layout';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';
import { FilterState, setFilterActive } from '../../../store/reducers/filterReducer';

import Moment from 'moment';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import LcLoading from '../../../components/Generic/LcLoading';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';

interface BackupSilverHistoryFilter {
    size: number;
    term: string;
    column: string;
    direction: string;
    entityFriendlyName?: string;
    vault?: string;
    status?: string;
    startTime?: string;
}

const BackupSilverHistory: React.FC = (props) => {

    const local = document.location.pathname;
    const user = useSelector<RootState, UserState>(state => state.user);
    const userPeriodSelected = user.PeriodSelection ? user.PeriodSelection[local] : undefined;
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    let [isLoading, setIsLoading] = useState(true);

    const [backupSilverHistory, setBackupSilverHistory] = useState<any[]>([]);
    const [filteredBackupSilverHistory, setFilteredBackupSilverHistory] = useState<any[]>([]);
    const [visibleBackupSilverHistory, setVisibleBackupSilverHistory] = useState<any[]>([]);
    const [filterBackupSilverHistory, setFilterBackupSilverHistory] = useState<BackupSilverHistoryFilter>({ size: 20, term: "", column: "", direction: "asc" });

    const [month, setMonth] = useState<string>(userPeriodSelected ? userPeriodSelected.fields[0].value.split('-')[1] : undefined);
    const [year, setYear] = useState<string>(userPeriodSelected ? userPeriodSelected.fields[0].value.split('-')[0] : undefined);

    // const statusDic = {
    //     'running': 'Executando',
    //     'completed': 'Concluído',
    //     'cancelled': 'Cancelado',
    //     'failed': 'Falhou',
    //     'completed with warnings': 'Concluído com avisos',
    // }

    async function GetBackupSilverHistoryData(backupSilverService: any, startDate: string, endDate: string, setBackupSilverHistory: any, setFilteredBackupSilverHistory: any, setIsLoading: any) {
        setIsLoading(true);
        setFilteredBackupSilverHistory([]);
        setBackupSilverHistory([]);
        try {
            var items = await backupSilverService.GetHistory(startDate, endDate);
            // var translateStatus = items.map(item => {
            //     item.status = statusDic[item.status.toLowerCase()];
            //     return item;
            // });
            setFilteredBackupSilverHistory(items);
            setBackupSilverHistory(items);

        } catch (e) {
            console.log(e);
            setFilteredBackupSilverHistory([]);
            setBackupSilverHistory([]);
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (!month && !year) {
            setMonth((new Date().getMonth() + 1).toString().padStart(2, '0'));
            setYear(new Date().getFullYear().toString());
        }
        if (user.ClientGroupSelected && month && year) {
            const startDate = [year, month, '01'].join('-');
            const endDate = [year, month, new Date(parseInt(year ? year :
                new Date().getFullYear().toString()), parseInt(month ? month :
                    (new Date().getMonth() + 1).toString().padStart(2, '0')), 0).getDate().toString()].join('-');
            const backupSilverService = new BackupSilverService(props);
            GetBackupSilverHistoryData(backupSilverService, startDate, endDate, setBackupSilverHistory, setFilteredBackupSilverHistory, setIsLoading);
        }
    }, [month, user.ClientGroupSelected, year, user.refreshFlag]);

    const columns = [
        { field: 'entityFriendlyName', headerName: 'Recurso', width: "25%" },
        { field: 'vault', headerName: 'Cofre', width: "25%" },
        {
            field: 'startTime', headerName: 'Data de início', width: "25%",
            renderCell: (row) => {
                return Moment(row.startTime).utc().format('DD/MM/YYYY hh:mm:ss')
            }
        },
        {
            field: 'endTime', headerName: 'Data de fim', width: "25%",
            renderCell: (row) => {
                return Moment(row.endTime).utc().format('DD/MM/YYYY hh:mm:ss')
            }
        },
        { field: 'status', headerName: 'Situação', width: "25%" },
    ];

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'Personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const opcoesSituacao = [
        { label: 'Todas', value: '' },
        { label: 'Executando', value: 'Executando' },
        { label: 'Concluído', value: 'Concluído' },
        { label: 'Cancelado', value: 'Cancelado' },
        { label: 'Falhou', value: 'Falhou' },
        { label: 'Concluído com avisos', value: 'Concluído com avisos' },
    ];

    const [filterVisible, setFilterVisible] = useState(false);
    const filter = {
        fields: [
            { label: 'Recurso', name: 'entityFriendlyName', type: 'text' },
            { label: 'Cofre', name: 'vault', type: 'text' },
            { label: 'Situação', name: 'status', type: 'select', options: opcoesSituacao },
            { label: 'Início a partir de', name: 'startTime', type: 'date' },
        ],
        onChange: (_filter: any, size: number) => setFilterBackupSilverHistory({ ...filterBackupSilverHistory, size: size, term: _filter[0].value.toLowerCase() }),
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }

    const loadMore = (size?: number) => {
        if (size) setVisibleBackupSilverHistory(filteredBackupSilverHistory.slice(0, size))
        else setVisibleBackupSilverHistory([...visibleBackupSilverHistory, ...filteredBackupSilverHistory.slice(visibleBackupSilverHistory.length, visibleBackupSilverHistory.length + 10)])
    };

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterBackupSilverHistory({ ...filterBackupSilverHistory, column: sort.column, direction: sort.direction, size: size })
    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const card = [
        {
            id: 1,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loadingType='Helix' loading={isLoading} >
                        <>  {visibleBackupSilverHistory && !isLoading
                            &&
                            <LcInfiniteTable
                                rows={filteredBackupSilverHistory}
                                columns={columns}
                                loadMore={loadMore}
                                size={filteredBackupSilverHistory.length}
                                filter={filter}
                                disableFilterModal={true}
                                onSortChange={onSortChange}
                                density={pref.data?.density || "high"}
                            />
                        }</>
                    </LcLoading>
                )
            }

        }
    ]

    const applyFilter = useCallback(() => {
        let isFilterActive = false;
        if (backupSilverHistory.length > 0) {
            const filtered = backupSilverHistory.filter(item => {
                const matchesEntityFriendlyName = filterBackupSilverHistory.entityFriendlyName ? item.entityFriendlyName.toLowerCase().includes(filterBackupSilverHistory.entityFriendlyName.toLowerCase()) : true;
                const matchesVault = filterBackupSilverHistory.vault ? item.vault.toLowerCase().includes(filterBackupSilverHistory.vault.toLowerCase()) : true;
                const matchesStatus = filterBackupSilverHistory.status ? item.status.toLowerCase() === filterBackupSilverHistory.status.toLowerCase() : true;

                // Garante que filterBackupSilverHistory.startTime seja um objeto Date
                const filterStartTime = filterBackupSilverHistory.startTime ? new Date(filterBackupSilverHistory.startTime) : null;

                // Converte a data de início do item para um objeto Date e compara
                const itemStartTime = new Date(item.startTime);
                const matchesStartTime = filterStartTime ? itemStartTime >= filterStartTime : true;

                return matchesEntityFriendlyName && matchesVault && matchesStatus && matchesStartTime;
            }).sort((a, b) => {
                const valueA = a[filterBackupSilverHistory.column] || "";
                const valueB = b[filterBackupSilverHistory.column] || "";

                if (filterBackupSilverHistory.direction === 'asc') {
                    return valueA.toString().localeCompare(valueB.toString());
                } else {
                    return valueB.toString().localeCompare(valueA.toString());
                }
            });

            if (filtered.length !== backupSilverHistory.length) {
                isFilterActive = true;
            }

            setFilteredBackupSilverHistory(filtered);
            setVisibleBackupSilverHistory(filtered.length > 0 ? filtered.slice(0, filterBackupSilverHistory.size) : []);
            dispatch(setFilterActive(isFilterActive));
        }
    }, [filterBackupSilverHistory, backupSilverHistory, dispatch]);


    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={filter.fields}
                onFilterChange={handleFilterChange}
            />
        );
    };

    const handleFilterChange = (updatedFilters) => {
        let newFilter = { ...filterBackupSilverHistory };

        updatedFilters.forEach(f => {
            if (f.name === 'startTime') {
                // Converte a data selecionada para o início do dia como um objeto Date
                newFilter[f.name] = f.value ? new Date(f.value + 'T00:00:00') : null;
            } else {
                newFilter[f.name] = f.value;
            }
        });

        setFilterBackupSilverHistory(newFilter);
    };

    useEffect(() => {
        applyFilter();
    }, [backupSilverHistory, filterBackupSilverHistory]);


    useEffect(() => {
        // Esta função é chamada quando o componente é desmontado
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);


    return (
        <Layout
            pageTitle="Histórico"
            periodSelection={periodSelection}
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: filteredBackupSilverHistory.length
            }}
        >

            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                    //setShow: setFilterVisible
                }}
            />

        </Layout >
    );
};

export default BackupSilverHistory;