// FilterPanel.tsx
import React, { useState, useEffect } from 'react';
import './index.css'; // Import the CSS file
import InputFieldFilter from './InputFieldFilter'; // Change the import statement
import PPDropDown from '../../PPDropDown';
import LcCheckBox from '../../Form/LcCheckBox';
import { DateRangePick, SliderField } from '../../Form/Input';
import moment from 'moment';
import { FilterField } from './FilterField';
import { useDispatch } from 'react-redux';
interface DataItem {
    [key: string]: any;
}

interface FilterPanelProps {
    reducerFilter?: any
    filter: FilterField[];
    data: DataItem[];
    onFilteredData: (filteredData: DataItem[]) => void;
    translations: { [key: string]: { label: string; type: string; topDropdown?: boolean; values?: { [key: string]: string } }; };
    savedFilters: { [key: string]: any }; // Novo prop para receber os filtros salvos
    onSaveFilters: (filters: { [key: string]: any }) => void; // Novo prop para s
    clearFilters?: () => void;
}

const FilterPanel: React.FC<FilterPanelProps> = (props) => {
    const [localFilter, setLocalFilter] = useState<FilterField[]>([]);
    const [tempFilters, setTempFilters] = useState<{ [key: string]: string | string[] | any }>({});
    const [selectFilter, setSelectFilter] = useState<boolean>(false);
    const [resetKey, setResetKey] = useState(0);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.savedFilters) {
            setTempFilters(props.savedFilters);

            const updatedLocalFilter = props.filter.map(f => ({
                ...f,
                values: (props.savedFilters && props.savedFilters[f.name]) || (f.type === 'multiSelect' ? [] : '')
            }));

            setLocalFilter(updatedLocalFilter);
            const filteredData = filterData(props.data, props.savedFilters, selectFilter);
            props.onFilteredData(filteredData);
        } else {
            setLocalFilter(props.filter);
        }
    }, []);


    const handleInputChange = (name: string, value: any) => {
        const updatedFilters = localFilter.map(f => {
            if (f.name === name) {
                return { ...f, values: value };
            }
            f.type === 'multiSelect' || f.type === 'select' ? setSelectFilter(true) : setSelectFilter(false);
            return f;
        });
        setLocalFilter(updatedFilters);
        setTempFilters({ ...tempFilters, [name]: value, });
    };

    // const handleTempFilterChange = (field: string, value: any) => {
    //     setTempFilters(prevFilters => ({
    //         ...prevFilters,
    //         [field]: value
    //     }));
    // };

    const handleClearFiltersClick = () => {
        if (props.clearFilters) {
            dispatch({ type: 'RESET_SYSTEM_PATCH' });
            dispatch({ type: 'RESET_PATCH_PATCH' });
            props.clearFilters();
            resetLocalFiltersAndRerender();

        } else {
            handleFilterClear();
            dispatch({ type: 'RESET_PATCH_PATCH' });
            dispatch({ type: 'RESET_SYSTEM_PATCH' });
        }
        if (props.onSaveFilters) {
            props.onSaveFilters({});
        }
    };

    const handleFilterClear = () => {
        resetLocalFiltersAndRerender();
        props.onFilteredData(props.data); // Reset to original data
    };

    const resetLocalFiltersAndRerender = () => {
        const resetFilter = props.filter.map(f => ({ ...f, values: f.type === 'multiSelect' ? [] : '' }));
        setLocalFilter(resetFilter);
        setResetKey(prevKey => prevKey + 1);
        setTempFilters({});
    };

    const applyFilters = () => {
        const filteredData = filterData(props.data, tempFilters, selectFilter);
        props.onFilteredData(filteredData);

        if (props.onSaveFilters) {
            props.onSaveFilters(tempFilters);
        }
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        applyFilters();
    };

    const isNumber = (value: any) => !isNaN(value);

    const applyNumericFilter = (itemValue: any, filterValue: string) => {
        const match = filterValue.match(/^([<>]=?)?(\d+(\.\d+)?)$/);
        if (!match) return itemValue.toString().includes(filterValue);

        const [, operator, number] = match;
        const numericValue = parseFloat(number);

        switch (operator) {
            case '>':
                return parseFloat(itemValue) > numericValue;
            case '>=':
                return parseFloat(itemValue) >= numericValue;
            case '<':
                return parseFloat(itemValue) < numericValue;
            case '<=':
                return parseFloat(itemValue) <= numericValue;
            default:
                return parseFloat(itemValue) === numericValue;
        }
    };

    const filterData = (data: DataItem[], filters: { [key: string]: any }, notInclude?: boolean) => {
        return data.filter(item => Object.entries(filters).every(([key, value]) => {
            if (!value) return true;
            if (Array.isArray(value) && value.length === 0) return true;
            if (Array.isArray(value)) {
                return value.some(v => {
                    if (item[key] === undefined) return false;
                    const itemValue = item[key].toString().toLowerCase();
                    const filterValue = v.toString().toLowerCase();
                    if (notInclude) {
                        return itemValue === filterValue;
                    } else {
                        return itemValue.includes(filterValue);
                    }
                });
            }
            if (typeof value === 'string') {
                if (isNumber(item[key])) {
                    return applyNumericFilter(item[key], value);
                }
                return item[key].toString().toLowerCase().includes(value.toLowerCase());
            } else if (typeof value === 'object' && 'startDate' in value && 'endDate' in value) {
                const { startDate, endDate } = value;
                const itemDate = moment(item[key]).startOf('day');
                const start = startDate ? moment(startDate).startOf('day') : null;
                const end = endDate ? moment(endDate).endOf('day') : null;

                if (start && end) return itemDate.isBetween(start, end, null, '[]');
                if (start) return itemDate.isSameOrAfter(start);
                if (end) return itemDate.isSameOrBefore(end);
                return true;
            }
            return false;
        }));
    };

    const getUniqueValues = (field: string) => {
        const values = props.data
            .map(item => item[field as keyof DataItem])
            .filter(value => value !== null && value !== undefined);
        return [...new Set(values)];
    };

    const renderFilters = () => {
        return localFilter.map((f, i) => {
            const translation = props.translations[f.name] || { label: f.label, type: f.type, value: f.value };
            const uniqueValues = getUniqueValues(f.name);
            if (!['dropdown', 'multiSelect', 'date', 'text', 'spanDateTime', 'interval', 'checkBox', 'select'].includes(translation.type)) return null;
            return (
                <div key={i} className="filter-field">
                    {translation.type === 'spanDateTime' && (
                        <DateRangePick
                            id={`FilterPanel_${f.name}`}
                            key={resetKey}
                            label={translation.label}
                            name={f.name}
                            value={f.value}
                            onChange={e => handleInputChange(f.name, e)}
                            width='100%'
                        />
                    )}
                    {/*{translation.type === 'interval' && marks && (*/}
                    {/*    <SliderField*/}
                    {/*        marks={f.marks}*/}
                    {/*        name={f.name}*/}
                    {/*        label={translation.label}*/}
                    {/*        min={f.min || 0}*/}
                    {/*        max={f.max || 100}*/}
                    {/*        value={f.value}*/}
                    {/*        onChange={e => handleInputChange(f.name, e.target.value)}*/}
                    {/*    />*/}
                    {/*)}*/}
                    {translation.type === 'checkBox' && (
                        <LcCheckBox
                            title={translation.label}
                            name={f.name}
                            checked={f.value === 'true'}
                            onChange={e => handleInputChange(f.name, e.target.checked ? 'true' : 'false')}
                        />
                    )}
                    {translation.type === 'select' && (
                        <PPDropDown
                            key={resetKey}
                            menuPlacement={translation.topDropdown ? 'top' : 'auto'}
                            name={f.name}
                            defaultValue={f.values.lenght > 0 ? f.values.map(value => ({ label: f.values?.[value] || value, value })) : undefined}
                            onChange={e => handleInputChange(f.name, e.value)}
                            options={uniqueValues.map(value => ({ label: translation.values?.[value] || value, value }))}
                            title={translation.label}
                            placeholder='Selecione...'
                        />
                    )}
                    {translation.type === 'multiSelect' && (
                        <PPDropDown
                            key={resetKey}
                            menuPlacement={translation.topDropdown ? 'top' : 'auto'}
                            name={f.name}
                            defaultValue={(f.values || props.reducerFilter) ? f.values.map(value => ({ label: translation.values?.[value] || value, value })) : undefined}
                            isMulti={true}
                            options={uniqueValues.map(value => ({ label: translation.values?.[value] || value, value }))}
                            onChange={e => handleInputChange(f.name, e.map(option => option.value))}
                            title={translation.label}
                            maxMenuHeight={150}
                            placeholder='Selecione...'
                        />
                    )}
                    {translation.type === 'text' && (
                        <InputFieldFilter
                            label={translation.label}
                            type={translation.type as 'number' | 'time' | 'text' | 'date' | 'datetime-local' | 'month' | 'week' | 'email' | 'password' | 'tel' | 'url' | 'file'}
                            value={f.values}
                            onChange={value => handleInputChange(f.name, value)}
                            placeholder='Digite...'
                        />
                    )}
                    {translation.type === 'date' && (
                        <InputFieldFilter
                            id={'date_field'}
                            label={translation.label}
                            type={translation.type as 'number' | 'time' | 'text' | 'date' | 'datetime-local' | 'month' | 'week' | 'email' | 'password' | 'tel' | 'url' | 'file'}
                            value={f.value || ''}
                            onChange={value => handleInputChange(f.name, value)}
                        //placeholder='Digite...'
                        />
                    )}
                </div>
            );
        });
    };

    return (
        <form onSubmit={handleSubmit} className="right-sidepanel-filters">
            <div style={{ gridGap: '1rem' }} className="principal-filters scrollable-v h-100p">
                {renderFilters()}
            </div>

            <div className="space-between mt-4">
                <button type="submit" id={'form_filtros_filtrar_button'} className="lc-button bg-info">Filtrar</button>
                <button type="button" id={'form_filtros_limpar_button'} className="lc-button" onClick={handleClearFiltersClick}>Limpar</button>
            </div>
        </form>
    );
};

export default FilterPanel;