import api, { APIClient } from "../api";
import { PPFormInput } from "processor-plataform-ui/dist/components/PPForm2";
import { CommentAttachment, EmptyRequest } from "./heyhoModel";



function DateFormater(date: any) {
    let options: any = {
        year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    };

    return date && new Intl.DateTimeFormat('pt-BR', options).format(new Date(date)) as string;
}

function getStatusChamado(statusId: any) {
    return StatusSolicitationWork[statusId];
}

export enum CommentType {
    Internal = 0, External = 1, Work = 2, Pattern = 3
}

export class HeyHoService extends APIClient {
    
    constructor(props: any) {
        super(props);
    }

    state = {
        data: {
            contratos: [],
            chamadoTree: [],
            filterGridDefault: { start: 0, size: 2500, filter: '', filterStatus: 0, filterCriticidade: 0, ordered: 'id', direction: 'asc' },
            template: {
                title: 'id',
                Fields: [{
                    edit: false,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'contrato',
                    type: 'select',
                    title: 'Contrato',
                    required: {
                        value: true,
                        required: 'Contrato é obrigatório'
                    },
                    disabled: false,
                    options: []
                }, {
                    edit: false,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'titulo',
                    type: 'text',
                    title: 'Assunto',
                    required: {
                        value: true,
                        required: 'Assunto é obrigatório'
                    },
                    disabled: false
                }, {
                    edit: false,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'ServiceTree',
                    type: 'select',
                    title: 'Árvore de serviço',
                    required: {

                        value: true,
                        required: 'Serviço é obrigatório'
                    },
                    disabled: false,
                    options: [],
                    denpendentFieldFilter: {
                        relatedNameField: 'contrato',
                        filter: function (data) {

                        }
                    },
                },
                {
                    edit: false,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'Descricao',
                    type: 'textArea',
                    title: 'Descrição detalhada',
                    required: {

                        value: true,
                        required: 'Descrição é obrigatória'
                    },
                    disabled: false
                },
                {
                    edit: false,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'Anexo',
                    type: 'file',
                    title: 'Anexar arquivos: Coloque evidencias que ajudem a entender o contexto',
                    required: {
                        value: false
                    },
                    disabled: false
                }]
            }
        }
    };

    title = 'HeyHo';

    getFilter() {
        return this.state && this.state.data.filterGridDefault;
    }

    // async initialize() {

    //     await HeyHoService.RetrieveContractsAndGTM();
        // HeyHoService.initTemplate(false);
    // }
    setFilter(filterParm: any) {
        const stateData = { ...this.state };
        stateData.data.filterGridDefault = filterParm;
        // this.setState({ stateData });
    }

    async RetrieveList(_filterGridDefault: any) {
        let filterData = _filterGridDefault;

        if (_filterGridDefault === null || _filterGridDefault === undefined || _filterGridDefault === "") {
            filterData = this.state.data.filterGridDefault;
        }

        //var filterData = this.state.data.filterGridDefault;
        var filter = `?start=${filterData.start}&size=${filterData.size}&filter=${filterData.filter}&filterStatus=${filterData.filterStatus}&filterCriticidade=${filterData.filterCriticidade}&ordered=${filterData.ordered}&direction=${filterData.direction}`;

        const url = `${this.endpoint}/api/Agendamentos/GetChamadosHeyHo?${filter}&${Math.random()}`;
        var response = await api( {
            method: 'get',
            url,
        });
        return response;
    }

    Retrieve(ticket: string) {
        const url = `${this.endpoint}/api/Agendamentos/GetChamadosHeyHoDetail/${ticket}`;
        var response = api({
            method: 'get',
            url,
        });
        return response;
    }

    async RetrieveChamadoTreeList_Depracated() {
        const url = `${this.endpoint}/api/Agendamentos/GetGTMLTree`;
        try {
            let chamadoTree = (await api({
                method: 'get',
                url,
            })).data;
            const stateData = { ...this.state };
            stateData.data.chamadoTree = chamadoTree;
            // this.setState({ stateData });
        } catch (e) {
            console.warn(e);
        }
    }

    async RetrieveContratos_Deprecated() {
        try {

            const url = `${this.endpoint}/api/Agendamentos/GetContractsAgendamento`;
            let contratos = (await api({
                method: 'get',
                url,
            })).data.dataCollection;
            const stateData = { ...this.state };
            stateData.data.contratos = contratos;
            // this.setState({ stateData });
        } catch (e) {
            console.warn(e);
        }
    }
    async RetrieveContractsAndGTM() {
        try {

            const url = `${this.endpoint}/api/RequisicoesContracts/GetContractsAndGTM`;
            let result = (await api({
                method: 'get',
                url,
            })).data;


            const stateData = { ...this.state };
            stateData.data.contratos = result.dataCollectionContracts;
            stateData.data.chamadoTree = result.dataCollectionGTMs;

            // this.setState({ stateData });
            // this.setState({ stateData });
        } catch (e) {
            console.warn(e);
        }
    }
    async getItem(attachment: any) {
        const url = this.endpoint + `/api/Agendamentos/SolicitationAttachment?attachmentId=${attachment.id}`;
        
        return api({
            method: 'get',
            url,
            responseType: 'arraybuffer',
        })
            .then(response => {
                return response;
            })
    }

    async downloadAttachmentById(attachment: any) {
        const url = `${this.endpoint}/api/Agendamentos/SolicitationAttachment?attachmentId=${attachment.id}`;
        await api({
            method: 'get',
            url,
            responseType: 'arraybuffer',
        })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                const fileName = attachment.originalName;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                link.remove();
            })
            .catch(error => {
                console.warn(error)
            })

    }

    async GetSolicitationCommentDetails(chamado: any) {
        const url = `${this.endpoint}/api/Agendamentos/GetSolicitationCommentDetails?Solicitationid=${chamado}`;
        return api({
            method: 'get',
            url,
        });
    }

    async getCommentsWithAttachments2(idSolicitation: number) {
        const url = `${this.endpoint}/api/Agendamentos/GetSolicitationCommentDetails?Solicitationid=${idSolicitation}`;

        var response = await api({
            method: 'get',
            url
        });

        await Promise.all(response.data.map(async (comment: CommentAttachment) => {
            if (comment.isAttachment) {
                try {
                    console.log(comment)
                    var responseAtt = await this.getItem(comment.attachment);
                    console.log(responseAtt)
                    const blob = new Blob([responseAtt.data]);
                    var reader = new FileReader();
                    var url = reader.readAsDataURL(blob);

                    reader.onload = () => {
                        comment.attachment.image = reader.result;
                    }
                }
                catch (error) {
                    console.error(`Error fetching attachment for comment ${comment.id}:`, error);
                }

            }
        }))

        return response;
    }

    Save(item: any) {

        if (item.id && item.id > 0) {
            return this.Update(item);
        } else {
            return this.Create(item);
        }
    }

    Create(item: any) {
        const url = `${this.endpoint}/api/Agendamentos/Create`;
        var response = api({
            method: 'post',
            url,
            data: item,
        });
        return response;
    }
    async CreateComment(item: any) {
        const url = `${this.endpoint}/api/Agendamentos/CreateComment`;
        var response = api({
            method: 'post',
            url,
            data: item,
        })
        return response;
    }
    async sendFile(file: any[], idSolicitation: number, idUserRequester?: number, text?: string, comment?: any) {
        const url = this.endpoint + `/api/Agendamentos/CreateAttachmentComment`;
        // const fileName = file.name;

        // // Sanitiza o no    const sanitizedFileName = fileName.normalize('NFKD').replace(/[^\x00-\x7F]/g, '');
        const descricao = text && text.normalize('NFKD').replace(/[^\x00-\x7F]/g, '');
        // const sanitizedComment = comment.normalize('NFKD').replace(/[^\x00-\x7F]/g, '');
        const param = {
            idSolicitation: idSolicitation,
            type: 1 as CommentType,
            idWork: 0,
        };

        const data = new FormData();
        Array.from(file).forEach((file: File) => {
            
            const specialCharactersRegex = /[^\x00-\x7F]/g;
            const cleanedName = file.name.replace(specialCharactersRegex, '');
            const fileName = cleanedName
            data.append("file", file, fileName); 
        });   
    // data.append("file", file, sanitizedComment);

    return api({
        method: 'post',
        url,
        headers: {
            "Description": JSON.stringify(descricao),
            "Comment": (comment) ? JSON.stringify(comment.normalize('NFKD').replace(/[^\x00-\x7F]/g, '')) : null,
            "UserId": idUserRequester || 0,
        },
        data,
        params: param,
    });
    }


    async Update(item: any) {
        const url = `${this.endpoint}/api/Agendamentos/Update`;
        var response = api({
            method: 'post',
            url,
            data: item,
            withCredentials: false,
        });
        return response;
    }

    public emptyRecord: EmptyRequest = {
        id: 0,
        contrato: '0',
        ServiceTree: "-1",
        dataInicio: "",
        heyHoId: 0,
        heyHoSatatus: "Nova",
        heyHoLink: "",
        criticidade: "Normal",
        Anexo: [],
        titulo: "",
        Descricao: '',
        gtmType: '', 
        idSituacao: 0
    };
    public getTemplate() {
        return this.state.data.template;
    }

    public getStateData() {
        return this.state.data;
    }

    public async templateEdit(disabled: boolean) {
        let item = {
            title: 'id',
            Fields: [
                {
                    edit: true,
                    row: 1,
                    col: 2,
                    colspan: 2,
                    rowspan: 2,
                    name: 'subject',
                    type: 'detailtext',
                    title: 'Assunto',
                    required: {
                        value: true,
                        required: 'Assunto é obrigatório'
                    },
                    disabled: true
                },
                {
                    edit: true,
                    row: 1,
                    col: 2,
                    colspan: 2,
                    rowspan: 2,
                    name: 'details',
                    type: 'detailtextArea',
                    title: 'Detalhes',
                    required: {
                        value: true,
                        required: 'Detalhes é obrigatório'
                    },
                    disabled: true
                }, {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 1,
                    rowspan: 1,
                    name: 'solicitationDate',
                    type: 'detailtext',
                    title: 'Data Solicitação',
                    required: {
                        value: true,
                        required: 'Data Solicitação é obrigatório'
                    },
                    disabled: true,
                    formatData: function (data: any) {
                        return DateFormater(data);
                    }
                },
                {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 1,
                    rowspan: 1,
                    name: 'nameBusinessArea',
                    type: 'detailtext',
                    title: 'Área de negócio',
                    required: {
                        value: true,
                        required: 'Área de negócio é obrigatório'
                    },
                    disabled: true, formatData: function (data: any) {
                        return data;
                    }
                },
                {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 1,
                    rowspan: 1,
                    name: 'nameCustomer',
                    type: 'detailtext',
                    title: 'Cliente',
                    required: {
                        value: true,
                        required: 'Assunto é obrigatório'
                    },
                    disabled: true,
                    formatData: function (data: any) {
                        return data;
                    }
                },
                {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 1,
                    rowspan: 1,
                    name: 'nameServiceGroup',
                    type: 'detailtext',
                    title: 'Grupo de Serviço',
                    required: {
                        value: true,
                        required: 'Grupo de Serviço é obrigatório'
                    },
                    disabled: true, formatData: function (data: any) {
                        return data;
                    }
                },
                {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 1,
                    rowspan: 1,
                    name: 'nameService',
                    type: 'detailtext',
                    title: 'Serviço',
                    required: {
                        value: true,
                        required: 'Serviço é obrigatório'
                    },
                    disabled: true,
                    validation: function (val: string | any[]) {
                        if (val && val.length > 0 && val.length < 30) {
                            return true;
                        }
                        return false;
                    }, formatData: function (data: any) {
                        return data;
                    }
                },
                {
                    edit: true,
                    row: 1,
                    col: 1,
                    colspan: 1,
                    rowspan: 1,
                    name: 'nameProcess',
                    type: 'detailtext',
                    title: 'Processo',
                    required: {
                        value: true,
                        required: 'Processo é obrigatório'
                    },
                    disabled: true,
                    validation: function (val: string | any[]) {
                        if (val && val.length > 0 && val.length < 30) {
                            return true;
                        }
                        return false;
                    }, formatData: function (data: any) {
                        return data;
                    }
                },
            ]
        };
        return { ...item };
    }
    public templateEdit2(data?: any) {
        let newForm: PPFormInput[] = [
            {
                title: "Assunto",
                name: 'subject',
                type: "textarea",
                rows: 1,
                autoheight: true,
                disabled: true,
                value: data && data.subject ? data.subject : undefined
            }, {
                title: 'Detalhes',
                name: 'details',
                type: 'textarea',
                rows: 4,
                autoheight: true,
                disabled: true,
                value: data && data.details ? data.details : undefined
            }, {
                title: 'Data da solicitação',
                name: 'solicitationDate',
                type: 'datetime-local',
                width: '50%',
                disabled: true,
                value: data && data.solicitationDate ? data.solicitationDate : undefined
            }, {
                title: 'Área de negócio',
                name: 'nameBusinessArea',
                type: 'text',
                width: '50%',
                disabled: true,
                value: data && data.nameBusinessArea ? data.nameBusinessArea : undefined

            }, {
                title: 'Cliente',
                name: 'nameCustomer',
                type: 'text',
                width: '50%',
                disabled: true,
                value: data && data.nameCustomer ? data.nameCustomer : undefined
            }, {
                title: 'Grupo de serviço',
                name: 'nameServiceGroup',
                type: 'text',
                width: '50%',
                disabled: true,
                value: data && data.nameServiceGroup ? data.nameServiceGroup : undefined
            }, {
                title: 'Serviço',
                name: 'nameService',
                type: 'text',
                width: '50%',
                disabled: true,
                value: data && data.nameService ? data.nameService : undefined
            }, {
                title: 'Processo',
                name: 'nameProcess',
                type: 'text',
                width: '50%',
                disabled: true,
                value: data && data.nameProcess ? data.nameProcess : undefined
            }

        ]
        return newForm;
    }

    public async templateComment(disabled: boolean) {
        let item = {
            title: 'id',
            Fields: [{
                edit: true,
                row: 1,
                col: 2,
                colspan: 2,
                rowspan: 2,
                name: 'Descricao',
                type: 'textArea',
                title: 'Detalhes',
                backgroundColor: '#ffffff',
                required: {
                    value: true,
                    message: 'Detalhes é obrigatório'
                },

                disabled: disabled,
                validation: function (val: string | any[]) {
                    return (val && val.length > 0 && val.length < 30);
                },
            }, {
                edit: true,
                row: 1,
                col: 2,
                colspan: 2,
                rowspan: 2,
                name: 'Anexo',
                type: 'file',
                title: 'Anexar arquivos: Gere evidências que sejam úteis',
                required: {
                    value: false,
                    message: ' '
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    return true;
                    if (val && val.length > 0 && val.length < 30) {
                        return true;
                    }
                    return false;
                },
            }
            ]
        };
        return { ...item };
    }

    public templateComment2() {
        let commentForm: PPFormInput[] = [
            {
                title: 'Detalhes',
                name: 'Descricao',
                type: 'text',
                rows: 5,
                required: true
            }, {
                title: 'Anexar arquivos: Gere evidências que sejam úteis',
                name: 'Anexo',
                type: 'file',
                required: false,
                maxSize: 2048,
                spanLeft: '1000px'
            }
        ]

        return commentForm;
    }

    public detailsTemplate(details: any) {
        let detailTemplate: PPFormInput[] = [
            {
                title: 'Autor',
                name: 'nameUser',
                type: 'text',
                disabled: true,
                width: '50%',
                value: details.nameUser ?? undefined
            }, {
                title: 'Data',
                name: 'commentDate',
                type: 'datetime-local',
                disabled: true,
                width: '50%',
                value: details.commentDate ?? undefined
            }, {
                title: 'Texto',
                name: 'text',
                type: 'text',
                disabled: true,
                value: details.text ?? undefined
            },

        ];
        return detailTemplate;
    }
    public async initTemplate(disabled: boolean) {
        if ((!this.state.data.chamadoTree) || (!this.state.data.contratos)) {
            await this.RetrieveContractsAndGTM();
        }

        const stateData = { ...this.state };
        stateData.data.template = {
            ...{
                title: 'id',
                Fields: [{
                    edit: false,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'contrato',
                    type: 'select',
                    title: 'Contrato',
                    required: {
                        value: true,
                        required: 'Contrato é obrigatório'
                    },
                    disabled: disabled,
                    options: stateData.data.contratos
                }, {
                    edit: false,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'titulo',
                    type: 'text',
                    title: 'Assunto',
                    required: {
                        value: true,
                        required: 'Assunto é obrigatório'
                    },
                    disabled: disabled
                }, {
                    edit: false,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'ServiceTree',
                    type: 'select',
                    title: 'Árvore de serviço',
                    required: {

                        value: true,
                        required: 'Serviço é obrigatório'
                    },
                    disabled: disabled,
                    options: stateData.data.chamadoTree,
                    denpendentFieldFilter: {
                        relatedNameField: 'contrato',
                        filter: function (data) {
                            if (!(data.contrato >= 0)) {
                                return stateData.data.chamadoTree;
                            }
                            var contratos = stateData.data.contratos.filter((x: any) => x.value === data.contrato)[0] as any;
                            if (contratos && contratos.gtmids && contratos.gtmids.length) {
                                return stateData.data.chamadoTree.filter((y: any) => {
                                    return contratos.gtmids.filter(x => x === y.gtmid).length > 0;
                                });
                            }
                        }
                    },
                },
                {
                    edit: false,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'Descricao',
                    type: 'textArea',
                    title: 'Descrição detalhada',
                    required: {

                        value: true,
                        required: 'Descrição é obrigatória'
                    },
                    disabled: disabled
                },
                {
                    edit: false,
                    row: 1,
                    col: 1,
                    colspan: 2,
                    rowspan: 2,
                    name: 'Anexo',
                    type: 'file',
                    title: 'Anexar arquivos: Coloque evidencias que ajudem a entender o contexto',
                    required: {
                        value: false
                    },
                    disabled: disabled
                }]
            }
        };
        // this.setState({ ...stateData });
    }

}

export enum StatusSolicitationWork {
    'Em andamento ' = 0,
    'Novo' = 1,
    'Em andamento' = 2,
    'Encaminhado' = 3,
    'Parado' = 4,
    'Pausado' = 5,
    'Cancelado' = 6,
    'Concluído' = 7,
    'Encerrada' = 8,
    'Reaberto' = 9,
    'Em planejamento' = 10,
    'Esperando solicitante' = 11,
    'Esperando atendente' = 12,
    'Esperando outra área' = 13,
    'Em aprovação' = 14,
}

