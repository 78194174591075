import { Tooltip } from "@material-ui/core";
import { Resouces, SavingPlanReserveModel } from "../../../../services/finOps/FinOpsSavingPlanReserve/FinOpsSavingPlanReserveModel";
import { SiMicrosoftazure } from "react-icons/si";
import { FaAws } from "react-icons/fa";
import moment from "moment";
import UsedVsNotUsedChart from "../UsedVsNotUsedChart";
import LcInfiniteTable from "../../../../components/Data/LcInfiniteTable";
import './index.css';

interface Props {
	data: SavingPlanReserveModel;
	totalUsed: number;
	totalReserved: number;
}

const SavingsPlansReservesDetailsModal: React.FC<Props> = (props: Props) => {

	const savingsPlanReserveDetails = props.data;
	const { totalUsed, totalReserved } = props;

	const collumnsDetails = [
		{ field: "subscrition", headerName: "Subscrição", width: "28%", align: 'left', },
		{ field: "resourceGroup", headerName: "Grupo de recursos", width: "18%", align: 'left', },
		{ field: "resourceName", headerName: "Nome do recurso", width: "28%", align: 'left', truncate: true, },
		{ field: "product", headerName: "Produto", width: "15%", align: 'left', },
		{ field: "quantityUsed", headerName: "Quantidade usada", width: "11%", },
	]

	const translateStatusColor = (status: Resouces) => {
		const tranlateStatusColor = {
			'important': '#ffa400',
			'critical': "#dc291e",
			'moderate': "#f6de00",
			'low': "#3ebb3f",
			'unclassified': "#cccccf",
		};
		return tranlateStatusColor[status.resouceStatus];
	}

	const labelStatusToExpired = (status: Date) => {
		let expiredDate = new Date(status)

		if (expiredDate.getFullYear() > 1) {
			const dataAtual = new Date();
			const dataLimite30Dias = new Date();
			dataLimite30Dias.setDate(dataAtual.getDate() + 31);

			if (expiredDate < dataAtual) {
				return "Expirado";
			} else if (expiredDate <= dataLimite30Dias) {
				return "Expirando";
			} else {
				return "Ativo";
			}
		} else return "Desconhecido";
	}

	const colorStatusToExpired = (data: SavingPlanReserveModel) => {
		let expiredDate = new Date(data.expiredDate)

		if (expiredDate.getFullYear() > 1) {
			const dataAtual = new Date();
			const dataLimite30Dias = new Date();
			dataLimite30Dias.setDate(dataAtual.getDate() + 31);

			if (expiredDate < dataAtual) {
				return "var(--status-red)"; // Expirado
			} else if (expiredDate <= dataLimite30Dias) {
				return "var(--status-yellow)"; // Expirando
			} else {
				return "var(--status-green)"; // Ativo
			}
		} else return "var(--background-neutral)"; // Sem informação
	}

	return savingsPlanReserveDetails &&
		<div className='detailsPlan'>
			<div className="headerPlanDetails">
				<div className='detailSavingsPlanOrReserve'>
					<section className="inicialInfo">
						<div className='detailsName'>
							<div className='row'>
								<Tooltip title={savingsPlanReserveDetails.plan} arrow>
									<h4>{savingsPlanReserveDetails.plan}</h4>
								</Tooltip>
								<span className='bagdeStatusPlan' style={{ backgroundColor: colorStatusToExpired(savingsPlanReserveDetails) }}>
									{labelStatusToExpired(savingsPlanReserveDetails.expiredDate as Date)}
								</span>
							</div>
							<div className='mt-4 row'>
								<Tooltip arrow title={savingsPlanReserveDetails.provider === 'Azure' ? 'Microsoft Azure' : 'Amazon AWS'}>
									<span style={{ minWidth: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
										{savingsPlanReserveDetails.provider === 'Azure' ? <SiMicrosoftazure size={'14px'} color='#2E1956' /> : <FaAws size={'16px'} color='#2E1956' />}
									</span>
								</Tooltip>
								<span className='ml-2 subTitlePlan'>{savingsPlanReserveDetails.type} para o serviço {savingsPlanReserveDetails.service} {savingsPlanReserveDetails.provider}</span>
							</div>
						</div>
						<div className='resource_scope'>
							<span className="labelGray textSmall">Escopo:</span>
							<Tooltip arrow title={savingsPlanReserveDetails.scope}>
								<p>{savingsPlanReserveDetails.scope}</p>
							</Tooltip>

						</div>
					</section>
					<section className="useDetails">
						<div className='percente'>
							<h6 className='mr-5'>Utilização:</h6>
							<div className='totalPercente'>
								<p>{((totalUsed / totalReserved) * 100).toFixed(0)}%</p>
								<div style={{ width: `${((totalUsed / totalReserved) * 100).toFixed(0)}%` }} className='usePercente'></div>
							</div>
						</div>
						<div className='usedAndReservedInfo'>
							<p><span className="labelGray mr-2">Reservadas:</span> {totalReserved} horas</p>
							<p ><span className="labelGray mr-2">Usadas:</span> {totalUsed} horas</p>
						</div>
					</section>
					<section className="restDetails">
						<div className='rowPlanDetails'>
							<div>
								<p className="labelGray">Custos com {savingsPlanReserveDetails.type}:</p>
								<p style={{ fontSize: '12px' }}>
									{savingsPlanReserveDetails.monthlyCost.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
								</p>
							</div>

							<div>
								<p className="labelGray">Custo sem {savingsPlanReserveDetails.type}:</p>
								<p style={{ fontSize: '12px' }}>
									{(savingsPlanReserveDetails.monthlyCost + savingsPlanReserveDetails.possibleSavings).toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
								</p>
							</div>

							<div>
								<p className="labelGray">Economia:</p>
								<p style={{ fontSize: '12px', color: '#3ebb3f' }}>
									{savingsPlanReserveDetails.possibleSavings.toLocaleString("pt-br", { style: "currency", currency: "BRL" })}
								</p>
							</div>
						</div>
						<div className='rowPlanDetails'>
							<div className='divDetails'>
								<div className='row' >
									<span className="labelGray">Pagamento:</span>
									<Tooltip arrow title={savingsPlanReserveDetails.payment}>
										<p>{savingsPlanReserveDetails.payment}</p>
									</Tooltip>
								</div>
								<div className='row mt-6'>
									<span className="labelGray" >Região:</span>
									<Tooltip arrow title={savingsPlanReserveDetails.region}>
										<p>{savingsPlanReserveDetails.region}</p>
									</Tooltip>
								</div>
							</div>

							<div className='divDetails'>
								<div className='row'>
									<span className="labelGray" >Flexibilidade:</span>
									<Tooltip arrow title={savingsPlanReserveDetails.flexibility}>
										<p>{savingsPlanReserveDetails.flexibility}</p>
									</Tooltip>
								</div>
								<div className='row mt-6'>
									<span className="labelGray" >Alocação:</span>
									<Tooltip arrow title={savingsPlanReserveDetails.alocation}>
										<p>{savingsPlanReserveDetails.alocation}</p>
									</Tooltip>
								</div>
							</div>
							<div className='divDetails'>
								<div className='row'>
									<span className="labelGray" >Compromisso:</span>
									<Tooltip arrow title={savingsPlanReserveDetails.commitment}>
										<p>{savingsPlanReserveDetails.commitment}</p>
									</Tooltip>
								</div>
								<div className='row mt-6'>
									<span className="labelGray" >Expira em:</span>
									<Tooltip arrow title={moment(savingsPlanReserveDetails.expiredDate).year() === 1 ? "-" : moment(savingsPlanReserveDetails.expiredDate).format('DD/MM/YYYY')}>
										<p>{moment(savingsPlanReserveDetails.expiredDate).year() === 1 ? "-" : moment(savingsPlanReserveDetails.expiredDate).format('DD/MM/YYYY')}</p>
									</Tooltip>
								</div>
							</div>
						</div>
					</section>
				</div>
				<div className="graphDetails">
					<UsedVsNotUsedChart data={savingsPlanReserveDetails} />
				</div>

			</div>
			<div className='bodySystem'>
				<h4>Recursos</h4>
				<LcInfiniteTable
					fontSize='small'
					rows={savingsPlanReserveDetails.resouces}
					columns={collumnsDetails}
					loadMore={() => undefined}
					size={savingsPlanReserveDetails.resouces.length}
					hidePagination
					status={translateStatusColor}
					height='26vh' />
			</div>
		</div>
}

export default SavingsPlansReservesDetailsModal;