import { PPModal } from 'processor-plataform-ui';
import React from 'react';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcLoading from '../../../components/Generic/LcLoading';
import { AutomationRunHistory } from '../../../services/automations/automationsModel';
import { useQuery } from 'react-query';
import moment from 'moment';

interface ExecutionHistoryModalProps {
    scheduler: { id: number } | null;
    showHistoricModal: boolean;
    toggleRunHistoryModal: (id: number) => void;
    automationsService: {
        GetAutomationRunHistory: (id: number) => Promise<{ data: AutomationRunHistory[] }>;
    };
}

const historicColumns = [
    { field: 'runId', headerName: 'ID da Execução', width: '35%' },
    { field: 'date', headerName: 'Data', width: '35%', renderCell: (row: AutomationRunHistory) => moment(row.date).format('DD/MM/YYYY') },
    {
        field: 'timeStarted', headerName: 'Início', width: '35%',
        renderCell: (row: AutomationRunHistory) => (row.timeStarted && moment(row.timeStarted, 'HH:mm:ss.SSS').format('HH:mm:ss')) || 'Não informado'
    },
    {
        field: 'timeFinished', headerName: 'Fim', width: '35%',
        renderCell: (row: AutomationRunHistory) => (row.timeFinished && moment(row.timeFinished, 'HH:mm:ss.SSS').format('HH:mm:ss')) || 'Não informado'
    },
    { field: 'statusLabel', headerName: 'Status', width: '35%', renderCell: (row: AutomationRunHistory) => row.statusLabel.trim() }
];
export const ExecutionHistoryModal: React.FC<ExecutionHistoryModalProps> = ({
    scheduler,
    showHistoricModal,
    toggleRunHistoryModal,
    automationsService
}) => {

    const { data: runHistory = [], isLoading: isLoadingHistory } = useQuery(
        ['automationRunHistory', scheduler?.id, showHistoricModal],
        async () => {
            if (!scheduler?.id || !showHistoricModal) {
                return [];
            }
            const result = await automationsService.GetAutomationRunHistory(scheduler.id);
            return result.data.sort((a, b) => 
                new Date(b.date).getTime() - new Date(a.date).getTime()
            );
        },
        {
            enabled: !!scheduler?.id && showHistoricModal,
            refetchOnWindowFocus: false,
            onError: (error) => {
                console.error('Error fetching automation run history:', error);
            }
        }
    );

    


    return (
        <PPModal title='Histórico de Execuções' visible={showHistoricModal} onClose={toggleRunHistoryModal} size='large' >
        <LcLoading loading={isLoadingHistory} label='Carregando histórico...' >
            <LcInfiniteTable
                loading={isLoadingHistory}
                columns={historicColumns}
                rows={runHistory}
                size={runHistory.length}
                onSortChange={(sortData) => {
                    const { sort } = sortData;
                    runHistory.sort((a: any, b: any) => {
                        if (sort.direction === 'asc') return (a[sort.column] > b[sort.column]) ? 1 : -1;
                        else return (a[sort.column] > b[sort.column]) ? -1 : 1;
                    });
                }}
                density='high'
            />
        </LcLoading>
    </PPModal>
    );
}
