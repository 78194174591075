import moment from 'moment';
import React, { useState } from 'react';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import EventsGraph from '../../endpointDetectionResponse/resumeEvent/EventsGraph';
import { ActiveInfoModel } from '../../../services/saas/SaasModel';
import { Chart } from '../../endpointDetectionResponse/resumeEvent/Models';
import { SaasService } from '../../../services/saas/saasService';
import { month } from '.';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import './index.css';

interface ActivationsByDeviceCardModel {
    activationsLabel: boolean;
    pinnedFilterPanel: boolean;
}


const buildActiveChartData = (data): any => {
    const platformColors = {
        'Windows': '#471fcc',
        'Windows 10 Mobile': '#19588d',
        'IOS': '#777777',
        'Mac': '#1d1d1f',
        'Android': '#01ff01',
    };

    const counts = {
        'Windows': 0,
        'Windows 10 Mobile': 0,
        'IOS': 0,
        'Mac': 0,
        'Android': 0,
    };

    // Calcular contagens de cada plataforma
    data.forEach(({ iOS, windows, android, windows10Mobile, mac }) => {
        counts['Windows'] += windows;
        counts['Windows 10 Mobile'] += windows10Mobile;
        counts['IOS'] += iOS;
        counts['Android'] += android;
        counts['Mac'] += mac;
    });

    // Construir os dados formatados
    const newActiveData = Object.entries(counts)
        .filter(([platform, count]) => count > 0)
        .map(([platform, count]) => ({
            value: count,
            name: platform,
            itemStyle: {
                color: platformColors[platform]
            }
        }));

    return newActiveData;
};

const getActiveReport = (arrayDeObjetos) => {
    let ativacoesPorProductType = {};
    let ativacoesPorDispositivo = {
        android: 0,
        iOS: 0,
        windows: 0,
        mac: 0,
        windows10Mobile: 0
    };
    let productTypeMaisAtivado = "";
    let totalAtivacoes = 0;
    let totalAtivacoesProductTypeMaisAtivado = 0;
    let productTypesSemAtivacao: any = [];
    let dispositivoMaisAtivado = "";

    arrayDeObjetos.forEach(objeto => {
        let productType = objeto.productType;
        let totalDispositivos = 0;

        Object.keys(ativacoesPorDispositivo).forEach(dispositivo => {
            totalDispositivos += objeto[dispositivo] || 0;
        });

        totalAtivacoes += totalDispositivos;

        if (totalDispositivos > 0) {
            ativacoesPorProductType[productType] = (ativacoesPorProductType[productType] || 0) + totalDispositivos;
            if (ativacoesPorProductType[productType] > totalAtivacoesProductTypeMaisAtivado) {
                totalAtivacoesProductTypeMaisAtivado = ativacoesPorProductType[productType];
                productTypeMaisAtivado = productType;
            }
        } else {
            productTypesSemAtivacao.push(productType);
        }

        Object.keys(ativacoesPorDispositivo).forEach(dispositivo => {
            ativacoesPorDispositivo[dispositivo] += objeto[dispositivo] || 0;
        });
    });

    dispositivoMaisAtivado = Object.keys(ativacoesPorDispositivo).reduce((a, b) => ativacoesPorDispositivo[a] > ativacoesPorDispositivo[b] ? a : b);

    let porcentagemDispositivoMaisAtivado = (ativacoesPorDispositivo[dispositivoMaisAtivado] / totalAtivacoes) * 100;

    return {
        productTypeMaisAtivado: productTypeMaisAtivado.charAt(0).toUpperCase() + productTypeMaisAtivado.slice(1).toLowerCase(),
        totalAtivacoes,
        totalAtivacoesProductTypeMaisAtivado,
        porcentagemDispositivoMaisAtivado,
        productTypesSemAtivacao: productTypesSemAtivacao.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()),
        dispositivoMaisAtivado: dispositivoMaisAtivado.charAt(0).toUpperCase() + dispositivoMaisAtivado.slice(1).toLowerCase(),
        totalAtivacoesDispositivoMaisAtivado: ativacoesPorDispositivo[dispositivoMaisAtivado],
    };
}

export const ActivationsByDeviceCard: React.FC<ActivationsByDeviceCardModel> = ({
    activationsLabel,
    pinnedFilterPanel

}) => {
    
    const saasService = new SaasService({});
    const [activeChartData, setActiveChartData] = useState<Chart>();
    const [activetionsReport, setActivationsReport] = useState<ActiveInfoModel>()
    const user = useSelector<RootState, UserState>(state => state.user);

    const { isLoading } = useQuery(
        ['activationChart', user.ClientGroupSelected, user.refreshFlag],
        async () => {
            const response = await saasService.GetActivationChart();
            return response.data;
        },
        {
            refetchOnWindowFocus: false,
            onSuccess: (data) => {
                if (data) {
                    setActivationsReport(getActiveReport(data));
                    setActiveChartData(buildActiveChartData(data));
                }
            },
            onError: (error) => {
                console.error('Error fetching activation chart:', error);
            }
        }
    );


    return (
        <LcLoading loading={isLoading}>
        {
            activeChartData ?
                <div style={{ height: '100%' }} className={`iconValueInsights ${activationsLabel ? '' : 'show  w-100p'}`}>
                    {
                        activeChartData &&
                        <EventsGraph data={activeChartData} />
                    }
                </div>
                :
                <LcNoData />
        }
        <div className={`row subscriptionInsights ${activationsLabel ? 'show' : ''} ${pinnedFilterPanel ? 'fixedAI' : ''} scrollable-v`}>
            {
                activetionsReport ?
                    <>
                        <div className='row mb-5' style={{
                            display: 'flex',
                        }}>
                            <i className='ppi ppi-lightning-bolt' style={{ fontSize: '20px' }}></i>
                            <p className='ml-5'>Os dispositivos <strong>{activetionsReport?.dispositivoMaisAtivado}</strong> recebeu {activetionsReport?.totalAtivacoesDispositivoMaisAtivado} ativações
                                em {month[Number(moment().month())]}, cerca de {activetionsReport?.porcentagemDispositivoMaisAtivado.toFixed(1)} das {activetionsReport?.totalAtivacoes} ativações realizadas no período </p>
                        </div>
                        <div className='row mb-5'>
                            <i className='ppi ppi-lightning-bolt' style={{ fontSize: '20px' }}></i>
                            <p className='ml-5'><strong>{activetionsReport?.productTypeMaisAtivado}</strong> foi o software mais ativado em março,
                                com {activetionsReport?.totalAtivacoesProductTypeMaisAtivado} das {activetionsReport?.totalAtivacoes}</p>
                        </div>
                        {
                            activetionsReport && activetionsReport.productTypesSemAtivacao.length > 0 &&
                            <div className='row mb-2'>
                                {/* <PiLightningBold size={'2rem'} color='#898da6' /> */}
                                <i className='ppi ppi-lightning-bolt' style={{ fontSize: '20px' }}></i>
                                <p className='ml-5'><strong>{activetionsReport?.productTypesSemAtivacao.length > 1 ?
                                    activetionsReport?.productTypesSemAtivacao.join(', ') : activetionsReport?.productTypesSemAtivacao.join(' ')} </strong>
                                    não {activetionsReport?.productTypesSemAtivacao.length > 1 ? 'foram ativados' : 'foi ativado'} nenhuma vez em  {month[Number(moment().month())]}</p>
                            </div>
                        }
                    </>
                    :
                    <LcNoData />
            }
        </div>
    </LcLoading>
    );
}
