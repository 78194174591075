import React, { useEffect, useMemo, useState } from 'react';
import { PiCube } from 'react-icons/pi';
import LcLoading from '../../../Generic/LcLoading';
import IconValue from '../../Dashboard/IconValue';
import { AksClustersModel, OnOffModel } from '../../../../services/cmsPremier/cmsPremierModel';
import { useDispatch, useSelector } from 'react-redux';
import { CmsPremierService } from '../../../../services/cmsPremier/cmsPremierService';
import { CMSEnvironmenetState } from '../../../../store/reducers/CmsEnvironmentReducer';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import { FaAws } from 'react-icons/fa';
import { SiMicrosoftazure } from 'react-icons/si';
import { Tooltip } from '@material-ui/core';
import { useQuery } from 'react-query';

interface ContainersInfoCardProps {
    setLoading?: (loading: boolean) => void
    withoutBallon?: boolean
    isHome?: boolean
}

const columnsContainers = [
    {
        field: 'name', headerName: 'Nome', width: "35%", renderCell: (row: any) =>{
            return  (
            <div style={{ display: 'flex', alignItems: 'center', minWidth: '0' }}>
                <Tooltip arrow title={row.cloud !== 'AWS' ? 'Microsoft Azure' : 'Amazon AWS'}>
                    <span style={{ minWidth: '20px', paddingLeft: '4px', display: 'flex', alignItems: 'center' }}>
                        {row.cloud !== 'AWS' ? <SiMicrosoftazure size={'14px'} color='#141B4D' /> : <FaAws size={'16px'} color='#141B4D' />}
                    </span>
                </Tooltip>
                {/* <Tooltip arrow title={row.name}> */}
                    <span
                        className='overflowEllipsis ml-4'
                    >
                        {row.name}
                    </span>
                {/* </Tooltip> */}
            </div>
            )
        }
    },
    {
        field: 'subscription', headerName: 'Assinatura', width: "25%", renderCell: (row: any) =>{
            return  (
            <Tooltip arrow title={row.subscription}>
                <span
                    className='overflowEllipsis'
                >
                    {row.subscription}
                </span>
            </Tooltip>
            )
        }
    },
    {
        field: 'locationOrRegion', headerName: 'Região', width: "12%",
    },
    {
        field: 'type', headerName: 'Serviço', width: "12%",
    },
    {
        field: 'status', headerName: 'Situação', width: "8%", overflow: "visible",
        renderCell: (row: any) => {
            return row.status === 'Active' ? <p className='text-green '>Ativo</p>: row.status === 'Succeeded' ? <p className='text-green'>Inicializado</p>  : <p className='text-danger'>Parado</p>;
            }
    },   
]

const translationsFilter = {
    name: {
        label: "Nome",
        type: "text",
    },
    subscription: {
        label: "Assinatura",
        type: "text",
    },
    locationOrRegion: {
        label: "Região",
        type: "multiSelect",
    },
    type: {
        label: "Serviço",
        type: "multiSelect",
    },
    status: {
        label: "Situação",
        type: "multiSelect",
        values: {
            'Active':'Ativo',
            'Inactive':'Parado'
        },
        topDropdown: true
    },
};
interface ContainerQueryResponse {
    containersData: AksClustersModel[];
    containersCard: {
        on: number;
        off: number;
    };
}
export const ContainersInfoCard: React.FC<ContainersInfoCardProps> = (props) => {
    const dispatch = useDispatch();
    const user = useSelector<RootState, UserState>(state => state.user);
    const cmsEnvironment = useSelector<RootState, CMSEnvironmenetState>(state => state.cmsEnvironment);

    function updateCloudServices(cloudArray) {
         // Verifique se o `cloudArray` é um array e contém objetos
        if (Array.isArray(cloudArray)) {
            // Crie um novo array para armazenar os resultados
            const result: AksClustersModel[] = [];

            // Percorra cada objeto no array
            cloudArray.forEach(cloud => {
            // Verifique se a propriedade `services` existe e é um array
            if (Array.isArray(cloud.services)) {
                // Adicione um novo objeto para cada serviço
                cloud.services.forEach(service => {
                result.push({
                    ...cloud,  // Inclui todas as propriedades do objeto `cloud`
                    name: service.name,  // Substitui a propriedade `name` com o valor do serviço
                    status: service.status  // Substitui a propriedade `status` com o valor do serviço
                });
                });
            }
            });

            return result;
        }

            return cloudArray;
        }

    const {data, isLoading} = useQuery<ContainerQueryResponse>({
        queryKey: ['containers', user.ClientGroupSelected, user.refreshFlag],
        queryFn: async (): Promise<ContainerQueryResponse> => {
            const CmsService = new CmsPremierService({});
            const containerData = await CmsService.getAKSClusters();
            const containerResult = updateCloudServices(containerData);
            const result = containerResult.reduce(
                (acc, curr) => {
                    if (curr.status.includes("Active") || curr.status.includes("Succeeded")) {
                        acc.on += 1;
                    } else {
                        acc.off += 1;
                    }
                    return acc;
                },
                { on: 0, off: 0 }
            );
            return {
                containersData: containerResult,
                containersCard: result
            };
        },
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10,
        retry: 3,
        refetchOnWindowFocus: false,
        onError: (error) => {
            console.error("Error:", error);
        }
    });

    useEffect(() => {
        if(cmsEnvironment.enviromentFilter === 'Containers' && !isLoading && data) {
            let payload = {
                enviromentFilter: 'Containers',
                columns: columnsContainers,
                rowsList: data?.containersData || [],
                rowsListFiltered: data?.containersData,
                funcitonGeneric: undefined,
                translationFilter: translationsFilter

            }
            dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
            props.setLoading && props.setLoading(false)
        }
    },[cmsEnvironment.enviromentFilter, data,  isLoading])

    const handleClickEnviroment = () => {
        let payload = {
            enviromentFilter: 'Containers',
            columns: columnsContainers,
            rowsList: data?.containersData,
            rowsListFiltered: data?.containersData,
            funcitonGeneric: undefined,
            translationFilter: translationsFilter

        }
        dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
        props.setLoading && props.setLoading(false)

    } 


    return (
        <LcLoading loading={isLoading}  >
            <IconValue
                icon={<PiCube size={32}  />}
                value={`${data?.containersCard?.on ?? 0}/${((data?.containersCard?.on ?? 0) + (data?.containersCard?.off ?? 0))}`}
                color="#255A4B"
                onClick={props.withoutBallon ? undefined : handleClickEnviroment}
                description={props.isHome ? 'Containers' : 'Ativos/Total'}
                balonSelected={(cmsEnvironment.enviromentFilter === 'Containers') && !props.withoutBallon}
            />
        </LcLoading>
    );
}