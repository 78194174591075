import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import LcLoading from '../../../../components/Generic/LcLoading';
import ReactECharts from 'echarts-for-react';
import { useDispatch } from 'react-redux';
import { GraphModel } from '../../../../services/patchmanager/patchManagerModel';
import LcNoData from '../../../../components/Generic/LcNoData';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { defaultTooltipConfig } from '../../../../components/Generic/eChartsConfig';

interface Props {
    selected: number;
    systemByPlatformSupported?: any[];
    systemByPlatformObsolete?: any[];
    legendOrientation: 'horizontal' | 'vertical';
    isLoading: boolean;
    setPageExitConfirmation: (open: boolean) => void;
}

export const SystemsByPlatformGraph: React.FC<Props> = (
    {
        isLoading, 
        systemByPlatformSupported,
        systemByPlatformObsolete,
        legendOrientation,
        selected,
        setPageExitConfirmation
        }) => {

    const [systemGraph, setSystemGraph] = useState<GraphModel[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<{ [key: string]: boolean }>({});
    const [total, setTotal] = useState(0);
    const chartRef = useRef<ReactECharts>(null);
    const dispatch = useDispatch();

    const getColorsGraph = (SOName: string): string => {
        if (SOName.includes('Windows') && SOName.includes('Server')) {
            return '#0580ce';
        }
        if (SOName.includes('Windows')) {
            return "#4ebaff";
        }
        if (SOName.includes('Linux') || SOName.includes('Ubuntu')) {
            return '#ffa400';
        }
        if (SOName.includes('Mac')) {
            return '#777777';
        }
        return '#777777';
    }

    const getSystemPlatformDataSupported = useMemo(() => () => {
        try {
            const systemPlatformResponse = systemByPlatformSupported;
            if (systemPlatformResponse) {
                const updatedData = systemPlatformResponse.map(item => {
                    const nameParts = item.name.split(' '); // Divide o nome em palavras
                    const newName = nameParts.slice(1).join(' '); // Remove a primeira palavra e junta o restante
                    return { ...item, name: newName }; // Retorna um novo objeto com o nome atualizado
                });
                setSystemGraph(updatedData);
                const initialSelected = systemPlatformResponse.reduce((acc, item) => {
                    acc[item.name] = true;
                    return acc;
                }, {});
                setSelectedOptions(initialSelected);
                setTotal(systemPlatformResponse.reduce((sum, { value }) => sum + Number(value), 0));
            }
        } catch (error) {
            console.error('Error:', error);
        } 
    },[systemByPlatformSupported])

    const getSystemPlatformDataObsolete = useMemo(() => () => {
        // const SOSystems = ['windows', 'windows-server', 'linux', 'macos'];
        try {
            const obsoleteResponse = systemByPlatformObsolete;
            const translatedArray = translateArray(obsoleteResponse);
            const updatedData = translatedArray.map(item => {
                const nameParts = item.name.split(' '); // Divide o nome em palavras
                const newName = nameParts.slice(1).join(' '); // Remove a primeira palavra e junta o restante
                return { ...item, name: newName }; // Retorna um novo objeto com o nome atualizado
            });
            setSystemGraph(updatedData);
            const initialSelected = translatedArray.reduce((acc, item) => {
                acc[item.name] = true;
                return acc;
            }, {});
            setSelectedOptions(initialSelected);
            setTotal(translatedArray.reduce((sum, { value }) => sum + Number(value), 0));
        } catch (error) {
            console.error('Error:', error);
        }
    },[systemByPlatformObsolete])

    function translateArray(originalArray) {
        const nameMapping = {
            'windows': 'Windows Client',
            'windows-server': 'Windows Server',
            'linux': 'Linux',
            'macos': 'Mac'
        };

        return originalArray.map(item => ({
            name: nameMapping[item.name] || item.name,
            value: Number(item.value)
        }));
    }

    // function hasReduceFunction(obj: any): obj is { reduce: Function } {
    //     return typeof obj?.reduce === 'function';
    // }

    function hasMapFunction(obj: any): obj is { map: Function } {
        return typeof obj?.map === 'function';
    }

    useEffect(() => {
        if (selected === 1) {
            getSystemPlatformDataSupported()
        } else {
            getSystemPlatformDataObsolete()
        }
    }, [selected, systemByPlatformSupported, getSystemPlatformDataSupported, getSystemPlatformDataObsolete]);

    const handleLegendSelectChangedSystem = useCallback((params) => {
        const selected = params.selected;
        const newSelectedOptions = { ...selectedOptions };
        Object.keys(selected).forEach(key => {
            newSelectedOptions[key] = selected[key];
        });
        // setSelectedOptions(newSelectedOptions);
    
        const selectedItems = systemGraph.filter(item => newSelectedOptions[item.name]);
        const newTotal = selectedItems.reduce((sum, item) => sum + Number(item.value), 0);
        // setTotal(newTotal);
        if (chartRef.current) {
            const chartInstance = chartRef.current.getEchartsInstance();

            chartInstance.setOption({
                legend: {
                    selected: newSelectedOptions
                },
                series: [{
                    label: {
                        formatter: newTotal.toString()
                    }
                }]
            }, false, true);
        }
    }, [selectedOptions, systemGraph]);

    const getDataOptionsSystem = useMemo(() =>(systemGraphData) => {
        const dataLookup = systemGraphData && systemGraphData.length > 0
            ? systemGraphData.reduce((acc, item) => {
                acc[item.name] = Number(item.value);
                return acc;
            }, {})
            : {};

        return {
            tooltip: {
                trigger: 'item',
                ...defaultTooltipConfig,                
            },
            legend: {
                inactiveColor: '#ededed',
                inactiveBorderColor: '#ededed',
                type: 'scroll',
                orient: legendOrientation,
                width: legendOrientation === 'vertical' ? '' : '100%',
                right: legendOrientation === 'vertical' ? '-1%' : 'center',
                top: legendOrientation === 'vertical' ? 'middle' : 'bottom',  
                bottom: '10%',
                textStyle: {
                    fontSize: window.screen.width < 1400 ? 8 : 9,
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                    fontWeight: 'normal',
                },
                pageTextStyle: {
                    fontFamily: 'Ubuntu',
                    color: '#8b90a3',
                    fontSize: 10,
                },
                pageButtonItemGap: 5,
                pageButtonGap: 10,
                pageButtonPosition: 'end',
                pageFormatter: '{current}/{total}',
                pageIconSize: 10,
                pageIconColor: '#273b85',
                pageIcons: {
                    vertical: [
                        'path://M10 9L6 5l-4 4 1.4 1.4L6 7.8l2.6 2.6z',  // Chevron para baixo
                        'path://M6 9l-4-4 1.4-1.4L6 6.2l2.6-2.6L10 5z' // Chevron para cima                        
                    ],
                    horizontal: [
                        'path://M5 6l4-4 1.4 1.4L7.8 6l2.6 2.6L9 10z',  // Chevron para a direita
                        'path://M9 6l-4 4-1.4-1.4L6.2 6 3.6 3.4 5 2z' // Chevron para a esquerda                        
                    ]
                },
                formatter: (name) => {
                    const value = dataLookup[name] || 0;
                    return `${name}: ${Number(value).toLocaleString('pt-BR')}`;
                },
                selected: selectedOptions
            },
            series: [{
                type: 'pie',
                center:  window.screen.width < 1400 &&  legendOrientation === 'vertical' ? ['20%', '50%']  : legendOrientation === 'vertical' ? ['23%', '50%'] : ['50%', '50%'],
                radius: window.screen.width < 1400 ? ['40%', '60%'] : ['50%', '80%'],
                avoidLabelOverlap: false,
                itemStyle: {
                    borderRadius: 4,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                label: {
                    show: true,
                    position: 'center',
                    formatter: () => total,
                    fontSize: 20,
                    fontWeight: 'normal',
                    textStyle: {
                        overflow: 'truncate',
                        ellipsis: '...',
                        fontFamily: 'Ubuntu',
                    }
                },
                color: systemGraphData && hasMapFunction(systemGraphData) ? systemGraphData.map(i => getColorsGraph(i.name)) : [],
                data: systemGraphData,
                emphasis: {
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(58, 79, 135, 0.4)',
                        borderWidth: 0
                    }
                }
            }]
        };
    },[legendOrientation, selectedOptions, total]);

    const handleFilterSystem = useCallback((event) => {
        let newName = event.name
        let payload = {
            OsSystemName:newName
        } 
        dispatch({ type: 'OS_SYSTEM_FILTER', payload });
        setPageExitConfirmation(true)
    },[dispatch, setPageExitConfirmation])

    const memoizedOnEvents = useMemo(() => ({
        'legendselectchanged': handleLegendSelectChangedSystem,
        'click': handleFilterSystem
    }), [handleLegendSelectChangedSystem, handleFilterSystem]);

    const memoizedOption = useMemo(() => getDataOptionsSystem(systemGraph), [getDataOptionsSystem, systemGraph]);

    return (
        <LcLoading loading={isLoading}>
            {
                systemGraph.length > 0 ?
                <>
                    <ReactECharts
                        ref={chartRef}
                        key={'SystemGraph'}
                        opts={{ renderer: 'svg', locale: 'PT-br' }}
                        className='SystemGraph'
                        option={memoizedOption}
                        onEvents={memoizedOnEvents}
                    />
                    {
                        selected === 2 &&
                        <div className='row lc-title' style={{ fontSize: '10px', alignItems: 'center', justifyContent: 'center', margin: '1rem 0', }}>
                            <AiOutlineExclamationCircle color='#3498d7' fontSize={15} />
                            <strong> Sistemas Obsoletos:</strong> não recebem mais atualizações de segurança do fabricante do SO.
                        </div>
                    }
                </>
            :
                <LcNoData size="default" />
            }
        </LcLoading>
    );
}
