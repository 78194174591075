import React, { useEffect, useState } from 'react';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import { ProgressBarWithLabel } from '../../../components/Layout/Dashboard/ProgressBarWithLabel';
import { HostDetailedMetric, HostDetailedMetricsRequest, HostsAvailability, LiveWatchService, liveWatchSeverityDic, liveWatchStatusDic } from '../../../services/LiveWatchServiceGroupService';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { PPModal } from 'processor-plataform-ui';
import LiveWatchPerformanceDetailsChart, { DetailsChartDataset } from '../ResourcePerformance/DetailsChart';
import { Tooltip } from '@material-ui/core';

interface LWRiskIndexProps {
    isPanelFocused: boolean;
    incidentPeriod: any
    newFilteredRows: HostsAvailability[]
    setSelectedPeriodLabel(label: string): void;
    setHostsAvailability(hostsAvailability: HostsAvailability[]): void;
    setRiskPercent(riskPercent: number): void;
}

const severityDic = liveWatchSeverityDic;
const statusDic = liveWatchStatusDic;

const LWRiskIndex: React.FC<LWRiskIndexProps> = (props) => {

    const { isPanelFocused, incidentPeriod, setSelectedPeriodLabel, newFilteredRows, setHostsAvailability, setRiskPercent } = props;

    const service = new LiveWatchService({});
    const user = useSelector<RootState, UserState>(state => state.user);

    const [filteredHostsAvailability, setFilteredHostsAvailability] = useState<HostsAvailability[]>([]);
    const [currentWeekHostsAvailability, setCurrentWeekHostsAvailability] = useState<HostsAvailability[]>([]);
    const [previousWeekHostsAvailability, setPreviousWeekHostsAvailability] = useState<HostsAvailability[]>([]);
    const [hostDetailedMetricsRequest, setHostDetailedMetricsRequest] = useState<HostDetailedMetricsRequest>();
    const [hostDetailedMetrics, setHostDetailedMetrics] = useState<HostDetailedMetric[]>();
    const [mappedHostDetailedMetrics, setMappedHostDetailedMetrics] = useState<DetailsChartDataset[]>([]);
    const [hostsAvailabilitySelected, setHostsAvailabilitySelected] = useState<HostsAvailability>();
    const [showDetailedMetrics, setShowDetailedMetrics] = useState<boolean>(false);
    const [loadingDetailedMetrics, setLoadingDetailedMetrics] = useState<boolean>(false);

    const openChartAction = (row: HostsAvailability) => {
        const startedDate = new Date(row.started_at);
        const today = new Date();

        const request = {
            host_id: row.host_id.toString(),
            start_date: startedDate.toISOString().slice(0, 10),
            end_date: today.toISOString().slice(0, 10)
        }

        setHostDetailedMetricsRequest(request);
        setHostsAvailabilitySelected(row);
        setShowDetailedMetrics(true);
    }

    const closeChartAction = () => {
        setHostDetailedMetricsRequest(undefined);
        setHostsAvailabilitySelected(undefined);
        setShowDetailedMetrics(false);
    }

    const loadDetailedMetrics = () => {
        if (hostDetailedMetricsRequest) {
            setLoadingDetailedMetrics(true);
            service.GetHostDetailedMetrics(hostDetailedMetricsRequest)
                .then((result) => {
                    if (hostsAvailabilitySelected) {
                        const selectedHost = result.find(host => {
                            return host.item.id.toString() === hostsAvailabilitySelected.item_id
                                && host.item.trends.length > 0
                        });
                        if (selectedHost) {
                            const trends = selectedHost.item.trends;
                            const items = trends.map(trend => ({
                                value: parseFloat(trend.average.calculated.value),
                                unit: trend.average.calculated.unit,
                                time: trend.timestamp
                            }))
                            setMappedHostDetailedMetrics(items);
                        }
                    }
                })
                .catch(console.error)
                .finally(() => setLoadingDetailedMetrics(false));
        }
    }
    useEffect(loadDetailedMetrics, [hostDetailedMetricsRequest]);

    const renderHostnameCell = (row: HostsAvailability) => {
        const clickableHostname = row.status.toLowerCase() === 'unresolved' ?
            <a className='link-text' onClick={() => openChartAction(row)}>{row.hostname}</a> :
            row.hostname;

        return <span className='overflowEllipsis'>{clickableHostname}</span>
    }
    const renderTriggerNameCell = (row: any) => <Tooltip arrow title={row.trigger_name}><span className='overflowEllipsis'>{row.trigger_name}</span></Tooltip>
    const renderStatusCell = (row: any) => <span style={{ color: statusDic[row.status][1] }}>{statusDic[row.status][0]}</span>
    const renderDurationCell = (row: any) => <span style={{ color: 'var(--status-red)' }}>{row.duration}</span>
    const renderUnavailabilityCell = (row: any) => {
        const value = row.unavailability_percentage !== null ? `${Number(row.unavailability_percentage).toFixed(2)}%` : '-'
        return (<span style={{ color: 'var(--status-red)' }}>{value}</span>)
    }
    const renderSeverityCell = (row: any) =>
        <div className='severity-container' style={{ backgroundColor: row.severity ? severityDic[row.severity][1] : severityDic[0][1] }}>
            <span style={{ color: row.severity ? severityDic[row.severity][2] : severityDic[0][2] }}>
                {row.severity ? severityDic[row.severity][0] : severityDic[0][0]}
            </span>
        </div>

    const tableFields = [
        { field: 'hostname', headerName: 'Recurso', width: 500, renderCell: renderHostnameCell },
        { field: 'trigger_name', headerName: 'Sensor', width: 500, renderCell: renderTriggerNameCell },
        { field: 'duration', headerName: 'Duração do alerta', width: 300, renderCell: renderDurationCell, align: 'right' },
        { field: 'unavailability_percentage', headerName: 'Taxa semanal de falha', width: 300, renderCell: renderUnavailabilityCell, align: 'right' },
        { field: 'status', headerName: 'Status', width: 200, renderCell: renderStatusCell, align: 'center' },
        { field: 'severity', headerName: 'Severidade', width: 200, renderCell: renderSeverityCell, align: 'center' }
    ];

    const { data, isLoading: loadingHostsAvailability } = useQuery({
        queryKey: ['hostsAvailability', user.ClientGroupSelected, user.refreshFlag],
        queryFn: () => service.GetHostsAvailability(),
        refetchOnWindowFocus: false,
        keepPreviousData: true,
        onSuccess: (response) => {
            const sevenDaysAgo = new Date();
            sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
            sevenDaysAgo.setHours(0, 0, 0, 0);

            const currentWeek = response.problems
                .filter(event => new Date(event.started_at) >= sevenDaysAgo && event.severity > 1)
                .sort((a, b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime());

            const previousWeek = response.problems
                .filter(event => new Date(event.started_at) < sevenDaysAgo && event.severity > 1)
                .sort((a, b) => new Date(b.started_at).getTime() - new Date(a.started_at).getTime());

            setHostsAvailability(currentWeek);
            setFilteredHostsAvailability(currentWeek);
            setCurrentWeekHostsAvailability(currentWeek);
            setPreviousWeekHostsAvailability(previousWeek);

        },
        onError: (error) => {
            console.error('Error fetching hosts availability:', error);
        }
    });

    const riskPercent = data?.total_unavailability ?? 0;
    const progressBarColor = Number(riskPercent.toFixed(2)) > 0 ? 'var(--status-red)' : 'var(--status-green)';
    setRiskPercent && setRiskPercent(riskPercent);
    useEffect(() => {
        const filterHostsAvailabilityByPeriod = (period: string) => {
            const today = new Date();
            let startDate = new Date(today);
            let endDate = new Date(today);

            if (period === 'CURR_WEEK') {
                endDate = today;
                startDate.setDate(today.getDate() - 7);
                setHostsAvailability(currentWeekHostsAvailability);
                setFilteredHostsAvailability(currentWeekHostsAvailability);
            }

            if (period === 'PREV_WEEK') {
                endDate.setDate(today.getDate() - 8);
                startDate.setDate(today.getDate() - 14);
                setHostsAvailability(previousWeekHostsAvailability);
                setFilteredHostsAvailability(previousWeekHostsAvailability);
            }

            const formattedStartDate = startDate.toLocaleDateString('pt-BR');
            const formattedEndDate = endDate.toLocaleDateString('pt-BR');
            setSelectedPeriodLabel(`(${formattedStartDate} a ${formattedEndDate})`);
        }
        filterHostsAvailabilityByPeriod(incidentPeriod?.value)
    }, [currentWeekHostsAvailability, incidentPeriod, previousWeekHostsAvailability, setHostsAvailability, setSelectedPeriodLabel]);

    useEffect(() => {
        if (newFilteredRows) {
            setFilteredHostsAvailability(newFilteredRows)
        }
    }, [newFilteredRows])

    return (
        <>
            <PPModal visible={showDetailedMetrics} onClose={closeChartAction} title={hostsAvailabilitySelected ? hostsAvailabilitySelected.trigger_name : ''} size='90vw'>
                <LiveWatchPerformanceDetailsChart
                    loading={loadingDetailedMetrics}
                    dataset={mappedHostDetailedMetrics}
                    hostname={hostsAvailabilitySelected ? hostsAvailabilitySelected.hostname : ''}
                    isSubcard
                />
            </PPModal>
            <LcLoading loading={loadingHostsAvailability}>
                {
                    isPanelFocused ?
                        filteredHostsAvailability && filteredHostsAvailability.length > 0 ?
                            <LcInfiniteTable
                                rows={filteredHostsAvailability.sort((a, b) => b.status.localeCompare(a.status))}
                                columns={tableFields}
                                size={filteredHostsAvailability.length}
                                hidePagination
                            />
                            : <LcNoData />
                        :
                        riskPercent && riskPercent > 0 ?
                            <ProgressBarWithLabel
                                atualPercentScore={riskPercent}
                                maxPercentScore={100}
                                size='normal'
                                barColor={progressBarColor}
                                decimals={2}
                                textTooltip={`Seu índice de risco atual é ${riskPercent.toFixed(2)}%`} />
                            :
                            <LcNoData />
                }
            </LcLoading>
        </>

    )
}

export default LWRiskIndex;