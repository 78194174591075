import { PPModal } from 'processor-plataform-ui';
import React from 'react';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcLoading from '../../../components/Generic/LcLoading';
import { AutomationAudit, AutomationResource, AutomationsScheduler, AutomationTemplate, CetegorizedResource } from '../../../services/automations/automationsModel';
import moment from 'moment';
import { useQuery } from 'react-query';
import AutomationsService from '../../../services/automations/automationsService';
import { Tooltip } from '@material-ui/core';
import { PiDesktopLight, PiDatabaseLight, PiCodeSimpleLight, PiCloudWarningLight } from 'react-icons/pi';
import { SiKubernetes } from 'react-icons/si';
import { parseSchedulerData } from '.';

interface AuditEditHistoryModalProps {
    scheduler: { id: number } | null;
    showAuditModal: boolean;
    toggleAuditModal: (id: number) => void;
    resource: AutomationResource;
    template: AutomationTemplate;
    templates: AutomationTemplate[];
}

export const AuditEditHistoryModal: React.FC<AuditEditHistoryModalProps> = ({
    scheduler,
    showAuditModal,
    toggleAuditModal,
    resource,
    template,
    templates
}) => {
    const automationsService = new AutomationsService({});

    const { data: auditRecords = [], isLoading: isLoadingAudit } = useQuery(
        ['automationAuditRecords', scheduler?.id, showAuditModal],
        async () => {
            if (!scheduler?.id || !showAuditModal) {
                return [];
            }

            const result = await automationsService.GetAuditRecords(scheduler.id);

            if (result.status === 204) {
                return [];
            }

            return result.data.sort((a, b) =>
                new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
            );
        },
        {
            enabled: !!scheduler?.id && showAuditModal,
            refetchOnWindowFocus: false,
            onError: (error) => {
                console.error('Error fetching audit records:', error);
            }
        }
    );

    const renderAuditModifications = (row: AutomationAudit) => {

        var _values: any = {};
        var _timestamp = (row.timestamp && moment(row.timestamp).format('DD/MM/YYYY HH:mm:ss')) || 'Não informado';

        if (row.hasOwnProperty('currentValues') && row.currentValues !== '') {

            let _parsed = JSON.parse(row.currentValues);
            let _current: AutomationsScheduler = Object.keys(_parsed).reduce((obj, k) => {
                obj[`${k.charAt(0).toLowerCase()}${k.slice(1)}`] = _parsed[k];
                return obj;
            }, {} as AutomationsScheduler);
            let _resource = categorizeResourceFromAutomation(_current);
            _values = {
                'Grupo': automationsService.groupsDic[_resource.groupType],
                'Automação': automationsService.automationTemplatesDic[template.name][1],
                'Recurso': _resource.name,
                'Tamanho': template.name.indexOf('Resize') > -1 ? JSON.parse(_current.data as string)!.Size : 'Sem Informações',
                'Dias da Semana': _current.weekDaysScheduled?.length > 0 ? _current.weekDaysScheduled.split('-').map(d => automationsService.automationsWeekDic[d][0]).toString() : '',
                'Horário': moment(_current.hourScheduled, 'HH:mm:ss').format('HH:mm'),
                'Status': ['Desabilitada', 'Habilitada'][_current.status]
            };

            if (row.action === 'UPDATE') {
                delete _values['Grupo'];
                delete _values['Automação'];
                delete _values['Recurso'];
            }
        }

        return (
            <div className='auditInfoContainer'>
                <span className='auditTimestamp'>{_timestamp}</span>
                {
                    Object.keys(_values).length > 0 &&
                    <div className='auditInfo'>
                        <div className='auditInfoLabels'>
                            {Object.keys(_values).map(v => <p>{v}:</p>)}
                        </div>
                        <div className='auditInfoLabels'>
                            {Object.keys(_values).map(v => <p>{_values[v]}</p>)}
                        </div>
                    </div>
                }
            </div>
        )
    }
    const categorizeResourceFromAutomation = (scheduler: AutomationsScheduler, iconSize: number = 16, iconColor: string = '#2E1956') => {
        let _template = templates.find(t => t.id === scheduler.automationId);
        let _data = parseSchedulerData(scheduler.data);
        let _result = { id: '', provider: '', name: '', subscription: '', groupType: '', serviceType: '', icon: <></> } as CetegorizedResource;
        let _resourceIcons = {
            'VM': <PiDesktopLight size={`${iconSize}px`} color={iconColor} />,
            'DB': <PiDatabaseLight size={`${iconSize}px`} color={iconColor} />,
            'Container': <SiKubernetes size={`${iconSize}px`} color={iconColor} />,
            'AppServicePlan': <PiCodeSimpleLight size={`${iconSize}px`} color={iconColor} />,
            'unknown': <PiCloudWarningLight size={`${iconSize}px`} color={iconColor} />
        }
        let _resourceIconTooltips = {
            'VM': 'Máquina virtual',
            'DB': 'Banco de dados',
            'unknown': 'Não identificado'
        }

        let _friendlyName = '-';
        if (_data.CloudOS === 'AWS') {
            if (_data.hasOwnProperty('ClusterName'))
                _friendlyName = _data.ClusterName! ?? '-';
            else if (_data.hasOwnProperty('ResourceId') && _data.ResourceId?.includes(":"))
                _friendlyName = _data.ResourceId.split(":")[4] ?? '-';
            else
                _friendlyName = _data.ResourceId! ?? '-';
        } else
            _friendlyName = _data.ResourceName! ?? '-';

        if (_template && _data.Automation !== '') {
            _result.id = _data.ResourceId ?? '';
            _result.provider = _data.CloudOS;
            _result.name = _friendlyName;
            _result.subscription = _data.SubscriptionId ?? _data.AccountId ?? '';
            _result.groupType = _template.groupType;
            _result.serviceType = _template.serviceType;
            _result.icon = (
                <Tooltip arrow title={_resourceIconTooltips[_template.groupType]}>
                    <span>{_resourceIcons[_template.groupType]}</span>
                </Tooltip>
            );
        }

        return _result;
    }
    const auditColumns = [
        { field: 'version', headerName: 'Versão', width: '5%', renderCell: (row: AutomationAudit) => `${auditRecords.indexOf(row) + 1}.0` },
        { field: 'automation', headerName: 'Automação', width: '20%', renderCell: (row: AutomationAudit) => automationsService.automationTemplatesDic[template.name][1] },
        { field: 'resource', headerName: 'Recurso', width: '15%', renderCell: (row: AutomationAudit) => resource.resourceName },
        { field: 'clientUserName', headerName: 'Modificado Por', width: '20%' },
        { field: 'modification', headerName: 'Modificado Em', width: '35%', renderCell: (row: AutomationAudit) => renderAuditModifications(row) }
    ];
    return (

        <PPModal title='Auditoria - Histórico de Edições' visible={showAuditModal} onClose={toggleAuditModal} size='large' >
            <LcLoading loading={isLoadingAudit} label='Carregando auditoria...' >
                <LcInfiniteTable
                    loading={isLoadingAudit}
                    columns={auditColumns}
                    rows={auditRecords}
                    size={auditRecords.length}
                    onSortChange={(sortData: any) => {
                        const { sort } = sortData;
                        auditRecords.sort((a: any, b: any) => {
                            if (sort.direction === 'asc') return (a[sort.column] > b[sort.column]) ? 1 : -1;
                            else return (a[sort.column] > b[sort.column]) ? -1 : 1;
                        });
                    }}
                    density='high'
                />
            </LcLoading>
        </PPModal>
    );
}
