import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { BackupSaasService } from '../../../services/backupSaas/backupSaasService';
import { Grid, LinearProgress } from '@material-ui/core';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';

import Moment from 'moment';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import { CacheManagerService } from '../../../services/cacheManagerService';

const BackupSaasHistorico: React.FC = (props) => {

    const local = document.location.pathname;
    const user = useSelector<RootState, UserState>(state => state.user);
    const userPeriodSelected = user.PeriodSelection ? user.PeriodSelection[local] : undefined;
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    const [jobs, setJobs] = useState<any[]>([]);
    const [filteredJobs, setFilteredJobs] = useState<any[]>([]);
    const [visibleJobs, setVisibleJobs] = useState<any[]>([]);
    const [filter, setFilter] = useState<{ size: number, term: string, column: string, direction: string }>({ size: 20, term: "", column: "", direction: "asc" });

    const [month, setMonth] = useState<string>(userPeriodSelected ? userPeriodSelected.fields[0].value.split('-')[1] : undefined);
    const [year, setYear] = useState<string>(userPeriodSelected ? userPeriodSelected.fields[0].value.split('-')[0] : undefined);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [lastUpdate, setLastUpdate] = useState<string>("");

    const cacheManagerService = new CacheManagerService(props);

    const refreshLastUpdate = () => {
        cacheManagerService.LastSyncUpdate("ProcessorSyncBackupSaas", "BackupSaaSHistory")
            .then(response => {
                setLastUpdate(response.data);
            })
            .catch((err) => {
                console.warn(err);
                setLastUpdate(new Date().toISOString());
            })
    }

    useEffect(() => {
        setIsLoadingTable(true);
        setFilteredJobs([]);
        if (!month && !year) {
            setMonth((new Date().getMonth() + 1).toString().padStart(2, '0'));
            setYear(new Date().getFullYear().toString());
        }
        if (user.ClientGroupSelected && month && year) {
            const startDate = [year, month, '01'].join('-');
            const endDate = [year, month, new Date(parseInt(year ? year :
                new Date().getFullYear().toString()), parseInt(month ? month :
                    (new Date().getMonth() + 1).toString().padStart(2, '0')), 0).getDate().toString()].join('-');
            const backupSaasService = new BackupSaasService(props);
            backupSaasService.GetHistory(startDate, endDate)
                .then((items: any[]) => {
                    if (items.length > 0) {
                        setJobs(items);
                        setFilteredJobs(items)
                        setIsLoadingTable(false);
                    } else {
                        setJobs([]);
                        setFilteredJobs([])
                        setIsLoadingTable(false);
                    }
                })
                .catch(error => {
                    console.log(error);
                });
            refreshLastUpdate();
        }
    }, [month, user.ClientGroupSelected, year, user.refreshFlag]);

    useEffect(() => {
        var filtered = jobs.filter((p: any) =>
            filter.term == '' ||
            p.Status.toLowerCase().indexOf(filter.term) > -1 ||
            p.Connector.toLowerCase().indexOf(filter.term) > -1 ||
            p.Type.toLowerCase().indexOf(filter.term) > -1 ||
            p.StartDate.toLowerCase().indexOf(filter.term) > -1 ||
            p.EndDate.toLowerCase().indexOf(filter.term) > -1
        )
            .sort((a: any, b: any) => {
                if (filter.direction == 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

        setFilteredJobs(filtered);
        setVisibleJobs(filtered.slice(0, filter.size));
    }, [filter, filteredJobs.length > 0]);

    const columns = [
        { field: 'Status', headerName: 'Situação', width: "20%" },
        { field: 'Connector', headerName: 'Produto', width: "20%" },
        { field: 'Type', headerName: 'Tipo', width: "20%" },
        { field: 'StartDate', headerName: 'Data de início', width: "20%" },
        { field: 'EndDate', headerName: 'Data de fim', width: "20%" }
    ];

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const loadMore = (size?: number) => {
        if (size) setVisibleJobs(filteredJobs.slice(0, size))
        else setVisibleJobs([...visibleJobs, ...filteredJobs.slice(visibleJobs.length, visibleJobs.length + 10)])
    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const [filterVisible, setFilterVisible] = useState(false);
    const _filterAdvanced = {
        fields: [{ label: 'Pesquisar', name: 'search', type: 'text' }],
        onChange: (filter: any, size: number) => setFilter({ ...filter, size: size, term: filter[0].value.toLowerCase() }),
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }

    return (
        <Layout
            cardType
            pageTitle="Histórico"
            periodSelection={periodSelection}
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: filteredJobs.length
            }}
        >
            <LcInfiniteTable
                loading={isLoadingTable}
                columns={columns}
                rows={visibleJobs}
                filter={_filterAdvanced}
                size={filteredJobs.length}
                loadMore={loadMore}
                onSortChange={onSortChange}
                density={pref.data?.density || "high"}
            />
        </Layout>
    )
}

export default BackupSaasHistorico;
