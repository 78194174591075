import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { RetencaoService } from '../../../services/retencao/retencaoService';

//Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import { PrefState } from '../../../store/reducers/prefReducer';

import { useSnackbar } from 'notistack';
import DialogConfirmForm from '../../../components/Layout/Dialog/DialogConfirmForm';
import LcInfiniteTable from "../../../components/Data/LcInfiniteTable";
import { PiDownloadLight, PiKeyLight } from 'react-icons/pi';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import LcLoading from '../../../components/Generic/LcLoading';

interface FilterData {
    size: number;
    dataSolicitacao: {
        dateName: string,
        startDate: string,
        endDate: string
    },
    usuario: string,
    direction: string;
}

const SolicitacoesDeAcesso: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const dispatch = useDispatch();

    const retencaoService = new RetencaoService(props);

    const [table, setTable] = useState<any[]>([]);
    const [filteredTable, setFilteredTable] = useState<any[]>([]);
    const [visibleTable, setVisibleTable] = useState<any[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { enqueueSnackbar } = useSnackbar();
    const [isVisibleAlertMessage, setIsVisibleAlertMessage] = useState<boolean>(false);
    const [isVisibleKey, setIsVisibleKey] = useState<boolean>(false);
    const [accessKey, setAccessKey] = useState<string>('');

    useEffect(() => {
        if (user.ClientGroupSelected) {
            setFilteredTable([]);
            retencaoService.GetTable()
                .then(response => {
                    let table = response.data.map((Item: { DataSolicitacao: any, Usuario: string, URL: string }, index: number) => {
                        const utcDate = new Date(Item.DataSolicitacao)
                        return {
                            id: index,
                            dataSolicitacao: Item.DataSolicitacao,
                            utcDate: utcDate,
                            usuario: Item.Usuario,
                            url: Item.URL,
                        }
                    });
                    setTable(table);
                    setFilteredTable(table);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    setTable([]);
                    setFilteredTable([]);
                    setIsLoading(false);
                });
        }
    }, [user.ClientGroupSelected, user.refreshFlag]);

    function downloadFile() {
        enqueueSnackbar("Baixando arquivo. Aguarde...", {
            variant: 'warning',
            preventDuplicate: true,
            persist: false,
        });
        const FileDownload = require("js-file-download");
        retencaoService.DownloadFile().then(response => { FileDownload(response.data, "GR_Acesso_Retencao.pdf") }).catch(error => { console.log(error) });
    }

    function createAccessKey() {
        enqueueSnackbar("Criação da chave iniciada, aguarde.", {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        });
        retencaoService.CreateAccessKey()
            .then(response => {
                if (response.data) {
                    enqueueSnackbar("Criação da chave realizada com sucesso.", {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    });
                    setAccessKey(response.data);
                    setIsVisibleKey(!isVisibleKey)
                }
            })
            .catch(error => {
                enqueueSnackbar("Erro ao criar chave de acesso.", {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                });
                console.log(error)
            });
    };

    const columns = [
        {
            field: 'dataSolicitacao', headerName: 'Data da solicitação', width: "20%", filterable: false,
            renderCell: (row: any) => {
                const dateToFormat = new Date(row.dataSolicitacao);
                return dateToFormat.toLocaleDateString();
            }
        },
        { field: 'usuario', headerName: 'Solicitante', width: "20%" },
        {
            field: 'url', headerName: 'URL', width: "60%",
            renderCell: (row: any) => {
                const urlBase = row.url.toString().split('?')[0];
                return (<>{urlBase}*************************</>)
            }
        },
    ];

    const functions = [
        {
            title: 'Instrução de download',
            icon: <PiDownloadLight />,
            tooltip: 'Instrução de download',
            onClick: () => downloadFile()
        },
        {
            title: 'Criar chave de acesso ',
            icon: <PiKeyLight />,
            tooltip: 'Criar chave de acesso',
            onClick: () => setIsVisibleAlertMessage(!isVisibleAlertMessage)
        }
    ];

    // #region Filtro
    const defaultFilterValue = {
        size: 20,
        dataSolicitacao: {
            dateName: '',
            startDate: '',
            endDate: ''
        },
        usuario: '',
        direction: 'asc'
    };

    const [filterData, setFilterData] = useState<FilterData>(defaultFilterValue);
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [pinnedFilter, setPinnedFilter] = useState<boolean>(false);

    const filterStructure = {
        fields: [
            { label: 'Data', name: 'dataSolicitacao', type: 'spanDateTime' },
            { label: 'Usuário', name: 'usuario', type: 'select', options: [...new Set(table.map(i => i.usuario))].map(i => ({ label: i, value: i })).sort((a, b) => a.label.localeCompare(b.label)) },
        ],
        onChange: (_filter: any, size: number) => setFilterData({
            ...filterData,
            size: size,
            usuario: _filter[1].value.toLowerCase(),
            dataSolicitacao: _filter[0]
        }),
        visible: showFilter,
        onChangeVisibility: () => setShowFilter(!showFilter)
    }
    const filterSystem = () => {
        return (
            <FilterLcInfiniteTable
                filter={filterStructure.fields}
                onFilterChange={(updatedFilters) => {
                    const newFilter = { ...filterData };
                    updatedFilters.forEach(f => newFilter[f.name] = f.value);
                    setFilterData(newFilter);
                }}
            />
        );
    };

    const applyFilter = useCallback(() => {
        const filterAction = (item: any) => {
            const mustFilterUsuario = ![defaultFilterValue.usuario, undefined].includes(filterData.usuario) && filterData.usuario.length > 0
            const mustFilterDate = filterData.dataSolicitacao !== undefined && ![defaultFilterValue.dataSolicitacao.startDate, undefined].includes(filterData.dataSolicitacao.endDate)
                && ![defaultFilterValue.dataSolicitacao.startDate, undefined].includes(filterData.dataSolicitacao.endDate)

            if (mustFilterUsuario || mustFilterDate) {
                const matchesHostname = mustFilterUsuario ? item.usuario.toLowerCase().includes(filterData.usuario.toLowerCase()) : true;
                const matchesSeverity = mustFilterDate ? new Date(item.dataSolicitacao) >= new Date(`${filterData.dataSolicitacao.startDate}T00:00:00-03:00`)
                    && new Date(item.dataSolicitacao) <= new Date(`${filterData.dataSolicitacao.endDate}T23:59:59-03:00`) : true;
                return matchesHostname && matchesSeverity;
            }

            return true;
        }

        const filtered = table.filter(filterAction);
        setFilteredTable(filtered);
    }, [table, filterData]);

    useEffect(() => {
        applyFilter();
    }, [applyFilter]);

    const gridFilter = {
        toggleVisibility: () => {
            setShowFilter(!showFilter)
        },
        size: filteredTable.length
    }

    const dashboardRightPanelFilter = {
        title: 'Filtros',
        pinned: pinnedFilter,
        show: showFilter,
        content: filterSystem,
        togglePinned: () => setPinnedFilter(!pinnedFilter),
        close: () => setPinnedFilter(false)
    }
    // #endregion

    const OnCloseEventAlertMessage = (response: any) => {
        if (response) {
            setIsVisibleAlertMessage(!isVisibleAlertMessage)
            createAccessKey()
        } else {
            setIsVisibleAlertMessage(!isVisibleAlertMessage)
        }
    }
    const OnCloseEventKey = (response: any) => {
        setIsVisibleKey(!isVisibleKey)
        setAccessKey('')

        enqueueSnackbar("Os dados estão sendo atualizados em segundo plano no provedor, isto pode levar alguns minutos, retorne novamente mais tarde.", {
            variant: 'info',
            preventDuplicate: true,
            persist: false,
        });
        setIsLoading(true);
        setTable([]);
        setFilteredTable([]);
        retencaoService.GetTable().then(response => {
            let table = response.data.map((Item: { DataSolicitacao: any, Usuario: string, URL: string }, index: number) => {
                const utcDate = new Date(Item.DataSolicitacao)
                return {
                    id: index,
                    dataSolicitacao: Item.DataSolicitacao,
                    utcDate: utcDate,
                    usuario: Item.Usuario,
                    url: Item.URL,
                }
            });
            setTable(table);
            setFilteredTable(table);
            setIsLoading(false);
        })
            .catch(error => {
                console.log(error);
                setTable([]);
                setFilteredTable([]);
                setIsLoading(false);
            });
    }

    const loadMore = (size?: number) => {
        if (size) setVisibleTable(filteredTable.slice(0, size))
        else setVisibleTable([...visibleTable, ...filteredTable.slice(visibleTable.length, visibleTable.length + 10)])
    };

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    return (
        <Layout
            pageTitle="Solicitações de Acesso"
            functionsGeneric={functions}
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density == "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density == "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density == "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            gridFilter={gridFilter}
        >

            <DialogConfirmForm
                onCloseEvent={OnCloseEventAlertMessage}
                open={isVisibleAlertMessage}
                confirmMessage='Gerar Chave'
                idConfirm="091cec99-7126-447d-bd62-91802a0e4441"
                abortMessage='Cancelar'
                idAbort="d13142b6-65bc-4ddc-9226-9834b4fd9465"
                title='Aviso de criação de chave'
                content='Esta operação criará uma chave que será exibida apenas uma única vez, anote a chave após gerá-la.'>
            </DialogConfirmForm>

            <DialogConfirmForm
                onCloseEvent={OnCloseEventKey}
                open={isVisibleKey}
                confirmMessage='Ok'
                idConfirm="e533c9e6-19b7-452e-b361-34b0fd42691a"
                abortMessage="Fechar"
                idAbort="28ebf46b-2518-4194-abcc-cbbee1a31c33"
                title='Chave gerada'
                content={`Sua chave foi gerada com sucesso. Anote e lembre-se de armazená-la de maneira segura, em caso de perca será necessário gerar uma nova chave. Chave: ${accessKey}`}>
            </DialogConfirmForm>

            <LCDashboard
                cards={[
                    {
                        id: 1,
                        type: 'Custom',
                        hideHeader: true,
                        position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
                        customContentRender: () => {
                            return (
                                <LcLoading loading={isLoading} loadingType='Helix'>
                                    <LcInfiniteTable
                                        loading={isLoading}
                                        columns={columns}
                                        rows={filteredTable}
                                        size={filteredTable.length}
                                        loadMore={loadMore}
                                        density={pref.data?.density || "high"}
                                    />
                                </LcLoading>
                            )
                        }
                    }
                ]}
                rightSidePanel={dashboardRightPanelFilter}
            />
        </Layout>
    );
};

export default SolicitacoesDeAcesso;