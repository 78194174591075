import React from 'react';
import PatchManagerResume from '../../../../../pages/PatchManager/ResumePathManager';
//import LiveWatchInsights from '../../../../../pages/LiveWatch/Insights';
import LiveWatchSummary from '../../../../../pages/LiveWatch/Summary';
import XDRCheckpointResume from '../../../../../pages/endpointDetectionResponse/XDRCheckpointResume';

export type GTMType = 'patchmanager' | 'xdr' | 'livewatch';

export interface SecCardProps {
    GTMs: GTMType[];
    QtdGroups: number;
}

const componentFactory = (gtm: GTMType, props: { isHome: boolean, QtdGroups: number, isSecOpsPage: boolean, secOpsQtdGroups: number, GTMs: GTMType[] }) => {
    switch (gtm) {
        case 'xdr':
            return <XDRCheckpointResume key={`${gtm}`} {...props} />;
        case 'livewatch':
            return <LiveWatchSummary key={`${gtm}`} {...props} />;
        case 'patchmanager':
            return <PatchManagerResume key={`${gtm}`} {...props}  />;
        default:
            return 
    }
}

const SecCard: React.FC<SecCardProps> = ({ GTMs, QtdGroups }) => {
    const isSecOpsPage = window.location.pathname.toLowerCase().includes('secops');
    const isHomePage = window.location.pathname === '/';

    let result = GTMs.includes('patchmanager')
    ? [...new Set(GTMs.filter(item => item === 'xdr' || item === 'patchmanager'))] as GTMType[]
    : [...new Set(GTMs.filter(item => item === 'xdr' || item === 'livewatch'))] as GTMType[]; 
    if (result.length === 0) {
        result = [...new Set(GTMs.filter(item => item === 'xdr' || item === 'patchmanager' || item === 'livewatch'))];
    } 
    result.sort((a, b) => {
        if (a === 'xdr') return -1;
        if (b === 'xdr') return 1;
        if (a === 'patchmanager') return -1;
        if (b === 'patchmanager') return 1;
        return 0;
    });

    const props = { isHome: isHomePage, QtdGroups: QtdGroups, secOpsQtdGroups: result.length, isSecOpsPage: isSecOpsPage, GTMs: result};
    return (
        <>
            {isSecOpsPage
                ?
                GTMs.map(gtm => componentFactory(gtm, props))
                : <div className='row between' style={{height: 'calc(100vh - 140px)', flexDirection: 'column'}}>
                    {result.map(gtm => componentFactory(gtm, props))}
                </div> 
                    
            } 
        </>
    );
};

export default SecCard;