import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';
import { PrefState } from '../../store/reducers/prefReducer';
import { setFilterActive } from '../../store/reducers/filterReducer';
import AdminRecommendationService from './../../services/adminRecommendationService';
import moment from 'moment';
import LcIconLink from '../../components/Generic/LcIconLink';
import LcInfiniteTable from "../../components/Data/LcInfiniteTable";
import LCDashboard, { Card } from '../../components/Data/Dashboard/LCDashboard';
import IconValue from '../../components/Layout/Dashboard/IconValue';
import FilterPanel from '../../components/Data/FilterPanel';
import LcLoading from '../../components/Generic/LcLoading';
import { PiArrowUpLight, PiFileTextLight, PiPiggyBankLight, PiShield, PiShieldCheck, PiShieldCheckLight, PiThumbsUpLight, PiTrendUpLight } from 'react-icons/pi';
import { CMSRecomendationState } from '../../store/reducers/CmsRecomendationReducer';
import { PPModal } from 'processor-plataform-ui';
import { Tooltip } from '@material-ui/core';

interface RecomendationModel {
    name: string
    subscription: string,
    type: string
    category: string,
    impact: string,
    impactedField: string,
    impactedValue: string,
    shortDescription: shortDescription,
    lastUpdated: string,
    risk: string | null,
}

interface shortDescription {
    problem: string,
    solution: string
}
interface GroupedRecommendationFilter {
    size: number;
    term: string;
    column: string;
    direction: string;
}

const AdminRecommendations: React.FC = (props: any) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const cmsRecomendations = useSelector<RootState, CMSRecomendationState>(state => state.cmsRecomendations);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [categorySelected, setCategorySelected] = useState<RecomendationModel>();
    const [showModal, setShowModal] = useState(false);
    const [filtered, setFiltered] = useState<boolean>(false);
    const [categories, setCategories] = useState<any[]>();
    const [highAvailability, setHighAvailability] = useState<RecomendationModel[]>([])
    const [performance, setPerformance] = useState<RecomendationModel[]>([])
    const [operationalExcellence, setOperationalExcellence] = useState<RecomendationModel[]>([])
    const [cost, setCost] = useState<RecomendationModel[]>([])
    const [security, setSecurity] = useState<RecomendationModel[]>([])
    const [filterVisible, setFilterVisible] = useState(false);
    const [savedFilters, setSavedFilters] = useState<{ [key: string]: any }>({});

    const filterDefault: GroupedRecommendationFilter = {
        size: 20,
        term: "",
        column: "impact",
        direction: "desc",
    };
    const [filterRecommendation, setFilterRecommendation] = useState<{ size: number, term: string, column: string, direction: string }>(filterDefault);

    useEffect(() => {
        async function loadRecommendations() {
            try {
                setIsLoading(true);
                const service = new AdminRecommendationService(props);
                const response = await service.GetRecommendations();
                setCategories(response.categories);

                const categorizedRecommendations = response.itens.reduce((acc, resp) => {
                    const recommendation = {
                        name: resp.name,
                        subscription: resp.subscription,
                        type: resp.type,
                        category: resp.properties.category,
                        impact: resp.properties.impact,
                        impactedField: resp.properties.impactedField,
                        impactedValue: resp.properties.impactedValue,
                        shortDescription: resp.properties.shortDescription,
                        lastUpdated: resp.properties.lastUpdated,
                        risk: resp.properties.risk,
                    };

                    if (acc[recommendation.category]) {
                        acc[recommendation.category].push(recommendation);
                    }

                    return acc;
                }, {
                    'Custo': [],
                    'Alta disponibilidade': [],
                    'Performance': [],
                    'Excelência operacional': [],
                    'Segurança': []
                });

                setHighAvailability(categorizedRecommendations['Alta disponibilidade']);
                setPerformance(categorizedRecommendations['Performance']);
                setOperationalExcellence(categorizedRecommendations['Excelência operacional']);
                setCost(categorizedRecommendations['Custo']);
                setSecurity(categorizedRecommendations['Segurança']);

                dispatch({
                    type: 'CMS_RECOMENDATION_FILTER',
                    payload: {
                        recomendationFilter: 'Custo',
                        rowsList: categorizedRecommendations['Custo'],
                        rowsListFiltered: categorizedRecommendations['Custo']
                    }
                });

            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }

        loadRecommendations();
    }, [dispatch, props, user.ClientGroupSelected, user.refreshFlag]);

    const columnsLevel1 = [
        {
            field: 'name', headerName: 'Nome', width: "25%", renderCell: (row: any) => {
                return <Tooltip title={row.name} arrow>
                    <div className="ellipsis">{row.name}</div>
                </Tooltip>
            }
        },
        {
            field: 'subscription', headerName: 'Assinatura', width: "25%", renderCell: (row) => {
                return <Tooltip title={row.subscription} arrow>
                    <div className="ellipsis">{row.subscription}</div>
                </Tooltip>
            }
        },
        {
            field: 'type', headerName: 'Tipo', width: "20%", renderCell: (row) => {
                return <Tooltip title={row.type} arrow>
                    <div className="ellipsis">{row.type}</div>
                </Tooltip>
            }
        },
        {
            field: 'impact', headerName: 'Impacto', width: "7%", renderCell: (row: any) => {
                return translateImpact(row?.impact)
            }
        },
        {
            field: 'risk', headerName: 'Risco', width: "8%",
            renderCell: (row: any) =>
            (
                <span>
                    {row.risk == null ?
                        <>Sem riscos</>
                        :
                        <>{row.risk}</>
                    }
                </span>
            ),
        },
        {
            field: 'lastUpdated', headerName: 'Última atualização', width: "20%", renderCell: (row: any) => {
                return moment(row?.lastUpdated).format('DD/MM/YYYY hh:mm')
            }
        },
        {
            field: '',
            width: '20px', overflow: "visible", sort: false,
            renderCell: (row: any) => {
                return (
                    <LcIconLink id='ver_detalhes_button' tooltip='Ver detalhes' tooltipPosition='right' icon={<PiFileTextLight />} color="var(--text-default)" onClick={() => { setCategorySelected(row); setShowModal(true) }} />
                )
            }
        }
    ];

    const translateImpact = (impact: string) => {
        const translations: { [key: string]: string } = {
            "High": "Alto",
            "Medium": "Médio",
            "Low": "Baixo",
        };

        return translations[impact] || impact;
    }

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilterRecommendation({ ...filterRecommendation, column: sort.column, direction: sort.direction, size: size })
    };

    useEffect(() => {
        const impactOrderAsc = ['Low', 'Medium', 'High'];
        const impactOrderDesc = [...impactOrderAsc].reverse();

        let filtered = cmsRecomendations.rowsListFiltered.sort((a: any, b: any) => {
            if (filterRecommendation.column === 'impact') {
                const order = filterRecommendation.direction === 'asc' ? impactOrderAsc : impactOrderDesc;
                return order.indexOf(a.impact) - order.indexOf(b.impact);
            } else {
                if (filterRecommendation.direction === 'asc') {
                    return a[filterRecommendation.column] > b[filterRecommendation.column] ? 1 : -1;
                } else {
                    return a[filterRecommendation.column] < b[filterRecommendation.column] ? 1 : -1;
                }
            }
        });
        dispatch({ type: 'CMS_ENVIRONMENT_FILTER_ROWS', payload: filtered })
    }, [cmsRecomendations.rowsListFiltered, dispatch, filterRecommendation]);

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }


    const opcoesCategorias = [
        { value: 'Custo', label: 'Custo', icon: <PiPiggyBankLight size={32} />, color: 'var(--color-secondary-cyan)', list: cost },
        { value: 'Segurança', label: 'Segurança', icon: <PiShieldCheckLight size={32} />, color: 'var(--color-primary-pink)', list: security },
        { value: 'Alta disponibilidade', label: 'Alta disponibilidade', icon: <PiArrowUpLight size={32} />, color: 'var(--color-primary-blue)', list: highAvailability },
        { value: 'Performance', label: 'Performance', icon: <PiTrendUpLight size={32} />, color: 'var(--color-secondary-green-light)', list: performance },
        { value: 'Excelência operacional', label: 'Excelência operacional', icon: <PiThumbsUpLight size={32} />, color: 'var(--color-secondary-purple-bright)', list: operationalExcellence },
    ];

    const periodSelection = {
        periods: [
            {
                label: 'Este mês', text: 'Este mês',
                fields: [
                    { name: 'currentMonth', value: 'currentMonth' }
                ],
                default: true
            }
        ],
        customPeriodType: 'month',
        onChange: () => { }
    };

    const createCard = (opcao, index) => {
        const categoryData = categories?.find(cat => cat.displayDescription === opcao.label) || { count: 0 };
        const cardClass = ((cmsRecomendations.recomendationFilter !== opcao.label)) ? 'card-fade' : 'card-active';
        let colPosition = 1;
        for (let i = 0; i < index; i++) {
            colPosition += 2;
        }

        return {
            type: 'Custom',
            cssClass: cardClass,
            position: {
                row: 1,
                col: colPosition,
                ColSpan: 2,
                RowSpan: 1
            },
            title: () => (
                <div className={'lc-segment-title-row'} >
                    <div className={'lc-title ellipsis'}>
                        {opcao.label}
                    </div>
                </div>
            ),
            customContentRender: () => (
                <LcLoading loading={isLoading}>
                    <IconValue
                        title={opcao.label}
                        balonSelected
                        icon={opcao.icon}
                        value={categoryData.count.toString()}
                        color={opcao.color}
                        onClick={() => {
                            let payload = {
                                recomendationFilter: opcao.label,
                                rowsList: opcao.list,
                                rowsListFiltered: opcao.list
                            };
                            dispatch({ type: 'CMS_RECOMENDATION_FILTER_ROWS', payload: cmsRecomendations.rowsList });
                            dispatch({ type: 'CMS_RECOMENDATION_FILTER', payload: payload });
                            setFiltered(false);
                            setFilterVisible(false);
                            // setFilterRecommendation(filterDefault)
                        }}
                    />
                </LcLoading>
            )
        };
    };
    // Criação dos cards por categoria
    const categoryCards = opcoesCategorias.map((opcao, index) => createCard(opcao, index));

    // Card de Recomendações
    const recommendationsCard: Card = {
        type: 'Custom',
        hideHeader: true,
        position: {
            row: 2, col: 1, ColSpan: 10,
            RowSpan: 3,
        },

        customContentRender: () => {
            return <>
                {
                    cmsRecomendations.rowsListFiltered &&
                    <LcLoading loading={isLoading}>
                        <LcInfiniteTable
                            loading={isLoading}
                            columns={columnsLevel1}
                            rows={cmsRecomendations.rowsListFiltered}
                            disableFilterModal={true}
                            size={cmsRecomendations.rowsListFiltered.length}
                            onSortChange={onSortChange}
                            density={pref.data?.density || "medium"}
                            hidePagination
                        />
                    </LcLoading>
                }
            </>
        }
    };

    const cards = [...categoryCards, recommendationsCard];
    const translations = {
        name: {
            label: "Nome",
            type: "text",
        },
        subscription: {
            label: "Assinatura",
            type: "text",
        },
        impact: {
            label: "Impacto",
            type: "multiSelect",
            values: {
                'High': 'Alto',
                'Medium': 'Médio',
                'Low': 'Baixo'
            }
        },
    }

    const filterSystem = () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    filter={Object.keys(translations).map(key => ({
                        label: translations[key].label,
                        name: key,
                        type: translations[key].type,
                        values: translations[key].values,
                    }))}
                    data={cmsRecomendations.rowsList}
                    onFilteredData={(filteredData) => { setFiltered(true); dispatch({ type: 'CMS_RECOMENDATION_FILTER_ROWS', payload: filteredData }) }}
                    translations={translations}
                    clearFilters={() => { setFiltered(false); dispatch({ type: 'CMS_RECOMENDATION_FILTER_ROWS', payload: cmsRecomendations.rowsList }) }}
                    savedFilters={savedFilters}
                    onSaveFilters={setSavedFilters}
                />
            </div>
        )
    }

    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    return (
        <Layout pageTitle="Recomendações" periodSelection={periodSelection}
            dots={
                <>
                    <div className='item' style={{ color: pref.data?.density === "high" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
                    <div className='item' style={{ color: pref.data?.density === "medium" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
                    <div className='item' style={{ color: pref.data?.density === "low" ? "#abaeb9" : "#000" }} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
                </>
            }
            gridFilter={{
                toggleVisibility: () => {
                    setFilterVisible(!filterVisible)
                },
                size: cmsRecomendations.rowsListFiltered.length,
                color: filtered ? 'var(--color-secondary-lime)' : '',
                // clickble: filterVisible
            }}
        >
            <LCDashboard
                grid={[4, 10]}
                cards={cards}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: filterVisible,
                    content: filterSystem,
                    close: setFilterVisible
                }}
            />

            <PPModal title={`Detalhes`} visible={showModal} onClose={setShowModal}>
                {
                    categorySelected &&
                    <div >
                        <p className='mt-2'><strong>Campo impactado:</strong> {categorySelected.impactedField}</p>
                        <p className='mt-2'><strong>Valor impactado:</strong> {categorySelected.impactedValue}</p>
                        <p className='mt-2'><strong>Impacto:</strong> {translateImpact(categorySelected.impact)}</p>
                        <p className='mt-2'><strong>Risco:</strong> {categorySelected.risk !== null ? categorySelected?.risk : 'Sem Risco'}</p>
                        <p className='mt-2'><strong>Solução:</strong> {categorySelected.shortDescription.solution} </p>
                    </div>
                }
            </PPModal>

        </Layout>
    );
}

export default AdminRecommendations;