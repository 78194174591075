import React, { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { HostingService } from '../../../../services/hostingService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import { GraphData } from '../../../../services/cmsPremier/cmsPremierModel';
import LcLoading from '../../../../components/Generic/LcLoading';
import LcNoData from '../../../../components/Generic/LcNoData';
import { CmsPremierService } from '../../../../services/cmsPremier/cmsPremierService';
import moment from 'moment';

interface Props {
    typePeriod: string
    month: string;
    year: string
}
interface StorageData {
    status: string;
    count: number;
    date: string;
}

interface SeriesData {
    name: string;
    type: string;
    color: string;
    data: { name: string; value: [string, number] }[];
    showSymbol: boolean;
    hoverAnimation: boolean;
}

const screenWidth = window.innerWidth;
let fontSize = screenWidth <= 1400 ? 10 : 12;
const allStatuses = ['COOL', 'HOT', 'Archived', 'STANDARD', 'STANDARDIA', 'OUTROS'];

const StorageGraph: React.FC<Props> = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [loading, setLoading] = useState<boolean>(false);
    const [series, setSeries] = useState< any[]>([]);

    const processSeriesData = (groupedArray) => {
        // Get all unique dates
        let allDates = [];
        groupedArray.forEach(item => {
            allDates = allDates.concat(item.counts.map(c => c.date));
        });
        allDates = [...new Set(allDates)].sort((a, b) => moment(a, 'DD/MM/YYYY').diff(moment(b, 'DD/MM/YYYY')));

        // Ensure each series has data for all dates
        return groupedArray.map(seriesItem => {
            const newData = allDates.map(date => {
                const found = seriesItem.counts.find(c => c.date === date);
                return {
                    name: date,
                    value: [date, found ? found.count : 0],
                };
            });

            return {
                ...seriesItem,
                data: newData,
            };
        });
    };

    useEffect(() => {
        const getStorageInfo = async () => {
            setLoading(true);
            const hostingService = new CmsPremierService({});
            const response = await hostingService.getKPIStorages();
            console.log(response)
            const statusData: { [status: string]: { [date: string]: number } } = {};
            allStatuses.forEach(status => {
                statusData[status] = {};
            });
            if(response.length === 0){
                setSeries([]);
                setLoading(false);
                return;
            }else {
                response.forEach((cur: StorageData) => {
                    const { status, count, date } = cur;
                    const formattedDate = moment(date).format('DD/MM/YYYY');
                    if (allStatuses.includes(status)) {
                        if (!statusData[status][formattedDate]) {
                            statusData[status][formattedDate] = 0;
                        }
                        statusData[status][formattedDate] += count;
                    }
                });
    
                const newSeries  = allStatuses.map(status => {
                    const dates = Object.keys(statusData[status]);
                    const counts = Object.values(statusData[status]);
    
                    const data = dates.map((date, index) => ({
                        name: date,
                        value: [date, counts[index]],
                    }));
    
                    return {
                        name: status,
                        type: 'line',
                        color: getColorForAccessTier(status),
                        data,
                        showSymbol: true,
                        hoverAnimation: true,
                    };
                });
                setSeries(newSeries);
                setLoading(false);

            }

        };
        getStorageInfo();
    }, [user.ClientGroupSelected, user.refreshFlag, props.typePeriod, props.year, props.month, allStatuses])

 
    function formatarDataAbreviada(dataISO) {
        const data = new Date(dataISO);
        const dia = data.getDate();
        const mesAbreviado = new Intl.DateTimeFormat('pt-BR', { month: 'short' }).format(data);
        const ano = data.getFullYear();

        const dataFormatada = `${dia} ${mesAbreviado} ${ano}`;

        return dataFormatada;
    }

    function getColorForAccessTier(accessTier): string {
        const corPorAccessTier = {
            'COOl': '#59bac5',
            'HOT': '#e64646',
            'Archived': '#f3c944',
            'STANDARD': '#e64646',
            'STANDARDIA': '#e64646',
            'OUTROS': '#eee',
        };
        return Object.keys(corPorAccessTier).includes(accessTier) ? corPorAccessTier[accessTier] : undefined;
    }
 
    const option = {

        tooltip: {
            trigger: 'axis',
            backgroundColor: '#f1f5fb',
            borderWidth: 0,
            textStyle: {
                fontSize: 11,
                fontFamily: 'Ubuntu',
                color: '#2B304F',
            },
        },
        legend: {
            inactiveColor: '#ededed',
            inactiveBorderColor: '#ededed',

            type: 'scroll',  // Enable scroll (pagination)
            orient: 'horizontal',
            width: '100%',
            right: 'center',
            bottom: '10px',
            textStyle: {
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                fontSize: fontSize,
            },
            itemGap: 10,
            pageButtonItemGap: 3,
            pageButtonGap: 5,
            pageButtonPosition: 'end',
            pageFormatter: '{current}/{total}',  // You can customize this
            pageIconSize: 10,
            pageIconColor: '#273b85',
            pageIcons: {
                horizontal: [
                    'path://M5 6l4-4 1.4 1.4L7.8 6l2.6 2.6L9 10z',  // Chevron para a direita
                    'path://M9 6l-4 4-1.4-1.4L6.2 6 3.6 3.4 5 2z' // Chevron para a esquerda                        
                ]
            },
            pageTextStyle: {
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                fontSize: fontSize,
            },            
            icon: 'circle',
            data: series.map(legend => legend.name),                       
        },
        grid: {
            top: '5%',
            left: '1%',
            right: '30px',
            bottom: '45px',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: series.length > 0 ? series[0].data.map(v => v.name) : [],
            axisLabel: {
                fontSize: fontSize,
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                fontSize: fontSize ,
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                formatter: '{value}' // Adiciona 'GB' às labels do eixo Y
            },

            
        },
        series: series
    };



    return (
        <LcLoading loading={loading}>
            {
                series.length > 0 ?
                    <ReactECharts
                        style={{ width: '100%', height: '100%' }}
                        opts={{ renderer: 'svg', locale: 'PT-br' }}
                        className='StorageChart'
                        option={option}
                    />
                    :
                    <LcNoData size='small' />
            }
        </LcLoading>
    );
}

export default StorageGraph;