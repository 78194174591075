import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import { CostCenterService, FilterDashboard, FinOpsPivotDataResult } from '../../../services/costCenter/costCenterService';
import LcLoading from '../../../components/Generic/LcLoading';
//Redux
import { useSelector } from 'react-redux';
import { UserState, WebPresence } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import { useParams } from 'react-router-dom';
import styles from './index.module.css';

//SadDogs
import SadDog1 from '../../../assets/images/saddog.svg';
import SadDog2 from '../../../assets/images/saddog2.svg';
import SadDog3 from '../../../assets/images/saddog3.svg';
import PivotTableWebix from '../../../components/webix';
import Moment from 'moment';
import { useQuery } from 'react-query';
import LcNoData from '../../../components/Generic/LcNoData';

interface Param {
    type: string;
}
function convertDateString(dateString) {
    try {
        const [year, month, day] = dateString.split('T')[0].split('-').map(Number);
        return new Date(year, month - 1, day);
    } catch (e) {
        return null;
    }

}
function chooseSadDog(min, max) {
    switch (parseInt(Math.random() * (max - min) + min)) {
        case 1:
            return SadDog1;
            break
        case 2:
            return SadDog2;
            break;
        case 3:
            return SadDog3;
            break;
        default:
            return SadDog1;
    }
}
var dateR = new Date();


dateR.setDate(dateR.getDate() - 1);
var currentMonthL = (dateR.getMonth() + 1).toString().padStart(2, '0');
var currentYearL = dateR.getFullYear().toString();
const CentroDeCustoPivot: React.FC = (props) => {
    const costCenterService = new CostCenterService(props);
    const { type } = useParams<Param>();
    const [gtmId, setGtmId] = useState<string>(type);
    const webPresenceData = useSelector<RootState, WebPresence>(state => state.webPresenceReducer);
    const local = document.location.pathname;
    const user = useSelector<RootState, UserState>(state => state.user);
    const userPeriodSelected = user.PeriodSelection ? user.PeriodSelection[local] : undefined;
    const [canSee, setCanSee] = useState<boolean>(true);
    const [changePeriod, setchangePeriod] = useState<boolean>(false);
    const [fields, setFields] = useState<any[]>([{ id: 'Srv', value: "Serviço", type: "text" }]);
    const [pivotDataV, sePivotDataV] = useState<any[]>([]);
    const [datePack, setDatePack] = useState<{ month: string, year: string, clientGroupId: number | undefined }>({
        month: userPeriodSelected ? userPeriodSelected.fields[0].value.split('-')[1] : currentMonthL,
        year: userPeriodSelected ? userPeriodSelected.fields[0].value.split('-')[0] : currentYearL,
        clientGroupId: user.ClientGroupSelected
    });
    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setDatePack({ month: date.split('-')[1], year: date.split('-')[0], clientGroupId: user.ClientGroupSelected });
    };

    const periodSelectionDefault = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ],
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };
    const [periodSelection, setPeriodSelection] = useState<any>(periodSelectionDefault)

    const { data: pivotData, isLoading: loadingpivotData, refetch: refetchReportKPI, isRefetching: isRefetchingKPIs }
        = useQuery<FinOpsPivotDataResult[]>(['pivotData', datePack], async (datePack) => {
            return await loadData(datePack);
        }, {
            keepPreviousData: false,
            enabled: true,
            staleTime: 0,
            refetchOnWindowFocus: false,
            refetchOnMount: true,
            onSuccess: () => {
                console.log('onSuccess');

            },
            optimisticResults: false
        });

    useEffect(() => {
        loadData({ ...datePack });
    }, []);


    // useEffect(() => {
    //     setLoading(false);
    // }, [pivotData]);

    async function loadData(refresh): Promise<FinOpsPivotDataResult[]> {


        var datePackLocal: { month: string, year: string, clientGroupId: number | undefined } = {} as unknown as { month: string, year: string, clientGroupId: number | undefined };
        if (!datePack) {
            var dateR = new Date();
            dateR.setDate(dateR.getDate() - 1);
            var currentMonth = (dateR.getMonth() + 1).toString().padStart(2, '0');
            var currentYear = dateR.getFullYear().toString();
            setDatePack({ month: currentMonth, year: currentYear, clientGroupId: user.ClientGroupSelected });
            datePackLocal = { month: currentMonth, year: currentYear, clientGroupId: user.ClientGroupSelected };
        } else {
            datePackLocal = datePack;
        }

        try {
            if (!webPresenceData.costCenterId) {
                webPresenceData.costCenterId = 0;
            }

            var filter = {
                clientGroupId: Number(user.ClientGroupSelected ? user.ClientGroupSelected : '0'),
                user: user.UserId,
                accountId: "",
                costCenterID: 0,
                contractID: 0,
                componentName: "",
                productName: "",
                serviceCode: "",
                serviceName: "",
                provedor: "",
                ano: Number(datePackLocal.year),
                mes: Number(datePackLocal.month),
                dia: 0,
                gtmId: Number(gtmId),
                reportType: "1",
                displayType: "1",
                showChildrenCostCenters: 0,
                periodo: 1
            } as FilterDashboard;
            var responseAData = (await costCenterService.GetDataDetails(filter)) as FinOpsPivotDataResult[];
            var fieldBase = [{ id: 'Srv', value: "Serviço", type: "text" }];
            fieldBase.push({ id: 'Prv', value: "Provedor", type: "text" });
            fieldBase.push({ id: 'Ano', value: "Ano", type: "number" });
            fieldBase.push({ id: 'Mes', value: "Mês", type: "number" });
            fieldBase.push({ id: 'Dia', value: "Dia", type: "number" });
            fieldBase.push({ id: 'Cdc', value: "Centro de Custo", type: "text" });
            fieldBase.push({ id: 'Ctr', value: "Contrato", type: "text" });
            fieldBase.push({ id: 'Rsg', value: "Grupo", type: "text" });
            fieldBase.push({ id: 'Rec', value: "Recurso", type: "text" });
            if (responseAData[0].Smg == true) {
                fieldBase.push({ id: 'Vbr', value: "Valor", type: "number" });
                fieldBase.push({ id: 'Tag', value: "Tags", type: "text" });
                fieldBase.push({ id: 'Cat', value: "Categoria", type: "text" });
                fieldBase.push({ id: 'Sub', value: "SubCategoria", type: "text" });
                fieldBase.push({ id: 'Met', value: "Metrica", type: "text" });
                fieldBase.push({ id: 'Uni', value: "Unidade", type: "text" });
                fieldBase.push({ id: 'Bdg', value: "Orçamento", type: "number" });
                fieldBase.push({ id: 'Acc', value: "AccountId", type: "text" });
                fieldBase.push({ id: 'Ccr', value: "Regra CdeC", type: "text" });
                fieldBase.push({ id: 'Csq', value: "Qtd", type: "number" });
                fieldBase.push({ id: 'Cst', value: "Custo", type: "number" });
                fieldBase.push({ id: 'Mrg', value: "Margem", type: "number" });
                fieldBase.push({ id: 'Tax', value: "Taxa", type: "number" });
                fieldBase.push({ id: 'Dat', value: "Date", type: "text" });
            } else if (responseAData[0].Spt == true) {
                fieldBase.push({ id: 'Pct', value: "Porcento", type: "number" });
            } else {
                fieldBase.push({ id: 'Vbr', value: "Valor", type: "number" });
                fieldBase.push({ id: 'Bdg', value: "Orçamento", type: "number" });
                fieldBase.push({ id: 'Tag', value: "Tags", type: "text" });
                fieldBase.push({ id: 'Cat', value: "Categoria", type: "text" });
                fieldBase.push({ id: 'Sub', value: "SubCategoria", type: "text" });
                fieldBase.push({ id: 'Met', value: "Metrica", type: "text" });
                fieldBase.push({ id: 'Uni', value: "Unidade", type: "text" });
                fieldBase.push({ id: 'Acc', value: "AccountId", type: "text" });
                fieldBase.push({ id: 'Ccr', value: "Regra CdeC", type: "text" });
                fieldBase.push({ id: 'Dat', value: "Date", type: "text" });
                fieldBase.push({ id: 'Csq', value: "Qtd", type: "number" });
            }
            setFields([...fieldBase]);
            setCanSee(false);
            sePivotDataV(responseAData.filter(fil => fil.Mes !== undefined))
            return responseAData;
        } catch (error) {
            var filter = {
                clientGroupId: Number(user.ClientGroupSelected ? user.ClientGroupSelected : '0'),
                user: user.UserId,
                accountId: "",
                costCenterID: 0,
                contractID: 0,
                componentName: "",
                productName: "",
                serviceCode: "",
                serviceName: "",
                provedor: "",
                ano: Number(datePackLocal.year),
                mes: Number(datePackLocal.month) - 1,
                dia: 0,
                gtmId: Number(gtmId),
                reportType: "1",
                displayType: "1",
                showChildrenCostCenters: 0,
                periodo: 1
            } as FilterDashboard;

            var responseAData = (await costCenterService.GetDataDetails(filter)) as FinOpsPivotDataResult[]
            if (responseAData && responseAData.length > 0) {
                var fieldBase = [{ id: 'Srv', value: "Serviço", type: "text" }];
                fieldBase.push({ id: 'Prv', value: "Provedor", type: "text" });
                fieldBase.push({ id: 'Ano', value: "Ano", type: "number" });
                fieldBase.push({ id: 'Mes', value: "Mês", type: "number" });
                fieldBase.push({ id: 'Dia', value: "Dia", type: "number" });
                fieldBase.push({ id: 'Cdc', value: "Centro de Custo", type: "text" });
                fieldBase.push({ id: 'Ctr', value: "Contrato", type: "text" });
                fieldBase.push({ id: 'Rsg', value: "Grupo", type: "text" });
                fieldBase.push({ id: 'Rec', value: "Recurso", type: "text" });
                setPeriodSelection(periodWithoutPresentMonth)
                setchangePeriod(!changePeriod);

                if (responseAData[0].Smg == true) {
                    fieldBase.push({ id: 'Vbr', value: "Valor", type: "number" });
                    fieldBase.push({ id: 'Tag', value: "Tags", type: "text" });
                    fieldBase.push({ id: 'Cat', value: "Categoria", type: "text" });
                    fieldBase.push({ id: 'Sub', value: "SubCategoria", type: "text" });
                    fieldBase.push({ id: 'Met', value: "Metrica", type: "text" });
                    fieldBase.push({ id: 'Uni', value: "Unidade", type: "text" });
                    fieldBase.push({ id: 'Bdg', value: "Orçamento", type: "number" });
                    fieldBase.push({ id: 'Acc', value: "AccountId", type: "text" });
                    fieldBase.push({ id: 'Ccr', value: "Regra CdeC", type: "text" });
                    fieldBase.push({ id: 'Csq', value: "Qtd", type: "number" });
                    fieldBase.push({ id: 'Cst', value: "Custo", type: "number" });
                    fieldBase.push({ id: 'Mrg', value: "Margem", type: "number" });
                    fieldBase.push({ id: 'Tax', value: "Taxa", type: "number" });
                    fieldBase.push({ id: 'Dat', value: "Date", type: "text" });
                } else if (responseAData[0].Spt == true) {
                    fieldBase.push({ id: 'Pct', value: "Porcento", type: "number" });
                } else {
                    fieldBase.push({ id: 'Vbr', value: "Valor", type: "number" });
                    fieldBase.push({ id: 'Bdg', value: "Orçamento", type: "number" });
                    fieldBase.push({ id: 'Tag', value: "Tags", type: "text" });
                    fieldBase.push({ id: 'Cat', value: "Categoria", type: "text" });
                    fieldBase.push({ id: 'Sub', value: "SubCategoria", type: "text" });
                    fieldBase.push({ id: 'Met', value: "Metrica", type: "text" });
                    fieldBase.push({ id: 'Uni', value: "Unidade", type: "text" });
                    fieldBase.push({ id: 'Acc', value: "AccountId", type: "text" });
                    fieldBase.push({ id: 'Ccr', value: "Regra CdeC", type: "text" });
                    fieldBase.push({ id: 'Dat', value: "Date", type: "text" });
                    fieldBase.push({ id: 'Csq', value: "Qtd", type: "number" });
                }
                setFields([...fieldBase]);
                setCanSee(false);
                sePivotDataV(responseAData.filter(fil => fil.Mes !== undefined))

            } else {
                setPeriodSelection(periodSelectionDefault);
                setchangePeriod(!changePeriod)
            }
            return responseAData;
        }
    }

    const periodWithoutPresentMonth = {
        periods: [
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };
    console.log(loadingpivotData, isRefetchingKPIs)
    return (
        <Layout periodSelection={periodSelection} changePeriod={changePeriod} pageTitle={'Consumo'} pivot >
            <LcLoading loading={loadingpivotData || isRefetchingKPIs} label='Carregando informações'>
                {fields && fields.length > 1 && pivotDataV && pivotDataV.length > 0
                    ? <PivotTableWebix
                        key={'1'} data={pivotDataV}
                        fields={fields}
                        structure={{
                            rows: ["Prv", "Rsg"],
                            columns: ["Mes"],
                            values: [{ name: "Pct", operation: ["sum"] }],
                            filters: [{ name: "Srv" }, { name: "Rsg" }, { name: "Cdc" }, { name: "Ctr" }, { name: "Srv" }, { name: "Mes" }, { name: "Dia" }]
                        }}
                    /> :
                    <LcNoData label={`Não conseguimos localizar informações sobre o consumo.`}>
                        {/* <p className={styles.errorMessage}>
                            Não conseguimos localizar informações sobre o consumo.
                            <br />
                            Por gentileza, contate o administrador do sistema para validar se o seu usuário já é membro de algum centro de custo
                            <br /> ou se a sua organização já possui dados sobre consumo nesta modalidade contratada.
                        </p> */}
                    </LcNoData>
                }
            </LcLoading>
        </Layout>
    );
};

export default CentroDeCustoPivot;