import React, { useEffect, useMemo, useState } from 'react';
import { PiHardDrives } from 'react-icons/pi';
import LcLoading from '../../../Generic/LcLoading';
import IconValue from '../../Dashboard/IconValue';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { CMSEnvironmenetState } from '../../../../store/reducers/CmsEnvironmentReducer';
import { UserState } from '../../../../store/reducers/userReducer';
import { CmsPremierService } from '../../../../services/cmsPremier/cmsPremierService';
import { Tooltip } from '@material-ui/core';
import { FaAws } from 'react-icons/fa';
import { SiMicrosoftazure } from 'react-icons/si';
import { TableColumn } from '../../../Data/LcInfiniteTable';
import { useQuery } from 'react-query';

interface StorageInfoCardProps {
    setLoading?: (loading: boolean) => void
    withoutBallon?: boolean
}

const columnsStorage: TableColumn[] = [
    {
        field: 'name', headerName: 'Nome', width: "30%", renderCell: (row) => {
            return (
                <div style={{ display: 'flex', alignItems: 'center', minWidth: '0' }}>
                    <Tooltip arrow title={row.provider !== 'aws' ? 'Microsoft Azure' : 'Amazon AWS'}>
                        <span style={{ minWidth: '20px', paddingLeft: '4px', display: 'flex', alignItems: 'center' }}>
                            {row.provider !== 'aws' ? <SiMicrosoftazure size={'14px'} color='#141B4D' /> : <FaAws size={'16px'} color='#141B4D' />}
                        </span>
                    </Tooltip>
                    <span
                        className='overflowEllipsis ml-4'
                    >
                        {row.name || <i className="lci lci-divider-horizontal" />}
                    </span>
                </div>
            )
        }
    },
    {
        field: 'assinatura', headerName: 'Assinatura', width: "30%", renderCell: (row) => {
            return (
                <Tooltip arrow title={row.assinatura}>
                    <span
                        className='overflowEllipsis mr-4'
                    >
                        {row.assinatura || <i className="lci lci-divider-horizontal" />}
                    </span>
                </Tooltip>
            )
        }
    },
    {
        field: 'location', headerName: 'Região', width: "15%",
    },
    {
        field: 'type', headerName: 'Tipo', width: "13%", renderCell: (row) => {
            return (
                <Tooltip arrow title={row.type}>
                    <span
                        className='overflowEllipsis'
                    >
                        {row.type || <i className="lci lci-divider-horizontal" />}
                    </span>
                </Tooltip>
            )
        }
    },
    {
        field: 'size', headerName: 'Capacidade(GB)', width: "10%", align: 'right', renderCell: (row: any) => {
            return row.size > 0 ? <p className='mr-4'>{(row.size / (1024 ** 3)).toFixed(2)}</p> : 0
        }
    },
]
const translationsFilter = {
    name: {
        label: "Nome",
        type: "text",
    },
    assinatura: {
        label: "Assinatura",
        type: "multiSelect",
    },
    location: {
        label: "Região",
        type: "multiSelect",
    },
    type: {
        label: "Tipo",
        type: "multiSelect",
        topDropdown: true
    },
    // status: {
    //     label: "Status",
    //     type: "multiSelect",
    //     topDropdown: true
    // },
};

export const StorageInfoCard: React.FC<StorageInfoCardProps> = (props) => {

    const cmsEnvironment = useSelector<RootState, CMSEnvironmenetState>(state => state.cmsEnvironment);
    const dispatch = useDispatch();
    const user = useSelector<RootState, UserState>(state => state.user);
    const [storageCard, setStorageCard] = useState<number>(0);

    const { data: storageData, isLoading, isRefetching } = useQuery({
        queryKey: ['storages', user.ClientGroupSelected, user.refreshFlag],
        queryFn: async () => {
            const CmsService = new CmsPremierService({});
            let data = await CmsService.getStoragesFull();
            const arrayRowVms: any[] = [
                ...data.azure.map((azure: any) => ({
                    name: azure.name,
                    assinatura: azure.id.split('/')[2],
                    type: azure.kind,
                    location: azure.location,
                    size: azure.properties.usedCapacity,
                    provider: 'azure'
                })),
                ...data.aws.map((aws: any) => ({
                    name: aws.bucketName,
                    assinatura: aws.accountId,
                    location: aws.region,
                    type: aws.storageClass,
                    size: aws.size,
                    provider: 'aws'
                }))
            ].sort((a, b) => b.name.localeCompare(a.name));
            setStorageCard(arrayRowVms.length);
            return arrayRowVms
        },
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5, // 5 minutos
        cacheTime: 1000 * 60 * 10, // 10 minutos
        retry: 3,
        onSuccess: (data) => {
            setStorageCard(data.length);
        },
        onError: (error) => {
            console.error("Error:", error);
        },
    });

    useEffect(() => {
        if (cmsEnvironment.enviromentFilter === 'Storage' && !(isLoading || isRefetching)) {
            handleClickEnviroment();
        }
    }, [cmsEnvironment.enviromentFilter, storageData, isLoading, isRefetching])

    const handleClickEnviroment = () => {
        let payload = {
            enviromentFilter: 'Storage',
            columns: columnsStorage,
            rowsList: storageData,
            rowsListFiltered: storageData,
            funcitonGeneric: undefined,
            translationFilter: translationsFilter
        }
        dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: payload })
        props.setLoading && props.setLoading(false)
    }

    return (
        <LcLoading loading={isLoading || isRefetching}>
            <IconValue
                icon={<PiHardDrives size={32} />}
                value={`${storageCard ? storageCard : 0}`}
                color="#A0A4FF"
                onClick={props.withoutBallon ? undefined : handleClickEnviroment}
                description={'Storages'}
                balonSelected={(cmsEnvironment.enviromentFilter === 'Storage' && !props.withoutBallon)}
            />
        </LcLoading>
    )
}
