import React, { useCallback, useEffect, useState } from 'react';
import './index.css'
import Layout from '../../../components/Layout/Layout';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import LcLoading from '../../../components/Generic/LcLoading';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import { HostItemRequest, HostPivot, HostPivotItem, LiveWatchService } from '../../../services/LiveWatchServiceGroupService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';
import { PiChartBarLight, PiMinusCircleLight } from 'react-icons/pi';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { PPModal } from 'processor-plataform-ui';
import { Tooltip } from '@material-ui/core';
import LiveWatchPerformanceDetailsChart, { DetailsChartDataset } from './DetailsChart';

interface Props {
	isHome: boolean;
	isSecOpsPage?: boolean
	qtdGroups?: number;
	secOpsQtdGroups?: number;
}

interface FilterData {
	size: number;
	host: string;
	"item.name": string,
	direction: string;
}

const LiveWatchResourcePerformance: React.FC<Props> = (props) => {

	const { isHome, qtdGroups, secOpsQtdGroups, isSecOpsPage } = props;

	const service = new LiveWatchService({});
	const user = useSelector<RootState, UserState>(state => state.user);

	const defaultFilterValue = { size: 20, host: "", "item.name": "", direction: "asc" };

	// #region Performance dos recursos - dados
	const [loadingHostsPivot, setLoadingHostsPivot] = useState<boolean>(false);
	const [hostsPivot, setHostsPivot] = useState<HostPivot[]>([]);
	const [hostPivot, setHostPivot] = useState<HostPivot>();
	const [filteredHostsPivot, setFilteredHostsPivot] = useState<HostPivot[]>([]);

	const loadHostsPivot = () => {
		setLoadingHostsPivot(true);
		service.GetHostsPivot()
			.then((response) => {
				setHostsPivot(response);
				setFilteredHostsPivot(response);
			})
			.catch(console.error)
			.finally(() => setLoadingHostsPivot(false));
	}
	useEffect(loadHostsPivot, [user.ClientGroupSelected, user.refreshFlag]);
	// #endregion

	// #region Gráfico do item - dados
	const [hostItems, setHostItems] = useState<HostPivotItem[]>([]);
	const [mappedHostItems, setMappedHostItems] = useState<DetailsChartDataset[]>([]);
	const [hostItemRequest, setHostItemRequest] = useState<HostItemRequest>();
	const [loadingHostItem, setLoadingHostItem] = useState<boolean>(false);
	const [showHostItem, setShowHostItem] = useState<boolean>(false);

	const loadHostItem = () => {
		if (hostItemRequest) {
			setLoadingHostItem(true);
			service.GetHostsItem(hostItemRequest)
				.then((result) => {
					const items = result.map(item =>
					({
						value: parseFloat(item.value),
						unit: hostPivot ? hostPivot['item.units'] : '',
						time: item.clock
					})
					);
					setHostItems(result)
					setMappedHostItems(items);
				})
				.catch(console.error)
				.finally(() => setLoadingHostItem(false));
		}
	}
	useEffect(loadHostItem, [hostItemRequest]);

	const openChartAction = (row: HostPivot) => {
		setHostItemRequest({
			item_id: Number(row['item.id']),
			value_type: Number(row['item.type'])
		});
		setHostPivot(row);
		setShowHostItem(true);
	}

	const closeChartAction = () => {
		setShowHostItem(false);
		setHostItemRequest(undefined);
	}
	// #endregion

	// #region Filtro
	const [filterData, setFilterData] = useState<FilterData>(defaultFilterValue);
	const [showFilter, setShowFilter] = useState<boolean>(false);

	const filterStructure = {
		fields: [
			{ label: 'Recurso', name: 'host', type: 'select', options: [...new Set(hostsPivot.map(i => i.host))].map(i => ({ label: i, value: i })) },
			{ label: 'Sensor', name: 'item.name', type: 'select', options: [...new Set(hostsPivot.map(i => i['item.name']))].map(i => ({ label: i, value: i })) },
		],
		onChange: (_filter: any, size: number) => setFilterData({
			...filterData, size: size,
			host: _filter[0].value.toLowerCase(),
			"item.name": _filter[1].value.toLowerCase()
		}),
		visible: showFilter,
		onChangeVisibility: () => setShowFilter(!showFilter)
	}
	const filterSystem = () => {
		return (
			<FilterLcInfiniteTable
				filter={filterStructure.fields}
				onFilterChange={(updatedFilters) => {
					const newFilter = { ...filterData };
					updatedFilters.forEach(f => newFilter[f.name] = f.value);
					setFilterData(newFilter);
				}}
			/>
		);
	};
	const applyFilter = useCallback(() => {
		const filtered = hostsPivot.filter(item => {
			const matchesHost = !filterData.host || item.host.toLowerCase().includes(filterData.host.toLowerCase());
			const matchesItemName = !filterData["item.name"] || item["item.name"].toLowerCase().includes(filterData["item.name"].toLowerCase());
			return matchesHost && matchesItemName;
		});

		setFilteredHostsPivot(filtered);
	}, [hostsPivot, filterData]);

	useEffect(() => {
		applyFilter();
	}, [applyFilter]);

	const dashboardFilterPanel = {
		title: 'Filtros',
		pinned: false,
		show: showFilter,
		content: filterSystem
	}
	const layoutFilterFunction = {
		toggleVisibility: () => {
			setShowFilter(!showFilter)
		},
		size: filteredHostsPivot.length
	}
	// #endregion

	const dashboardCards = [
		{// 1. Performance dos recursos
			id: 1,
			type: 'Custom',
			position: { ColSpan: 12, RowSpan: 1, row: 1, col: 1 },
			title: () => 'Performance dos recursos',
			customContentRender: () => {
				const renderHostnameCell = (row: HostPivot) => <span className='overflowEllipsis'>{row.host}</span>
				const renderHostgroupsCell = (row: HostPivot) => row.hostgroup ? row.hostgroup : '---';
				const renderItemNameCell = (row: HostPivot) => <span className='overflowEllipsis'>{row['item.name']}</span>
				const renderItemLastValueCell = (row: HostPivot) => {
					// Formata o valor com 2 casas decimais
					const value = {
						'': isNaN(Number(row['item.lastvalue'])) ? row['item.lastvalue'] : Number(row['item.lastvalue']).toFixed(2), // Sem unidade
						'bytes': `${Number(row['item.lastvalue']).toFixed(2)} B`,
						'bytes per second': `${Number(row['item.lastvalue']).toFixed(2)} bps`,
						'percentage': `${Number(row['item.lastvalue']).toFixed(2)} %`,
					}[row['item.units']];

					return <span className='overflowEllipsis'>{value ? value : '---'}</span>;
				};
				const renderChartCell = (row: HostPivot) => {
					return Number(row['item.type']) === 0 ? (
						<Tooltip arrow title='Ver gráfico' placement='right'>
							<div>
								<LcIconLink
									icon={<PiChartBarLight color='var(--text-link)' size={24} />}
									onClick={() => openChartAction(row)}
								/>
							</div>
						</Tooltip>
					) : (
						<Tooltip arrow title='Não suportado' placement='right'>
							<div>
								<LcIconLink icon={<PiMinusCircleLight color='var(--text-light)' size={24} />} />
							</div>
						</Tooltip>
					)
				}
				const tableFields = [
					{ field: 'host', headerName: 'Recurso', width: 300, renderCell: renderHostnameCell },
					// { field: 'hostgroup', headerName: 'Grupo', width: 700, renderCell: renderHostgroupsCell },
					{ field: 'item.name', headerName: 'Sensor', width: 600, renderCell: renderItemNameCell },
					{ field: 'item.lastvalue', headerName: 'Última medição', width: 400, renderCell: renderItemLastValueCell },
					{ field: 'item.type', headerName: 'Gráfico', width: 200, renderCell: renderChartCell }
				];
				return (
					<LcLoading loading={loadingHostsPivot} >
						<LcInfiniteTable
							loading={loadingHostsPivot}
							columns={tableFields}
							rows={filteredHostsPivot}
							size={filteredHostsPivot.length}
							hidePagination
							height='calc(100vh - 150px)'
						/>
					</LcLoading>
				)
			}
		}
	];

	return (
		<Layout pageTitle="Performance dos recursos" gridFilter={layoutFilterFunction}>
			<PPModal visible={showHostItem} onClose={closeChartAction} title={hostPivot ? hostPivot.host : ''} size='90vw'>
				<LiveWatchPerformanceDetailsChart loading={loadingHostItem} dataset={mappedHostItems} hostname={hostPivot ? hostPivot['item.name'] : ''} />
			</PPModal>
			<LCDashboard
				cards={dashboardCards}
				rightSidePanel={dashboardFilterPanel}
			/>
		</Layout>
	);
};

export default LiveWatchResourcePerformance;
