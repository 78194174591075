import React, { useEffect, useState } from 'react';
import Layout from '../../components/Layout/Layout';
//Redux
import { useSelector } from 'react-redux'
import { RootState } from '../../store';
import { UserState } from '../../store/reducers/userReducer';
import { MenuState } from '../../store/reducers/menuReducer';
import ManageOfficeStatusService from '../../services/manageOfficeStatus/manageOfficeStatus'
import { TechEAEASService } from '../../services/EASubscriptionService/techEAEASService';
//Img Fluxo
import { SaasService } from '../../services/saas/saasService';
import LCDashboard, { Card } from '../../components/Data/Dashboard/LCDashboard';
import FilterPanel from '../../components/Data/FilterPanel';
import { FilterField } from '../../components/Data/FilterPanel/FilterField';
import { HealthCheckTable } from '../../components/Layout/Cards/HealthCheckTable';
import LcNoData from '../../components/Generic/LcNoData';

export const statusServices = [
    {
        statusNuvem: 'Investigating',
        statusLC: 'warning',
    },

    {
        statusNuvem: 'ServiceDegradation',
        statusLC: 'warning',
    },

    {
        statusNuvem: 'ServiceInterruption',
        statusLC: 'danger',
    },

    {
        statusNuvem: 'RestoringService',
        statusLC: 'warning',
    },

    {
        statusNuvem: 'ExtendedRecovery',
        statusLC: 'warning',
    },

    {
        statusNuvem: 'InvestigationSuspended',
        statusLC: 'warning',
    },

    {
        statusNuvem: 'ServicesRestored',
        statusLC: 'success',
    },

    {
        statusNuvem: 'FalsePositive',
        statusLC: 'success',
    },

    {
        statusNuvem: 'PostIncidentReportPublished',
        statusLC: 'success',
    },

    {
        statusNuvem: 'ServiceOperational',
        statusLC: 'success',
    },

]

let isDev = process.env.NODE_ENV === 'development';
const ManageOfficeStatus: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const menu = useSelector<RootState, MenuState>(state => state.menu);
    const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
    const [records, setRecords] = useState<any[]>([]);
    const [filteredRecords, setFilteredRecords] = useState<any[]>([]);
    const [ filtered, setFiltered] = useState<boolean>(false);
    const [fullDataRowCount, setFullDataRowCount] = useState<number>(0);
    const [showFluxoEAEAS, setShowFluxoEAEAS] = useState(false);
    const [showFluxoCSP, setShowFluxoCSP] = useState<boolean>(false);
    const [savedFilters, setSavedFilters] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        async function executeUseEffect() {
            if (user.ClientGroupSelected) {
                const service = new ManageOfficeStatusService({})
                service.GetServices().then(r => {
                    let data = r.data;
                    let mappedData = data.map((e) => {
                        const date = new Date(e.statusTime);
                        const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;

                        return {
                            id: e.id,
                            workloadDisplayName: e.workloadDisplayName,
                            workload: e.workload,
                            credentialAlias: e.credentialAlias,
                            statusTime: formattedDate,
                            status: statusServices.find(x => x.statusNuvem === e.status)?.statusLC || 'info'
                        };
                    });

                    setRecords(mappedData);
                    setFullDataRowCount(mappedData.length);
                }).catch(error => {
                    console.warn(error);
                })
            }
        }
        executeUseEffect();
    }, [user.ClientGroupSelected, user.refreshFlag]);


    useEffect(() => {
        let instance = new TechEAEASService({});
        (!isDev && menu && menu.selectedItem && menu.selectedItem.id === 'cedcb443-a23e-4545-904b-5d1061e718aa') &&
        instance.GetSaasLicenseByCountryTotalView()
                .then(response => {
                    setShowFluxoEAEAS(response.data.length === 0)
                })
                .catch(error => {
                    setShowFluxoEAEAS(true);
                });
    }, [menu.selectedItem])
    //#endregion

    useEffect(() => {
        const saasService = new SaasService({});
        if (!isDev && menu && menu.selectedItem && menu.selectedItem.id === '67aa56e4-ad63-44b0-aa27-d2d2a8665a63') {
            saasService.GetbyClientGroupIdAndTileId('13')
                .then(response => {
                    let asGTMCSP = response.data;
                    setShowFluxoCSP(!asGTMCSP);
                })
                .catch(error => {
                    console.warn(error);
                });
        }
    }, [menu.selectedItem])
    //#endregion

    const card: Card[] = [
        {
            id: 1,
            hideHeader: true,
            type: 'Custom',
            centeredContent: false,
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <>
                        {
                            (showFluxoEAEAS || showFluxoCSP) ?
                                <LcNoData size="default" label='Não há dados para serem exibidos no momento.' />
                                :
                                <HealthCheckTable filteredRows={filteredRecords} />
                        }
                    </>
                )
            }

        }
    ]

    const translationsMissing = {
        workloadDisplayName: {
            label: "Serviço",
            type: "text"
        },
        status: {
            label: "Status do serviço",
            type: "multiSelect",
            values: {
                "warning": 'Aviso',
                "danger": 'Perigo',
                "success": 'No ar',
                "info": 'Informação',
            }
        },
    };
    const filterSystem = () => {
        return (
            <div className="right-sidepanel-filters">
                <FilterPanel
                    filter={Object.keys(translationsMissing).map(key => ({
                        label: translationsMissing[key].label,
                        name: key,
                        type: translationsMissing[key].type as FilterField['type'],
                        values: translationsMissing[key].values,
                    }))}
                    data={records}
                    onFilteredData={(filteredData) => { setFullDataRowCount(filteredData.length); setFiltered(true); setFilteredRecords(filteredData as any[]) }}
                    translations={translationsMissing}
                    clearFilters={() => { setFullDataRowCount(records.length); setFilteredRecords(records); setFiltered(false) }}
                    savedFilters={savedFilters}
                    onSaveFilters={setSavedFilters}
                />
            </div>
        )
    }
    const gridFilter = {
        toggleVisibility: () => {
            setShowFilterPanel(!showFilterPanel)
        },
        size: fullDataRowCount,
        color: filtered ? 'var(--color-secondary-lime)' : '',
    }
    return (
        <Layout pageTitle="Disponibilidade dos serviços"
            gridFilter={gridFilter}>
            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: false,
                    show: showFilterPanel,
                    content: () => filterSystem()
                }}
            />
        </Layout>
    )
}
export default ManageOfficeStatus;