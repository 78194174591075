import { LinearProgress } from '@material-ui/core';
import { PPModal } from 'processor-plataform-ui';
import React, { useState } from 'react';
import { PiUserPlusLight } from 'react-icons/pi';
import Confirmation from '../../components/Dialog/Confirmation';
import LcCheckList from '../../components/Form/LcCheckList';
import LcIconLink from '../../components/Generic/LcIconLink';
import { CostCenterNode, CostCenterUser, CostCenterUserSelected } from '.';
import { useSnackbar } from 'notistack';
import { CostCenterService } from '../../services/costCenter/costCenterService';

interface LinkedUsersModalProps {
    sideModalVisibleUsuario: boolean;
    setSideModalVisibleUsuario: (open: boolean) => void;
    linkedCostCenterUser: any[];
    costCenterChildrens: CostCenterNode[];
    costCenterAspNetUser: any[];
    selectedCostCenter: any;
    toggleAspNetUser: any[];
    isLoading: boolean;
    costCenterUser: any[];
}

const allremoved = async (selectedUserForRemove: CostCenterUserSelected[], costCenterUser: any[], costCenterChildrens: any[], emailsUsersAddRemoved: string[]) => {
    let usersToRemove: any[] = [];
    for (const element of selectedUserForRemove) {
        const user: CostCenterUser = costCenterUser.find(x => x.id === element.value);
        usersToRemove.push(user.id);
    }
    const promises = costCenterChildrens.map(async (childRemove) => {
        const service = new CostCenterService({});
        const resp = await service.GetCostCenterUsers(childRemove.id);
        const childUser = resp.data.filter(user => emailsUsersAddRemoved.includes(user.email));
        if (childUser) {
            let idsRemoved = [...childUser].map(i => i.id)
            usersToRemove.push([...idsRemoved]);
        }
    });
    await Promise.all(promises);
    return [].concat(...usersToRemove)
}


export const LinkedUsersModal: React.FC<LinkedUsersModalProps>= (props) => {
    const service = new CostCenterService({});
    const [isLoadingUser, setIsLoadingUser] = useState(true)
    const [canRemove, setCanRemove] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [selectedUserForRemove, setSelectedUserForRemove] = useState<CostCenterUserSelected[]>([])
    const [isVisibleAddUser, setIsVisibleAddUser] = useState<boolean>(false)
    const [emailsUsersAddRemoved, setEmailsUserAddRemoved] = useState<string[]>([])
    const { enqueueSnackbar } = useSnackbar();
    const [isAddingUsers, setIsAddingUsers] = useState<boolean>(false)
    const [selectedUsersToAdd, setselectedUsersToAdd] = useState<CostCenterUserSelected[]>([]);


    const onCloseSideModalUser = () => {
        props.setSideModalVisibleUsuario(!props.sideModalVisibleUsuario)
        setIsLoadingUser(true)
    }

    const setUsersToRemove = (data: any) => {
        console.log(data)
        let selected = data.filter(x => x.selected === true);
        let emails: string[] = selected.map(i => i.email);
        setEmailsUserAddRemoved(emails)
        let users: CostCenterUser[] = [];
        setSelectedUserForRemove(selected);

        selected.forEach(async element => {
            console.log(element, props.costCenterUser)
            let user: CostCenterUser = props.costCenterUser.find(x => x.id === element.value);
            users.push(user);
        });
        users.find((can: CostCenterUser) => can.inheritedCostCenter) ? setCanRemove(false) : setCanRemove(true)
    }

    const removeUserFromCC = async () => {
        setIsLoadingUser(true)
        try {
            let ids = await allremoved(selectedUserForRemove, props.costCenterUser, props.costCenterChildrens, emailsUsersAddRemoved)
            ids.length > 0 && await service.RemoveCostCenterUsers(ids).then(result => {
            if (result.status === 200) {
                    setEmailsUserAddRemoved([])
                    enqueueSnackbar("Usuário(s) removido(s)", {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    });
                    props.setSideModalVisibleUsuario(!props.sideModalVisibleUsuario)
                    setIsLoadingUser(false)
                    setOpen(false)
                }
            });
        } catch (e) {
            console.warn(e)
            enqueueSnackbar("Erro ao remover usuário do centro de custo", {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            });
            props.setSideModalVisibleUsuario(!props.sideModalVisibleUsuario)
            setIsLoadingUser(false)
            setOpen(false)
        }
    }
    const addUserToCC = async () => {
        setIsAddingUsers(true)
        let removeArray: number[] = []
        const promises = props.costCenterChildrens.map(async (childRemove) => {
            const resp = await service.GetCostCenterUsers(childRemove.id);
            const childUser = resp.data.find(user => emailsUsersAddRemoved.includes(user.email));
            if (childUser) {
                removeArray.push(childUser.id)
            }
        })
        try {
            await Promise.all(promises);
            removeArray.length > 0 && await service.RemoveCostCenterUsers(removeArray)
            const result = await service.AddCostCenterUser(selectedUsersToAdd)
            if (result.status === 200) {
                setEmailsUserAddRemoved([])
                setIsLoadingUser(!isLoadingUser)
                setIsVisibleAddUser(!isVisibleAddUser)
                props.setSideModalVisibleUsuario(!props.sideModalVisibleUsuario)
                setIsAddingUsers(false)
                enqueueSnackbar("Usuário(s) vinculado(s)", {
                    variant: 'success',
                    preventDuplicate: true,
                    persist: false,
                });
            }
        } catch (e) {
            console.warn(e)
            setIsAddingUsers(false)
            enqueueSnackbar("Erro ao vincular usuário(s) do centro de custo", {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            });
        }
    };

    const setToogledUsers = (data: any) => {
        let selected: CostCenterUserSelected[] = data.filter(x => x.selected === true);
        let emails: string[] = selected.map(i => i.email);
        setEmailsUserAddRemoved(emails)
        let usersToAdd: any[] = [];
        selected.forEach(element => {
            let user = props.costCenterAspNetUser.find(x => x.id === element.value);
            let costCenterUser = {
                IdUser: user.id,
                Name: user.name,
                email: user.email,
                CostCenterID: props.selectedCostCenter.costCenterId,
            }
            usersToAdd.push(costCenterUser);
            if (props.costCenterChildrens.length > 0) {
                props.costCenterChildrens.forEach(childCostCenter => {
                    let user = props.costCenterAspNetUser.find(x => x.id === element.value);
                    let costCenterUser = {
                        IdUser: user.id,
                        Name: user.name,
                        email: user.email,
                        CostCenterID: childCostCenter.id,
                        inheritedCostCenter: props.selectedCostCenter.costCenterId,
                    }
                    usersToAdd.push(costCenterUser);
                });
            }
        });
        setselectedUsersToAdd(usersToAdd);
    }


    return(
        
        <PPModal size='medium' title="Usuários vinculados" onClose={onCloseSideModalUser} visible={props.sideModalVisibleUsuario}
            loading={props.isLoading}
            functions={[
                {
                    label: " Remover selecionado(s)", onClick: () => setOpen(true), disabled: !(selectedUserForRemove.length !== 0 && canRemove),
                    tooltip: !canRemove ? "Usuário(s) selecionado não pode ser removido porque é Herdado de outro Centro de custo" : selectedUserForRemove.length > 0 ? '' :
                        "Necessario selecionar pelo menos um usuário"

                }
            ]}
        >
            <LcIconLink icon={<PiUserPlusLight />} tooltip="Vincular Usuário(s)"
                tooltipPosition="left" onClick={() => { setIsVisibleAddUser(true) }} />

            <LcCheckList title="Vinculados" height="100%" list={props.linkedCostCenterUser}
                toggleAll={true} search={true}
                onChange={setUsersToRemove}
            />

            <Confirmation
                confirm={removeUserFromCC}
                close={setOpen}
                text='Deseja realmente remover este(s) usuário(s)?'
                display={open}
            />

            <PPModal loading={isAddingUsers} size='500px' title="Vincular usuário" onClose={() => setIsVisibleAddUser(false)} visible={isVisibleAddUser}
                functions={[
                    { label: (isAddingUsers ? <LinearProgress /> : "Vincular selecionados"), onClick: () => addUserToCC() }
                ]}
            >
                <LcCheckList height="100%" title="Disponíveis" list={props.toggleAspNetUser}
                    toggleAll={true} search={true}
                    onChange={setToogledUsers}
                />

            </PPModal>

        </PPModal>
    );
}
