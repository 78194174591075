import React, { useEffect, useRef, useState } from "react";
import Layout from "../../../components/Layout/Layout";
import { PiArrowSquareOutLight } from "react-icons/pi";
import { useHistory } from "react-router-dom";
import PPDropDown from "../../../components/PPDropDown";
import ReactECharts from "echarts-for-react";
import {
    FinOpsAdvanceFilterService,
    FinOpsDashBoard,
} from "../../../services/finOps/finOpsService";
import moment from "moment";
import FinOpsMapManagerService, {
    FinOpsAllocationRule,
} from "../../../services/finOpsManagerService";
import LcLoading from "../../../components/Generic/LcLoading";
import TopConsuption from "./TopConsuption";
import { RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { UserState } from "../../../store/reducers/userReducer";
import AlertConsuption from "./AlertConsuption";
import Recomendations from "./Recomendations";
import HigherConsumption from "./HigherConsumption";
import {
    KipConsumptionModel,
} from "../../../services/cmsPremier/cmsPremierModel";
import NextScheduleAutomation from "./NextScheduleAutomation";
import LCDashboard, {
    Card,
} from "../../../components/Data/Dashboard/LCDashboard";
import WebSitesResumo from "../../websites/resumo";
import { WebSitesService } from '../../../services/websites/index';
import { MenuState } from "../../../store/reducers/menuReducer";
import MessageService from "../../../services/AIx/messages/MessageService";
import LcIconLink from "../../../components/Generic/LcIconLink";
import AIxChatComponent from "../../../components/aix/ChatClient/AIxChatComponent";
import { LWResourcesCardInfo } from "../../LiveWatch/Summary/LWResourcesCardInfo";
import LWRiskIndex from "../../LiveWatch/Summary/LWRiskIndex";
import { incidentPeriods } from "../../LiveWatch/Summary";
import { VMsInfoCard } from "../../../components/Layout/Cards/VMsInfoCard";
import { SavingAndReserveInfoCard } from "../../../components/Layout/Cards/SavingAndReserveInfoCard";
import { ContainersInfoCard } from "../../../components/Layout/Cards/ContainersInfoCard";
import { StorageInfoCard } from "../../../components/Layout/Cards/StorageInfoCard";
import { DatabasesInfoCard } from "../../../components/Layout/Cards/DatabasesInfoCard";

const beginOfMonth = () => {
    const currentDate = new Date();

    // Get the day of the month
    const dayOfMonth = currentDate.getDate();
    let result = false;
    if (dayOfMonth > 6) {
        result = false;
    } else {
        result = true;
    }
    return result;
};
interface ResumeCMSProps {
    isHome?: boolean;
    qtdGroups?: number;
}

const rankTypeOptions = [
    { label: "Centro de custo", value: "costCenter" },
    { label: "Serviço", value: "service" },
    { label: "Recurso", value: "resource" },
];
const defaultFilter: FinOpsAllocationRule = {
    id: 0,
    order: 1,
    default: false,
    description: "Filtro",
    conditions: [
        {
            idCondition: 0,
            field: "",
            operation: "contains",
            filter: "",
            logicOperator: "",
        },
    ],
    costCenters: [],
    contracts: [],
};

const ResumeCMS: React.FC<ResumeCMSProps> = (props) => {
    const [assistantid, setAssistantid] = useState<string>("");
    const [AIXAgentVisible, setAIXAgentVisible] = useState(false);
    const finOpsMapManagerService = new FinOpsMapManagerService({});
    const user = useSelector<RootState, UserState>((state) => state.user);
    const menu = useSelector<RootState, MenuState>((state) => state.menu);
    const dispatch = useDispatch();
    const [month, setMonth] = useState<string>("");
    const [year, setYear] = useState<string>("");
    const [tipoDePeriodo, setTipoDePeriodo] = useState<string>(beginOfMonth() ? "priormonth" : "currentmonth");
    const [loading, setLoading] = useState<boolean>(false);
    const [rankType, setRankType] = useState<string>("resource");
    const [selectedCostCenter, setSelectedCostCenter] = useState<any>("");
    const [selectedContractId, setSelectedContractId] = useState<any>("");
    const [graphGroupType, setGraphGroupType] = useState<string>("all");
    const [refresh, setRefresh] = useState<any>("");
    const [resourceConsumptionKpi, setResourceConsumptionKpi] = useState<KipConsumptionModel[]>([]);
    const [serviceConsumptionKpi, setServiceConsumptionKpi] = useState<KipConsumptionModel[]>([]);
    const [costCenterConsumptionKpi, setCostCenterConsumptionKpi] = useState<KipConsumptionModel[]>([]);
    const [costCenters, setCostCenters] = useState<any[]>([]);
    const [contracts, setContracts] = useState<any[]>([]);
    const [notificacoes, setNotificacoes] = useState<any[]>([]);
    const [isWebSiteData, setIsWebSiteData] = useState<boolean>(false);
    const [filterConditions, setFilterConditions] =
    useState<FinOpsAllocationRule>(defaultFilter);
    const [kpi, setKPI] = useState<FinOpsDashBoard | null>(null);
    const [isLoadingKPIs, setIsLoadingKPIs] = useState(true);
    const [isRefetchingKPIs, setIsRefetchingKPIs] = useState(false)
    const history = useHistory();
    const chartConsumoLast = useRef<ReactECharts>(null);
    
    const parseSolutionFromExtraData = (extraData: string | null | undefined): string => {
        if (!extraData) return '';
        
        try {
            const parsed = JSON.parse(extraData);
            return parsed?.solution?.toLowerCase() ?? '';
        } catch {
            return extraData.toLowerCase();
        }
    };
    
    const checkIsOnlyCMSCorporate = () => {
        return menu.items.some(item => {
            const cmsModules = {
                corporate: item.child.find(c => 
                    parseSolutionFromExtraData(c.extraData) === "cloudmanagedservicescorporate")?.permitido ?? false,
                premier: item.child.find(c => 
                    parseSolutionFromExtraData(c.extraData) === "cloudmanagedservicespremier")?.permitido ?? false,
                enterprise: item.child.find(c => 
                    parseSolutionFromExtraData(c.extraData) === "cloudmanagedservicesenterprise")?.permitido ?? false
            };
    
            return cmsModules.corporate && !cmsModules.premier && !cmsModules.enterprise;
        });
    };

    const IsOnlyCMSCorporate = checkIsOnlyCMSCorporate();

    useEffect(() => {
        const getWebsitesClassicInfo = () => {
            const webSitesService = new WebSitesService({});
            webSitesService.GetWebSites()
                .then(response => {
                    const webSitesRetrieved = response.data;
                    setIsWebSiteData(webSitesRetrieved && webSitesRetrieved.length > 0);
                })
                .catch(error => {
                    console.error("Ocorreu um erro:", error);
                    setIsWebSiteData(false);
                });
        }
        const getAssitantData = async () => {
            setAssistantid("");
            if (user && user.ClientGroupSelected) {
                const messageService = new MessageService({});
                var assistantid = await messageService.getAssistantId("17", user.ClientGroupSelected?.toString(), "1");
                setAssistantid(assistantid);
            }
        }
        Promise.all([
            getWebsitesClassicInfo(),
            getAssitantData()
        ])
    }, [user, user.ClientGroupSelected, user.refreshFlag])


    const onChagePeriod = (selected: any) => {
        const firstFieldValue = selected.fields[0]?.value;
        const firstFieldName = selected.fields[0]?.name;

        if (firstFieldName === "month") {
            setYear(firstFieldValue.split("-")[1]);
            setMonth(firstFieldValue.split("-")[0]);
            setTipoDePeriodo(firstFieldName);
        } else {
            setYear("0");
            setMonth("0");
            setTipoDePeriodo(firstFieldValue);
        }
    };

    const periodSelection = {
        periods: [
            {
                label: 'Este mês', text: 'Este mês',
                fields: [
                    { name: 'currentMonth', value: 'currentMonth' }
                ],
                default: true
            }
        ],
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    const lineChartOption = (dados: any, max: number = 1, min: number = 1) => {
        return {
            align: "center",
            grid: {
                left: "3%",
                right: "3%",
                bottom: "3%",
                top: "3%",
                containLabel: true,
            },
            xAxis: {
                show: false,
                type: "category",
                data: dados.map((data: any) => {
                    return data.dia;
                }),
            },
            yAxis: {
                show: false,
                type: "value",
            },
            series: [
                {
                    showSymbol: true,
                    symbol: (value, params) => {
                        return params.dataIndex == dados.length - 1 ? "circle" : "none";
                    },
                    symbolSize: 7,
                    data: dados.map((data: any) => {
                        return data.valor;
                    }),
                    type: "line",
                    silent: true, // Desativa os eventos do mouse para esta série
                },
            ],
            visualMap: {
                show: false,
                inRange: {
                    color: ["#93CE07", "#ff2100"],
                },
            },
        };
    }

    useEffect(() => {
        Promise.all([
            retrieveCostCenters(),
            retrieveContracts(),
            loadData(),
        ]);
    }, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        setRefresh(
            user.ClientGroupSelected +
            tipoDePeriodo +
            month +
            year +
            JSON.stringify(filterConditions) +
            selectedCostCenter +
            selectedContractId
        );
    }, [user.ClientGroupSelected, filterConditions, tipoDePeriodo, selectedCostCenter, selectedContractId, month, year]);

    const handleChange = (event) => {
        const { name, value } = event;

        // Limpe o gráfico apenas se os dropdowns relacionados ao gráfico "Consumo mensal" forem alterados
        if (name !== "rankType") {
            chartConsumoLast.current?.getEchartsInstance().clear();
        }
        if (name === "rankType") setRankType(value);

        if (name === "graphGroupType") {
            setGraphGroupType(value);
        }
    };

    async function retrieveReportKPIs(refresh): Promise<FinOpsDashBoard> {
        try {
            const finOpsService = new FinOpsAdvanceFilterService({});
            let kpisLocal = await finOpsService.GetKPIByYearMonthLastPeriod(
                tipoDePeriodo,
                selectedCostCenter,
                selectedContractId,
                graphGroupType,
                filterConditions,
                Number(month),
                Number(year)
            );
            //console.log(kpisLocal);
            if (kpisLocal.length > 0) {
                kpisLocal[0].dataGroupbyTotal &&
                    kpisLocal[0].dataGroupbyTotal.forEach((rowlast) => {
                        rowlast.series = rowlast.series.sort((a: any, b: any) => {
                            return a.date > b.date ? 1 : -1;
                        });
                    });
                setResourceConsumptionKpi(
                    kpisLocal[0].resourceConsumption.map((kpi) => {
                        return {
                            ...kpi,
                            consumption:
                                kpi.consumption &&
                                (kpi.consumption * 100) / kpisLocal[0].totalConsumption,
                        };
                    })
                );

                setCostCenterConsumptionKpi(
                    kpisLocal[0].costCenterConsumption.map((kpi) => {
                        return {
                            ...kpi,
                            consumption:
                                kpi.consumption && (kpi.consumption * 100) / kpisLocal[0].totalConsumption
                        };
                    })
                );

                setServiceConsumptionKpi(
                    kpisLocal[0].serviceConsumption.map((kpi) => {
                        return {
                            ...kpi,
                            consumption:
                                kpi.consumption &&
                                (kpi.consumption * 100) / kpisLocal[0].totalConsumption,
                        };
                    })
                );
                setLoading(false);
                return kpisLocal[0] as unknown as FinOpsDashBoard;
            }
        } catch (e) { }
        setLoading(false);
        return {} as FinOpsDashBoard;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fazer a chamada à função retrieveReportKPIs aqui
                const result = await retrieveReportKPIs(refresh);
                setKPI(result);
                setIsLoadingKPIs(false);
            } catch (error) {
                console.error("Erro ao buscar os dados:", error);
                setIsLoadingKPIs(false);
            }
        };
        fetchData();
    }, [refresh]);

    async function retrieveCostCenters() {
        try {
            const finOpsMapManagerService = new FinOpsAdvanceFilterService({});
            let costCenterResponse =
                await finOpsMapManagerService.LoadCostCentersByUserId();
            let costCenters = [
                { label: "(Todos)", value: "" },
                ...costCenterResponse.map((u) => {
                    return {
                        label: u.name,
                        value: u.id,
                    };
                }),
            ];

            setCostCenters(costCenters);
        } catch (e) {
            console.warn(e);
        }
    }
    async function retrieveContracts() {
        try {
            const finOpsMapManagerService = new FinOpsAdvanceFilterService({});

            var contracts = await finOpsMapManagerService.LoadContracts();

            contracts = contracts.map((u) => {
                return {
                    label: u.contractName,
                    value: u.contractId,
                };
            });

            let contractsLocal = [{ label: "(Todos)", value: "" }, ...contracts];
            setContracts(contractsLocal);
        } catch (e) { }
    }
    async function loadData() {
        setLoading(true)
        try {

            var response = await finOpsMapManagerService.LoadProcessedAlertApproved();

            var list = response.alerts;
            var notificationList = [];

            if (list.filter((x) => x.send === true).length !== 0) {
                notificationList = list
                    .filter((x) => x.send === true)
                    .map((c) => {
                        var recordResult = {} as any;
                        try {
                            recordResult.id = c.id;
                            recordResult.alertIcon =
                                c.conditions[0].condition === "budget"
                                    ? "ppi ppi-bell"
                                    : c.conditions[0].comparator === "mais"
                                        ? "ppi ppi-arrow-up"
                                        : "ppi ppi-arrow-down";
                            recordResult.alertClientGroup = {
                                id: response.clientGroupId,
                                description: "Processor",
                            };
                            recordResult.alertDescription = c.description;
                            recordResult.alertDate = c.date ? c.date : moment().toISOString();

                            recordResult.alertCostCenter = c.costCenters;
                            recordResult.alertContracts = c.contracts;
                            recordResult.alertResources = c.resources;
                            recordResult.alertServices = c.services;

                            recordResult.alertPriorPeriodTotal =
                                c.priorPeriodTotal.toLocaleString("pt-BR", {
                                    style: "decimal",
                                    maximumFractionDigits: 2,
                                });
                            recordResult.alertPeriodTotal = c.periodTotal.toLocaleString(
                                "pt-BR",
                                { style: "decimal", maximumFractionDigits: 2 }
                            );
                            recordResult.alertAchieved = c.achieved;
                            recordResult.alertDifference = c.difference;
                            recordResult.budgetLimit = c.budgetLimit;
                            if (c.conditions && c.conditions.length > 0 && c.conditions[0]) {
                                recordResult.alertVariation =
                                    c.cloudCostEffectiveVariation.toLocaleString("pt-BR", {
                                        style: "decimal",
                                        maximumFractionDigits: 2,
                                    }) + "%";
                                recordResult.alertCondition =
                                    c.conditions[0].condition === "variacao" ? " Variação" : "N/A";

                                // Verificar se a propriedade 'percentage' está presente e não é null
                                if (c.conditions[0].percentage != null) {
                                    recordResult.alertPercentage =
                                        (c.conditions[0] ? "+" : "-") +
                                        c.conditions[0].percentage.toLocaleString("pt-BR", {
                                            style: "decimal",
                                            maximumFractionDigits: 2,
                                        }) + "%";
                                } else {
                                    // Tratar o caso em que percentage é null ou undefined
                                    recordResult.alertPercentage = "N/A"; // Ou qualquer outro valor padrão
                                }

                                recordResult.alertCompatator =
                                    c.conditions[0].comparator === "mais" ? "Mais" : "Menos";
                            }

                            recordResult.alertPeriod = "Últimos";

                            try {
                                recordResult.alertPeriodValue = c.period.periodValue;
                            } catch (ec) {
                                console.error(ec);
                            }

                            recordResult.alertPeriodUnit = "dias";
                            recordResult.alertPeriodStartDate = moment(new Date()).subtract(
                                c.period.periodValue + 2,
                                "days"
                            );
                            recordResult.alertPeriodEndDate = moment(new Date()).subtract(
                                2,
                                "days"
                            );
                            recordResult.alertScope = c.escope;

                            if (c.conditions[0].condition === "variacao") {
                                recordResult.alertHistory = {
                                    history: c.history.map((x) => {
                                        return {
                                            dia: x.dia + "/" + x.mes + "/" + x.ano,
                                            valor: x.total,
                                        };
                                    }),
                                    dataDoAlerta:
                                        c.history && c.history.length > 0
                                            ? new Date(
                                                c.history[0].dia +
                                                "/" +
                                                c.history[0].mes +
                                                "/" +
                                                c.history[0].ano
                                            ).toLocaleDateString()
                                            : "-",
                                };
                            } else if (c.conditions[0].condition === "budget") {
                                recordResult.alertHistory = {
                                    history: c.history.map((x) => {
                                        return {
                                            dia: x.dia + "/" + x.mes + "/" + x.ano,
                                            valor: x.accTotal,
                                        };
                                    }),
                                    dataDoAlerta:
                                        c.history && c.history.length > 0
                                            ? new Date(
                                                c.history[0].dia +
                                                "/" +
                                                c.history[0].mes +
                                                "/" +
                                                c.history[0].ano
                                            ).toLocaleDateString()
                                            : "-",
                                };
                            }
                        } catch (e) {
                            setLoading(false);
                            console.error(`Ocorreu um erro: ${e}`);
                        }
                        return recordResult;
                    });
            }

            let sortedNotificationList = notificationList.sort(
                (element1: any, element2: any) => {
                    return element1.alertDate < element2.alertDate ? 1 : -1;
                }
            );

            setNotificacoes(sortedNotificationList);
        } catch (error) {
            setLoading(false);
            console.warn(error);
            setNotificacoes([]);
        }
    }

    const gridConfig = {
        recomendacoes: {
            id: 1,
            grid: { ColSpan: 4, col: 1, RowSpan: 2, row: 1 }
        },
        vms: {
            id: 3,
            grid: { ColSpan: 2, col: 5, RowSpan: 1, row: 1 }
        },
        databases: {
            id: 4,
            grid: { ColSpan: 2, col: 5, RowSpan: 1, row: 2 }
        },
        storages: {
            id: 5,
            grid: { ColSpan: 2, col: 7, RowSpan: 1, row: 1 }
        },
        containers: {
            id: 6,
            grid: { ColSpan: 2, col: 7, RowSpan: 1, row: 2 }
        },
        savings: {
            id: 8,
            grid: { ColSpan: 2, col: 9, RowSpan: 1, row: 1 }
        },
        reservas: {
            id: 2,
            grid: { ColSpan: 2, col: 9, RowSpan: 1, row: 2 }
        },
        agendamentos: {
            id: 7,
            grid: { ColSpan: 3, col: 11, RowSpan: 1, row: 1 }
        },
        alertas: {
            id: 9,
            grid: { ColSpan: 3, col: 11, RowSpan: 1, row: 2 }
        },
        topconsumos: {
            id: 10,
            grid: isWebSiteData ?
                { ColSpan: 5, col: 1, RowSpan: 2, row: 3 } :
                { ColSpan: 6, col: 1, RowSpan: 2, row: 3 }
        },
        consumos: {
            id: 11,
            grid: isWebSiteData ?
                { ColSpan: 5, col: 6, RowSpan: 2, row: 3 } :
                { ColSpan: 7, col: 7, RowSpan: 2, row: 3 }
        },
        websites: {
            id: 12,
            grid: isWebSiteData ?
                { ColSpan: 3, col: 11, RowSpan: 2, row: 3 } :
                { ColSpan: 0, col: 0, RowSpan: 0, row: 0, hidden: true }
        },
    }

    //homerainbow
    const positions5Groups: Card[] = [
        //Recomendações
        {
            id: 1,
            position: { row: 5, col: 1, ColSpan: 2, RowSpan: 3, hidden: IsOnlyCMSCorporate },

        },
        {
            id: 2, //reservas
            hideHeader: true,
            position: { ColSpan: 1, RowSpan: 1, row: IsOnlyCMSCorporate? 5 :3, col: 1, hidden: false },

        },
        {
            id: 3, //VMs
            hideHeader: true,
            position: { ColSpan: IsOnlyCMSCorporate?2 :1, RowSpan: 1, row:  1, col: 1, hidden: false },

        },
        {
            id: 4, //Databases   
            hideHeader: true,
            position: { ColSpan: IsOnlyCMSCorporate?2 : 1, RowSpan:  1, row: IsOnlyCMSCorporate ? 2 : 1, col: IsOnlyCMSCorporate ? 1 : 2, hidden: false },

        },
        {
            id: 5, //Storage
            hideHeader: true,
            position: { ColSpan: IsOnlyCMSCorporate?2 :1, RowSpan:  1, row: IsOnlyCMSCorporate ? 3 :  2, col: 1, hidden: false },

        },
        {
            id: 6, //Containers
            hideHeader: true,
            position: { ColSpan: IsOnlyCMSCorporate?2 :1, RowSpan: 1, row: IsOnlyCMSCorporate ? 4 :  2, col: IsOnlyCMSCorporate? 1: 2, hidden: false },

        },
        {
            //proximo agendamento
            id: 7, hideHeader: true,
            position: { row: IsOnlyCMSCorporate ? 6: 4, col: 1, ColSpan: 2, RowSpan: IsOnlyCMSCorporate ? 2: 1, hidden: false },

        },
        {
            //savings
            id: 8,
            hideHeader: true,
            position: { row:IsOnlyCMSCorporate ?5 :  3, col: 2, ColSpan: 1, RowSpan:IsOnlyCMSCorporate ? 1: 1, hidden: false },

        },
        {
            id: 9, //Top consumos (%)        
            position: { ColSpan: 3, RowSpan: 2, row: 3, col: 1, hidden: true },
        },
        {
            id: 10, //Consumos(%)

            position: { ColSpan: 5, RowSpan: 2, row: 3, col: 4, hidden: true },

        },
        {
            id: 11, //Websites         
            position: { ColSpan: 4, RowSpan: 2, row: 3, col: 7, hidden: true },

        },
        {
            id: 12, //Log de Atividades         
            position: { ColSpan: 4, RowSpan: 2, row: 3, col: 9, hidden: true },

        },
    ];

    var positions3Groups: Card[] = [
       //Recomendações
        {
        id: 1,
        position: { row: 5, col: 1, ColSpan: 2, RowSpan: 3, hidden: IsOnlyCMSCorporate },

        },
        {
            id: 2, //reservas
            hideHeader: true,
            position: { ColSpan: 1, RowSpan: 1, row: IsOnlyCMSCorporate? 5 :3, col: 1, hidden: false },

        },
        {
            id: 3, //VMs
            hideHeader: true,
            position: { ColSpan: IsOnlyCMSCorporate?2 :1, RowSpan: 1, row:  1, col: 1, hidden: false },

        },
        {
            id: 4, //Databases   
            hideHeader: true,
            position: { ColSpan: IsOnlyCMSCorporate?2 : 1, RowSpan:  1, row: IsOnlyCMSCorporate ? 2 : 1, col: IsOnlyCMSCorporate ? 1 : 2, hidden: false },

        },
        {
            id: 5, //Storage
            hideHeader: true,
            position: { ColSpan: IsOnlyCMSCorporate?2 :1, RowSpan:  1, row: IsOnlyCMSCorporate ? 3 :  2, col: 1, hidden: false },

        },
        {
            id: 6, //Containers
            hideHeader: true,
            position: { ColSpan: IsOnlyCMSCorporate?2 :1, RowSpan: 1, row: IsOnlyCMSCorporate ? 4 :  2, col: IsOnlyCMSCorporate? 1: 2, hidden: false },

        },
        {
            //proximo agendamento
            id: 7, hideHeader: true,
            position: { row: IsOnlyCMSCorporate ? 6: 4, col: 1, ColSpan: 2, RowSpan: IsOnlyCMSCorporate ? 2: 1, hidden: false },

        },
        {
            //savings
            id: 8,
            hideHeader: true,
            position: { row:IsOnlyCMSCorporate ?5 :  3, col: 2, ColSpan: 1, RowSpan:IsOnlyCMSCorporate ? 1: 1, hidden: false },

        },
        {
            id: 9, //Top consumos (%)        
            position: { ColSpan: 3, RowSpan: 2, row: 3, col: 1, hidden: true },
        },
        {
            id: 10, //Consumos(%)

            position: { ColSpan: 5, RowSpan: 2, row: 3, col: 4, hidden: true },

        },
        {
            id: 11, //Websites         
            position: { ColSpan: 4, RowSpan: 2, row: 3, col: 7, hidden: true },

        },
        {
            id: 12, //Log de Atividades         
            position: { ColSpan: 4, RowSpan: 2, row: 3, col: 9, hidden: true },

        },
    ];

    var positions2Groups: Card[] = [
        //Recomendações
        {
            id: 1,
            position: { row: 5, col: 1, ColSpan: 2, RowSpan: 3, hidden: IsOnlyCMSCorporate },

        },
        {
            id: 2, //reservas
            hideHeader: true,
            position: { ColSpan: 1, RowSpan: 1, row: IsOnlyCMSCorporate? 5 :3, col: 1, hidden: false },

        },
        {
            id: 3, //VMs
            hideHeader: true,
            position: { ColSpan: IsOnlyCMSCorporate?2 :1, RowSpan: 1, row:  1, col: 1, hidden: false },

        },
        {
            id: 4, //Databases   
            hideHeader: true,
            position: { ColSpan: IsOnlyCMSCorporate?2 : 1, RowSpan:  1, row: IsOnlyCMSCorporate ? 2 : 1, col: IsOnlyCMSCorporate ? 1 : 2, hidden: false },

        },
        {
            id: 5, //Storage
            hideHeader: true,
            position: { ColSpan: IsOnlyCMSCorporate?2 :1, RowSpan:  1, row: IsOnlyCMSCorporate ? 3 :  2, col: 1, hidden: false },

        },
        {
            id: 6, //Containers
            hideHeader: true,
            position: { ColSpan: IsOnlyCMSCorporate?2 :1, RowSpan: 1, row: IsOnlyCMSCorporate ? 4 :  2, col: IsOnlyCMSCorporate? 1: 2, hidden: false },

        },
        {
            //proximo agendamento
            id: 7, hideHeader: true,
            position: { row: IsOnlyCMSCorporate ? 6: 4, col: 1, ColSpan: 2, RowSpan: IsOnlyCMSCorporate ? 2: 1, hidden: false },

        },
        {
            //savings
            id: 8,
            hideHeader: true,
            position: { row:IsOnlyCMSCorporate ?5 :  3, col: 2, ColSpan: 1, RowSpan:IsOnlyCMSCorporate ? 1: 1, hidden: false },

        },
        {
            id: 9, //Top consumos (%)        
            position: { ColSpan: 3, RowSpan: 2, row: 3, col: 1, hidden: true },
        },
        {
            id: 10, //Consumos(%)
            position: { ColSpan: 5, RowSpan: 2, row: 3, col: 4, hidden: true },
        },
        {
            id: 11, //Websites         
            position: { ColSpan: 4, RowSpan: 2, row: 3, col: 7, hidden: true },
        },
        {
            id: 12, //Log de Atividades         
            position: { ColSpan: 4, RowSpan: 2, row: 3, col: 9, hidden: true },
        },
    ];

    function getConfigHome(
        cardId: number | undefined,
        qtdGroups: number
    ): Card {
        var Id = cardId as number;
        positions2Groups = positions5Groups;
        positions3Groups = positions5Groups;
        if (qtdGroups <= 2) {
            return positions2Groups.filter((c) => c.id === Id).length > 0 ? positions2Groups.filter((c) => c.id === Id)[0] : {} as unknown as Card;
        }

        if (qtdGroups <= 3) {
            return positions3Groups.filter((c) => c.id === Id).length > 0 ? positions3Groups.filter((c) => c.id === Id)[0] : {} as unknown as Card;
        }

        if (qtdGroups <= 6) {
            return positions5Groups.filter((c) => c.id === Id).length > 0 ? positions3Groups.filter((c) => c.id === Id)[0] : {} as unknown as Card;
        }
        return {} as unknown as Card;
    }

    const navigateToAutomations = () => {
        const location = history.location.pathname;
        history.push(location.replace('resume', 'automacoes'))
    }

    const navigateToEnvironment = () => {
        const location = history.location.pathname;
        const cmsType = location.includes('premier') ? 1 : 0;
        history.push(location.replace('resume', `environment/${cmsType}`))
    }

    const cards: Card[] = [
        ...(menu.selectedItem?.name === "CMS Corporate" ? [{
            // 1. Recursos
            id: 1,
            type: "Custom",
            showFocusButton: false,
            hideHeader: false,
            ticketButtonLabel: "Ir para LiveWatch",
            ticketButton: () => !props.isHome ? <PiArrowSquareOutLight color='#fff' onClick={() => history.push('/livewatch/summary')} /> : undefined,
            bgColor: "var(--color-primary-purple)",
            title: () => <div style={{ color: "white" }} className="lc-title ellipsis">Recursos</div>,
            position: { col: 1, ColSpan: 4, row: 1, RowSpan: 1 },
            customContentRender: () => <LWResourcesCardInfo/>
        },
        {// 2. Índice de Risco
            id: 2,
            type: "Custom",
            showFocusButton: false,
            hideHeader: false,
            infoContent: () =>  'Incidentes da semana',
            ticketButtonLabel: "Ir para LiveWatch",
            ticketButton: () => !props.isHome ? <PiArrowSquareOutLight color='#173192' onClick={() => history.push('/livewatch/summary')} /> : undefined,
            title: () =>
                <div className='title-with-selector-container'>
                    <span>{ 'Índice de risco'}</span>
                </div>,
            position: { col: 1, ColSpan: 4, row: 2, RowSpan: 1 },
            customContentRender: () => {
                return (
                    <LWRiskIndex 
                        isPanelFocused={false} 
                    />
                )
            },

        }   
        ] : [  {// 1. Recomendações
            id: gridConfig.recomendacoes.id,
            type: "Custom",
            position: gridConfig.recomendacoes.grid,
            showFocusButton: false,
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => !props.isHome ? <PiArrowSquareOutLight color='#173192' onClick={navigateToEnvironment} /> : undefined,
            title: () => "Recomendações",
            customContentRender: () => <div id="recomendacoes"  ><Recomendations isHome={props.isHome} /></div>
        },]),
        {// 2. VMs
            id: gridConfig.vms.id,
            type: "Custom",
            cssClass: props.isHome ? 'small-card' : undefined,
            position: gridConfig.vms.grid,
            showFocusButton: false,
            title: () => "VMs",
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => <PiArrowSquareOutLight color='#173192' onClick={() => { navigateToEnvironment(); dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: { enviromentFilter: 'VMs' } }) }} />,
            customContentRender: () => {
                return <VMsInfoCard 
                    withoutBallon={true} 
                    isHome={props.isHome} 
                    />
            },
        },
        {// 3. Databases
            id: gridConfig.databases.id,
            type: "Custom",
            cssClass: props.isHome ? 'small-card' : undefined,
            position: gridConfig.databases.grid,
            showFocusButton: false,
            title: () => "Databases",
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => <PiArrowSquareOutLight color='#173192' onClick={() => { navigateToEnvironment(); dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: { enviromentFilter: 'Databases' } }) }} />,
            customContentRender: (focused?: boolean) => {
                return <DatabasesInfoCard
                    isHome={props.isHome}
                    withoutBallon={true}    
                />
            },
        },
        {// 4. Storage
            id: gridConfig.storages.id,
            type: "Custom",
            cssClass: props.isHome ? 'small-card' : undefined,
            position: gridConfig.storages.grid,
            showFocusButton: false,
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => <PiArrowSquareOutLight color='#173192' onClick={() => { navigateToEnvironment(); dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: { enviromentFilter: 'Storage' } }) }} />,
            title: () => "Storage",
            customContentRender: () => {
                return  <StorageInfoCard
                        withoutBallon={true}
                    />
            },
        },
        {// 5. Containers
            id: gridConfig.containers.id,
            type: "Custom",
            cssClass: props.isHome ? 'small-card' : undefined,
            position: gridConfig.containers.grid,
            showFocusButton: false,
            title: () => "Containers",
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => <PiArrowSquareOutLight color='#173192' onClick={() => { navigateToEnvironment(); dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: { enviromentFilter: 'Containers' } }) }} />,
            customContentRender: () => {
                return <ContainersInfoCard
                    isHome={props.isHome}
                    withoutBallon={true}    
                />
            },
        },
        {// 6. Savings plans
            id: gridConfig.savings.id,
            type: "Custom",
            cssClass: props.isHome ? 'small-card' : undefined,
            position: gridConfig.savings.grid,
            showFocusButton: false,
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => <PiArrowSquareOutLight color='#173192' onClick={() => { navigateToEnvironment(); dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: { enviromentFilter: 'Savings plans' } }) }} />,
            title: () => "Savings plans",
            customContentRender: () => {
                return <SavingAndReserveInfoCard
                    type='Savings plans'
                    isHome={props.isHome}
                    withoutBallon={true}
                />
            }
        },
        {// 7. Reservas
            id: gridConfig.reservas.id,
            type: "Custom",
            cssClass: props.isHome ? 'small-card' : undefined,
            position: gridConfig.reservas.grid,
            showFocusButton: false,
            title: () => "Reservas",
            ticketButtonLabel: "Ir para Ambiente",
            ticketButton: () => <PiArrowSquareOutLight color='#173192' onClick={() => { navigateToEnvironment(); dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: { enviromentFilter: 'Reservas' } }) }} />,
            customContentRender: () => {
                return <SavingAndReserveInfoCard
                    type='Reservas'
                    isHome={props.isHome}
                    withoutBallon={true}
                />
            }
        },
        {// 8. Próximo agendamento
            id: gridConfig.agendamentos.id,
            cssClass: props.isHome ? 'no-padding' : undefined,
            showFocusButton: false,
            hideHeader: false,
            ticketButtonLabel: !props.isHome ? "Ir para automações" : undefined,
            ticketButton: !props.isHome ? () => {
                return <PiArrowSquareOutLight color='#fff' onClick={navigateToAutomations} />;
            } : undefined,
            bgColor: "var(--color-primary-purple)",
            title: () => !props.isHome && <div style={{ color: "white" }} className="lc-title ellipsis">Agendamentos</div>,
            type: "Custom",
            position: gridConfig.agendamentos.grid,
            customContentRender: () => {
                return <NextScheduleAutomation isHome={props.isHome} isOnlyCMSCorporate={IsOnlyCMSCorporate} />;
            },
        },
        {// 9. Alerta de consumo
            id: gridConfig.alertas.id,
            type: "Custom",
            position: gridConfig.alertas.grid,
            ticketButtonLabel: notificacoes?.length > 0 ? "Ir para alertas" : undefined,
            ticketButton: () => {
                return (
                    notificacoes?.length > 0 ?
                        <PiArrowSquareOutLight
                            onClick={() => {
                                history.push("/finops/alertasNotificacao")
                            }}
                        />
                        :
                        undefined
                );
            },
            title: () => {
                return <div className="lc-title ellipsis">Alerta de consumo</div>;
            },
            customContentRender: () => {
                const hasAlerts = notificacoes?.length > 0;
                const alertDescription = hasAlerts
                    ? notificacoes[0].alertDescription
                    : "";

                // Fixed check to ensure that all properties exist and 'history' is an array
                const chartOptions = hasAlerts && notificacoes[0].alertHistory && Array.isArray(notificacoes[0].alertHistory.history)
                    ? lineChartOption(notificacoes[0].alertHistory.history, 1, 0)
                    : [];

                return (
                    <LcLoading loading={loading}>
                        <AlertConsuption
                            hasAlerts={hasAlerts}
                            chartOptions={chartOptions}
                            alertDescription={alertDescription}
                        />
                    </LcLoading>
                );
            },
        },
        {// 10. Top consumos (%)
            id: gridConfig.topconsumos.id,
            type: "Custom",
            showFocusButton: !props.isHome,
            position: gridConfig.topconsumos.grid,
            title: () => {
                return (
                    <>
                        {
                            <div  id="topconsumos_dropdown"  className={"lc-segment-title-row-resume"}>
                                <div className={"lc-title ellipsis"}>
                                    Top consumos (%)
                                </div>
                                <PPDropDown
                                    id="topconsumos_dropdown"
                                    name="rankType"
                                    options={rankTypeOptions}
                                    defaultValue={rankTypeOptions.find(
                                        (option) => option.value === rankType
                                    )}
                                    value={rankTypeOptions.find(
                                        (option) => option.value === rankType
                                    )}
                                    onChange={(event) =>
                                        handleChange({ ...event, name: "rankType" })
                                    }
                                    width="220px"
                                />
                            </div>
                        }
                    </>
                );
            },
            customContentRender: () => {
                return (
                    <LcLoading loading={isLoadingKPIs || isRefetchingKPIs || loading}>
                        <TopConsuption
                            id="topconsumos_list"
                            //key={kpi}
                            kpi={kpi}
                            resourceConsumptionKpi={resourceConsumptionKpi}
                            costCenterConsumptionKpi={costCenterConsumptionKpi}
                            serviceConsumptionKpi={serviceConsumptionKpi}
                            rankType={rankType}
                            rankTypeOption={rankTypeOptions.find((option) => option.value === rankType)?.label}
                        />
                    </LcLoading>
                );
            },
        },
        {// 11. Consumos(%)
            id: gridConfig.consumos.id,
            type: "Custom",
            showFocusButton: !props.isHome,
            position: isWebSiteData ? gridConfig.consumos.grid : gridConfig.consumos.grid,
            customContentRender: (focused?: boolean) => {
                return (
                    <HigherConsumption
                        costCenters={costCenters}
                        contracts={contracts}
                        selectedContract={selectedContractId}
                        selectedCostCenter={selectedCostCenter}
                        tipoDePeriodo={tipoDePeriodo}
                        filter={filterConditions}
                        focused={focused}
                    />
                );
            },
        },
        {// 12. Websites
            id: gridConfig.websites.id,
            type: "Custom",
            position: isWebSiteData ? { ColSpan: 3, RowSpan: 2, row: 3, col: 11, } : gridConfig.websites.grid,
            showFocusButton: false,
            title: () => "Websites",
            cssClass: 'overflow-hidden',
            customContentRender: () => <WebSitesResumo isHome />
        }
    ];

    useEffect(() => {
        if (menu && menu.selectedItem) {
            let isCMSMenuSelected = [
                "1dbdb5d1-64ff-4100-8bbc-77ad70874e9e", // corporate
                "5cdc40b0-f7da-4cc7-86be-a477315d733b", // enterprise
                "3911a37e-8334-4078-a497-2120514a1699"  // premier
            ].includes(menu.selectedItem.id);

            isCMSMenuSelected && loadData();
            isCMSMenuSelected && dispatch({ type: 'MENU_VISIBLE', payload: false });
        }
    }, [menu.selectedItem])

    var assitantToolBaricon = [{
        icon: <LcIconLink icon='ppi ppi-lightning-bolt'
            id='aiLCChamados'
            size='small'
            degrade
            onClick={() => { }}
            tooltip={"LiveWatch AI"}
            tooltipPosition='right' />,
        onClick: () => {
            setAIXAgentVisible(!AIXAgentVisible)
        }, tooltip: `LiveWatch AI`
    }];

    return props.isHome ? (
        <LCDashboard
            isHomeType
            grid={[7, 2]}
            cards={
                props.qtdGroups && props.qtdGroups > 0
                    ? cards.map((card, index) => {
                        if (props.qtdGroups) {
                            const posi = getConfigHome(card.id, props.qtdGroups);
                            if (posi !== undefined) {
                                card.position = posi.position;
                                if (posi.hideHeader) {
                                    card.hideHeader = posi.hideHeader;
                                }
                            }
                        }
                        return card;
                    })
                    : []
            }
        />
    ) : (
        <Layout
            pageTitle="Resumo"
            periodSelection={periodSelection}
            functionsGeneric={assistantid && assistantid.length > 0 ? [{
                icon: (
                    <LcIconLink
                        icon='ppi ppi-lightning-bolt'
                        id='aiLCChamados'
                        size='small'
                        degrade
                        tooltip="CMS AI"
                        tooltipPosition="right"
                    />
                ),
                onClick: () => setAIXAgentVisible(!AIXAgentVisible), // Corrigido para alterar o estado corretamente
                tooltip: "CMS AI"
            }] : []}
        >
            <LCDashboard
                cards={cards}
                rightSidePanel={assistantid && assistantid.length > 0 && AIXAgentVisible ? {
                    title: 'LiveCloud AI',
                    titleClass: 'ai-gradient-text',
                    pinned: false,
                    hiddenPin: false,
                    show: AIXAgentVisible,
                    focusable: true,
                    content: () => (
                        <LcLoading loading={loading}>
                            <div style={{ alignSelf: 'center' }}>
                                <AIxChatComponent eventChat={(_event) => { }} assistantId={assistantid} assistantName={"LiveWatch"} />
                            </div>
                        </LcLoading>
                    ),
                } : undefined}
            />
        </Layout>
    );
};

export default ResumeCMS;
