import { PPModal } from 'processor-plataform-ui';
import React, { useEffect, useState } from 'react';
import LcLoading from '../../../Generic/LcLoading';
import LcInfiniteTable from '../../../Data/LcInfiniteTable';
import LcTooltip from '../../../Generic/LcTooltip';
import LcIconLink from '../../../Generic/LcIconLink';
import { Typography } from '@material-ui/core';
import ManageOfficeStatusService from '../../../../services/manageOfficeStatus/manageOfficeStatus';
import { statusServices } from '../../../../pages/manageOfficeStatus';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import LcNoData from '../../../Generic/LcNoData';

interface HealthCheckTableProps {
    filteredRows?: any[]
}

const formatDate = (stringDate) => {
    let date = new Date(stringDate)
    return `Atualizado em ${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`
}

const formatHour = (stringDate) => {
    let date = new Date(stringDate)
    let GMT = date.toString().substring(25, 33).replace('0', '').replace('00', '')
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} (${GMT})`
}


export const HealthCheckTable: React.FC<HealthCheckTableProps> = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [records, setRecords] = useState<any[]>([]);
    const [selectedRow, setSelectedRow] = useState<any>([]);
    const [sideModal, setSideModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingModal, setIsLoadingModal] = useState<boolean>(false);
    const [messagesSelected, setMessagesSelected] = useState<any[]>([]);

    const sortRecords = (recordsToSort, column, direction) => {
        return recordsToSort.sort((a, b) => {
            // Ordenação para a coluna 'status'
            if (column === 'status') {
                const order = { 'danger': 1, 'warning': 2, 'success': 3, 'info': 4 };
                const statusOrderA = order[a.status] || 5;
                const statusOrderB = order[b.status] || 5;
    
                if (statusOrderA !== statusOrderB) {
                    return direction === 'asc' ? statusOrderA - statusOrderB : statusOrderB - statusOrderA;
                }
    
                // Se os status forem iguais, ordena alfabeticamente pelo nome (workloadDisplayName)
                return direction === 'asc'
                    ? a.workloadDisplayName.localeCompare(b.workloadDisplayName)
                    : b.workloadDisplayName.localeCompare(a.workloadDisplayName);
            }
    
            // Ordenação genérica para outras colunas
            return direction === 'asc'
                ? a[column].localeCompare(b[column])
                : b[column].localeCompare(a[column]);
        });
    };
    
    useEffect(() => {
        async function executeUseEffect() {
            setIsLoading(true);
            try {
                const service = new ManageOfficeStatusService({})
                const response = await service.GetServices()
                let data = response.data;
                let mappedData = data.map((e) => {
                    const date = new Date(e.statusTime);
                    const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
                    return {
                        id: e.id,
                        workloadDisplayName: e.workloadDisplayName,
                        workload: e.workload,
                        credentialAlias: e.credentialAlias,
                        statusTime: formattedDate,
                        status: statusServices.find(x => x.statusNuvem === e.status)?.statusLC || 'info'
                    };
                });
                let sortedArray = sortRecords(mappedData, 'status', 'asc');
                setRecords(sortedArray);
            
            } catch (error) {
                console.error(error);
            } finally {
                setIsLoading(false);
            }
        }
        executeUseEffect();
    }, [user.ClientGroupSelected, user.refreshFlag]);
    useEffect(() => {
        if (props.filteredRows) {
            setRecords(props.filteredRows);
        }
    }, [props.filteredRows]);

    const columnsInf = [
        { field: 'workloadDisplayName', headerName: 'Serviço', width: '60%' },
        {
            field: 'status', headerName: 'Status do serviço', width: '15%', overflow: 'visible',
            renderCell: (row: any) => {
                switch (row.status) {
                    case "success":
                        return (
                            <span style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingRight: '70px' }}>
                                <LcTooltip content="No ar" position="center" trigger="hover">
                                    <i className='lci lci-dot-check'
                                        style={
                                            { fontSize: '24px', paddingLeft: '50px', color: "#008000" }
                                        }
                                    />
                                </LcTooltip>
                            </span>
                        )
                    case "warning":
                        return (
                            <span style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingRight: '70px' }}>
                                <LcTooltip content="Aviso" position="center" trigger="hover">
                                    <i className='lci lci-dot-i text-warning'
                                        style={
                                            { fontSize: '24px', paddingLeft: '50px' }
                                        }
                                    />
                                </LcTooltip>
                            </span >
                        )
                    case "danger":
                        return (
                            <span style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingRight: '70px' }}>
                                <LcTooltip content="Fora do ar" position="center" trigger="hover">
                                    <i className='lci lci-dot-exclamation text-danger'
                                        style={
                                            { fontSize: '24px', paddingLeft: '50px' }
                                        }
                                    />
                                </LcTooltip >
                            </span>
                        )
                    default:
                        return <i className='lci lci-dot-i text-info' />
                }
            }
        },
        {
            field: 'status', headerName: 'Mensagens', width: '20%', overflow: 'visible',
            renderCell: (row: any) => {
                if (row.status == 'danger' || row.status == 'warning') {
                    return (
                        <>
                            <span style={{ width: '100%', display: 'flex', alignItems: "center", flexDirection: 'row' }}>
                                <LcIconLink icon="lci lci-information-circle" size='small' tooltip="Ver detalhes" onClick={() => showMessages(row)} />
                                <span className='link' onClick={() => showMessages(row)}>Aviso</span>
                            </span>
                        </>
                    )
                }
            }
        }
    ]

    const showMessages = (item: any) => {
        setSelectedRow(item);
        setIsLoadingModal(true);
        setSideModal(!sideModal);
        const service = new ManageOfficeStatusService({})
        service.GetAllMessagesByWorkLoad(item.workloadDisplayName)
            .then(r => {
                setMessagesSelected(r.data);
                setIsLoadingModal(false);
            });
    }

    const statusHealthCheck = (row: any) => {
        return row.status === 'success' ? '#008000' : row.status;
    }


    return (
        <LcLoading loading={isLoading} loadingType='Helix'>
            {
                records && records.length > 0 ?
                <LcInfiniteTable
                    rows={records}
                    columns={columnsInf}
                    size={records.length}
                    hidePagination
                    fontSize='medium'
                    status={statusHealthCheck}
                    density={"medium"}
                />
                : <LcNoData/>
            }
            <PPModal title={`Mensagens: ${selectedRow.workloadDisplayName}`} visible={sideModal} onClose={() => {setSideModal(false);setMessagesSelected([])}}>
                <LcLoading loading={isLoadingModal} label="Carregando mensagens...">
                    {
                        (messagesSelected === null || messagesSelected.length === 0) &&
                        <div>
                            <h4>
                                Não há dados disponíveis
                            </h4>
                        </div>
                    }
                    {
                        (!(messagesSelected == null || messagesSelected.length === 0)) && messagesSelected.map((item: any) => {
                            return (
                                <div key={item.id}>
                                    <div style={{ marginBottom: '1.5rem' }}>
                                        {`${formatDate(item.lastModifiedDateTime)} ${formatHour(item.lastModifiedDateTime)}`}
                                    </div>
                                    <Typography style={{ whiteSpace: "pre-line" }}>
                                        {`${item.impactDescription}`}
                                    </Typography>
                                    <hr></hr>
                                </div>
                            )
                        })
                    }
                </LcLoading>
            </PPModal>
        </LcLoading>
    )
}
