import React, {
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import Layout from '../../../components/Layout/Layout';
import {
    CostCenterService,
    FilterDashboard,
    FinOpsPivotDataResult
} from '../../../services/costCenter/costCenterService';
import LcLoading from '../../../components/Generic/LcLoading';
import { useDispatch, useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import './index.css';
import PivotTableWebix, {
    isValidFilter,
    isValidStructure
} from '../../../components/webix';
import moment from 'moment';
import { useQuery } from 'react-query';
import LcNoData from '../../../components/Generic/LcNoData';
import {
    PiCalendarBlankLight,
    PiCalendarPlusLight,
    PiCloudArrowDownLight
} from 'react-icons/pi';
import { AnalyticalCostCenterState } from '../../../store/reducers/analyticalReducer';
import { useLocation } from 'react-router-dom';
import { AiOutlineAppstore, AiOutlineAppstoreAdd } from "react-icons/ai"
const BASE_FIELDS = [
    { id: 'Srv', value: 'Serviço', type: 'text' },
    { id: 'Prv', value: 'Provedor', type: 'text' },
    { id: 'Ano', value: 'Ano', type: 'number' },
    { id: 'Mes', value: 'Mês', type: 'number' },
    { id: 'Dia', value: 'Dia', type: 'number' },
    { id: 'Cdc', value: 'Centro de Custo', type: 'text' },
    { id: 'Ctr', value: 'Contrato', type: 'text' },
    { id: 'Rsg', value: 'Grupo', type: 'text' },
    { id: 'Rec', value: 'Recurso', type: 'text' }
];

function groupByMesAnoAndExtract(data: FinOpsPivotDataResult[]): { Mes: number; Ano: number }[] {
    const resultMonthYear: { Mes: number; Ano: number }[] = [];
    data.reduce((acc, item) => {
        const key = `${item.Ano}-${item.Mes}`;
        if (!acc[key]) {
            acc[key] = [];
        }
        const exists = acc[key].some(
            (x: { Mes: number; Ano: number }) => x.Mes === item.Mes && x.Ano === item.Ano
        );
        if (!exists) {
            acc[key].push({ Mes: item.Mes, Ano: item.Ano });
            resultMonthYear.push({ Mes: item.Mes, Ano: item.Ano });
        }
        return acc;
    }, {} as Record<string, { Mes: number; Ano: number }[]>);
    return resultMonthYear;
}

const convertToCSV = (objArray: any) => {
    const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let header = '';

    for (let i = 0; i < array.length; i++) {
        let line = '';
        for (const index in array[i]) {
            if (header.split(';').indexOf(index) === -1) {
                header += index + ';';
            }
            if (line !== '') line += ';';
            line += array[i][index];
        }
        str += line + '\r\n';
    }
    return header.slice(0, -1) + '\r\n' + str;
};

const FinOpsAnalyticDashBoard: React.FC = () => {
    const location = useLocation();
    const analytical = useSelector<RootState, AnalyticalCostCenterState>(
        state => state.analytical
    );
    const local = document.location.pathname;
    const user = useSelector<RootState, UserState>(state => state.user);
    const userPeriodSelected = user.PeriodSelection ? user.PeriodSelection[local] : undefined;
    const dispatch = useDispatch();

    // Estado que controla a exibição de Margem
    const [showMargin, setShowMargin] = useState<boolean>(false);

    // Estado para acumular períodos
    const [accumulate, setAccumulate] = useState<boolean>(false);

    // Estado de loading local (além do isLoading do React Query)
    const [loading, setLoading] = useState<boolean>(true);

    // Estado inicial de datePack é definido sem precisar de useEffect
    const [datePack, setDatePack] = useState<{
        month: string;
        year: string;
        clientGroupId: number | undefined;
        refresh: string;
        accumulate: boolean;
    }>(() => {
        const dateR = new Date();
        dateR.setDate(dateR.getDate() - 1);
        const currentMonth = (dateR.getMonth() + 1).toString();
        const currentYear = dateR.getFullYear().toString();

        return {
            month: userPeriodSelected ? Number(userPeriodSelected.fields[0].value.split('-')[1]).toString() : currentMonth,
            year: userPeriodSelected ? userPeriodSelected.fields[0].value.split('-')[0] : currentYear,
            clientGroupId: user.ClientGroupSelected,
            refresh: Math.random().toString(),
            accumulate: false
        };
    });

    const [fields, setFields] = useState<any[]>();
    const [selectedView, setSelectedView] = useState<boolean>(
        !!localStorage.getItem('visionSelected')
    );

    const [refreshPivot, setRefreshPivot] = useState<number>(0);
    const [periodoValue, setPeriodoValue] = useState<number>(1);

    // Estrutura base do pivot
    const [pivotStructure, setPivotStructure] = useState<any>({
        rows: ['Prv', 'Acn', 'Srv'],
        columns: accumulate ? ['Ano', 'Mes'] : ['Mes'],
        values: showMargin
            ? [
                { name: 'Cst', operation: ['sum'] },
                { name: 'Tax', operation: ['sum'] },
                { name: 'Mrg', operation: ['sum'] },
                { name: 'Vbr', operation: ['sum'] }
            ]
            : [{ name: 'Vbr', operation: ['sum'] }],
        filters: [
            { name: 'Prv' },
            { name: 'Acc' },
            { name: 'Cdc' },
            { name: 'Ctr' },
            { name: 'Srv' },
            { name: 'Cat' },
            { name: 'Mes' },
            { name: 'Dia' },
            { name: 'Rec' }
        ]
    });

    /**
     * Função principal de carregamento dos dados.
     * Mantida como useCallback para ser usada no `useQuery`.
     */
    const loadData = useCallback(async (): Promise<FinOpsPivotDataResult[]> => {
        try {

            setLoading(true);
            const costCenterService = new CostCenterService({});
            const filter: FilterDashboard = {
                clientGroupId: Number(
                    user.ClientGroupSelected ? user.ClientGroupSelected : '0'
                ),
                costCenterID: 0,
                contractID: 0,
                componentName: '',
                productName: '',
                serviceCode: '',
                serviceName: '',
                provedor: '',
                ano: Number(datePack.year),
                mes: Number(datePack.month),
                dia: 0,
                gtmId: 0,
                user: user.UserId,
                reportType: '1',
                periodo: periodoValue
            };

            const responseAData = await costCenterService.GetDataDetails(filter);
            const response = responseAData as FinOpsPivotDataResult[];

            // Se já tínhamos dados e o usuário pediu para acumular
            // filtra duplicados e concatena
            if (pivotData && pivotData.length > 0 && accumulate) {
                const dataPivotDates = groupByMesAnoAndExtract(pivotData);
                const dataResult = [
                    ...response.filter(
                        c =>
                            !dataPivotDates.some(
                                x => Number(x.Ano) === Number(c.Ano) && x.Mes === c.Mes
                            )
                    ),
                    ...pivotData
                ];
                return costCenterService.OrderDataDetailsAnalitico(dataResult);
            }

            return response;
        } catch (error: any) {
            if (error && error.toString().includes('ERR_CERT_AUTHORITY_INVALID')) {
                alert(error);
            }
            console.error(error);
            return [];
        }
    }, [periodoValue, accumulate, datePack, user.ClientGroupSelected, user.UserId]);

    /**
     * useQuery para buscar dados. Agora não precisamos chamar `refetch` 
     * em `useEffect` — a query refaz sempre que uma das dependências mudar.
     */
    const {
        data: pivotData,
        isLoading: loadingpivotData,
        isRefetching: isRefetchingKPIs
    } = useQuery<FinOpsPivotDataResult[]>(
        [
            'pivotData',
            datePack.month,
            datePack.year,
            user.ClientGroupSelected,
            user.UserId,
            accumulate,
            user.refreshFlag,
            periodoValue
        ],
        loadData,
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            staleTime: 7200000,
            onSuccess: (response) => {
                // Monta campos dinamicamente
                const fieldBase = [...BASE_FIELDS];
                // Sempre empurra "Valor"
                fieldBase.push({ id: 'Vbr', value: 'Valor', type: 'number' });

                // Se for margem
                if (response[0] && response[0].Smg === true) {
                    setShowMargin(true);
                    fieldBase.push({ id: 'Tag', value: 'Tags', type: 'text' });
                    fieldBase.push({ id: 'Cat', value: 'Categoria', type: 'text' });
                    fieldBase.push({ id: 'Sub', value: 'SubCategoria', type: 'text' });
                    fieldBase.push({ id: 'Met', value: 'Metrica', type: 'text' });
                    fieldBase.push({ id: 'Uni', value: 'Unidade', type: 'text' });
                    fieldBase.push({ id: 'Bdg', value: 'Orçamento', type: 'number' });
                    fieldBase.push({ id: 'Ccr', value: 'Regra CdeC', type: 'text' });
                    fieldBase.push({ id: 'Ccrd', value: 'Regra CdeC Nome', type: 'text' });
                    fieldBase.push({ id: 'Csq', value: 'Qtd', type: 'number' });
                    fieldBase.push({ id: 'Cst', value: 'Custo', type: 'number' });
                    fieldBase.push({ id: 'Mrg', value: 'Margem', type: 'number' });
                    fieldBase.push({ id: 'Tax', value: 'Taxa', type: 'number' });
                    fieldBase.push({ id: 'Dat', value: 'Date', type: 'text' });
                    fieldBase.push({ id: 'Acc', value: 'Assinatura', type: 'text' });
                    fieldBase.push({ id: 'Acn', value: 'Nome Assinatura', type: 'text' });
                    fieldBase.push({ id: 'Rgn', value: 'Região', type: 'text' });
                    fieldBase.push({ id: 'Rci', value: 'Id do Recurso', type: 'text' });
                } else if (response[0] && response[0].Spt === true) {
                    // Suporte?
                    fieldBase.push({ id: 'Pct', value: 'Porcento', type: 'number' });
                } else {
                    fieldBase.push({ id: 'Bdg', value: 'Orçamento', type: 'number' });
                    fieldBase.push({ id: 'Tag', value: 'Tags', type: 'text' });
                    fieldBase.push({ id: 'Cat', value: 'Categoria', type: 'text' });
                    fieldBase.push({ id: 'Sub', value: 'SubCategoria', type: 'text' });
                    fieldBase.push({ id: 'Met', value: 'Metrica', type: 'text' });
                    fieldBase.push({ id: 'Uni', value: 'Unidade', type: 'text' });
                    fieldBase.push({ id: 'Acc', value: 'Assinatura', type: 'text' });
                    fieldBase.push({ id: 'Ccr', value: 'Regra CdeC', type: 'text' });
                    fieldBase.push({ id: 'Ccrd', value: 'Regra CdeC Nome', type: 'text' });
                    fieldBase.push({ id: 'Dat', value: 'Date', type: 'text' });
                    fieldBase.push({ id: 'Csq', value: 'Qtd', type: 'number' });
                    fieldBase.push({ id: 'Acn', value: 'Nome Assinatura', type: 'text' });
                    fieldBase.push({ id: 'Rgn', value: 'Região', type: 'text' });
                    fieldBase.push({ id: 'Rci', value: 'Id do Recurso', type: 'text' });
                }

                setFields([...fieldBase]);

                // Quando finalizar com sucesso, pode setar loading para false
                setLoading(false);
            },
            // Se quiser comportamento otimista, pode manter:
            optimisticResults: true
        }
    );

    /**
     * Ajuste do loading local baseado nos status do React Query.
     * Caso queira depender somente de `loadingpivotData || isRefetchingKPIs`,
     * pode remover este estado local e usar diretamente esses valores.
     */
    React.useEffect(() => {
        setLoading(loadingpivotData || isRefetchingKPIs);
    }, [loadingpivotData, isRefetchingKPIs]);

    // Função para ajustar a estrutura
    const updateStructure = useCallback(
        (name: string, value: string, abbreviation: string, initialStructure: any) => {
            const currentPath = location.pathname;

            const updateFilters = (additionalFilters: any[], excludeNames: string[]) => {
                return [
                    ...additionalFilters,
                    ...initialStructure.filters.filter(
                        (item: any) => !excludeNames.includes(item.name)
                    )
                ];
            };
            const persistStructure = (structure: any) => {
                localStorage.setItem(
                    `pivotConfig-${currentPath}-${user.ClientGroupSelected}-Analytical`,
                    JSON.stringify(structure)
                );
                dispatch({ type: 'RESET_ANALYTICAL' });
            };

            const isCostCenterBudget = analytical.typeParam === 'costcenter_budget';
            let newStructure: any = {};

            if (isCostCenterBudget) {
                // Exemplo de estrutura de Cost Center Budget
                const newFilters = updateFilters(
                    [{ name: abbreviation, value: { includes: [value] } }],
                    [abbreviation]
                );
                newStructure = {
                    rows: ['Cdc'],
                    columns: initialStructure.columns,
                    filters: newFilters,
                    groupBy: initialStructure.groupBy,
                    values: [
                        { name: 'Vbr', operation: 'sum' },
                        { name: 'Bdg', operation: 'max' }
                    ]
                };
            } else {
                // Outras lógicas
                const newFilters = updateFilters(
                    [{ name: abbreviation, value: { includes: [value] } }],
                    [abbreviation]
                );
                newStructure = {
                    rows: [
                        abbreviation,
                        ...initialStructure.rows.filter((item: any) => item !== abbreviation)
                    ],
                    columns: initialStructure.columns,
                    filters: newFilters,
                    groupBy: initialStructure.groupBy,
                    values: initialStructure.values
                };
            }
            persistStructure(newStructure);
            return newStructure;
        },
        [analytical.typeParam, dispatch, location.pathname, user.ClientGroupSelected, analytical.hasConstCenterChildren]
    );

    /**
     * Lógica para combinar os filtros do Redux (analytical) com a estrutura do pivot
     */
    const reduxFilter = useMemo(
        () => (initialStructure: any, pivotStructureBase: any) => {
            const resetAndUpdate = (updates: Array<[string, string | undefined, string]>) => {
                let structure = JSON.parse(JSON.stringify(pivotStructureBase)); // Deep clone
                updates.forEach(([abbr, value, field]) => {
                    if (value !== undefined && value !== '') {
                        structure = updateStructure(abbr, value, field, structure);
                    }
                });
                return structure;
            };

            const singleFieldUpdates: Array<[string, string | undefined, string]> = [
                ['Rec', analytical.resource, 'Rec'],
                ['Srv', analytical.service, 'Srv'],
                ['Rgn', analytical.region, 'Rgn'],
                ['Acn', analytical.account, 'Acn'],
                ['Cdc', analytical.costcenter, 'Cdc']
            ];

            const combinedUpdates: { [key: string]: Array<[string, string | undefined, string]> } =
            {
                service_costcenter: [
                    ['Cdc', analytical.costcenter, 'Cdc'],
                    ['Srv', analytical.service, 'Srv']
                ],
                costcenter_service: [
                    ['Srv', analytical.service, 'Srv'],
                    ['Cdc', analytical.costcenter, 'Cdc']
                ],
                service_resource: [
                    ['Rec', analytical.resource, 'Rec'],
                    ['Srv', analytical.service, 'Srv']
                ],
                costcenter_resource: [
                    ['Rec', analytical.resource, 'Rec'],
                    ['Cdc', analytical.costcenter, 'Cdc']
                ],
                account_service: [
                    ['Srv', analytical.service, 'Srv'],
                    ['Acn', analytical.account, 'Acn']
                ],
                region_service: [
                    ['Srv', analytical.service, 'Srv'],
                    ['Rgn', analytical.region, 'Rgn']
                ]
            };

            if (!analytical.typeParam) {
                // Caso não tenha typeParam, aplica singleFieldUpdates
                initialStructure = resetAndUpdate(
                    singleFieldUpdates.filter(([, value]) => value && value !== '')
                );
            } else if (combinedUpdates[analytical.typeParam]) {
                // Se for alguma das combinações
                initialStructure = resetAndUpdate(combinedUpdates[analytical.typeParam]);
            } else if (
                analytical.typeParam === 'costcenter_budget' &&
                analytical.costcenter != null
            ) {
                initialStructure = resetAndUpdate([['Cdc', analytical.costcenter, 'Cdc']]);
            }

            return initialStructure;
        },
        [analytical, updateStructure]
    );

    /**
     * Efetua ajustes na estrutura do pivot depois que os dados são obtidos
     * (e quando `fields`, `pivotData` ou outras dependências são carregadas).
     */
    React.useEffect(() => {
        if (!pivotData || pivotData.length === 0 || !fields) return;

        const pivotStructureBase = {
            rows: ['Prv', 'Acn', 'Srv'],
            columns: accumulate ? ['Ano', 'Mes'] : ['Mes'],
            values: showMargin
                ? [
                    { name: 'Cst', operation: ['sum'] },
                    { name: 'Tax', operation: ['sum'] },
                    { name: 'Mrg', operation: ['sum'] },
                    { name: 'Vbr', operation: ['sum'] }
                ]
                : [{ name: 'Vbr', operation: ['sum'] }],
            filters: [
                { name: 'Prv' },
                { name: 'Acc' },
                { name: 'Cdc' },
                { name: 'Ctr' },
                { name: 'Srv' },
                { name: 'Cat' },
                { name: 'Mes' },
                { name: 'Dia' },
                { name: 'Rec' }
            ]
        };

        const currentPath = location.pathname;
        const clientGroupSelected = sessionStorage.getItem('ClientGroupSelected');
        const storedConfigKey = `pivotConfig-${currentPath}-${clientGroupSelected}-Analytical`;
        let storedConfig = localStorage.getItem(storedConfigKey);

        let savedConfig = storedConfig ? JSON.parse(storedConfig) : null;
        if (savedConfig && !isValidStructure(savedConfig, fields)) {
            savedConfig = null;
        }

        if (savedConfig?.filters) {
            savedConfig.filters = savedConfig.filters.filter((filter: any) =>
                isValidFilter(filter, pivotData)
            );
        }

        let initialStructure = savedConfig || pivotStructureBase;

        // Verifica se há valores não vazios vindos do Redux (analytical)
        const hasNonEmptyValues = Object.entries(analytical).some(([key, value]) => {
            return (
                !['id', 'hasConstCenterChildren'].includes(key) &&
                value !== '' &&
                value !== null &&
                value !== undefined
            );
        });

        // Se tiver valores do Redux, sobrescreve a estrutura
        if (hasNonEmptyValues) {
            setSelectedView(false);
            localStorage.removeItem('visionSelected');
            initialStructure = reduxFilter(initialStructure, pivotStructureBase);
        }

        setPivotStructure(initialStructure);
    }, [
        analytical,
        location.pathname,
        pivotData,
        fields,
        reduxFilter,
        accumulate,
        showMargin
    ]);

    /**
     * Se o usuário clicar em "usar visão salva", a gente faz refresh da pivot
     * ou se `refreshPivot` mudar.
     */
    React.useEffect(() => {
        if (!pivotData || pivotData.length === 0 || !fields || refreshPivot === 0) return;
        const selectedViewSaved = localStorage.getItem('visionSelected');
        let selectedViewConfig = selectedViewSaved ? JSON.parse(selectedViewSaved) : null;

        if (selectedViewConfig && isValidStructure(selectedViewConfig, fields)) {
            setPivotStructure(selectedViewConfig);
            setRefreshPivot(0);
        } else {
            setPivotStructure({
                rows: ['Prv', 'Acn', 'Srv'],
                columns: accumulate ? ['Ano', 'Mes'] : ['Mes'],
                values: showMargin
                    ? [
                        { name: 'Cst', operation: ['sum'] },
                        { name: 'Tax', operation: ['sum'] },
                        { name: 'Mrg', operation: ['sum'] },
                        { name: 'Vbr', operation: ['sum'] }
                    ]
                    : [{ name: 'Vbr', operation: ['sum'] }],
                filters: [
                    { name: 'Prv' },
                    { name: 'Acc' },
                    { name: 'Cdc' },
                    { name: 'Ctr' },
                    { name: 'Srv' },
                    { name: 'Cat' },
                    { name: 'Mes' },
                    { name: 'Dia' },
                    { name: 'Rec' }
                ]
            });
        }
    }, [accumulate, fields, pivotData, refreshPivot, showMargin]);

    /**
     * Ao mudar o período (exemplo do componente de seleção de período),
     * atualizamos o datePack. Isso por si só dispara o re-fetch via useQuery.
     */
    const onChagePeriod = (selected: any) => {
        if (selected.fields[0].name !== 'last365days') {
            const date = selected.fields[0].value; // ex: "2023-02"
            if (date.indexOf('-') > -1) {
                setPeriodoValue(1);
                setDatePack({
                    ...datePack,
                    month: date.split('-')[1],
                    year: date.split('-')[0],
                    accumulate
                });
            }
        } else {
            setPeriodoValue(12);
            setDatePack({
                ...datePack,
                accumulate
            });
        }
    };

    // Configuração de períodos
    const periodSelection = {
        periods: [{
            label: 'Este mês',
            text: 'Este mês',
            fields: [{ name: 'month', value: moment().format('YYYY-MM') }],
            default: true
        },
        {
            label: 'Mês anterior',
            text: 'Mês anterior',
            fields: [{ name: 'month', value: moment().add(-1, 'M').format('YYYY-MM') }]
        },
        {
            label: 'Dois meses atrás',
            text: 'Dois meses atrás',
            fields: [{ name: 'month', value: moment().add(-2, 'M').format('YYYY-MM') }]
        } ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [{ name: 'month', label: 'Mês', value: '' }]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };

    // Mapeia campos do objeto para as labels definidas em fields
    const transformObjectKeys = useMemo(() => {
        return (object: FinOpsPivotDataResult) => {
            if (!fields) return [];
            const transformedObject: any = {};
            Object.keys(object).forEach(key => {
                // filtra colunas desnecessárias
                if (['Dat', '_1', '_2', '_3', '_4'].includes(key)) return;
                const field = fields.find(f => f.id === key);
                if (field) {
                    transformedObject[field.value] = object[key];
                }
            });
            return transformedObject;
        };
    }, [fields]);

    // Download CSV
    const handleDownload = useCallback(() => {
        if (!pivotData) return;
        const jsonData: any[] = [];
        pivotData.forEach(row => {
            const line = transformObjectKeys(row);
            if (line) {
                jsonData.push(line);
            }
        });
        const csvString = convertToCSV(jsonData);
        const BOM = '\uFEFF'; // Byte Order Mark para UTF-8
        const blob = new Blob([BOM + csvString], { type: 'text/csv;charset=utf-8;' });
        const href = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = 'data.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }, [pivotData, transformObjectKeys]);

    return (
        <Layout
            functionsGeneric={
                user.IsAdm
                    ? [
                        {
                            icon: !accumulate ? (
                                <AiOutlineAppstore />
                            ) : (
                                <AiOutlineAppstoreAdd />
                            ),
                            onClick: () => {
                                setAccumulate(!accumulate);
                                // Se quiser já alterar no datePack também:
                                setDatePack(prev => ({ ...prev, accumulate: !accumulate }));
                            },
                            tooltip: 'Acumular períodos'
                        },
                        {
                            icon: <PiCloudArrowDownLight />,
                            onClick: () => {
                                handleDownload();
                            },
                            tooltip: 'Download CSV'
                        }
                    ]
                    : [
                        {
                            icon: !accumulate ? (
                                <AiOutlineAppstore />
                            ) : (
                                <AiOutlineAppstoreAdd />
                            ),
                            onClick: () => {
                                setAccumulate(!accumulate);
                                // Se quiser já alterar no datePack também:
                                setDatePack(prev => ({ ...prev, accumulate: !accumulate }));
                            },
                            tooltip: 'Acumular períodos'
                        }
                    ]
            }
            pivot
            pageTitle={'Dash'}
            colorIcon={selectedView ? '#FFBE15' : ''}
            periodSelection={periodSelection}
        >
            <LcLoading loading={loadingpivotData || isRefetchingKPIs || loading} loadingType='Helix'>
                {fields && fields.length > 0 && pivotData && pivotData.length > 0 ? (
                    <PivotTableWebix
                        idView='Analytical'
                        setSelectedView={setSelectedView}
                        key='1'
                        data={pivotData}
                        fields={fields}
                        classTable='analytical' mode="tree"
                        chart={{ scale: 'logarithmic', yAxis: { start: 0 } }}
                        structure={pivotStructure}
                        setRefreshPivot={setRefreshPivot}
                    />
                ) : (
                    <LcNoData />
                )}
            </LcLoading>
        </Layout>
    );
};

export default FinOpsAnalyticDashBoard;
