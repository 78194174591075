import api, { APIClient } from "./api";


export default class LiveWatchServiceGroupService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    RetrieveList() {
        const url = this.endpoint + `/api/ServiceGroups/GetServiceGroups`;
        return api({
            method: 'get',
            url,
        });
    }

    GetServiceGroup(id: any) {
        const url = this.endpoint + `/api/ServiceGroups/GetServiceGroup/${id}`;
        return api({
            method: 'get',
            url,
        });
    }

    GetLiveWatchResume = async () => {
        const url = this.endpoint + `/api/LiveWatch/GetLiveWatchResume`;
        return (await api({
            method: 'get',
            url,
        })).data;
    };

    GetService(id: any, serviceId: any) {
        const url = this.endpoint + `/api/ServiceGroups/${id}/GetService/${serviceId}`;
        return api({
            method: 'get',
            url,
        });
    }

    DeleteMonitoringTemplate(id: any) {
        const url = this.endpoint + `/api/ServiceGroups/DeleteMonitoringTemplate/${id}`;
        return api({
            method: 'delete',
            url,
        });
    }

    SalvarMonitoringTemplate(Template: any) {
        const url = `${this.endpoint}/api/ServiceGroups/SalvarMonitoringTemplate`;
        var response = api({
            method: 'post',
            url,
            data: Template,
        });
        return response;
    }

    SalvarService(service: any) {
        const url = `${this.endpoint}/api/ServiceGroups/SalvarService`;
        var response = api({
            method: 'post',
            url,
            data: service,
        });
        return response;
    }

    CreateServiceGroup(serviceGroup: any) {
        const url = `${this.endpoint}/api/ServiceGroups/ServiceGroup`;
        var response = api({
            method: 'post',
            url,
            data: serviceGroup,
        });
        return response;
    }

    DeleteService(id: any) {
        const url = `${this.endpoint}/api/ServiceGroups/DeleteService/${id}`;
        var response = api({
            method: 'delete',
            url
        });
        return response;
    }

    Editar(ServiceGroup: any) {
        const url = `${this.endpoint}/api/ServiceGroups/Editar`;
        var response = api({
            method: 'post',
            url,
            data: ServiceGroup
        });
        return response;
    }

    Deletar(id: any) {
        const url = `${this.endpoint}/api/ServiceGroups/Deletar?id=${id}`;
        var response = api({
            method: 'delete',
            url
        });
        return response;
    }

    public emptyRecord: any = {
        id: 0,
        name: "",
        description: "",
        services: null
    };

    public async template(record: any, disabled: boolean) {

        let item = {
            title: 'name',
            Fields: [{
                edit: true,
                row: 1,
                col: 1,
                colspan: 1,
                rowspan: 1,
                name: 'name',
                type: 'text',
                title: 'Nome',
                required: {

                    value: true,
                    required: 'Nome é obrigatório'
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    if (val && val.length > 0 && val.length < 30) {
                        return true;
                    }
                    return false;
                },
            }, {
                edit: true,
                row: 1,
                col: 2,
                colspan: 1,
                rowspan: 1,
                name: 'description',
                type: 'text',
                title: 'Descrição',
                required: {

                    value: true,
                    required: 'Descrição é obrigatório'
                },
                disabled: disabled,
                validation: function (val: string | any[]) {
                    if (val && val.length > 0 && val.length < 30) {
                        return true;
                    }
                    return false;
                },
            }]
        };

        return item;
    }
}

export class LiveWatchService extends APIClient {

    constructor(props: any) {
        super(props);
    }

    GetHistorySubgroup = async (): Promise<IncidentSubgroup[]> => {
        const url = this.endpoint + `/api/LiveWatch/history/subgroup`; // ?refresh=${Math.random()}
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    GetActiveIncidents = async (): Promise<Incident[]> => {
        const url = this.endpoint + `/api/LiveWatch/incidents/active`; // ?refresh=${Math.random()}
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    GetGroupedActiveIncidents = async (): Promise<GroupedIncidents> => {
        const url = this.endpoint + `/api/LiveWatch/incidents/active/grouped`; //?refresh=${Math.random()}
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    GetHistoryIncidents = async () => {
        const url = this.endpoint + `/api/LiveWatch/history/incidents`; // ?refresh=${Math.random()}
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    GetHostsResume = async (): Promise<HostsResume> => {
        const url = this.endpoint + `/api/LiveWatch/hosts/resume`; // ?refresh=${Math.random()}
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    GetHostsDetailed = async () => {
        const url = this.endpoint + `/api/LiveWatch/hosts/detailed`; // ?refresh=${Math.random()}
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    GetHostsSubgroup = async () => {
        const url = this.endpoint + `/api/LiveWatch/hosts/subgroups`; // ?refresh=${Math.random()}
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    GetHostsTriggers = async () => {
        const url = this.endpoint + `/api/LiveWatch/hosts/triggers`; // ?refresh=${Math.random()}
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    GetHostsPivot = async (): Promise<HostPivot[]> => {
        const url = this.endpoint + `/api/LiveWatch/hosts/pivot`; // ?refresh=${Math.random()}
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    GetHostsItem = async (data: HostItemRequest): Promise<HostPivotItem[]> => {
        const url = this.endpoint + `/api/LiveWatch/hosts/item`;
        return (await api({
            method: 'post',
            url,
            data
        })).data;
    }

    GetHostsAvailability = async (): Promise<HostsAvailabilityResponse> => {
        const url = this.endpoint + `/api/LiveWatch/hosts/availability`;// ?refresh=${Math.random()}
        return (await api({
            method: 'get',
            url,
        })).data;
    }

    GetHostDetailedMetrics = async (data: HostDetailedMetricsRequest): Promise<HostDetailedMetric[]> => {
        const url = this.endpoint + `/api/LiveWatch/hosts/metrics/detailed`;
        return (await api({
            method: 'post',
            url,
            data
        })).data;
    }

}

// [Label, BGColor, TextColor]
export const liveWatchSeverityDic = [
    ['Não classificado', 'var(--icon-disabled)', 'var(--text-default)'],
    ['Informação', 'var(--color-secondary-cyan)', 'var(--text-default)'],
    ['Aviso', 'var(--color-secondary-cyan)', 'var(--text-default)'],
    ['Moderado', 'var(--status-yellow)', 'var(--text-default)'],
    ['Alta', '#FFA400', 'var(--background-white)'],
    ['Crítico', 'var(--status-red)', 'var(--background-white)'],
]

// [Label, TextColor]
export const liveWatchStatusDic = {
    'Unresolved': ['Em alerta', 'var(--status-red)'],
    'Resolved': ['Resolvido', 'var(--status-green)'],
}
export interface Incident {
    hostname: string
    started_at: string,
    severity: number,
    ticket: string,
    trigger_name: string,
    hostgroups: { name: string }[],
    hostgroupNames?: string
}

export interface IncidentHistoric {
    event_time: string;
    hostname: string;
    severity: string;
    acknowledged: string;
    problem_description: string;
}
export interface GroupedIncidents {
    hostgroups: Record<string, number>;
    // severities: Record<"0" | "1" | "2" | "3" | "4" | "5", number>;
    severities: Record<"2" | "3" | "4" | "5", number>;
}

export interface HostsResume {
    total: number,
    enabled: number,
    disable: number
}

export interface HostsAvailability {
    eventid: string;
    status: string;
    resolved_at: string | null;
    started_at: string;
    duration: string;
    hostname: string;
    host_id: number;
    severity: number;
    trigger_id: string;
    trigger_name: string;
    unavailability_percentage: number;
    item_id: string;
    item_type: number;
}

export interface HostsAvailabilityResponse {
    problems: HostsAvailability[];
    total_unavailability: number;
}

export interface HostPivot {
    hostgroup: string | null;
    host: string;
    "item.id": string;
    "item.type": string;
    "item.name": string;
    "item.lastvalue": string;
    "item.units": string;
}

export interface HostPivotItem {
    itemid: string;
    clock: string;
    value: string;
    ns: string;
}

export interface HostItemRequest {
    item_id: number;
    value_type: number;
}

export interface HostDetailedMetricsRequest {
    host_id: string;
    start_date: string;
    end_date: string;
}

export interface IncidentSubgroup {
    subgroup: string;
    incidents: IncidentHistoric[];
}

export interface HostDetailedMetric {
    host: string;
    proxy: string;
    status: number;
    item: {
        id: number;
        name: string;
        type: {
            id: number;
            name: string;
        };
        measurement: Record<string, {
            value: string;
            unit: string;
        }>;
        trends: {
            timestamp: string;
            minimum: {
                original: {
                    value: string;
                    unit: string;
                };
                calculated: {
                    value: string;
                    unit: string;
                };
            };
            average: {
                original: {
                    value: string;
                    unit: string;
                };
                calculated: {
                    value: string;
                    unit: string;
                };
            };
            maximum: {
                original: {
                    value: string;
                    unit: string;
                };
                calculated: {
                    value: string;
                    unit: string;
                };
            };
            count: number;
        }[];
    };
}