// tooltipConfig.ts
export const defaultTooltipConfig = {    
    backgroundColor: 'var(--background-lightest)',
    borderWidth: 0,
    textStyle: {
        fontSize: 11,
        fontFamily: 'Ubuntu',
        color: 'var(--text-default)',
        fontWeight: 'normal',
    },
};