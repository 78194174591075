import React from 'react';
import './index.css';
import WeeklyCostEstimateChart from '../WeeklyEstimateCharts/Cost';
import { AutomationFormData, AutomationResourceDetails } from '../../../../services/automations/automationsModel';
import AutomationsService from '../../../../services/automations/automationsService';
import { ResourcePriceInfo } from './ResourcePriceInfo';
import { CostSummary } from './CostSummary';
import { CostArrow } from './CostArrow';
import { filterResourceInstanceTypeInfo } from './ResourceFilters'

// Define the TypeScript interface
interface ResourcePriceUnit {
    atual: boolean;
    instanceType: string;
    currencyCode: string;
    unitPrice: number;
    unit: string;
    region: string;
}

// Define the component props
interface ResourcePriceListProps {
    resources: ResourcePriceUnit[];
    daysOn: number;
    resourceDetails: AutomationResourceDetails;
    formData: AutomationFormData;
    usingResize: boolean;
}

// Define the ResourcePriceList component
export const AutomationCostDetail: React.FC<ResourcePriceListProps> = ({ resources, resourceDetails, formData, usingResize }) => {
    const automationsService = new AutomationsService({});

    const currentInstanceHourCost = resources[1]?.unitPrice ?? 0;
    const desiredInstanceHourCost = resources[0]?.unitPrice ?? 0;

    const currentResourceInstanceType = filterResourceInstanceTypeInfo(formData.resource.value as string, resourceDetails, automationsService);
    const desiredResourceInstanceType = resourceDetails.instanceTypes.find(it => it.name === formData.instanceType.value as string)! ?? { ...automationsService.emptyAutomationInstanceType };

    const currentInstanceWeekCost = resources[1]?.unitPrice ? resources[1]?.unitPrice * 24 * 7 : 0;
    const desiredInstanceWeekCost = resources[0]?.unitPrice ? resources[0]?.unitPrice * 24 * 7 : 0;

    const currentInstance = { size: currentResourceInstanceType.name, value: currentInstanceWeekCost };
    const desiredInstance = { size: formData.instanceType.label as string, value: desiredInstanceWeekCost };

    const valueChange = currentInstance.value < desiredInstance.value ? 'up' :
        (currentInstance.value > desiredInstance.value ? 'down' : 'same');
    const changeDiff = valueChange === 'up' ? desiredInstance.value - currentInstance.value :
        (valueChange === 'down' ? currentInstance.value - desiredInstance.value : 0);
    const changePerc = (valueChange === 'up' ? changeDiff / desiredInstance.value * 100 :
        (valueChange === 'down' ? changeDiff / currentInstance.value * 100 : 0)).toLocaleString( 'pt-BR', 
            { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 } );

    return (
        <div className="row w-100p">
            <div style={{ width: usingResize ? '40%' : '60%', margin: '0rem 1rem 0rem 1rem' }} className='resize-review-grid'>
                <div className='resize-review-col'>
                    <span>&nbsp;</span>
                    <span className='resize-review-sizeline'>&nbsp;</span>
                    <span>vCPU</span>
                    <span>Memória (GB)</span>
                    <span>Disco de dados máximos</span>
                    <span>Custo por hora estimado</span>
                    <span>Custo semanal estimado</span>
                </div>
                <ResourcePriceInfo
                    instanceType={currentResourceInstanceType}
                    instanceHourCost={currentInstanceHourCost}
                    instanceWeekCost={currentInstanceWeekCost}
                    usingResize={usingResize}
                />
                {
                    usingResize && <ResourcePriceInfo
                        instanceType={desiredResourceInstanceType}
                        instanceHourCost={desiredInstanceHourCost}
                        instanceWeekCost={desiredInstanceWeekCost}
                        usingResize={usingResize}
                    />
                }
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", width: "60%", }}>
                <div style={{ width: usingResize ? "70%" : "100%", height: '100%' }}>
                    <WeeklyCostEstimateChart
                        data={[currentInstance, desiredInstance]}
                    />
                </div>
                {usingResize && <CostArrow valueChange={valueChange} />}
                {usingResize &&
                    <CostSummary
                        valueChange={valueChange}
                        changeDiff={changeDiff}
                        changePerc={changePerc}
                        desiredInstanceWeekCost={desiredInstanceWeekCost}
                    />
                }
            </div>
        </div>
    );
};
