import React, { useEffect, useMemo, useState } from 'react';
import { PiDatabase } from 'react-icons/pi';
import LcLoading from '../../../Generic/LcLoading';
import IconValue from '../../Dashboard/IconValue';
import { OnOffModel } from '../../../../services/cmsPremier/cmsPremierModel';
import { CmsPremierService, DatabaseItem } from '../../../../services/cmsPremier/cmsPremierService';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store';
import { CMSEnvironmenetState } from '../../../../store/reducers/CmsEnvironmentReducer';
import { FaAws } from 'react-icons/fa';
import { SiMicrosoftazure } from 'react-icons/si';
import { UserState } from '../../../../store/reducers/userReducer';
import { Tooltip } from '@material-ui/core';
import { useQuery } from 'react-query';

interface DatabasesInfoCardProps {
    setLoading?: (loading: boolean) => void
    isHome?: boolean
    withoutBallon?: boolean
}


const columnsDatabases =  [
    {
        field: 'servidor', headerName: 'Servidor', width: "30%", overflow: 'visible', renderCell:(row: any) => {
            return(
                <div style={{ display: 'flex', alignItems: 'center', minWidth: '0', left: '0px'}}>
                    <Tooltip arrow title={row.provider !== 'awsdb' ? 'Microsoft Azure' : 'Amazon AWS'}>
                        <span className='mr-4' style={{ minWidth: '20px', paddingLeft: '4px', display: 'flex', alignItems: 'center' }}>
                            {row.provider !== 'awsdb' ? <SiMicrosoftazure size={'14px'} color='#141B4D' /> : <FaAws size={'16px'} color='#141B4D' />}
                        </span>
                    </Tooltip>
                    <span
                        className='overflowEllipsis'
                    >
                        {row.servidor}
                    </span>
                </div>
            )
        }
    },
    // {
    //     field: 'dbiResourceId', headerName: 'Id do recurso', width: "25%",
    // },
    {
        field: 'tipo', headerName: 'Tipo', width: "18%", align : 'left',renderCell: (row: any) => {
                return <Tooltip arrow title={row.tipo} >
                            <span
                                className='overflowEllipsis'
                            >
                                {row.tipo}
                            </span>
                    </Tooltip>
        }
    },
    {
        field: 'status', headerName: 'Situação', width: "20%", align: 'left',
        renderCell: (row: any) => {
            const situacaoMap = {
                'available': <p className='text-green'>Disponível</p>,
                'Ready': <p className='text-green'>Disponível</p>,
                'Online': <p className='text-green'>Online</p>,
                'stopped': <p className='text-danger'>Parado</p>,
                'starting': <p className='text'>Inicializando</p>
            };
            return situacaoMap[row.status] || row.status;
        }
    },
    {
        field: 'tipoStorage', headerName: 'Storage', width: "15%", align: 'left', renderCell: (row: any) => {
            return <Tooltip arrow title={row.tipoStorage} >
                        <span
                            className='overflowEllipsis'
                        >
                            {row.tipoStorage}
                        </span>
                </Tooltip>
        }
    },
    {
        field: 'tipoInstancia', headerName: 'Tamanho', width: "10%", align: 'left', renderCell: (row: any) => {
            return <Tooltip arrow title={row.tipoInstancia} >
                        <span
                            className='overflowEllipsis '
                        >
                            {row.tipoInstancia}
                        </span>
                </Tooltip>
        },
    },
    {
        field: 'regiao', headerName: 'Região', width: "7%", align: 'left', renderCell: (row: any) => {
            return <Tooltip arrow title={row.regiao} >
                        <span
                            className='overflowEllipsis'
                        >
                            {row.regiao}
                        </span>
                </Tooltip>
        },
    },
] 

const translationsFilter =  {
        servidor: {
            label: "Servidor",
            type: "text",
        },
        tipoStorage: {
            label: "Tipo de Storage",
            type: "multiSelect",
        },
        status: {
            label: "Situação",
            type: "multiSelect",
            values: {
                'available': "Disponível",
                'Ready': "Disponível",
                'stopped': 'Parado',
                'starting': 'Inicializando'
            }
        },
        tipoInstancia: {
            label: "Tamanho",
            type: "multiSelect",
            topDropdown: true
        },
        regiao: {
            label: "Região",
            type: "multiSelect",
            topDropdown: true
        },
    };
export const DatabasesInfoCard: React.FC<DatabasesInfoCardProps> = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const cmsEnvironment = useSelector<RootState, CMSEnvironmenetState>(state => state.cmsEnvironment);
    const dispatch = useDispatch();
    const {data, isLoading} = useQuery({
        queryKey: ['databases', user.ClientGroupSelected, user.refreshFlag],
        queryFn: async () => {
            const CmsService = new CmsPremierService({});
            let databasesFull= await CmsService.getDatabasesFull();
            const dataBaseList = addProviderToItems(databasesFull);
            const uniqueDatabases = removeDuplicatesByServer(dataBaseList);
            const result = uniqueDatabases.reduce(
                (acc, curr) => {
                    if (curr.dbInstanceStatus === 'available') {
                        acc.on += 1;
                    } else {
                        acc.off += 1;
                    }
                    return acc;
                },
                { on: 0, off: 0 }
            );
            return {
                databasesData: result,
                databases: uniqueDatabases
            };
        },
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10, 
        retry: 3,
        onError: (error) => {
            console.error("Error:", error);
        }
    });

    const addProviderToItems = (data: { [key: string]: any[] }) => {
        return Object.keys(data).flatMap(provider => 
            data[provider].map(item => {
            const { dbInstanceStatus, ...rest } = item;
                if(provider === 'awsdb') {
                    return {
                        provider, 
                        servidor: item.dbInstanceIdentifier,
                        status: dbInstanceStatus === 'Ready' ? 'available' : dbInstanceStatus,
                        regiao:item.availabilityZone,
                        tipo: item.engine,
                        tipoStorage: item.storageType,
                        tipoInstancia: item.dbInstanceClass,
                    };
                } else {
                    return {
                        provider,
                        status: item.situacao === 'Ready' ? 'available' : item.situacao,
                        ...rest
                    }
                }
            }))
        };

    const removeDuplicatesByServer = (array) => {
            const uniqueServers = new Map();
        
            array.forEach(item => {
                if (!uniqueServers.has(item.servidor)) {
                    uniqueServers.set(item.servidor, item);
                }
            });
        
            return Array.from(uniqueServers.values());
        }

    useEffect(() =>{
        if(cmsEnvironment.enviromentFilter === 'Databases'  && !isLoading) {
            props.setLoading && props.setLoading(true)
            let payload = {
                enviromentFilter: 'Databases',
                columns: columnsDatabases,
                rowsList: data?.databases,
                rowsListFiltered: data?.databases,
                funcitonGeneric: undefined,
                translationFilter: translationsFilter
            }
            dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
            props.setLoading && props.setLoading(false)
        }
    } ,[cmsEnvironment.enviromentFilter, data, isLoading, dispatch])
    
    const handleClickEnviroment = () => {
        let payload = {
            enviromentFilter: 'Databases',
            columns: columnsDatabases,
            rowsList: data?.databases,
            rowsListFiltered:data?.databases,
            funcitonGeneric: undefined,
            translationFilter: translationsFilter
        }
        dispatch({type:'CMS_ENVIRONMENT_FILTER', payload: payload })
        props.setLoading  && props.setLoading(false)

    } 

    return (
        <LcLoading loading={isLoading}  >
            <IconValue 
                icon={<PiDatabase size={32}  />}
                value={data?.databasesData ? `${(data?.databasesData?.on + data?.databasesData.off)}` : '0'}
                color="#34c38f"
                description={props.isHome ?'Databases': 'Ativas'}
                onClick={props.withoutBallon ? undefined : handleClickEnviroment}
                balonSelected={(cmsEnvironment.enviromentFilter === 'Databases' && !props.withoutBallon)}
            /> 
        </LcLoading>
    );
}