import React from 'react';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import ReactECharts from 'echarts-for-react';
import { SaasService } from '../../../services/saas/saasService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { useQuery } from 'react-query';

interface SaasEmailActivitiesChartModel {
    userPeriod: number
}
const getOptionsChart =(emailActivityChart) => {
    return {
        grid: {
            top: '10%',
            left: '2%',
            right: '2%',
            bottom: '0%',
            containLabel: true
        },
        color: ['#3398DB', '#B4009E', '#BAD24B', '#00B7C3', '#117D3F', '#5C2D91', '#F16323'],
        tooltip: {
            backgroundColor: '#f1f5fb',
            borderWidth: 0,
            textStyle: {
                fontSize: 11,
                fontFamily: 'Ubuntu',
                color: '#2B304F',
            },
            trigger: 'axis',
            formatter: function (params) {
                // Adding color box using item.marker and then appending series name and value
                return params.map(item => `${item.marker} ${item.seriesName}: ${item.value}`).join('<br/>');
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: emailActivityChart.labels,
            axisLabel: {
                fontSize: '10px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
        },
        yAxis: {
            type: 'value',
            scale: true,
            splitNumber: 5,
            axisLabel: {
                formatter: '{value}',
                fontSize: '10px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
            //interval: 35000
        },
        series: emailActivityChart.datasets,
    }
}
const EmailActivityChartData = (response: any) => {
    if (response.data != null) {
        let emailActivityChart = {
            labels: response.data.map((Item: any) => {
                let date = new Date(Item.reportDate);
                return date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
            }),
            datasets: [
                {
                    name: "Enviado",
                    data: response.data.map((Item: any) => {
                        return Item.send
                    }),
                    type: 'line',
                    smooth: true
                },
                {
                    name: "Recebido",
                    data: response.data.map((Item: any) => {
                        return Item.receive
                    }),
                    type: 'line',
                    smooth: true
                },
                {
                    name: "Lido",
                    data: response.data.map((Item: any) => {
                        return Item.read
                    }),
                    type: 'line',
                    smooth: true
                }
            ]
        };
        return emailActivityChart;
    } else {
        let emailActivityChart = {
            labels: [],
            datasets: []
        };
        return emailActivityChart;
    }
}

export const SaasEmailActivitiesChart: React.FC<SaasEmailActivitiesChartModel> = ({userPeriod}) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const {data: chartData, isLoading } = useQuery({
        queryKey: ['emailActivityChart', userPeriod, user.ClientGroupSelected, user.refreshFlag],
        keepPreviousData: true, 
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const saasService = new SaasService({});
            const response = await saasService.GetEmailActivityChart(userPeriod);
            const emailActivityChart = EmailActivityChartData(response);
            return getOptionsChart(emailActivityChart);
        },
        onError: (error) => {
            console.error('Erro ao carregar dados do gráfico:', error);
        }
    })

    return (
        <LcLoading loading={isLoading}>
            {chartData && Object.keys(chartData).length > 0 ?
            <ReactECharts
                className="chart-medium"
                style={{ width: '100%', height: '100%' }}
                opts={{ renderer: 'svg', locale: 'PT-br' }}
                option={chartData}
            />
            :
            <LcNoData size="small" label="Sem dados no período." />
        }
    </LcLoading>
    );
}