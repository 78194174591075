import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { PiMonitor } from 'react-icons/pi';
import LcLoading from '../../../Generic/LcLoading';
import IconValue from '../../Dashboard/IconValue';
import { useDispatch, useSelector } from 'react-redux';
import { OnOffModel } from '../../../../services/cmsPremier/cmsPremierModel';
import { RootState } from '../../../../store';
import { CMSEnvironmenetState } from '../../../../store/reducers/CmsEnvironmentReducer';
import { HostingService } from '../../../../services/hostingService';
import { UserState } from '../../../../store/reducers/userReducer';
import LcIconLink from '../../../Generic/LcIconLink';
import { useParams } from 'react-router-dom';
import { VariantType, useSnackbar } from 'notistack';
import PPModal from 'processor-plataform-ui/dist/components/PPModal';
import history from '../../../../history'
import { PPInput } from 'processor-plataform-ui';
import Confirmation from '../../../Dialog/Confirmation';
import LcInfiniteTable from '../../../Data/LcInfiniteTable';
import DialogConfirmForm from '../../Dialog/DialogConfirmForm';
import { Tooltip } from '@material-ui/core';
import { FaAws } from 'react-icons/fa';
import { SiMicrosoftazure } from 'react-icons/si';
import { Param } from '../../../../pages/hosting';
import { FormDataHeyho } from '../../../../services/Heyho/heyhoModel';
import { useQuery } from 'react-query';

interface VMsInfoCardProps {
    openRequest?: (open: boolean) => void
    SetFormDataRequest?: (form: FormDataHeyho) => void
    setLoading?: (loading: boolean) => void
    isHome?: boolean
    withoutBallon?: boolean
}

export interface HostingDetails {
    nome: string,
    hostname: string,
    situacao: string,
    tamanho: string,
    ipPublico: string,
    ipPrivado: string,
    nomePublico: string,
    assinaturaNome: string,
    so: string,
    assinaturaProvider: string,
    regiao: string,
}

const translationsFilter = {
    nome: {
        label: "Nome",
        type: "text",
    },
    assinaturaNome: {
        label: "Assinatura",
        type: "text",
    },
    tamanho: {
        label: "Tamanho",
        type: "multiSelect",
    },
    regiao: {
        label: "Região",
        type: "multiSelect",
        topDropdown: true,
    },
    // so: {
    //     label: "Sistema operacional",
    //     type: "multiSelect",
    // },
    situacao: {
        label: "Situação",
        type: "multiSelect",
        topDropdown: true,
        values: {
            'Started': 'Ligada',
            'Stopped': 'Desligada'
        }
    },
};

const columnsTableModal = [
    { field: 'nome', headerName: 'Nome', width: "85%" },
    { field: 'tamanho', headerName: 'Tamanho', width: "15%" },
];

export const VMsInfoCard: React.FC<VMsInfoCardProps> = (props) => {
    const cmsEnvironment = useSelector<RootState, CMSEnvironmenetState>(state => state.cmsEnvironment);
    const user = useSelector<RootState, UserState>(state => state.user);
    const { type } = useParams<Param>();
    const { enqueueSnackbar } = useSnackbar();
    const [typeLC, setTypeLC] = useState<string>(type === "1" ? "17" : "6");
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const [hostingViewResponses, setHostingViewResponses] = useState<any[]>([]);
    const [hostingViewResponsesFiltered, setHostingViewResponsesFiltered] = useState<any[]>([]);
    const [SelectedRow, setSelectedRow] = React.useState<any>();
    const [VMOperation, setVMOperation] = React.useState<number>();
    const [open, setOpen] = useState<boolean>(false);
    const [modalVisibleDetails, setModalVisibleDetails] = useState<boolean>(false);
    const [hostingDetails, setHostingDetails] = useState<HostingDetails>();
    const [openDelete, setOpenDelete] = React.useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [vmConfirmationName, setVMConfirmationName] = useState('');
    const [vhds, setVhds] = useState<any[]>([]);

    useEffect(() => {
        return history.listen((location) => {
            (location.pathname.toLowerCase().indexOf("0") >= 0) && setTypeLC("6");
            (location.pathname.toLowerCase().indexOf("1") >= 0) && setTypeLC("117");
        })
    }, []);


    const openVHDsLinha = useCallback((row: any) => {
        setVhds(row.vhd);
        setModalVisible(true)
    }, [])


    const deletarLinha = useCallback((row: any) => {
        let rowTemp = hostingViewResponsesFiltered.find(r => r.id === row.id);
        setSelectedRow(rowTemp);
        setOpenDelete(true);
    }, [hostingViewResponsesFiltered]);

    const functionsRow = useMemo(() => [
        {
            label: 'Listar discos',
            func: openVHDsLinha
        },
        {
            label: 'Excluir',
            func: deletarLinha
        }
    ], [openVHDsLinha, deletarLinha]);


    const ligarDesligarLinha = useCallback((row: any) => {
        let rowTemp = hostingViewResponsesFiltered.find(r => r.id === row.id);

        if (rowTemp.situacao === 'Started') {
            setSelectedRow(rowTemp);
            setVMOperation(0);
            setOpen(true);
        } else if (rowTemp.situacao === 'Stopped') {
            setSelectedRow(rowTemp);
            setVMOperation(1);
            setOpen(true);
        } else {
            enqueueSnackbar("Situação não definida", {
                variant: 'warning',
                preventDuplicate: true,
                persist: false,
            });
        }
    }, [hostingViewResponsesFiltered, enqueueSnackbar]);

    const conectarLinha = useCallback((row: any) => {
        const service = new HostingService({});
        service.Connect(
            row.hostname,
            (row.serviceName ?? row.hostname),
            row.hostname,
            (row.deploymentName ?? row.hostname),
            (row.virtualMachineName ?? row.hostname),
            row.assinaturaId,
            true,
            row.ipPublico
        );
    }, [])

    const editarLinha = useCallback((row: any) => {
        props.SetFormDataRequest && props.SetFormDataRequest({
            titulo: `Editar ${row.hostname}`,
            Descricao: `Nome da máquina (hostname):${row.hostname} \r\nTamanho (small / medium / large):  \r\nTamanho de armazenamento (GB): `,
            gtmType: typeLC
        });
        props.openRequest && props.openRequest(true);
    }, [typeLC]);

    const detalhesLinha = (row: any) => {
        setHostingDetails(row);
        setModalVisibleDetails(true);
    }

    const columnsTable = useMemo(() => {
        return [
            {
                field: 'nome', headerName: 'Nome', width: "25%",
                renderCell: (row: any) => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', minWidth: '0' }}>
                            <Tooltip arrow title={row.cloud !== 'AWS' ? 'Microsoft Azure' : 'Amazon AWS'}>
                                <span style={{ minWidth: '20px', paddingLeft: '4px', display: 'flex', alignItems: 'center' }}>
                                    {row.cloud !== 'AWS' ? <SiMicrosoftazure size={'14px'} color='#141B4D' /> : <FaAws size={'16px'} color='#141B4D' />}

                                </span>
                            </Tooltip>
                            <span
                                className='overflowEllipsis ml-4'
                            >
                                {row.nome || <i className="lci lci-divider-horizontal" />}
                            </span>
                        </div>
                    )

                }
            },
            {
                field: 'assinaturaNome', headerName: 'Assinatura', width: "20%", renderCell: (row: any) => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', minWidth: '0' }}>
                            <Tooltip arrow title={row.assinaturaNome}>
                                <span
                                    className='overflowEllipsis'
                                >
                                    {row.assinaturaNome || <i className="lci lci-divider-horizontal" />}
                                </span>
                            </Tooltip>
                        </div>
                    )
                }


                // renderCell: (row: any) =>{
                // let servico = row.assinatura && row.assinatura.length > 0 &&  row.assinatura.find(tag => tag.key.includes("servico"))
                // let gtm = row.assinatura && row.assinatura.length > 0 &&  row.assinatura.find(tag => tag.key.includes("gtm"))

                // return servico ? servico.value : gtm ? gtm.value : '-'
                // } 
            },
            {
                field: 'tamanho', headerName: 'Tamanho', width: "13%",
            },
            {
                field: 'so', headerName: 'SO', width: "20%",
            },
            {
                field: 'regiao', headerName: 'Região', width: "10%",
            },
            {
                field: 'situacao', headerName: 'Situação', width: "10%", overflow: "visible",
                renderCell: (row: any) => {
                    switch (row.situacao) {
                        case 'Started':
                            return <p className='text-green'>Ligada</p>;
                        case 'Stopped':
                            return <p className='text-danger'>Desligada</p>;
                        default:
                            return row.situacao;
                    }
                }
            },
            {
                field: 'id', headerName: 'Ações', width: '12%', overflow: "visible",
                renderCell: (row: any) => {
                    return (
                        <div className='w-100p space-between'>
                            <LcIconLink id={`ambiente_acoes_conectar_button_${row.id}`} icon="lci lci-link" size='small' tooltipPosition='right' tooltip="Conectar" onClick={() => conectarLinha(row)} />
                            <LcIconLink id={`ambiente_acoes_abrirchamado_button_${row.id}`} icon="ppi ppi-plus" size='small' tooltipPosition='right' tooltip="Abrir chamado" onClick={() => editarLinha(row)} />
                            <LcIconLink id={`ambiente_acoes_detalhes_button_${row.id}`} icon="lci lci-document-text" size='small' tooltipPosition='right' tooltip="Detalhes" onClick={() => detalhesLinha(row)} />
                            <LcIconLink id={`ambiente_acoes_desligar_button_${row.id}`} icon={row.situacao === "Started" ? "lci lci-stop" : "lci lci-play"} size='small' tooltipPosition='right' tooltip={row.situacao === "Started" ? "Desligar" : "Ligar"} onClick={() => ligarDesligarLinha(row)} />
                        </div>
                    )
                }
            },
        ];
    }, [conectarLinha, editarLinha, ligarDesligarLinha]);

    const { data, isLoading: loading, isRefetching } = useQuery({
        queryKey: ['vms', user.ClientGroupSelected, user.refreshFlag],
        queryFn: async () => {
            setIsLoading(true)
            const service = new HostingService({});
            let data = await service.GetAllVMs();
            const result = data.reduce(
                (acc, curr) => {
                    if (curr.situacao.includes("Started")) {
                        acc.on += 1;
                    } else {
                        acc.off += 1;
                    }
                    return acc;
                },
                { on: 0, off: 0 }
            );
            setHostingViewResponsesFiltered(data);
            setHostingViewResponses(data);
            return {
                vmsData: result,
                HostingViewResponses: data
            }
        },
        refetchOnWindowFocus: false,
        staleTime: 1000 * 60 * 5,
        cacheTime: 1000 * 60 * 10,
        retry: 3,
        onSuccess: (result) => {
            setHostingViewResponsesFiltered(result.HostingViewResponses);
            setHostingViewResponses(result.HostingViewResponses);
        },
        onError: (error) => {
            console.error('Error fetching VMs:', error);
        }
    });

    useEffect(() => {
        const uptadeEnviromentReducer = () => {
            let payload = {
                enviromentFilter: 'VMs',
                columns: columnsTable,
                rowsList: hostingViewResponses,
                rowsListFiltered: hostingViewResponsesFiltered,
                funcitonGeneric: functionsRow,
                translationFilter: translationsFilter
            }
            dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: payload })
            props.setLoading && props.setLoading(false)
        }

        if ((cmsEnvironment.enviromentFilter === 'VMs' || cmsEnvironment.enviromentFilter === '') && !isLoading && !loading && hostingViewResponsesFiltered.length > 0) {
            uptadeEnviromentReducer()
        }
    }, [cmsEnvironment.enviromentFilter, columnsTable, functionsRow, isLoading, loading, isRefetching, hostingViewResponses, hostingViewResponsesFiltered, user.ClientGroupSelected, user.refreshFlag])

    const handleClickEnviroment = () => {
        props.setLoading && props.setLoading(true)
        let payload = {
            enviromentFilter: 'VMs',
            columns: columnsTable,
            rowsList: hostingViewResponses || data?.HostingViewResponses,
            rowsListFiltered: hostingViewResponsesFiltered || data?.HostingViewResponses,
            funcitonGeneric: functionsRow,
            translationFilter: translationsFilter
        }
        dispatch({ type: 'CMS_ENVIRONMENT_FILTER', payload: payload })
        props.setLoading && props.setLoading(false)

    }

    const snackMessage = async (action: string, type: VariantType) => {
        enqueueSnackbar(`${action}`, {
            variant: type,
            preventDuplicate: true,
            persist: false,
            autoHideDuration: 10000

        })
    }

    const updateRowSituacao = (row: any) => {
        setIsLoading(true);

        const hostingViewResponsesTemp: any[] = hostingViewResponses;
        hostingViewResponsesTemp.map((item: any) => {
            if (item.hostname === row.hostname) {
                item.situacao = row.situacao;
            }
            return item;
        });
        setHostingViewResponses(hostingViewResponsesTemp);

        const hostingViewResponsesFilteredTemp: any[] = hostingViewResponsesFiltered;
        hostingViewResponsesFilteredTemp.map((item: any) => {
            if (item.hostname === row.hostname) {
                item.situacao = row.situacao;
            }
            return item;
        });


        setHostingViewResponsesFiltered(hostingViewResponsesTemp);
        setIsLoading(false);
    }
    const handleConfirmation = () => {
        //* Ligar e desligar por chamado
        const notNullOrEmpty = (string) => string !== undefined && string !== null && string !== "";
        const service = new HostingService({});

        //Verificar nome digitado
        if (SelectedRow && SelectedRow.hostname.trim() === vmConfirmationName.trim()) {
            let _hostname = SelectedRow.hostname.trim();
            let _serviceName = notNullOrEmpty(SelectedRow.serviceName.trim()) ? SelectedRow.serviceName.trim() : _hostname;
            let _deploymentName = notNullOrEmpty(SelectedRow.deploymentName.trim()) ? SelectedRow.deploymentName.trim() : _hostname;
            let _virtualMachineName = notNullOrEmpty(SelectedRow.virtualMachineName.trim()) ? SelectedRow.virtualMachineName.trim() : _hostname;
            let _assinatura = SelectedRow.assinaturaAcessoId;
            if (VMOperation === 1) {
                snackMessage('Operação em andamento. Isso pode levar alguns segundos. Aguarde enquanto concluímos a tarefa. Você receberá uma mensagem quando estiver pronta.', 'warning');
                setOpen(false);
                service.Ligar(_serviceName, _hostname, _deploymentName, _virtualMachineName, _assinatura)
                    .then((response) => {
                        const row = SelectedRow;
                        row.situacao = 'Started';
                        updateRowSituacao(row);
                        snackMessage('Máquina virtual ligada com sucesso.', 'success');
                        return response;
                    })
                    .catch((error) => {
                        console.error(error);
                        snackMessage(`O acionamento remoto da VM ${SelectedRow.hostname} será realizado via chamado. Redirecionando para o registro da requisição desta ação.`, 'success');
                        props.SetFormDataRequest && props.SetFormDataRequest({
                            titulo: `Ligar ${SelectedRow.hostname}`,
                            Descricao: `Solicito que VM cujo hostname é: ${SelectedRow.hostname} seja ligada`,
                            gtmType: typeLC
                        });
                        props.openRequest && props.openRequest(true)
                    })
                    .finally(() => {
                        setVMConfirmationName('');
                    });
            }

            if (VMOperation === 0) {
                snackMessage('Operação em andamento. Isso pode levar alguns segundos. Aguarde enquanto concluímos a tarefa. Você receberá uma mensagem quando estiver pronta.', 'warning');
                setOpen(false);
                service.Desligar(_serviceName, _hostname, _deploymentName, _virtualMachineName, _assinatura)
                    .then((response) => {
                        const row = SelectedRow;
                        row.situacao = 'Stopped';
                        updateRowSituacao(row);
                        snackMessage('Máquina virtual desligada com sucesso.', 'success');
                        return response;
                    })
                    .catch((error) => {
                        console.error(error);
                        snackMessage(`O desligamento remoto da VM ${SelectedRow.hostname} será realizado via chamado. Redirecionando para o registro da requisição desta ação.`, 'success');
                        props.SetFormDataRequest && props.SetFormDataRequest({
                            titulo: `Desligar ${SelectedRow.hostname}`,
                            Descricao: `Solicito que VM cujo hostname é: ${SelectedRow.hostname} seja desligada`,
                            gtmType: typeLC
                        });
                        props.openRequest && props.openRequest(true)
                        setVMConfirmationName('');
                    })
                    .finally(() => {
                    });
            }
        } else {
            setOpen(false);
            setVMConfirmationName('');
            enqueueSnackbar('O nome do host da máquina virtual digitado não confere com o selecionado', {
                variant: 'warning',
                preventDuplicate: true,
                persist: false
            })
        }


    }
    const handleConfirmCloseDelete = (confirm: boolean) => {
        if (confirm === true) {
            props.SetFormDataRequest && props.SetFormDataRequest({
                titulo: `Deletar ${SelectedRow.hostname}`,
                Descricao: ``,
                gtmType: typeLC
            });
            props.openRequest && props.openRequest(true)
        }
        setOpenDelete(false);
    };

    return (
        <>
            <LcLoading loading={isLoading || loading}  >
                <IconValue
                    icon={<PiMonitor size={props.isHome ? 44 : 32} />}
                    value={data?.vmsData ? `${data?.vmsData?.on}/${data?.vmsData?.off + data?.vmsData?.on}` : '0/0'}
                    description={props.isHome ? 'VMs' : 'Ativas/Total'}
                    cssClass={(!props.isHome || !props.withoutBallon) ? 'withHeaderButton' : ''}
                    color="#8C40E3"
                    onClick={props.withoutBallon ? undefined : handleClickEnviroment}
                    balonSelected={(cmsEnvironment.enviromentFilter === 'VMs' && !props.withoutBallon) ? true : false}
                />
            </LcLoading>
            <PPModal visible={modalVisibleDetails} title="Detalhes" onClose={() => setModalVisibleDetails(false)}>
                <p><b>Nome:</b> {hostingDetails?.nome}</p>
                <p><b>HostName:</b> {hostingDetails?.hostname}</p>
                <p><b>Situação:</b> {hostingDetails?.situacao === 'Started' ? 'Inicializado' : 'Parado'}</p>
                <p><b>Tamanho:</b> {hostingDetails?.tamanho}</p>
                <p><b>IP Público:</b> {hostingDetails?.ipPublico}</p>
                <p><b>IP Privado:</b> {hostingDetails?.ipPrivado}</p>
                <p><b>DNS:</b> {hostingDetails?.nomePublico}</p>
                <p><b>Assinatura:</b> {hostingDetails?.assinaturaNome}</p>
            </PPModal>

            <Confirmation
                close={() => { setOpen(false); setVMConfirmationName(''); }}
                display={open}
                textBtnOk='Confirmar'
                textBtnCancel='Cancelar'
                title={`${VMOperation === 0 ? 'Desligar' : 'Ligar'} máquina virtual?`}
                text={
                    <>
                        <p className='mb-4'>Máquina virtual selecionada:</p>
                        <p className='ml-4'>Nome: <b>{SelectedRow?.name}</b></p>
                        <p className='ml-4'>Host: <b>{SelectedRow?.hostname}</b></p>
                        <p className='mv-4'>Esta operação pode demorar um pouco. Tem certeza que deseja continuar?</p>
                        <p className='mv-4'>Digite o nome do <b>host</b> da máquina virtual escolhida para confirmar a operação de {VMOperation === 0 ? 'desligamento' : 'ligação'}.</p>
                        <PPInput type="text" required value={vmConfirmationName} onChange={event => setVMConfirmationName(event.target.value)} />
                    </>
                }
                confirm={() => handleConfirmation()}
            />

            <DialogConfirmForm
                onCloseEvent={handleConfirmCloseDelete}
                open={openDelete}
                confirmMessage='Confirmar'
                abortMessage='Cancelar'
                title="Excluir máquina virtual?"
                content='Esta operação pode demorar um pouco. Tem certeza que deseja continuar?'
            />

            <PPModal visible={modalVisible} title="VHDs" onClose={() => setModalVisible(false)}>
                <LcInfiniteTable
                    columns={columnsTableModal}
                    rows={vhds}
                    size={vhds.length || 0}
                />
            </PPModal>
        </>
    );
}

