import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import { PiAppleLogoLight, PiArrowsCounterClockwiseLight, PiArrowSquareOutLight, PiDevicesLight, PiFirstAidKitLight, PiInfoLight, PiLinuxLogoLight, PiPackageLight, PiWarningLight, PiWindowsLogoLight } from 'react-icons/pi';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import { useHistory } from 'react-router-dom';
import InstalledVsMissingGraph from './InstalledVsMissingGraph';
import MissingPatchesGraph from './MissingPatchesGraph';
import { SystemsByPlatformGraph } from './SystemsByPlatformGraph';
import PPDropDown from '../../../components/PPDropDown';
import { PatchManagerService } from '../../../services/patchmanager/patchManagerService';
import { GraphModel, Patch, PatchDetail, PatchStatus, SimplifiedSystem, SystemByPlataformList } from '../../../services/patchmanager/patchManagerModel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import moment from 'moment';
import LcIconLink from '../../../components/Generic/LcIconLink';
import { PiMonitorPlay } from "react-icons/pi";
import { PiMonitorLight } from "react-icons/pi";
import { PPModal } from 'processor-plataform-ui';
import './index.css';
import PPCheckBox from '../../../components/Form/PPCheckBox';
import { PatchManagerState } from '../../../store/reducers/patchManagerReducer';
import { translationServerity } from '../PatchListPathManager';
import Confirmation from '../../../components/Dialog/Confirmation';
import LcTooltip from '../../../components/Generic/LcTooltip';
import { translateSeverityColor, translateSeverityLabel, translateStatus } from '../utils/translations';
import { SystemModalDetails } from '../SystemListPatchManager/SystemModalDetails';
import { SecCardProps } from '../../../components/Data/Dashboard/HomeDashboardCard/SecCard';

interface Props extends SecCardProps{
    isHome: boolean;
    secOpsQtdGroups: number;
    isSecOpsPage: boolean
}

const translateStatusColorRowDetails = (row: PatchDetail) => {
    const tranlateStatusColor = {
        'Important': '#ffa400',
        'Critical': "#dc291e",
        'High': "#dc291e",
        'Medium': "#f6de00",
        'Moderate': "#f6de00",
        'Low': "#3ebb3f",
        'Unclassified': "#cccccf",
    };
    return tranlateStatusColor[row.severity_name];
}

const ResumePathManager: React.FC<Props> = (props) => {

    const SystemPlataformOpt = [{ label: 'Suportados', value: 1 }, { label: 'Obsoletos', value: 2 }]
    const user = useSelector<RootState, UserState>(state => state.user);
    const patchManager = useSelector<RootState, PatchManagerState>(state => state.patchManager);
    const dispatch = useDispatch();
    const history = useHistory();
    const patchManagerService = new PatchManagerService({});
    // const { data: rowsPatches = [], isLoading } = useQuery('patches', patchManagerService.GetPatches);
    const [loading, setLoading] = useState<boolean>(true);
    const [systemPlataformSelected, setSystemPlataformSelected] = useState(1);
    const [patchSummariesResume, setPatchSummariesResume] = useState<GraphModel[]>([]);
    const [highVulnerableSystems, setHighVulnerableSystems] = useState<any[]>([]);
    const [resumoAgents, setResumoAgents] = useState<any[]>([]);
    const [criticalPatches, setCriticalPatches] = useState<any[]>([]);
    const [systemsRequiringReboot, setSystemsRequiringReboot] = useState<any[]>([]);
    const [allPatchesInstalledSystems, setAllPatchesInstalledSystems] = useState<any[]>([]);
    const [loadingPMPatchesNotDistincts, setLoadingPMPatchesNotDistincts] = useState<boolean>(true);
    const [allPatchesInstalledSystemsLoading, setAllPatchesInstalledSystemsLoading] = useState<boolean>(true);
    const [loadingAutomation, setLoadingAutomation] = useState<boolean>(true);
    const [systemByPlatformSupported, setSystemByPlatformSupported] = useState<GraphModel[]>();
    const [systemByPlatformObsolete, setSystemByPlatformObsolete] = useState<GraphModel[]>();
    const [patchSummariesMissingSeverity, setPatchSummariesMissingSeverity] = useState<GraphModel[]>();
    const [patchSummariesInstalledVsMissing, setPatchSummariesInstalledVsMissing] = useState<GraphModel[]>();
    const [systemWithApds, setSystemWithApds] = useState<any[]>();
    const [systemWithoutApds, setSystemWithoutApds] = useState<any[]>();
    const [withADP, setWithADP] = useState<boolean>(true);
    const [withoutADP, setWithoutADP] = useState<boolean>(true);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [loadingModal, setLoadingModal] = useState<boolean>(false);
    const [focus, setFocus] = useState<boolean>(false);
    const [focusAgent, setFocusAgent] = useState<boolean>(false);
    const [rowsPatches, setRowsPatches] = useState<Patch[]>([]);
    const [rowsPatcheSelected, setRowsPatcheSelected] = useState<PatchDetail[]>([]);
    const [rowsPatchesFiltered, setRowsPatchesFiltered] = useState<Patch[]>([]);
    const [missingFocused, setMissingFocused] = useState<boolean>(false);
    const [pageExitConfirmation, setPageExitConfirmation] = useState<boolean>(false);
    const [patchesFail, setPatchesFail] = useState<boolean>(false);
    const [modalOpen, setModalOpen] = useState<boolean>(false);;
    const [severityTitleGraph, setSeverityTitleGraph] = useState<string>('');
    const [systemSelected, setSystemSelected] = useState<any | null>(null);

    const columnsPmAutomationSystems = [
        {
            field: "computerName", headerName: "Hostname", width: "20%", align: 'left', fontSize: '11px'
        },
        {
            field: "automated", headerName: "Implantação automática", width: "20%", align: 'center', fontSize: '11px',
            // You might want to include renderCell to customize how "automated" is displayed
            renderCell: (row) => row.automated ? <p style={{ color: 'var(--status-green)' }}>Sim</p> : <p style={{ color: 'var(--emphasis)' }}>Não</p>, // Display "Sim" for true and "Não" for false
        },
        {
            field: "operatingSystem", headerName: "Sistema operacional", width: "25%", align: 'left', fontSize: '11px',
        }
    ];

    const columnsSystem = [
        {
            field: "resource_name", headerName: "HostName", width: "30%", align: 'left', fontSize: '11px',
            renderCell: (row: SystemByPlataformList) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-6px' }} >
                        {
                            row.os_platform_name &&
                            <LcIconLink className='notHover' clickble={false} icon={
                                row.os_platform_name.toLocaleLowerCase().includes('windows') ? <PiWindowsLogoLight size={'1rem'} />
                                    :
                                    row.os_platform_name.toLocaleLowerCase().includes('mac') ? <PiAppleLogoLight size={'1rem'} />
                                        :
                                        <PiLinuxLogoLight size={'1rem'} />
                            } />
                        }
                        <span style={{ color: 'var(--text-link)', fontWeight: 400, cursor: 'pointer', textDecoration: 'underline' }} onClick={() => { setModalOpen(true); setSystemSelected(row); }} className='font-4x'>{row.resource_name}</span>
                    </div>

                )
            }
        },
        {
            field: "agentStatus", headerName: "Status do agente", width: "15%", align: 'center', fontSize: '11px',
            renderCell: (row: SystemByPlataformList) => {
                return row.computer_live_status !== 1 ?
                    <div style={{ width: '10px', height: '10px', borderRadius: '100%', backgroundColor: '#dc291e' }}></div>
                    :
                    <div style={{ width: '10px', height: '10px', borderRadius: '100%', backgroundColor: '#3ebb3f' }}></div>
            }
        },
        {
            field: "lastCommunication", headerName: "Última comunicação", width: "25%", align: 'center', fontSize: '11px',
            renderCell: (row: SystemByPlataformList) => {
                return moment(row.lastCommunication).format('DD/MM/YYYY hh:mm')
            }
        },
        {
            field: "operational_System", headerName: "Sistema operacional", width: "30%", align: 'left', overflow: 'visible', fontSize: '11px',
            renderCell: (row: SystemByPlataformList) => {
                return row.legacySystem ?
                    <div className='operationalSystem'>
                        <p>{row.os_platform_name}</p>
                        <LcIconLink size='small'
                            icon={<PiInfoLight color='#ffb530' fontSize={'1rem'} />}
                            tooltip="Sistema legado"
                            tooltipPosition='left'
                            clickble={false}
                        />
                    </div>
                    :
                    <div className='operationalSystem'>{row.os_platform_name}</div>
            }
        }
    ];

    const columnsDetailsPatches = [
        {
            field: "resource_name", headerName: "Nome do Computador", width: "15%", align: 'left',
            renderCell: (row: PatchStatus) => capitalizeFirstLetter(row.resource_name)
        },
        { field: "domain_netbios_name", headerName: "Domínio", width: "20%", align: 'left' },
        { field: "os_name", headerName: "Sistema operacional", width: "28%", align: 'left' },
        { field: "patch_id", headerName: "Patch Id", width: "10%", align: 'left' },
        {
            field: "resource_health_status", headerName: "Status da instalação", width: "15%", align: 'center', overflow: 'visible',
            renderCell: (row: PatchStatus) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '-6px' }}>
                        <p style={{ color: row.affected_patch_status === 202 ? 'var(--status-red)' : row.affected_patch_status === 201 ? 'var(--status-green)' : 'var(--status-yellow)' }}>
                            {
                                row.affected_patch_status === 202 ? "Não instalado"
                                    : row.affected_patch_status === 201 ? 'Instalado'
                                        : "Falha"
                            }
                        </p>
                    </div>
                )
            }
        },
        {
            field: "severity", headerName: "Severidade", width: "10%", align: 'center',
            renderCell: (row: PatchStatus) => {
                return <div className='severityLabelDetails ' style={{
                    padding: '0px 8px',
                    backgroundColor: translateSeverityColor(row.severity)
                }}>{translateSeverityLabel(row.severity)} </div>
            }
        }
    ]

    const columnsPatches = [
        { field: "patch_id", headerName: "Patch Id", width: "5%", align: 'left', },
        {
            field: "severity", headerName: "Severidade", width: "11%", align: 'center',
            renderCell: (row: Patch) => {
                return <div className='severityLabelDetails' style={{
                    padding: '0px 8px',
                    backgroundColor: translateSeverityColor(row.severity)
                }}>
                    {translateSeverityLabel(row.severity)}
                </div>
            }
        },
        {
            field: "patch_description", headerName: "Descrição do patch", width: "30%", align: 'left', overflow: 'visible',
            renderCell: (row: any) =>
                <div style={{ width: '100%' }}>
                    <LcTooltip content={row.patch_description} position='left' trigger='hover'>
                        <p className='overflowEllipsis' style={{ maxWidth: '30vw' }}>{row.patch_description}</p>
                    </LcTooltip>
                </div>
        },
        {
            field: "platform_name", headerName: "Plataforma", width: "10%", align: 'center',
            renderCell: (row: any) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {row.platform_name && <LcIconLink className='notHover' clickble={false} icon={
                            row.platform_name.includes('Windows') ? <PiWindowsLogoLight size={'1rem'} />
                                :
                                row.platform_name.includes('mac') ? <PiAppleLogoLight size={'1rem'} />
                                    :
                                    <PiLinuxLogoLight size={'1rem'} />
                        } />}
                        <span>{row.platform_name}</span>
                    </div>
                )
            }
        },
        {
            field: "rebootRequired", headerName: "Reboot", width: "15%", align: 'center', overflow: "visible",
            renderCell: (row: any) => {
                return !((row.rebootRequired) > 0) ?
                    <LcIconLink size='small' clickble={false}
                        icon={<PiInfoLight color='#ffb530' size={18} />}
                        tooltip="Necessário reiniciar a máquina"
                        tooltipPosition='right'
                    /> : <div>-</div>
            }
        },
        {
            field: "missingSystem", headerName: "Sistemas", width: "5%", align: 'center', overflow: 'visible',
            renderCell: (row: Patch) =>
                <div onClick={() => {
                    GetDetailsData(row);
                }} style={{ color: 'var(--text-link)', fontWeight: 600, cursor: 'pointer', }}>
                    <LcIconLink
                        size='small'
                        icon={<PiDevicesLight size={18} />}
                        tooltip="Ver detalhes"
                        tooltipPosition='right'
                    />
                </div>
        },
    ];

    const columnsPatchesFailed = [
        { field: "patch_id", headerName: "Patch Id", width: "5%", align: 'left', },
        {
            field: "severity", headerName: "Severidade", width: "11%", align: 'center',
            renderCell: (row: Patch) => {
                return <div className='severityLabelDetails' style={{
                    padding: '0px 8px',
                    backgroundColor: translateSeverityColor(row.severity)
                }}>
                    {translateSeverityLabel(row.severity)}
                </div>
            }
        },
        {
            field: "patchDescription", headerName: "Descrição do patch", width: "30%", align: 'left', overflow: 'visible',
            renderCell: (row: any) =>
                <div style={{ width: '100%' }}>
                    <LcTooltip content={row.patchDescription} position='left' trigger='hover'>
                        <p className='overflowEllipsis' style={{ maxWidth: '30vw' }}>{row.patchDescription}</p>
                    </LcTooltip>
                </div>
        },
        {
            field: "platformName", headerName: "Plataforma", width: "10%", align: 'center',
            renderCell: (row: any) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        {row.platform && <LcIconLink className='notHover' clickble={false} icon={
                            row.platform.includes('Windows') ? <PiWindowsLogoLight size={'1rem'} />
                                :
                                row.platform.includes('mac') ? <PiAppleLogoLight size={'1rem'} />
                                    :
                                    <PiLinuxLogoLight size={'1rem'} />
                        } />}
                        <span>{row.platform}</span>
                    </div>
                )
            }
        },
        {
            field: "rebootRequired", headerName: "Reboot", width: "15%", align: 'center', overflow: "visible",
            renderCell: (row: any) => {
                return !((row.rebootRequired) > 0) ?
                    <LcIconLink
                        size='small'
                        clickble={false}
                        notHover
                        icon={<PiInfoLight color='#ffb530' size={18} />}
                        tooltip="Necessário reiniciar a máquina"
                        tooltipPosition='right'
                    />
                    :
                    'Não'
            }
        },
        {
            field: "missingSystem", headerName: "Sistemas", width: "5%", align: 'center', overflow: 'visible',
            renderCell: (row: Patch) =>
                <div onClick={() => {
                    GetDetailsData(row);
                }} style={{ color: 'var(--text-link)', fontWeight: 600, cursor: 'pointer', }}>
                    <LcIconLink
                        size='small'
                        icon={<PiDevicesLight size={18} />}
                        tooltip="Ver detalhes"
                        tooltipPosition='right'
                    />
                </div>
        },
    ];

    const positionsCards: { group: number, cards: Card[] }[] = [
        {
            group: 2, cards: [
                {
                    id: 1, position: { ColSpan: 1, RowSpan: 1, row: 3, col: 2, hidden: true },
                },
                {
                    id: 2, position: { ColSpan: 1, RowSpan: 1, row: 4, col: 2, hidden: true },
                },
                {
                    id: 3, position: { ColSpan: 2, RowSpan: 1, row: 6, col: 1, hidden: true },
                },
                {
                    id: 4, position: { ColSpan: 4, RowSpan: 1, row: 4, col: 1, hidden: props.secOpsQtdGroups > 1 ? true : false },
                },
                {
                    id: 5, position: { ColSpan: 4, RowSpan: 1, row: 5, col: 1, hidden: props.secOpsQtdGroups > 1 ? true : false },
                },
                {
                    id: 6, position: { ColSpan: 4, RowSpan: 1, row: 6, col: 1, hidden: props.secOpsQtdGroups > 1 ? true : false },
                },
                {
                    id: 7, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 8, position: { ColSpan: 3, RowSpan: 1, row: 1, col: 1, hidden: false },
                },
                {
                    id: 9, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 10, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 11, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 12, position: { ColSpan: 1, RowSpan: 1, row: 2, col: 2, hidden: true },
                },
            ]
        },
        {
            group: 6, cards: [
                {
                    id: 1, position: { ColSpan: 3, RowSpan: 1, row: 4, col: 1, hidden: true },
                },
                {
                    id: 2, position: { ColSpan: 3, RowSpan: 1, row: 5, col: 1, hidden: true },
                },
                {
                    id: 3, position: { ColSpan: 4, RowSpan: 1, row: 6, col: 1, hidden: true },
                },
                {
                    id: 4, position: { ColSpan: 4, RowSpan: 1, row: 2, col: 1, hidden: !props.isSecOpsPage },
                },
                {
                    id: 5, position: { ColSpan: 4, RowSpan: 1, row: 3, col: 1, hidden: !props.isSecOpsPage },
                },
                {
                    id: 6, position: { ColSpan: 4, RowSpan: 1, row: 4, col: 1, hidden: !props.isSecOpsPage },
                },
                {
                    id: 7, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 8, position: { ColSpan: 3, RowSpan: 1, row: 1, col: 1, hidden: false },
                },
                {
                    id: 9, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 10, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 11, position: { ColSpan: 4, RowSpan: 3, row: 2, col: 9, hidden: true },
                },
                {
                    id: 12, position: { ColSpan: 2, RowSpan: 3, row: 1, col: 1, hidden: true },
                },
            ]
        }
    ];

    const positionHome = (cardId: number | undefined, qtdGroups: number) => {
        if (cardId === undefined) return undefined;
        const groupData = positionsCards.find(group => group.group >= qtdGroups);
        if (groupData) {
            const card = groupData.cards.find(c => c.id === cardId);
            if (card) {
                return card.position;
            }
        }
        return undefined;
    }

    const capitalizeFirstLetter = (str) => {
        if (str.length === 0) return str;
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const initComponentData = async () => {
        Promise.all([
            GetPatchSummariesResume(),
            GetPatchinSystemsSummariesInstalledVsMissing(),
            GetSystemsApds(),
            GetCriticalPatches(),
            GetHighVulnerableSystems(),
            GetSystemsRequiringReboot(),
            GetResumoAgents()]);
    }

    const GetDetailsData = async (row: Patch) => {
        setOpenModal(true);
        setLoadingModal(true);
        var detailslist = await patchManagerService.GetAllPatchDetails(row.patch_id);
        detailslist && setRowsPatcheSelected(detailslist.filter(x => x.affected_patch_status === 202));
        setLoadingModal(false);
    }

    const GetPatchinSystemsSummariesInstalledVsMissing = async () => {
        setAllPatchesInstalledSystemsLoading(true);
        try {
            const patchManagerService = new PatchManagerService({});
            var result = await patchManagerService.GetAllPatchesInstalledSystems();
            console.log(result)
            setAllPatchesInstalledSystems(result);
            setAllPatchesInstalledSystemsLoading(false);;
        } catch (e) {
            console.error(e)
            throw e;
        }

    }

    const GetSystemsRequiringReboot = async () => {
        setLoadingPMPatchesNotDistincts(true);
        try {
            const patchManagerService = new PatchManagerService({});
            var result = await patchManagerService.GetSystemsRequiringReboot();
            setSystemsRequiringReboot(result);
            setLoadingPMPatchesNotDistincts(false);
        } catch (e) {
            setLoadingPMPatchesNotDistincts(false);
            console.error(e)
            throw e;
        }
    }

    const GetHighVulnerableSystems = async () => {
        setLoadingPMPatchesNotDistincts(true);
        try {
            const patchManagerService = new PatchManagerService({});
            var result = await patchManagerService.GetHighVulnerableSystems();
            setHighVulnerableSystems(result);
            setLoadingPMPatchesNotDistincts(false);
        } catch (e) {
            setLoadingPMPatchesNotDistincts(false);
            console.error(e)
            throw e;
        }
    }

    const GetResumoAgents = async () => {
        setLoadingPMPatchesNotDistincts(true);
        try {
            //resumoAgents, setResumoAgents
            const patchManagerService = new PatchManagerService({});
            var result = await patchManagerService.GetResumoAgents();
            setResumoAgents(result);
            setLoadingPMPatchesNotDistincts(false);
        } catch (e) {
            setLoadingPMPatchesNotDistincts(false);
            console.error(e)
            throw e;
        }
    }

    const GetCriticalPatches = async () => {
        setLoadingPMPatchesNotDistincts(true);
        try {
            const patchManagerService = new PatchManagerService({});
            var result = await patchManagerService.GetCriticalPatches();
            setCriticalPatches(result);
            setLoadingPMPatchesNotDistincts(false);
        } catch (e) {
            setLoadingPMPatchesNotDistincts(false);
            console.error(e)
            throw e;
        }
    }

    const GetSystemsApds = async () => {
        setLoadingAutomation(true)
        try {
            const patchManagerService = new PatchManagerService({});
            const getSystemWithApd = await patchManagerService.GetSystemWithApd();
            const getSystemWithoutApd = await patchManagerService.GetSystemWithoutApd();
            setSystemWithApds(getSystemWithApd)
            setSystemWithoutApds(getSystemWithoutApd)
        } catch (error) {
            console.error(error)
        }
        setLoadingAutomation(false)
    }

    const GetPatchSummariesResume = async () => {
        setLoading(true);
        try {
            const patchManagerService = new PatchManagerService({});
            const patchesResponse = await patchManagerService.GetPatchSummariesResume();
            // if (!patchesResponse && !patchesResponse.getPatchSummariesResume) {
            //     setLoading(false)
            // }
            setPatchSummariesResume(patchesResponse.getPatchSummariesResume)

            if (patchesResponse && patchesResponse.getSystemByPlatformObsolete)
                setSystemByPlatformObsolete(patchesResponse.getSystemByPlatformObsolete)
            if (patchesResponse && patchesResponse.getSystemByPlatformSupported)
                setSystemByPlatformSupported(patchesResponse.getSystemByPlatformSupported)

            if (patchesResponse && patchesResponse.getPatchSummariesMissingSeverity)
                setPatchSummariesMissingSeverity(patchesResponse.getPatchSummariesMissingSeverity)

            if (patchesResponse && patchesResponse.getPatchSummariesInstalledVsMissing)
                setPatchSummariesInstalledVsMissing(patchesResponse.getPatchSummariesInstalledVsMissing)

            if (patchesResponse && patchesResponse.rowsPatches)
                setRowsPatches(patchesResponse.rowsPatches)

        } catch (error) {
            console.error('Error:', error);
        }
        setLoading(false);
    }

    const handleCheckboxChange = (name: string, e: any) => {
        var checked = e.target.value;
        switch (name) {
            case 'withADP':
                setWithADP(checked);
                break;
            case 'withoutADP':
                setWithoutADP(checked);
                break;
            default:
                break;
        }
    };



    const cards: Card[] = [
        //Patchs críticos
        {
            id: 1,
            type: 'Custom',
            bgColor: (props.isHome || props.isSecOpsPage) ? '#F1F5FB' : undefined,
            hideHeader: (props.isHome || props.isSecOpsPage),
            position: { ColSpan: 2, RowSpan: 1, row: 2, col: 3 },
            showFocusButton: true,
            title: () => <div className="ellipsis-title ">Patches críticos</div>,

            customContentRender: (focused: boolean) => {
                if (focused) {
                    return (patchSummariesResume.length > 0 ?
                        <LcInfiniteTable
                            rows={patchSummariesResume.filter(c => c.name === 'Criticos')[0].list as object[]}
                            columns={columnsPatchesFailed}
                            loadMore={() => undefined}
                            size={patchSummariesResume.filter(c => c.name === 'Criticos')[0].list?.length ?? 0}
                            hidePagination
                        /> : <LcNoData />
                    )
                } else {
                    return (
                        <div style={{ maxHeight: '8vh' }}>
                            <LcLoading loading={loading}>
                                {
                                    patchSummariesResume && patchSummariesResume.length > 0 ?
                                        <IconValue
                                            icon={<PiWarningLight size={32} />}
                                            value={patchSummariesResume.filter(c => c.name === 'Criticos')[0].value}
                                            color="#e0443a"
                                            description={(props.isHome || props.isSecOpsPage) ? 'Patches críticos Não instalados' : 'Não instalados'}
                                            cssClass='font-xl'
                                        />
                                        :
                                        <LcNoData size="small" />
                                }
                            </LcLoading>
                        </div>

                    )
                }
            }
        },
        //com falhas
        {
            id: 2,
            type: 'Custom',
            position: { ColSpan: 2, RowSpan: 1, row: 3, col: 3 },
            showFocusButton: true,
            bgColor: (props.isHome || props.isSecOpsPage) ? '#F1F5FB' : undefined,
            hideHeader: (props.isHome || props.isSecOpsPage),
            title: () => <div className="ellipsis-title" >{patchesFail ? "Patches com falha na implantação" : "Falha na implantação"}</div>,
            customContentRender: (focused: boolean) => {
                setPatchesFail(focused)
                if (focused) {

                    return (
                        patchSummariesResume && patchSummariesResume.length > 0 ?
                            <LcInfiniteTable
                                rows={patchSummariesResume.filter(c => c.name === 'Falhas')[0].list as object[]}
                                columns={columnsPatchesFailed}
                                loadMore={() => undefined}
                                size={patchSummariesResume.filter(c => c.name === 'Falhas')[0].list?.length ?? 0}
                                hidePagination
                                height='calc(100% - 10px)'
                            /> : <></>
                    )
                } else {
                    return (
                        <div style={{ maxHeight: '8vh' }}>
                            <LcLoading loading={loading}>
                                {
                                    patchSummariesResume && patchSummariesResume.length > 0 ?
                                        <IconValue
                                            icon={<PiWarningLight size={32} />}
                                            value={patchSummariesResume.filter(c => c.name === 'Falhas')[0].value}
                                            color="#ffb530"
                                            description={(props.isHome || props.isSecOpsPage) ? 'Patches que falharam' : 'Patches'}
                                            cssClass='font-xl'
                                        />
                                        :
                                        <LcNoData size="small" />
                                }
                            </LcLoading>
                        </div>
                    )
                }
            }
        },
        //Implantação automática de patches
        {
            id: 9,
            type: 'Custom', position: { ColSpan: 4, RowSpan: 1, row: 1, col: 5 },
            showFocusButton: true,
            centeredContent: !focus,
            hideHeader: (props.isHome || props.isSecOpsPage),
            title: () => <div className="ellipsis-title ">{focus ? "Implantação automática de patches" : "Implantação automática de patches"}</div>,
            customContentRender: (focused: boolean) => {
                const simplifiedSystemWithoutApds: SimplifiedSystem[] = systemWithoutApds ? systemWithoutApds.map(
                    ({ computerName, domain, operatingSystem = "" }) => ({
                        computerName,
                        domain,
                        automated: false, // Definido como false para systemWithoutApds
                        operatingSystem,  // Valor real do sistema operacional
                    })
                ) : [];

                const simplifiedSystemWithApds: SimplifiedSystem[] = systemWithApds ? systemWithApds.map(
                    ({ computerName, domain }) => ({
                        computerName,
                        domain,
                        automated: true,      // Definido como true para systemWithApds
                        operatingSystem: "", // Campo vazio, pois não há sistema operacional
                    })
                ) : [];

                const combinedSystems: SimplifiedSystem[] = [
                    ...(withoutADP ? simplifiedSystemWithoutApds : [] as SimplifiedSystem[]),
                    ...(withADP ? simplifiedSystemWithApds : [] as SimplifiedSystem[]),
                ];
                //const [withADP, setWithADP] = useState<any[]>()
                //const [withoutADP, setWithoutADP] = useState<any[]>()
                setFocus(focused)
                if (focused) {
                    return (<div style={{ display: 'contents' }} >
                        <div className='implantation-focused '>
                            <PPCheckBox checked={withADP} name='withADP' title={'Sistemas com automações ' + (simplifiedSystemWithApds ? simplifiedSystemWithApds?.length : 0)} onChange={(e) => handleCheckboxChange('withADP', e)} className='row' />
                            <PPCheckBox checked={withoutADP} name='withoutADP' title={'Sistemas sem automações ' + (simplifiedSystemWithoutApds ? simplifiedSystemWithoutApds?.length : 0)} onChange={(e) => handleCheckboxChange('withoutADP', e)} className='row' />
                        </div>
                        {combinedSystems && combinedSystems.length > 0 ?
                            <LcInfiniteTable
                                rows={(combinedSystems && combinedSystems) || []}
                                columns={columnsPmAutomationSystems}
                                loadMore={() => undefined}
                                size={(combinedSystems && combinedSystems?.length) ?? 0}
                                hidePagination
                                height='calc(100% - 55px)'
                            // status={getAutomatedTableStatusColor}
                            />
                            : <LcNoData />}
                    </div>
                    )
                } else {
                    return (
                        <LcLoading loading={loadingAutomation}>
                            {
                                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                                    <IconValue
                                        icon={<PiMonitorPlay size={32} />}
                                        value={systemWithApds ? systemWithApds.length : 0}
                                        color="#3EBB3F"
                                        description='Sistemas com automações'
                                        cssClass='automation-icons'
                                    />
                                    <IconValue
                                        icon={<PiMonitorLight size={32} />}
                                        value={systemWithoutApds ? systemWithoutApds.length : 0}
                                        color="#FFA400"
                                        description='Sistemas sem automações'
                                        cssClass='automation-icons'
                                        backgroundColor='#000'
                                    />
                                </div>
                            }
                        </LcLoading>

                    )
                }
            }
        },
        //Agentes
        {
            id: 3,
            type: 'Custom', position: { ColSpan: 2, RowSpan: 1, row: 4, col: 1 },
            showFocusButton: true,
            hideHeader: (props.isHome || props.isSecOpsPage),
            title: () => <div className="ellipsis-title ">{focusAgent ? "Agentes Desconectados" : "Agentes"}</div>,

            customContentRender: (focused: boolean) => {
                let agentResume = patchSummariesResume && patchSummariesResume.find(patch => patch.name === 'Agentes');
                setFocusAgent(focused)
                if (focused) {
                    return (
                        patchSummariesResume && patchSummariesResume.length > 0 ?
                            <LcInfiniteTable
                                rows={(agentResume && agentResume.list?.filter((fill: any) => fill.computer_live_status === 2)) || []}
                                columns={columnsSystem}
                                loadMore={() => undefined}
                                size={(agentResume && agentResume.list?.filter((fill: any) => fill.computer_live_status ===  2 )?.length) ?? 0}
                                hidePagination
                                height='calc(100% - 15px)'
                                // status={getStatusRow}
                                tooltipContentColumn='agentStatus'
                                customTooltipContentColumn={translateStatus}
                            /> : <LcNoData />
                    )
                } else {
                    return (
                        <div style={{ maxHeight: '8vh' }}>
                            <LcLoading loading={loading}>
                                {
                                    patchSummariesResume && patchSummariesResume.length > 0 ?
                                        <IconValue
                                            icon={<PiWarningLight size={32} />}
                                            value={agentResume && agentResume.list?.filter((fill: any) => fill.computer_live_status === 2).length}
                                            color="#e0443a"
                                            description='Desconectados'
                                            cssClass='font-xl'
                                        />
                                        :
                                        <LcNoData size="small" />
                                }
                            </LcLoading>
                        </div>
                    )
                }
            }
        },
        //Reboot Pendente
        {
            id: 4,
            type: 'Custom',
            bgColor: (props.isHome || props.isSecOpsPage) ? '#F1F5FB' : undefined,
            hideHeader: (props.isHome || props.isSecOpsPage),
            position: (props.isHome || props.isSecOpsPage) ? { ColSpan: 3, RowSpan: 1, row: 1, col: 7 } : { ColSpan: 2, RowSpan: 1, row: 4, col: 3 },
            showFocusButton: true,
            cssClass: 'fix-card-content',
            title: () => <div className="ellipsis-title ">Reboot pendente</div>,

            //customContentRender: (focused: boolean) => {
            //    if (focused) {
            //        return (
            //            systemsRequiringReboot && systemsRequiringReboot.length > 0 ? <LcInfiniteTable
            //                rows={systemsRequiringReboot as object[]}
            //                columns={columnsSystem}
            //                loadMore={() => undefined}
            //                size={systemsRequiringReboot.length ?? 0}
            //                hidePagination
            //            /> : <></>
            //        )
            //    } else {
            //        return (
            //            <div style={{ maxHeight: '8vh' }}>
            //                <LcLoading loading={loading}>
            //                    {
            //                        systemsRequiringReboot && systemsRequiringReboot.length > 0 ?
            //                            <IconValue
            //                                value={systemsRequiringReboot.length}
            //                                icon={<PiArrowsCounterClockwiseLight size={isHome ? 24 : 32} />}
            //                                color="#ffb530"
            //                                description={(isHome || isSecOpsPage) ? 'Sistemas com reboot pendente' : 'Sistemas'}
            //                                cssClass={(isHome || isSecOpsPage) ? '' : 'font-xl'}
            //                                isHome={isHome}
            //                            />
            //                            :
            //                            <LcNoData size="small" />
            //                    }
            //                </LcLoading>
            //            </div>
            //        )
            //    }
            //}
            customContentRender: (focused: boolean) => {
                if (focused) {
                    return (
                        patchSummariesResume && patchSummariesResume.length > 0 ? <LcInfiniteTable
                            rows={patchSummariesResume.filter(c => c.name === 'RebootPendente')[0].list as object[]}
                            columns={columnsSystem}
                            loadMore={() => undefined}
                            size={patchSummariesResume.filter(c => c.name === 'RebootPendente')[0].list?.length ?? 0}
                            hidePagination
                            customTooltipContentColumn={translateStatus}
                        /> : <></>
                    )
                } else {
                    return (
                        <div style={{ maxHeight: '8vh' }}>
                            <LcLoading loading={loading}>
                                {
                                    patchSummariesResume && patchSummariesResume.length > 0 ?
                                        <IconValue
                                            value={patchSummariesResume.filter(c => c.name === 'RebootPendente')[0].value}
                                            icon={<PiArrowsCounterClockwiseLight size={props.isHome ? 24 : 32} />}
                                            color="#ffb530"
                                            description={(props.isHome || props.isSecOpsPage) ? 'Sistemas com reboot pendente' : 'Sistemas'}
                                            cssClass={(props.isHome || props.isSecOpsPage) ? '' : 'font-xl'}
                                            isHome={props.isHome}
                                        />
                                        :
                                        <LcNoData size="small" />
                                }
                            </LcLoading>
                        </div>
                    )
                }
            }
        },
        //Sistemas
        {
            id: 5,
            type: 'Custom',
            bgColor: "var(--color-primary-purple)",
            hideHeader: (props.isHome || props.isSecOpsPage),
            position: (props.isHome || props.isSecOpsPage) ? { ColSpan: 4, RowSpan: 4, row: 2, col: 1 } : { ColSpan: 4, RowSpan: 1, row: 1, col: 1 },
            ticketButton: () => {
                return <LcIconLink tooltipPosition='right' color='#fff' notHover
                    tooltip='Ir para sistemas' icon={<PiArrowSquareOutLight />} onClick={() => { history.push('/patchmanager/systems') }} />
            },
            showFocusButton: false,
            cssClass: 'fix-card-content',
            title: () => <div style={{ color: '#fff' }} className="ellipsis-title ">Sistemas</div>,
            //customContentRender: () => {
            //    return (
            //        <div style={{ height: '8vh' }}>
            //            <LcLoading loading={loading}>
            //                {
            //                    resumoAgents && resumoAgents.length > 0 ?
            //                        <IconValue
            //                            value={resumoAgents.length}
            //                            color={'#fff'}
            //                            icon={<PiDevicesLight size={isHome ? 24 : 32} />}
            //                            description={(isHome || isSecOpsPage) ? 'Sistemas gerenciados' : 'Gerenciados'}
            //                            cssClass={'font-xl systemsPatchManager'}
            //                            isHome={isHome}
            //                        />
            //                        :
            //                        <LcNoData size="small" />
            //                }
            //            </LcLoading>
            //        </div>
            //    )
            //}
            customContentRender: () => {
                return (
                    <LcLoading loading={loading}>
                        {
                            patchSummariesResume && patchSummariesResume.length > 0 ?
                                <IconValue
                                    value={patchSummariesResume.filter(c => c.name === 'Systems')[0].value}
                                    color={'#fff'}
                                    descriptionColor={'#fff'}
                                    colorValue='#fff'
                                    icon={<PiDevicesLight size={props.isHome ? 24 : 32} />}
                                    description={(props.isHome ||  props.isSecOpsPage) ? 'Sistemas gerenciados' : 'Gerenciados'}
                                    cssClass='font-xl systemsPatchManager'
                                    isHome={props.isHome}
                                />
                                :
                                <LcNoData size="small" />
                        }
                    </LcLoading>

                )
            }
        },
        //Systems HighVulnerability
        {
            id: 12,
            type: 'Custom',
            bgColor: (props.isHome || props.isSecOpsPage) ? '#F1F5FB' : undefined,
            position: { ColSpan: 2, RowSpan: 1, row: 3, col: 1 },
            showFocusButton: true,
            cssClass: 'fix-card-content',
            hideHeader: (props.isHome || props.isSecOpsPage),
            infoContent: () => <div><span>Sistemas que possuem patches<br /> críticos ou importantes <br /> pendentes de instalação.</span></div>,
            title: () => <div title='Alta vulnerabilidade' className="ellipsis-title ">{focus ? "Sistemas com alta vulnerabilidade" : "Alta vulnerabilidade"}</div>,
            //customContentRender: (focused: boolean) => {

            //    setFocus(focused)
            //    if (focused) {
            //        return (
            //            highVulnerableSystems && highVulnerableSystems.length > 0 && highVulnerableSystems ?
            //                <LcInfiniteTable
            //                    rows={highVulnerableSystems && (highVulnerableSystems || [])}
            //                    columns={columnsSystem}
            //                    loadMore={() => undefined}
            //                    size={highVulnerableSystems && highVulnerableSystems.length}
            //                    hidePagination
            //                    height='calc(100% - 15px)'
            //                /> : <LcNoData />
            //        )

            //    } else {
            //        return (
            //            <div style={{ height: '8vh' }}>
            //                <LcLoading loading={loading}>
            //                    {
            //                        highVulnerableSystems && highVulnerableSystems.length > 0 ?
            //                            <IconValue
            //                                icon={<PiFirstAidKitLight size={isHome ? 24 : 32} />}
            //                                value={highVulnerableSystems.length}
            //                                color="#e0443a"
            //                                description={(isHome || isSecOpsPage) ? 'Sistemas com alta vulnerabilidade' : 'Sistemas'}
            //                                cssClass='font-xl'
            //                            />
            //                            :
            //                            <LcNoData size="small" />
            //                    }
            //                </LcLoading>
            //            </div>
            //        )
            //    }
            //}

            customContentRender: (focused: boolean) => {
                let highVulnerabilityResume = patchSummariesResume && patchSummariesResume.find(patch => patch.name === 'HighVulnerability');
                setFocus(focused)
                if (focused) {
                    return (
                        patchSummariesResume && patchSummariesResume.length > 0 && highVulnerabilityResume ?
                            <LcInfiniteTable
                                rows={highVulnerabilityResume && (highVulnerabilityResume.list?.filter((fill: any) => fill.healthStatus === '3') || [])}
                                columns={columnsSystem}
                                loadMore={() => undefined}
                                size={highVulnerabilityResume && highVulnerabilityResume.list?.filter((fill: any) => fill.healthStatus === '3').length}
                                hidePagination
                                height='calc(100% - 15px)'
                                customTooltipContentColumn={translateStatus}
                            /> : <LcNoData />
                    )

                } else {
                    return (
                        <div style={{ height: '8vh' }}>
                            <LcLoading loading={loading}>
                                {
                                    patchSummariesResume && patchSummariesResume.length > 0 ?
                                        <IconValue
                                            icon={<PiFirstAidKitLight size={props.isHome ? 24 : 32} />}
                                            value={patchSummariesResume.filter(c => c.name === 'HighVulnerability')[0].value}
                                            color="#e0443a"
                                            description={(props.isHome || props.isSecOpsPage) ? 'Sistemas com alta vulnerabilidade' : 'Sistemas'}
                                            cssClass='font-xl'
                                        />
                                        :
                                        <LcNoData size="small" />
                                }
                            </LcLoading>
                        </div>
                    )
                }
            }
        },
        //Patchs
        {
            id: 6,
            type: 'Custom',
            bgColor: (props.isHome || props.isSecOpsPage) ? '#F1F5FB' : undefined,
            position: { ColSpan: 2, RowSpan: 1, row: 2, col: 1 },
            showFocusButton: false,
            hideHeader: (props.isHome || props.isSecOpsPage),
            ticketButton: () => { return <LcIconLink tooltipPosition='right' tooltip='Ir para patches' icon={<PiArrowSquareOutLight />} onClick={() => { history.push('/patchmanager/patches') }} /> },
            title: () => <div className="ellipsis-title ">Patches</div>,
            cssClass: 'fix-card-content',
            customContentRender: () => {
                return (
                    <div style={{ height: '8vh' }}>
                        <LcLoading loading={allPatchesInstalledSystemsLoading}>
                            {
                                allPatchesInstalledSystems && allPatchesInstalledSystems.length > 0 ?
                                    <IconValue
                                        icon={<PiPackageLight size={props.isHome ? 24 : 32} />}
                                        value={allPatchesInstalledSystems.length}
                                        color="#2E1956"
                                        description={(props.isHome || props.isSecOpsPage) ? 'Patches implantados' : 'Implantados'}
                                        cssClass='font-xl'
                                        isHome={props.isHome}
                                    />
                                    :
                                    <LcNoData size="small" />
                            }
                        </LcLoading>
                    </div>
                )
            }
        },
        //Patches instalados vc ausentes
        {
            id: 7,
            type: 'Custom', position: { ColSpan: 4, RowSpan: 2, row: 1, col: 9 },
            showFocusButton: false,
            title: () => <div className="ellipsis-title ">Patches instalados vs não instalados</div>,

            customContentRender: () =>
                <InstalledVsMissingGraph
                    isloading={loading}
                    legendOrientation='vertical'
                    patchSummariesInstalledVsMissing={patchSummariesInstalledVsMissing}
                />
        },
        //Patches ausentes
        {
            id: 8,
            type: 'Custom',
            hideHeader: (props.isHome || props.isSecOpsPage),
            position: (props.isHome || props.isSecOpsPage) ? { ColSpan: 1, RowSpan: 1, row: 1, col: 1 }
                : { ColSpan: 4, RowSpan: 3, row: 2, col: 5 },
            showFocusButton: missingFocused,
            title: !(props.isHome || props.isSecOpsPage) ? () => <div className="ellipsis-title">{missingFocused ? `Patches ${severityTitleGraph} não instalados` : 'Patches não instalados'} </div>
                : undefined,
            customContentRender: (focused: boolean, handleFocusClick?: Function) => {
                setMissingFocused(focused)
                if (focused) {
                    dispatch({ type: 'RESET_PATCH_PATCH' })
                    return (
                        <LcLoading loading={loading}>
                            <LcInfiniteTable
                                rows={rowsPatchesFiltered}
                                columns={columnsPatches}
                                size={rowsPatchesFiltered.length}
                                hidePagination
                                status={translateStatusColorRowDetails}
                            />
                        </LcLoading>
                    )
                } else {
                    focused === false && !(props.isHome || props.isSecOpsPage) && patchManager.SeverityPatch && patchManager.SeverityPatch !== '' && handleFocusClick && handleFocusClick()
                    return (
                        <div style={{ height: '100%' }}>
                            <LcLoading loading={loading} >
                                <MissingPatchesGraph
                                    qtsGroups={props.secOpsQtdGroups}
                                    isHome={props.isHome}
                                    isSecOps={props.isSecOpsPage}
                                    title={(props.isHome || props.isSecOpsPage) ? 'Patches não instalados' : ''}
                                    patchSummariesMissingSeverity={patchSummariesMissingSeverity}
                                />
                            </LcLoading>

                        </div>
                    )
                }
            }
        },
        //Sistemas por Plataforma
        {
            id: 9,
            type: 'Custom', position: { ColSpan: 4, RowSpan: 2, row: 3, col: 9 },
            showFocusButton: false,
            title: () => {
                return <>
                    {
                        <div className={'lc-segment-title-row-resume'} style={{ alignItems: 'flex-end' }}>
                            <div className={'ellipsis-title'}>Sistemas por plataforma</div>
                            <PPDropDown
                                name='rankType'
                                options={SystemPlataformOpt}
                                defaultValue={SystemPlataformOpt.find(option => option.value === systemPlataformSelected)}
                                value={SystemPlataformOpt.find(option => option.value === systemPlataformSelected)}
                                onChange={(event) => setSystemPlataformSelected(event.value)}
                                width='128px'
                            />
                        </div>
                    }</>
            },
            customContentRender: () =>
                <SystemsByPlatformGraph
                    isLoading={loading}
                    systemByPlatformSupported={systemByPlatformSupported}
                    systemByPlatformObsolete={systemByPlatformObsolete}
                    selected={systemPlataformSelected}
                    legendOrientation='vertical'
                    setPageExitConfirmation={setPageExitConfirmation}
                />
        },
    ];

    useEffect(() => {
        initComponentData();
    }, [user.ClientGroupSelected, user.refreshFlag]);

    useEffect(() => {
        if (patchManager.SeverityPatch && patchManager.SeverityPatch !== '') {
            rowsPatches && setRowsPatchesFiltered(rowsPatches.filter(rowsPatche => rowsPatche.severity === translationServerity(patchManager.SeverityPatch) && rowsPatche.missing > 0))
        }
    }, [patchManager.SeverityPatch, rowsPatches])

    useEffect(() => {
        if (patchManager.SeverityPatch && patchManager.SeverityPatch !== '') {
            setSeverityTitleGraph(patchManager.SeverityPatch.toLowerCase() + 's')
        }
    }, [patchManager.SeverityPatch])

    return (
        (props.isHome || props.isSecOpsPage) ?
            (
                <LCDashboard isHomeType cssClass={props.secOpsQtdGroups === 1 ? '' : 'patchManagerHome'}
                    grid={props.isHome || props.isSecOpsPage ? [1, 3] : [6, 12]}
                    cards={props.secOpsQtdGroups && props.secOpsQtdGroups > 0 ? cards.map((card, index) => {
                        if (props.secOpsQtdGroups) {
                            var posi = positionHome(card.id, props.secOpsQtdGroups);
                            if (posi !== undefined) {
                                card.position = posi;
                            }
                        }
                        return card;
                    }) : []}
                />
            )
            :
            (
                <Layout
                    pageTitle='Resumo'
                // periodSelection={periodSelection} //? Caso seja para colocar periodo na resumo, descomentar
                >
                    <LCDashboard
                        cards={cards}
                        grid={[4, 12]}
                    />
                    <PPModal visible={openModal} onClose={() => setOpenModal(false)} size='large' title="Detalhes do Patch">
                        <LcLoading loading={loadingModal} label='Carregando Patches...' >
                            <LcInfiniteTable
                                rows={rowsPatcheSelected}
                                columns={columnsDetailsPatches}
                                loadMore={() => undefined}
                                size={rowsPatcheSelected.length}
                                hidePagination
                                status={translateStatusColorRowDetails}
                            />
                        </LcLoading>
                    </PPModal>
                    <Confirmation
                        display={pageExitConfirmation}
                        title='Tem certeza que deseja sair?'
                        text={'Ao clicar em "Continuar", você será redirecionado para outra página. Tem certeza de que deseja prosseguir?'}
                        confirm={() => history.push('systems')}
                        textBtnOk='Continuar'
                        close={() => setPageExitConfirmation(false)}
                    />
                    <SystemModalDetails modalOpen={modalOpen} setModalOpen={setModalOpen} systemSelected={systemSelected} />

                </Layout>
            )
    );
}

export default ResumePathManager;