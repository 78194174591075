import moment from 'moment';
import React, { useEffect, useState } from 'react';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import { formatNumberLC, calculateMaxY, sCdateToJsDate } from '../../CSP/resume';
import { LCTechService } from '../../../services/LCTech/LCTechService';
import ReactECharts from 'echarts-for-react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

interface last30DatsConsumptionCloudProps {
    month: string;
    year: string;
    focused: boolean;
}

const getOption = (resourceUsagePivotRecords: any[]) => {
    return {
        tooltip: {
            backgroundColor: '#f1f5fb',
            borderWidth: 0,
            textStyle: {
                fontSize: 11,
                fontFamily: 'Ubuntu',
                color: '#2B304F',
            },
            trigger: 'axis',
            formatter: function (params) {
                const valueFormatted = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(params[0].value[1]);
                return `${moment(params[0].value[0]).format('DD/MM/YYYY')} : ${valueFormatted}`;
            },
            axisPointer: {
                type: 'line', // vertical line
                lineStyle: {
                    color: '#ccc' // set the desired color for the vertical line
                }
            }
        },
        xAxis: {
            type: 'time',
            boundaryGap: false,
            data: resourceUsagePivotRecords.map(item => item.data),
            axisLabel: {
                fontSize: '10px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                interval: 0
            },
            splitLine: {
                show: false
            }
        },
        yAxis: {
            type: 'value',
            boundaryGap: [0, '100%'],
            axisLabel: {
                formatter: value => formatNumberLC(value),
                fontSize: '10px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
            max: function (value) {
                // Utiliza a função calculateMaxY para definir o valor máximo de forma dinâmica
                const calculatedMax = calculateMaxY(value.max);
                return calculatedMax;
            }
        },
        series: [{
            data: resourceUsagePivotRecords.map(item => [item.data, item.total]),
            type: 'line',
            areaStyle: {},
            smooth: true
        }],
        grid: {
            height: 'fit-content',
            top: '16px',
            left: '3%',
            right: '3%',
            bottom: '5%',
            containLabel: true
        },
    }
}
export const Last30DatsConsumptionCloud: React.FC<last30DatsConsumptionCloudProps> = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const [chartInstance, setChartInstance] = useState<any>(null);
    const lcTechService = new LCTechService({});
    const [resourceUsagePivotNoRecordMsg, setResourceUsagePivotNoRecordMsg] = useState<string>('');

    const processResourceData = (response: any) => {
        const trintaDiasAtras = moment().subtract(30, 'day').toDate();
        const resourcePivot = response.data.resourceUsageRecords
            .map(resourceUsage => {
                const date = sCdateToJsDate(resourceUsage.usageDate);
                return {
                    date,
                    Assinatura: resourceUsage.entitlementName,
                    "Grupo do Recurso": resourceUsage.resourceGroupName,
                    Recurso: resourceUsage.resourceName,
                    Categoria: resourceUsage.meterCategory,
                    SubCategoria: resourceUsage.meterSubCategory,
                    "Mês Consumo": date.getMonth() + 1,
                    "Ano Consumo": date.getFullYear(),
                    "Dia Consumo": date.getDate(),
                    Tags: resourceUsage.tag,
                    Moeda: resourceUsage.currencyCode,
                    Total: resourceUsage.totalCost?.toFixed(6),
                    Unidade: resourceUsage.unitOfMeasure,
                    Quantidade: parseInt(resourceUsage.quantityUsed),
                    "Nome do Medidor": resourceUsage.meterName
                };
            })
            .filter(item => item.date >= trintaDiasAtras && item.date < new Date());
    
        const totalPorData = resourcePivot.reduce((acc, item) => {
            const total = parseFloat(item.Total);
            const data = `${item["Ano Consumo"]}-${String(item["Mês Consumo"]).padStart(2, '0')}-${String(item["Dia Consumo"]).padStart(2, '0')}`;
            acc[data] = (acc[data] || 0) + total;
            return acc;
        }, {});
    
        return Object.entries(totalPorData)
            .map(([data, total]) => {
                const [ano, mes, dia] = data.split('-').map(Number);
                return { data: new Date(ano, mes - 1, dia), total };
            })
            .sort((a, b) => b.data.getTime() - a.data.getTime());
    };
    
    const { data: resourceUsagePivotRecords, isLoading: resourceUsagePivotRecordsLoading } = useQuery(
        ['resourceUsage', props.month, props.year, user.ClientGroupSelected, user.refreshFlag],
        () => lcTechService.RetrieveUsage(1, true, props.month, props.year),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            onError: (error) => {
                console.error('Error fetching resource usage:', error);
            },
            select: (response) => {
                return response?.data?.resourceUsageRecords?.length
                    ? processResourceData(response)
                    : [];
            }
    
        }
    );
    
    useEffect(() => {
        if (!resourceUsagePivotRecords || resourceUsagePivotRecords.length === 0) {
            setResourceUsagePivotNoRecordMsg(
                resourceUsagePivotRecords?.length === 0
                    ? 'Não há dados disponíveis de consumo.'
                    : 'Não foram encontrados dados de cloud pública.'
            );
        }
    }, [resourceUsagePivotRecords]);

    useEffect(() => {
        console.log(chartInstance)
        if (!chartInstance) return;

        const resizeObserver = new ResizeObserver(() => {
            if (chartInstance) {
                chartInstance.resize();
            }
        });

        const container = chartInstance.getDom().parentElement;
        if (container) {
            resizeObserver.observe(container);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [chartInstance, props.focused]);

    return (
        <LcLoading loading={resourceUsagePivotRecordsLoading}>
        {
            resourceUsagePivotRecords && resourceUsagePivotRecords.length > 0 ?
                <ReactECharts
                    style={{ width: '100%', height: '100%' }}
                    opts={{ renderer: 'svg', locale: 'PT-br' }}
                    option={getOption(resourceUsagePivotRecords)} 
                    onChartReady={(chart) => setChartInstance(chart)}
                    notMerge={true}
                /> 
                :
                <LcNoData size="small" label={resourceUsagePivotNoRecordMsg} />
        }
        </LcLoading>
    )
}
