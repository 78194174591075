import React, { Fragment, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
import './index.css';
import LcIconLink from '../../Generic/LcIconLink';
import LcDropDown from '../../Generic/LcDropDown';
import { DateRangePick, SliderField } from '../../Form/Input';
import LcCheckBox from '../../Form/LcCheckBox';
import { PPInput, PPModal } from 'processor-plataform-ui';
import LcAccordion from '../../Generic/LcAccordion';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import PPDropDown from '../../PPDropDown';
import LcNoData from '../../Generic/LcNoData';
import LcTooltip from '../../Generic/LcTooltip';
import { PiDotsThreeVertical } from 'react-icons/pi';

//Campos do Filtro
interface FilterField {
    label: string,
    name: string,
    type: string,
    value?: string | any,
    options?: any[],
    show?: boolean,
    min?: number,
    max?: number,
    topDropdown?: boolean
    marks?: [{ label: string, value: number }]
    multiselect?: boolean
}

//Objeto do Filtro
interface FilterData {
    fields: FilterField[],
    onChange: Function,
    visible?: boolean,
    onChangeVisibility?: Function
}

export interface Sort {
    size: number,
    sort: SortCollumn,
}
interface SortCollumn {
    column: string, 
    direction: string, 
    headerName?: string 
}
export interface TableColumn {
    flex?: number;
    field: string;
    headerName?: string;
    width?: string | number;
    align?: string;
    hide?: boolean;
    truncate?: boolean;
    expandable?: boolean;
    renderCell?: (row: any, density?: string) => JSX.Element | JSX.Element[] | string | undefined | any;
    showOnHover?: boolean;
    fontSize?: string;
    overflow?: string;
}

interface Props {
    id?:string,
    groupName?: string[],
    loading?: boolean,
    rows: any[],
    columns: TableColumn[],
    density?: "high" | "medium" | "low",
    functions?: {
        icon: string,
        tooltip?: string,
        tooltipPosition?: 'left' | 'right' | 'inline-left' | 'inline-right',
        func: Function,
        disabled?: boolean
    }[],
    functionGeneric?: {
        id?: string;
        icon: any,
        tooltip?: string,
        badge?: { number: number, status: string },
        func: Function,
        disabled?: boolean
    }[],
    functionsRow?: {
        label: string,
        func: Function,
        disabled?: Function,
        hidden?: Function
    }[],

    loadMore?: Function,
    notLoadMore?: boolean,
    size?: number, //Tamanho total dos dados retornado pela API
    height?: string,
    FilterModalHeight?: string,
    fontSize?: 'tiny' | 'small' | 'medium' | 'large' | 'big',
    onSortChange?: Function,
    onSelectChange?: Function,
    filter?: FilterData,
    status?: Function,
    rowAutoHeight?: boolean,
    hidePagination?: boolean,
    collapsedGroups?: boolean,
    noDataMessage?: string,
    topDropdown?: boolean,
    hidetopBar?: boolean,
    hideHeader?: boolean,
    disableFilterModal?: boolean,
    truncate?: boolean,
    tooltipContentColumn?: string
    customTooltipContentColumn?: Function,
    className?: string,
}

const ExpansibleCell = ({ content, expandable, rowId, onExpandToggle }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const CHAR_LIMIT = 370; // Definindo o limite de caracteres como uma constante

    // Função para verificar se o conteúdo é um elemento React.
    const isReactElement = (content: any): content is ReactElement => React.isValidElement(content);

    // Função para extrair texto de conteúdo React ou JSX para determinar a necessidade do botão expandir.
    const extractTextForLengthCheck = (element: any): string => {
        if (typeof element === 'string') {
            return element;
        } else if (Array.isArray(element)) {
            return element.map(extractTextForLengthCheck).join('');
        } else if (isReactElement(element) && element.props && element.props.children) {
            return extractTextForLengthCheck(element.props.children);
        }
        return '';
    };


    const contentAsString = typeof content === 'string' ? content : extractTextForLengthCheck(content);
    const showExpandButton = expandable && contentAsString.length > CHAR_LIMIT;

    const renderContent = () => {
        if (!showExpandButton) {
            // Se não é para mostrar o botão de expandir, apenas retorne o conteúdo diretamente.
            return typeof content === 'string' ? ReactHtmlParser(content) : content;
        } else {
            // Se é para mostrar o botão, aplique a lógica de truncamento baseada em CHAR_LIMIT.
            return isExpanded ? ReactHtmlParser(contentAsString) : ReactHtmlParser(`${contentAsString.substring(0, CHAR_LIMIT)}...`);
        }
    };

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
        if (onExpandToggle) {
            // Adiciona um pequeno atraso antes de chamar o callback
            setTimeout(() => {
                onExpandToggle(rowId, !isExpanded); // Chama o callback com o novo estado
            }, 10); // Ajuste este valor conforme necessário
        }
    };



    return (
        <div className='expandable-cell' onClick={toggleExpand} style={{ cursor: showExpandButton ? 'pointer' : 'default' }}>
            <div className={isExpanded ? 'expandable expanded' : 'expandable collapsed'}>
                {renderContent()}
            </div>
            {showExpandButton && (
                <span className='expand-button'>
                    {isExpanded ? 'Ver menos' : 'Ver mais'}
                </span>
            )}
        </div>
    );
};

const getStatusRow = (status: string) => {
    switch (status) {
        case 'primary':
            return '#2E1956';
        case 'secondary':
            return '#1b3a7a';
        case 'danger':
            return '#e64646';
        case 'success':
            return '#a5bf3e';
        case 'warning':
            return '#ffb800';
        case 'link':
            return '#0580ce';
        default:
            return status;
    }
}


const LcInfiniteTable: React.FC<Props> = (props) => {

    const [showScroll, setShowScroll] = useState(false);
    const bodyRef = useRef<HTMLDivElement>(null);
    const rowRefs = useRef({});
    const colorStatusRefs = useRef({});
    const [isInView, setIsInView] = useState(false);
    const observerRef = useRef<HTMLDivElement>(null);
    const [clientHeight, setClientHeight] = useState(0);
    const [filter, setFilter] = useState<FilterField[] | undefined>(props.filter?.fields);
    const [asFilter, setAsFilter] = useState(false);
    const [modalFilterVisible, setModalFilterVisible] = useState(false);
    const [selectedRows, setSelectedRows] = useState<object[]>([]);
    const [sort, setSort] = useState<{ column: string, direction: string, headerName?: string }>();
    const [mouseOnRow, setMouseOnRow] = useState<number | undefined>(undefined);
    const [topBarHeight, setTopBarHeight] = useState(0);
    const [headerHeight, setHeaderHeight] = useState(0);
    const [bodyHeight, setBodyHeight] = useState(0);
    const user = useSelector<RootState, UserState>(state => state.user);
    
    const adjustColorStatusHeight = (rowId, isExpanded) => {
        const rowElement = rowRefs.current[rowId];
        const colorStatusElement = colorStatusRefs.current[rowId];
        const baseHeight = 60; // Altura original da linha
        const topAdjustmentFactor = 0.3; // Fator de ajuste para o top, ajuste conforme necessário
    
        if (rowElement && colorStatusElement) {
            if (isExpanded) {
                const newHeight = rowElement.offsetHeight - 0; // Ajuste conforme necessário
                const heightIncrease = rowElement.offsetHeight - baseHeight;
                // Ajusta o fator de ajuste de top baseado na diferença de altura
                const topAdjustment = ((heightIncrease / baseHeight) * 100) * topAdjustmentFactor;
    
                colorStatusElement.style.top = `-${topAdjustment}px`; // Aplica o ajuste como valor absoluto em px
                colorStatusElement.style.height = `${newHeight}px`;
            } else {
                colorStatusElement.style.top = '0px';
                colorStatusElement.style.height = '100%';
            }
        }
    };
    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    // Ensure loading state is set before triggering loadMore
                    setIsInView(true);
                    if (!props.loading && props.loadMore) {
                        props.loadMore();
                    }
                } else {
                    setIsInView(false);
                }
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            }
        );

        const currentRef = observerRef.current;
        
        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
            observer.disconnect();
        };
    }, [observerRef, props.loading, props.loadMore]);
    useEffect(() => {
        const checkScroll = () => {
            if (bodyRef.current) {
                const hasVerticalScrollbar = bodyRef.current.scrollHeight > bodyRef.current.clientHeight;
                setShowScroll(hasVerticalScrollbar);
            }
        };

        // Verifica a barra de rolagem ao montar e quando as rows mudarem
        checkScroll();
        window.addEventListener('resize', checkScroll);

        // Limpeza do evento
        return () => window.removeEventListener('resize', checkScroll);
    }, [props.rows]);

    useEffect(() => {
        let defaultRowHeight =
            props.density === "low" ? 64 : //60 de altura + 4 de espaço
                props.density === "medium" ? 42 : //38 de altura + 4 de espaço
                    29; //28 de altura + 1 de espaço

        if (bodyHeight > 0) {
            let rows = Math.floor((bodyHeight) / defaultRowHeight);
            setClientHeight(rows)
            props.loadMore && props.loadMore(rows);
        }
    }, [topBarHeight, headerHeight, bodyHeight])

    useEffect(() => {
        !props.notLoadMore && props.rows && props.rows.length > 0 && isInView &&
            props.loadMore && props.loadMore();
    }, [isInView])

    useEffect(() => {
        setFilter(props.filter?.fields)
    }, [user.ClientGroupSelected])

    const DateRange = (l, event, f) => {

        let response = { startDate: '', endDate: '' };

        if ((l.name + '_begin') === event.target[f.name + '_begin'].name) {
            response.startDate = event.target[f.name + '_begin'].value;
        }
        if ((l.name + '_end') === event.target[f.name + '_end'].name) {
            response.endDate = event.target[f.name + '_end'].value;
        }

        if (response.startDate !== '' && response.endDate !== '') {
            return response;
        } else {
            if ((response.startDate !== '' && response.endDate === '') || (response.endDate !== '' && response.startDate === '')) {
                alert("Verificar datas do filtro");
                return response = { startDate: '', endDate: '' };
            } else {
                return response = { startDate: '', endDate: '' };
            }
        }
    }

    const CheckBox = (l, event, f) => {
        if (event.target[f.name].value === 'on') {
            return 'true';
        } else {
            return '';
        }
    }

    const Interval = (l, event, f) => {
        return event.target[f.name].value;
    }

    const handleFilterAdvanced = (event: any) => {
        event.preventDefault();
        setSelectedRows([]);
        let _filterAdvanced = Object.assign([], filter);
        let filterCount = 0;
        //TODO FFF: Entender o que esse monte de case (que era if else) faz?????????
        filter &&
            filter
                .map(f => {
                    switch (f.type) {
                        case "spanDateTime":
                            _filterAdvanced.find((l: FilterField) => f.value = DateRange(l, event, f));
                            f.value.startDate !== '' && f.value.endDate !== '' && filterCount++;
                            break;
                        case "checkBox":
                            _filterAdvanced.find((l: FilterField) => f.value = CheckBox(l, event, f));
                            f.value !== '' && filterCount++;
                            break;
                        case "interval":
                            _filterAdvanced.find((l: FilterField) => f.value = Interval(l, event, f));
                            f.value !== `${f.min},${f.max}` && filterCount++;
                            break;
                        case "select":
                            if (f.multiselect) {
                                _filterAdvanced.find((l: FilterField) => l.name == event.target[f.name].name ? f.value.concat(event.target[f.name].value) : false)
                                f.value && f.value.length > 0 && filterCount++;
                            }
                            break;
                        default:
                            _filterAdvanced.find((l: FilterField) => l.name == event.target[f.name].name ? f.value = event.target[f.name].value : false)
                            f.value !== '' && filterCount++;
                            break;
                    }
                })
        setAsFilter(filterCount > 0);
        if (props.filter) {
            props.filter.onChange(_filterAdvanced, clientHeight);
            setModalFilterVisible(false);
            props.filter.onChangeVisibility &&
                props.filter.onChangeVisibility(false)
        }
    }

    const handleFilterClear = () => {
        setAsFilter(false);
        var _filter = filter?.map((f: any) => {
            return {
                ...f,
                value: ""
            }
        })
        setFilter(_filter);
        props.filter?.onChange(_filter, clientHeight);
    }

    const handleSort = (column: string, headerName?: string) => {
        if (!sort || sort.column !== column || sort.headerName !== headerName) {
            setSort({ column, direction: 'asc', headerName });
        } else {
            setSort({ ...sort, direction: sort.direction === 'asc' ? 'desc' : 'asc' });
        }
    }

    type SortOrder = 'asc' | 'desc';
    function dynamicSort<T>(items: T[], propertyName: keyof T, order: SortOrder = 'asc'): T[] {
        return items.sort((a, b) => {
            const valueA = a[propertyName];
            const valueB = b[propertyName];

            // Handle null or undefined values
            if (valueA == null && valueB == null) return 0;
            if (valueA == null) return order === 'asc' ? -1 : 1;
            if (valueB == null) return order === 'asc' ? 1 : -1;

            // General comparison for different or mixed types
            let comparison = 0;
            if (typeof valueA === 'number' && typeof valueB === 'number' ||
                typeof valueA === 'string' && typeof valueB === 'string') {
                comparison = valueA > valueB ? 1 : (valueA < valueB ? -1 : 0);
            } else {
                // Fallback to string comparison for mixed or other types
                const stringA = String(valueA);
                const stringB = String(valueB);
                comparison = stringA.localeCompare(stringB);
            }

            return order === 'asc' ? comparison : -comparison;
        });
    }

    const [sortedRows, setSortedRows] = useState<any[]>([]);

    useEffect(() => {
        if (props.onSortChange && sort) {
            props.onSortChange({ sort, size: clientHeight });
        }
    }, [sort]);

    const memoizedSortedRows = useMemo(() => {
        if (!(props.onSortChange)) {
            if (sort && props.rows) {
                return dynamicSort(props.rows, sort.column, sort.direction as 'asc' | 'desc');
            }
        }
        return props.rows;
    }, [props.rows, sort]);

    useEffect(() => {
        setSortedRows(memoizedSortedRows);
    }, [memoizedSortedRows]);

    useEffect(() => {
        props.onSelectChange &&
            props.onSelectChange(selectedRows);
    }, [selectedRows])


    const handleOnchangeFilterField = (event: any, name?: string) => {
        if (Array.isArray(event)) {
            const value = event.map(e => e.value);
            filter &&
                setFilter(filter.map(f => {
                    return f.name == name ? { ...f, value: value } : f
                }))
        } else {
            const { value } = event;
            filter &&
                setFilter(filter.map(f => {
                    return f.name == name ? { ...f, value: value } : f
                }))
        }
    }

    // Verifique se o modal de filtro deve ser aberto com base em props.filter?.visible e disableFilterModal
    const isFilterModalVisible = props.filter?.visible && !props.disableFilterModal;

    return (
        <div id={props.id } className={`lc-datatableinfinite ${props.className || ''}`} style={{ height: props.height || '100%' }}>
            <PPModal
                size='450px'
                visible={isFilterModalVisible || modalFilterVisible}
                title="Filtro"
                onClose={() => {
                    setModalFilterVisible(false);
                    props.filter?.onChangeVisibility &&
                        props.filter.onChangeVisibility(false)
                }}>
                <form onSubmit={handleFilterAdvanced} style={{ minHeight: props.FilterModalHeight, justifyContent: 'space-around' }}>
                    {
                        filter &&
                        filter
                            .filter(f => (f.show == undefined || f.show == true))
                            .map((f, i) => {
                                const isMultiSelect = f.multiselect !== undefined && f.multiselect === true;
                                const singleOpts = f.options?.map(opt => ({ value: opt.value, label: opt.name })) || [];
                                const singleOptsValue = singleOpts.find(opt => opt.value == f.value) || [];
                                const multiOpts = f.options?.map(opt => ({ value: opt.value, label: opt.name, selected: opt.selected })) || [];
                                const multiSelector = (opt) => f.value?.length > 0 ? f.value?.includes(opt.value) : opt.selected;
                                const multiOptsValue = multiOpts.filter(multiSelector).map(opt => ({ value: opt.value, label: opt.label, selected: multiSelector(opt) })) || [];
                                return (
                                    <div key={i}>
                                        {(f.type === 'spanDateTime') &&
                                            <DateRangePick
                                                label={f.label}
                                                name={f.name}
                                                value={f.value}
                                            />
                                        }

                                        {(f.type === 'interval') &&
                                            <SliderField
                                                marks={f.marks}
                                                name={f.name}
                                                label={f.label}
                                                min={f.min ? f.min : 0}
                                                max={f.max ? f.max : 100}
                                            />
                                        }

                                        {(f.type === 'checkBox') &&
                                            <LcCheckBox
                                                title={f.label}
                                                name={f.name}
                                                checked={f.value ? true : false}
                                            />
                                        }
                                        {(f.type === 'select') &&
                                            <PPDropDown
                                                menuPlacement={f.topDropdown ? 'top' : 'auto'}
                                                name={f.name}
                                                value={isMultiSelect ? multiOptsValue : singleOptsValue}
                                                onChange={(e) => handleOnchangeFilterField(e, f.name)}
                                                options={isMultiSelect ? multiOpts : singleOpts}
                                                title={f.label}
                                                maxMenuHeight={130}
                                                placeholder='Selecione...'
                                                isMulti={isMultiSelect}
                                            />
                                        }

                                        {(f.type === 'text') &&
                                            /*<PPInput name={f.name} value={f.value} onChange={(e) => handleOnchangeFilterField(e.target, f.name)} type={f.type} title={f.label} />*/
                                            <PPInput autoComplete='off' name={f.name} value={f.value || ''} onChange={(e) => handleOnchangeFilterField(e.target, f.name)} type={f.type} title={f.label} />

                                        }

                                        {(f.type === 'date') &&
                                            /*<PPInput name={f.name} value={f.value} onChange={(e) => handleOnchangeFilterField(e.target, f.name)} type={f.type} title={f.label} />*/
                                            <PPInput name={f.name} value={f.value || ''} onChange={(e) => handleOnchangeFilterField(e.target, f.name)} type={f.type} title={f.label} />
                                        }
                                    </div>
                                )
                            })
                    }
                    <div className="space-between">
                        <button id="8bda7e8c-2b4c-4466-9cd1-cff90fa38045" className="lc-button bg-info" type="submit">Filtrar</button>
                        <button id="a24f7945-b978-4f42-9fbf-956f44784958" className="lc-button" onClick={() => handleFilterClear()}>Limpar</button>
                    </div>
                </form>
            </PPModal>
            {
                !props?.hidetopBar &&
                <div className="topbar" ref={(ref: any) => { ref && setTopBarHeight(ref.clientHeight) }}            >
                    <div className="functions">

                        {
                            props.functions &&
                            <div>
                                {
                                    props.functions.map((func, index) => {
                                        return <LcIconLink
                                            tooltipPosition={func.tooltipPosition ? func.tooltipPosition : index === 0 ? 'left' : undefined}
                                            disabled={func.disabled || false}
                                            key={index}
                                            icon={func.icon}
                                            tooltip={func.tooltip}
                                            onClick={() => { func.func(selectedRows) }} />
                                    })
                                }
                            </div>
                        }
                        {
                            props.functionGeneric &&
                            <div>
                                {
                                    props.functionGeneric.map((func, index) => {
                                        return <LcIconLink
                                            id={func.id}
                                            tooltipPosition={index == 0 ? 'left' : undefined}
                                            key={index}
                                            icon={func.icon}
                                            tooltip={func.tooltip}
                                            onClick={func.func} />
                                    })
                                }
                            </div>

                        }
                        {
                            props.filter && !props.filter.onChangeVisibility &&
                            <div>
                                <LcIconLink  id= "filter_button"icon={`lci lci-filter ${asFilter ? 'text-warning' : ''}`} onClick={() => { setModalFilterVisible(!modalFilterVisible) }} tooltip="Filtrar" tooltipPosition='left' />
                            </div>
                        }
                    </div>
                    {
                        !props?.hidePagination &&
                        <div className="pagination">
                            {
                                props.onSelectChange &&
                                <div className="pageinfo">
                                    {selectedRows.length} linha{selectedRows.length == 1 ? '' : 's'} selecionadas
                                </div>
                            }
                            {
                                !props.filter?.onChangeVisibility &&
                                <span className="pageinfo">
                                    {props.rows && props.rows.length} de {props.size}
                                </span>

                            }
                        </div>
                    }
                </div>
            }

            {
                props.rows.length > 0 && !props?.hideHeader &&
                <div className={`header ${props.status ? " status" : ""}`} ref={(ref: any) => { ref && setHeaderHeight(ref.clientHeight) }}>
                    <div className='row'>
                        {
                            props.onSelectChange &&
                            <div className="selectable" >
                                <i className={`lci lci-checkbox${selectedRows.length > 0 ? '-on text-warning' : ' text-grey'}`}
                                    onClick={() => {
                                        selectedRows.length > 0 ?
                                            setSelectedRows([])
                                            :
                                            setSelectedRows(props.rows)
                                    }} />
                            </div>
                        }

                        <div className="cells" style={{ justifyContent: props.groupName ? 'space-around' : '', marginLeft: props.groupName ? '1.4rem' : '' }}>
                            {
                                props.columns.map((c: any, i: number) => {
                                    return c.hide !== true && (
                                        c.headerName !== '' ?
                                            <div
                                                key={i}
                                                className={`cell ${c.sort !== false ? 'sortable' : ''} ${sort?.column === c.field && sort?.headerName === c.headerName ? 'text-link' : ''} ${c.align || ''}`}
                                                style={{ width: c.width }}
                                                onClick={() => c.sort !== false && handleSort(c.field, c.headerName)}
                                            >
                                                <span>{c.headerName}</span>
                                                {
                                                    c.headerName && sort && sort.column === c.field && sort.headerName === c.headerName &&
                                                    <i className={`lci lci-arrow-${sort.direction === 'asc' ? 'down' : 'up'}`} />
                                                }
                                            </div>
                                            :
                                            <div key={i} style={{ width: c.width }}></div>
                                    )
                                })
                            }
                        </div>
                        {
                            props.functionsRow &&
                            <div className="functions" />
                        }
                        {(showScroll || props.status) && <div className="scroll"></div>}
                    </div>
                </div>
            }
            <div className={`body ${props.density || "high"}density`}
                style={{ height: `calc(100% - ${topBarHeight + headerHeight}px)` }}
                ref={bodyRef}
            //ref={(ref: any) => { ref && setBodyHeight(ref.clientHeight) }}
            >
                {
                    sortedRows && sortedRows.length > 0 &&
                    sortedRows.map((row: any, iRow: number) => {

                        if (props.groupName) {
                            return (
                                props.groupName?.map((i, index) => {
                                    return (
                                        <LcAccordion title={i} visible={props.collapsedGroups ? !props.collapsedGroups : true} key={index}>
                                            {
                                                row[i]?.map((rows: any, iRows: number) => {
                                                    return (
                                                        <div
                                                            className={`row${props.rowAutoHeight ? ' autoHeight' : ''}${props.status ? " status" : ""}`}
                                                            ref={(el) => (rowRefs.current[row.id] = el)}
                                                            onMouseOver={() => { setMouseOnRow(iRows) }}
                                                            onMouseLeave={() => { setMouseOnRow(undefined) }}
                                                            key={iRows}
                                                        >
                                                            {props.status && (
                                                                <>
                                                                    <LcTooltip
                                                                        content={props.tooltipContentColumn && (props.customTooltipContentColumn ? props.customTooltipContentColumn(rows[props.tooltipContentColumn]) :
                                                                            rows[props.tooltipContentColumn] ? rows[props.tooltipContentColumn] : '')
                                                                        }
                                                                        trigger='hover'
                                                                        position='left'
                                                                    >
                                                                        <div
                                                                            ref={(el) => (colorStatusRefs.current[row.id] = el)}
                                                                            className="color-status"
                                                                            style={{
                                                                                backgroundColor: `${getStatusRow(props.status(rows))}`,
                                                                            }}
                                                                        >
                                                                        </div>
                                                                    </LcTooltip>
                                                                </>
                                                            )}

                                                            {
                                                                (() => {
                                                                    let isOnSelectedRows = selectedRows.find((r: any) => { return r.id == rows.id });

                                                                    if (props.onSelectChange) {
                                                                        return <div className="selectable" onClick={() => {
                                                                            isOnSelectedRows ?
                                                                                setSelectedRows(selectedRows.filter((r: any) => r.id != rows.id))
                                                                                :
                                                                                setSelectedRows([...selectedRows, rows])
                                                                        }}>
                                                                            <i className={`lci lci-checkbox${isOnSelectedRows ? '-on text-warning' : ' text-grey'}`} />
                                                                        </div>
                                                                    }
                                                                })()
                                                            }

                                                            <div key={iRows} className="cells" style={{
                                                                display: 'flex',
                                                                justifyContent: 'space-around',
                                                                width: '100%'
                                                            }}
                                                            >
                                                                {props.columns.map((col, iCol) => {
                                                                    if (!col.hide) {
                                                                        let cellContent;
                                                                        if (col.renderCell) {
                                                                            // Se há uma função renderCell, usa-a para renderizar o conteúdo da célula.
                                                                            cellContent = col.renderCell(rows, props.density);
                                                                        } else {
                                                                            // Se não há renderCell, verifica se precisa truncar ou expandir o conteúdo.
                                                                            const rawContent = rows[col.field];
                                                                            if (col.truncate) {
                                                                                // Se precisa truncar, envolve o conteúdo em <span> com a classe 'ellipsis'.
                                                                                cellContent = <span className='ellipsis'>{rawContent}</span>;
                                                                            } else {
                                                                                // Se não precisa truncar, exibe o conteúdo diretamente.
                                                                                cellContent = rawContent;
                                                                            }
                                                                        }

                                                                        // Se a coluna for expansível, envolve o cellContent no componente ExpansibleCell.
                                                                        if (col.expandable) {
                                                                            cellContent = <ExpansibleCell content={cellContent} expandable={true} rowId={row.id} onExpandToggle={adjustColorStatusHeight}
                                                                            />;
                                                                        }

                                                                        return (
                                                                            <div key={iCol}
                                                                                title={col.truncate ? rows[col.field] : undefined}
                                                                                className={`cell ${col.fontSize || props.fontSize || ''} ${col.align || ""}`}
                                                                                style={{
                                                                                    visibility: (col.showOnHover && mouseOnRow !== iRow) ? 'hidden' : 'visible',
                                                                                    width: col.width,
                                                                                    overflow: col.overflow,
                                                                                }}
                                                                            >
                                                                                {cellContent}
                                                                            </div>
                                                                        );
                                                                    }
                                                                    return null;
                                                                })}
                                                            </div>

                                                            {props.functionsRow &&
                                                                <div className="functions body-functions">
                                                                    <LcDropDown element={<LcIconLink icon={<PiDotsThreeVertical />} size="small" />} position="right">
                                                                        {
                                                                            props.functionsRow.map((f, i) => {
                                                                                if (!f.hidden || !f.hidden(rows)) {
                                                                                    let disabled = (f.disabled && f.disabled(rows)) || false;
                                                                                    return (<div key={i} className={`item${disabled ? ' disabled' : ''}`} onClick={() => { !disabled && f.func(rows) }}>{f.label}</div>)
                                                                                }
                                                                            })
                                                                        }
                                                                    </LcDropDown>
                                                                </div>
                                                            }

                                                        </div>
                                                    )
                                                })

                                            }
                                        </LcAccordion>
                                    )

                                })

                            )
                        } else {
                            return (
                                <div
                                    className={`row${props.rowAutoHeight ? ' autoHeight' : ''}${props.status ? " status" : ""}`}
                                    ref={(el) => (rowRefs.current[row.id] = el)}
                                    onMouseOver={() => { setMouseOnRow(iRow) }}
                                    onMouseLeave={() => { setMouseOnRow(undefined) }}
                                    key={iRow}
                                >
                                    {props.status && (
                                        <>
                                            <LcTooltip
                                                content={props.tooltipContentColumn && (props.customTooltipContentColumn ? props.customTooltipContentColumn(row[props.tooltipContentColumn]) :
                                                    row[props.tooltipContentColumn] ? row[props.tooltipContentColumn] : '')}
                                                trigger='hover'
                                                position='left'
                                            >
                                                <div className='color-status'
                                                    ref={(el) => (colorStatusRefs.current[row.id] = el)}
                                                    style={{
                                                        backgroundColor: `${getStatusRow(props.status(row))}`,
                                                    }}
                                                >
                                                </div>
                                            </LcTooltip>
                                        </>
                                    )}

                                    {
                                        (() => {
                                            let isOnSelectedRows = selectedRows.find((r: any) => { return r.id == row.id });

                                            if (props.onSelectChange) {
                                                return <div className="selectable" onClick={() => {
                                                    isOnSelectedRows ?
                                                        setSelectedRows(selectedRows.filter((r: any) => r.id != row.id))
                                                        :
                                                        setSelectedRows([...selectedRows, row])
                                                }}>
                                                    <i className={`lci lci-checkbox${isOnSelectedRows ? '-on text-warning' : ' text-grey'}`} />
                                                </div>
                                            }
                                        })()
                                    }

                                    <div className="cells">
                                        {props.columns.map((col, iCol) => {
                                            if (!col.hide) {
                                                let cellContent;
                                                if (col.renderCell) {
                                                    // Se há uma função renderCell, usa-a para renderizar o conteúdo da célula.
                                                    cellContent = col.renderCell(row, props.density);
                                                } else {
                                                    // Se não há renderCell, verifica se precisa truncar ou expandir o conteúdo.
                                                    const rawContent = row[col.field];
                                                    if (col.truncate) {
                                                        // Se precisa truncar, envolve o conteúdo em <span> com a classe 'ellipsis'.
                                                        cellContent = <span className='ellipsis'>{rawContent}</span>;
                                                    } else {
                                                        // Se não precisa truncar, exibe o conteúdo diretamente.
                                                        cellContent = rawContent;
                                                    }
                                                }

                                                // Se a coluna for expansível, envolve o cellContent no componente ExpansibleCell.
                                                if (col.expandable) {
                                                    cellContent = <ExpansibleCell content={cellContent} expandable={true} rowId={row.id} onExpandToggle={adjustColorStatusHeight} />;
                                                }

                                                return (
                                                    <div
                                                        id={col.field + '_' + iCol + '_' + iRow}
                                                        key={iCol}
                                                        title={col.truncate ? row[col.field] : undefined}
                                                        className={`cell ${col.fontSize || props.fontSize || ''} ${col.align || ""}`}
                                                        style={{
                                                            visibility: (col.showOnHover && mouseOnRow !== iRow) ? 'hidden' : 'visible',
                                                            width: col.width,
                                                            overflow: col.overflow,
                                                        }}
                                                    >
                                                        {cellContent}
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>


                                    {props.functionsRow &&
                                        <div key={iRow} className="functions">
                                            <LcDropDown element={<LcIconLink icon={<PiDotsThreeVertical />} size="small" />} position="right">
                                                {
                                                    props.functionsRow.map((f, i) => {
                                                        if (!f.hidden || !f.hidden(row)) {
                                                            let disabled = (f.disabled && f.disabled(row)) || false;
                                                            return (<div key={i} className={`item${disabled ? ' disabled' : ''}`} onClick={() => { !disabled && f.func(row) }}>{f.label}</div>)
                                                        }
                                                    })
                                                }
                                            </LcDropDown>
                                        </div>
                                    }

                                </div>
                            )
                        }
                    })
                }
                {
                    ((!props.notLoadMore && ((props.loading) || ((props.size && props.size > 0 ? true : false) && (props.size && props.size > clientHeight && (props.rows && props.rows.length < props.size))))
                        && !(!props.loading && (!props.rows || (props.rows && props.rows.length === 0)))))
                    &&
                    <div className="loader">
                        <div ref={observerRef}>
                            {
                                // inView ?
                                    <LcNoData label="Carregando..." icon="refresh" />
                                    // :
                                    // <i className="lci lci-dots-horizontal" />
                            }
                        </div>
                    </div>
                }
                {
                    (!((props.loading)
                        || (props.size && props.size > clientHeight && (props.rows && props.rows.length < props.size))) && (!props.loading && (!props.rows || (props.rows && props.rows.length === 0)))) &&
                    <div style={{ height: props.height || '100%' }}>
                        <LcNoData height="full" label={props.noDataMessage || "Não há dados para serem exibidos no momento."} />
                    </div>
                }

            </div>

        </div >
    );
}

export default LcInfiniteTable;