import { PPModal, PPForm2 } from 'processor-plataform-ui';
import React, { useState } from 'react';
import LcIconLink from '../../../components/Generic/LcIconLink';
import Attachment from '../Attachment';
import { useDispatch } from 'react-redux';
import { HeyHoService } from '../../../services/Heyho/heyHoService';
import { PPFormInput } from 'processor-plataform-ui/dist/components/PPForm2';
import { PiCopyLight } from 'react-icons/pi';
import { copyToClipbord } from '../HHUtilities';
import { useSnackbar } from 'notistack';
import HHComponentAI from '../../../components/Generic/HeyHoNewRequest/HHComponentAI';
import { FormDataHeyho } from '../../../services/Heyho/heyhoModel';
import LcLoading from '../../../components/Generic/LcLoading';

interface ModalNewRequestProps {
    openModalNewRequest: boolean;
    setOpenModalNewRequest: (open: boolean) => void;
    setLoading: (open: boolean) => void;
    resetData:() => void;
}

export const ModalNewRequest: React.FC<ModalNewRequestProps> = (props) => {
    const ServiceHeyho: HeyHoService= new HeyHoService({})
    const emptyRecordMessage = {
        detail: false,
        edit: false,
        add: false,
        record: ServiceHeyho.emptyRecord
    }
    const [sugestaoExp, setSugestaoExp] = useState<boolean>(false);
    const [AiSelected, setAiSelected] = useState<number>(0);
    const [description, setDescription] = useState<string>('');
    const [subject, setSubject] = useState<string>('');
    const dispatch = useDispatch();
    const [updateField, setUpdateField] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [anexos, setAnexos] = useState<File[]>([]);
    const { enqueueSnackbar } = useSnackbar();
    const template: any = ServiceHeyho.templateEdit2();
    const [record, setRecord] = useState<any>(emptyRecordMessage);

    const handleCancel =() => {
        setRecord({ ...emptyRecordMessage }); 
        setSugestaoExp(false);
        setAiSelected(0);
        setDescription('');
        setSubject('');
        dispatch({ type: 'MENU_NEW_REQUEST', payload: false });
        props.setOpenModalNewRequest(false); 
        setUpdateField(false)
        setAnexos([])

    }

    const interfaceTyped: { [key: string]: any; } = { ...record.record };
    const fieldsForm: PPFormInput[] = [
        {
            id:'form_novochamado_assunto_field',
            name: 'titulo',
            type: 'text',
            title: 'Assunto',
            required: true,
            value: subject
        },
        {
            id:'form_novochamado_descricaodetalhada_field',
            name: 'Descricao',
            type: 'textarea',
            title: 'Descrição detalhada',
            required: true,
            rows: sugestaoExp ? 4 : 7,
            value: description
        },
    ]
    const onChangeForm = (event: FormDataHeyho) => {
        setRecord(prevRequest => ({
            ...prevRequest,
            record: {
                ...prevRequest.record,
                titulo: event.titulo,
                Descricao: event.Descricao           
            }
        }));
        setDescription(event.Descricao)
        setSubject(event.titulo)
        setUpdateField(true)
    }

    const handleFiles = (files_: any[]) => {
        let _record = record.record;
        _record.Anexo = [...anexos, ...files_];
        setRecord({ ...record, record: _record })
        setAnexos([...anexos, ...files_]);
    }
    
    const removeFile = (fileName: string) => {
        const files_ = anexos && anexos.filter(item => item.name !== fileName);
        setAnexos(files_);
    };

    const saveTicket = async (
        resetData: () => void
    ) => {
        if (!record.record.Descricao || /^\s*$/.test(record.record.Descricao)) {
            enqueueSnackbar(`Erro ao criar o chamado é obrigatorio o preenchimento do campo "Descrição detalhada"`, {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            })
            return;
        }
        if (!record.record.titulo || /^\s*$/.test(record.record.Descricao)) {
            enqueueSnackbar(`Erro ao criar o chamado é obrigatorio o preenchimento do campo "Assunto"`, {
                variant: 'error',
                preventDuplicate: true,
                persist: false,
            })
            return;
        }
        setSubmitting(true);
        try {
            const ServiceHeyho = new HeyHoService({})
            const newSolicitation = await ServiceHeyho.Save(record.record)
            if(newSolicitation.data === 400){
                enqueueSnackbar(`Erro ao criar chamado.`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                });
                return;
            }else {
                await handleFile(newSolicitation.data.id, newSolicitation.data.idUserRequester)
                enqueueSnackbar(
                    <div style={{ display: 'flex' }}>
                        Chamado {newSolicitation.data.id} criado com sucesso.
                        <LcIconLink
                            icon={<PiCopyLight/>}
                            size={'small'}
                            tooltip={'Copiar número de chamado'}
                            tooltipPosition={'inline-left'}
                            onClick={() => copyToClipbord(newSolicitation.data.id)}
                        />
                    </div>
                    , {
                        variant: 'success',
                        preventDuplicate: true,
                        persist: false,
                    });
            }
        } catch (e) {
                enqueueSnackbar(`Erro ao criar chamado.`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false,
                });
                console.error(e);
                props.setLoading(false);
            } finally {
                setSubmitting(false);
                resetData();
                handleCancel()
            }

    }

    const handleFile = async (id: number, idUserRequester: number) => {
        if (anexos.length > 0) {
            await ServiceHeyho.sendFile(anexos, id, idUserRequester,description)
                .then((response2) => {
                    if (response2.data === 201) {
                        enqueueSnackbar(`Anexo para o ticket ${id} criado com sucesso.`, {
                            variant: 'success',
                            preventDuplicate: true,
                            persist: false,
                        });
                    }
                    else if (response2.data === 400) {
                        enqueueSnackbar(`Erro ao anexar arquivo.`, {
                            variant: 'error',
                            preventDuplicate: true,
                            persist: false,
                        });
                    }
                })
                .catch((error) => {
                    enqueueSnackbar(`Erro ao anexar arquivo.`, {
                        variant: 'error',
                        preventDuplicate: true,
                        persist: false,
                    });
                    console.error(error);
                })
        }
        props.setLoading(false);
        setAnexos([])
    }


    return (
        <PPModal
        visible={props.openModalNewRequest}
        onClose={handleCancel}
        size={'large'}
        title={(record !== null && record.record !== null) && interfaceTyped[template.title] > 0 ?
            `Ticket:${interfaceTyped[template.title]}` : 'Novo chamado'}
    >
        <LcLoading loading={submitting} label='Carregando...'>
            <div className='newRequestModal scrollable-v'>
                <PPForm2
                    fields={fieldsForm}
                    returnType="object"
                    onChange={onChangeForm}
                    submitting={submitting}
                    updateFieldsOnChangeOrigin={updateField}
                    functions={
                        {
                            submitButton: {
                                hide: true,
                                label: 'Ok',
                            },
                        }
                    }
                >
                    <HHComponentAI 
                        originalText={description} 
                        visible={sugestaoExp} 
                        handleVisible={() => setSugestaoExp(!sugestaoExp)} 
                        setText={(e) =>  { onChangeForm({titulo: record.record.titulo, Descricao: e})} }
                        setUpdateField={(e) => setUpdateField(e)}
                        formData={record.record}
                        onChangeForm={(e) => onChangeForm(e)}
                    />
                    <Attachment
                        anexos={anexos}
                        removeFile={(e) => removeFile(e)}
                        setAnexos={handleFiles}
                        visible={!sugestaoExp}
                    />
                    <div className="w-100p space-between">
                        <button className='pp-button bg-info ml-3' id={'form_novochamado_abrirchamado_button'}onClick={() => saveTicket(props.resetData)}>Abrir chamado</button>
                        <button className='pp-button bg-gray mr-3' id={'form_novochamado_cancelar_button'} onClick={handleCancel}>Cancelar</button>
                    </div>
                </PPForm2>
            </div>
        </LcLoading>
    </PPModal>
    );
}
