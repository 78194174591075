import React, { useEffect, useState } from 'react';
import './index.css'; 
import { RecordProp } from '../../Model/RecordVendor';
import { VendorCredentialItem } from '../../Model/VendorCredentialItem';
import { AzureSubscription } from "../../Model/AzureSubscription";
import { VendorService } from '../../../../services/vendorService';
import { useForm } from 'react-hook-form';
import LcIconLink from '../../../../components/Generic/LcIconLink';
import { PPForm2, PPIconLink, PPModal, PPMultiSelect } from 'processor-plataform-ui';
import PPExpand2 from '../../../../components/Generic/PPExpand2';
import moment, { Moment } from 'moment';
import LcCheckList from '../../../../components/Form/LcCheckList';

const VendorCredentialItemList: React.FC<RecordProp> = (props) => {
    const ServiceInstance = new VendorService(props);
    const emptyRecord: VendorCredentialItem =
    {
        alias: '',
        id: 0,
        keysSecret: '',
        secret: '',
        vendorCredentialId: 0,
        active: true,
    };

    const { reset } = useForm();

    const emptyRecordMessage = {
        empty: true, record: emptyRecord
    };

    const [record, setRecord] = useState(emptyRecordMessage);
    const [listDataInterno, setListDataInterno] = useState<VendorCredentialItem[]>([]); 

    const [selectedSubscriptions, setSelectedSubscriptions] = useState<AzureSubscription[]>([]);

    function RetrieveListLocal() {
        let newDataList: VendorCredentialItem[] = [];
        if (props != null
            && props != undefined
            && props.record != null
            && props.record.vendorCredentialItem) {
            props.record.vendorCredentialItem.map((x) => {
                newDataList.push(x);
            });
        }

        if (newDataList && newDataList.length > 0) {
            setListDataInterno(newDataList);
        }
    };

    useEffect(() => {
        reset();
        RetrieveListLocal();
    }, [props, record]);

    const functions = [
        {
            id: "55c32e24-f3d4-4467-afbe-1dbe8c04798d",
            title: 'Novo',
            tooltip: 'Adicionar',
            icon: 'ppi ppi-plus',
            func: () => {
                ADD();
            }
        }
    ];

    function DeleteItem(id: number) {

        let newList = [...listDataInterno.filter(x => x.id != id)]
        setListDataInterno([...newList]);

        const currentRecord = props.record;
        const currentItems = currentRecord.vendorCredentialItem && currentRecord.vendorCredentialItem.length > 0 ?
            currentRecord.vendorCredentialItem : [];

        const newItems = currentItems && currentItems.length > 0 ? currentItems.map((item) => { item.active = !(item.id == id); return item; }) : [];

        currentRecord.vendorCredentialItem = [...newItems];

        props.setRecord(currentRecord);
    };

    function EditItem(item: any) {
        let addRecord = { ...emptyRecordMessage };
        addRecord.empty = false;
        addRecord.record = { ...item };
        setRecord(addRecord);
    };

    function ADD() {
        let addRecord = { ...emptyRecordMessage };
        addRecord.empty = false;
        addRecord.record = { ...emptyRecord };
        setRecord(addRecord);
    };

    const SaveVendorItems = (event: any) => {
        if (event.validated) {
            const currentRecord = props.record;
            const currentItems = currentRecord.vendorCredentialItem && currentRecord.vendorCredentialItem.length > 0 ?
                currentRecord.vendorCredentialItem : [];

            if (props.record.cloudOSProviderId === 16) {
                props.providerSubscriptions && props.providerSubscriptions(selectedSubscriptions);
            }

            if (record.record.id > 0) {
                let thisRecord = record.record;
                let keys = JSON.parse(thisRecord.keysSecret);

                thisRecord.alias = event.data.find(x => x.name.toLowerCase() === 'alias').value;
                event.data.forEach(campo => {
                    if (campo.name.toLowerCase() !== 'alias' && !(campo.name in keys)) {
                        keys[campo.name] = campo.value;
                    }
                });
                Object.keys(keys).map(key => {
                    event.data.map(campo => {
                        if (campo.name === key)
                            keys[key] = campo.value;
                    })
                })
                let jsonKey = JSON.stringify(keys)

                thisRecord.keysSecret = jsonKey;
                thisRecord.isDirty = true;

                let newList = currentItems.filter(it => it.id !== thisRecord.id) ?? [];
                newList.push(thisRecord);
                currentRecord.vendorCredentialItem = newList;
                props.setRecord(currentRecord);
                setRecord(emptyRecordMessage);

            } else {
                let thisRecord = record.record;
                let obj = new Object();
                event.data.map(campo => {
                    if (campo.name != "alias") {
                        obj[campo.name] = campo.value
                    }
                });
                let jsonKey = JSON.stringify(obj);

                thisRecord.alias = event.data.find(x => x.name.toLowerCase() == 'alias').value;
                thisRecord.vendorCredentialId = props.record.id;
                thisRecord.active = true;
                thisRecord.keysSecret = jsonKey;
                thisRecord.secret = ''
                thisRecord.isDirty = true;

                let newList = currentItems.filter(it => it.id != thisRecord.id) ?? [];
                newList.push(thisRecord);
                currentRecord.vendorCredentialItem = newList;
                setRecord(emptyRecordMessage);
                props.setRecord(currentRecord);
            }
        }
    }

    const getExpireDate = (expireDate: any): Moment => {
        let expire = '';
        if (expireDate.includes(':')) {
            let old = expireDate.split(" ")[0].split('/');
            expire = `${old[1]}/${old[0]}/${old[2]}`
        } else {
            let old = expireDate.toString().split('/');
            expire = `${old[1]}/${old[0]}/${old[2]}`;
        }
        var t = moment(new Date()).subtract(expire);
        return t;
    }

    const getExpireDateStatus = (vendor: VendorCredentialItem): string => {
        if (vendor.keysSecret.includes('Expire')) {
            var objeParset = (JSON.parse(vendor?.keysSecret));
            if (objeParset.ExpireDate) {
                let expireAt = getExpireDate(objeParset.ExpireDate);
                let days = expireAt.days();

                if (days <= 0)
                    return 'danger';

                if (days <= 30)
                    return 'warning';

                if (days > 30)
                    return 'success';

                return 'danger';
            } else {
                return 'danger'
            }

        } else {
            return 'danger'
        }
    }
 

    return (
        <div className="lc-datagrid ">
            <div className="topbar"> Provedores
                <div className="functions">
                    {
                        functions &&
                        functions.map((func, index) => {
                            return <LcIconLink id={func.id} key={index} icon={func.icon} onClick={() => func.func()} tooltip={func.tooltip} tooltipPosition="left" />
                        })
                    }
                </div>
            </div>
            <div className='detailList' >
                {listDataInterno && listDataInterno.length > 0 && listDataInterno?.map((Vitem: VendorCredentialItem, indexV) => {
                    return (
                        <PPExpand2
                            status={getExpireDateStatus(Vitem)}
                            height='36px'
                            key={indexV}
                            title={Vitem.alias}
                            titleRight={
                                <>
                                    <PPIconLink tooltip='Editar' tooltipPosition="right" icon='ppi ppi-pencil' onClick={() => { EditItem(Vitem) }} />
                                    <PPIconLink tooltip='Remover este provedor' tooltipPosition="right" icon='ppi ppi-x' onClick={() => { DeleteItem(Vitem.id); }} />
                                </>
                            }
                        >
                            <PPForm2
                                key={indexV}
                                updateFieldsOnChangeOrigin
                                fields={ServiceInstance.getFormTemplate(props.record.cloudOSProviderId, props.record.clientGroupId, props.record.id, Vitem, true)}
                                functions={{ hideFunctions: true }}
                            />
                            <div></div>
                        </PPExpand2>
                    );
                })
                }
            </div>

            <PPModal
                title={record.record.id > 0 ? `Editar ${record.record.alias}` : 'Novo Provedor ' + ServiceInstance.getProviderName(props.record.cloudOSProviderId)}
                size='large'
                visible={record.empty != true}
                onClose={() => { setRecord(emptyRecordMessage) }} >
                {
                    (ServiceInstance.enumProvidersTemplates.filter((x) => x.id == props.record.cloudOSProviderId).length > 0)
                        ?
                        <>
                            <PPForm2
                                fields={ServiceInstance.getFormTemplate(props.record.cloudOSProviderId, props.record.clientGroupId, props.record.id, record.record)}
                                onSubmit={SaveVendorItems}
                                updateFieldsOnChangeOrigin
                                keepErrorsOnChange
                            >
                            </PPForm2>
                        </>
                        :
                        "Selecione um provider!"
                }
            </PPModal>
        </div>
    );
}

export default VendorCredentialItemList;