import React, { Fragment, useCallback, useEffect, useState } from 'react';
import './index.css';
//Redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
//Components
import LcLoading from '../../../components/Generic/LcLoading';
import { PPInput } from 'processor-plataform-ui';
import { formatDecimal } from '../../../services/currency';
import PPDropDown from "../../../components/PPDropDown";
//services
import { FinOpsAdvanceFilterService } from '../../../services/finOps/finOpsService';
import moment from 'moment';
import LcNoData from '../../../components/Generic/LcNoData';

export interface FinOpsNestedCostCenterCostProps {
    tipoDePeriodo: string;
    selectedContract: string;
    selectedCostCenter: string;
    costCenters: any[];
    contracts: any[];
    filter: any;
    focused?: boolean
    ConfimationModal?: Function
    month?: string;
    year?: string;
    periodText?: string
}

const FinOpsNestedCostCenterCosts = React.memo(({
    tipoDePeriodo,
    selectedContract,
    selectedCostCenter,
    costCenters,
    contracts,
    filter,
    focused,
    ConfimationModal,
    month,
    year,
    periodText,
}: FinOpsNestedCostCenterCostProps) => {
    //Redux
    const user = useSelector<RootState, UserState>((state) => state.user);
    const [groupbyParam, setGroupbyParam] = useState<string>("service");
    const [selectedCompareOption, setSelectedCompareOption] = useState('');
    const [selectedCostCenterComponent, setSelectedCostCenterComponent] = useState<any>(selectedCostCenter);
    const [selectedContractComponent, setSelectedContractComponent] = useState<any>(selectedContract);
    const [filterText, setFilterText] = useState<any>('');
    const [tipoDePeriodoComponent, setTipoDePeriodoComponent] = useState<string>(tipoDePeriodo);
    const [finopsDetailSort, setFinopsDetailSort] = useState({ col: 'value', dir: 1 });
    const [finopsDetailData, setFinopsDetailData] = useState<any[]>([]);
    const [finopsDetailDataLoading, setFinopsDetailDataLoading] = useState<boolean>(false);
    const [periodMonthLast, setPeriodMonthLast] = useState<string>()
    const dispatch = useDispatch();

    const dateOptions = [
        { label: 'Últimos 3 meses', value: 'last90Days' },
        { label: 'Últimos 6 meses', value: 'last180Days' },
        { label: 'Últimos 12 meses', value: 'last365Days' },
        { label: 'Este mês', value: 'currentmonth' },
        { label: 'Mês anterior', value: 'priormonth' },
        { label: 'Dois meses atrás', value: 'prior2months' },
    ];

    const jsonFinOpsGroupBy = [
        {
            label: "Centro de custo",
            value: "costcenter"
        },
        {
            label: "Centro de custo / serviço",
            value: "costcenter_service"
        },
        {
            label: "Centro de custo / recurso",
            value: "costcenter_resource"
        },
        {
            label: "Serviço",
            value: "service"
        },
        {
            label: "Serviço / Centro de custo",
            value: "service_costcenter"
        },
        {
            label: "Serviço / recurso",
            value: "service_resource"
        },
        {
            label: "Assinatura ",
            value: "account"
        },
        {
            label: "Assinatura / serviço",
            value: "account_service"
        },
        {
            label: "Região ",
            value: "region"
        },
        {
            label: "Região / serviço",
            value: "region_service"
        },
        {
            label: "Fabricante",
            value: "provider"
        },
        {
            label: "Fabricante / serviço",
            value: "provider_service"
        }

    ];

    var compareOptions = [
        { label: 'Todos os valores', value: '' },
        { label: 'Valores que aumentaram', value: 'greaterThan' },
        { label: 'Valores que diminuíram', value: 'lesserThan' }
    ];

    useEffect(() => {
        setTipoDePeriodoComponent(tipoDePeriodo);
        setSelectedContractComponent(selectedContract);
        setSelectedCostCenterComponent(selectedCostCenter);
    }, [tipoDePeriodo, selectedContract, selectedCostCenter]);

    useEffect(() => {
        if (month && month !== '0' && year !== '0') {
            const currentMonth = moment(`${year}-${month}`, 'YYYY-MM');
            const previousMonth = currentMonth.subtract(1, 'months');
            const formattedMonth = previousMonth.format("MMM [de] YYYY");
            const capitalizedMonth = formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1);

            setPeriodMonthLast(capitalizedMonth);
        }
    }, [year, month]);

    const loadNewData = useCallback(async () => {
        setFinopsDetailDataLoading(true);
        let _selectedCostCenter = selectedCostCenter ? selectedCostCenter.toString() : '0';
        let _selectedContract = selectedContract ? selectedContract.toString() : '';
        try {
            const finOpsMapManagerService = new FinOpsAdvanceFilterService({});
            const response = await finOpsMapManagerService.GetLoadUsagebyGroupCostCenterDetail(
                tipoDePeriodoComponent,
                groupbyParam,
                _selectedContract,
                '',
                filterText,
                _selectedCostCenter,
                4,
                filter,
                year,
                month,
            )
            setFinopsDetailData(response);
        } catch (error) {
            console.error(error);
        } finally {
            setFinopsDetailDataLoading(false);
        }
    }, [selectedCostCenter, selectedContract, tipoDePeriodoComponent, groupbyParam, filterText, filter, year, month])

    useEffect(() => {
        loadNewData()
    }, [loadNewData, user.refreshFlag, user.ClientGroupSelected]);

    const calcDiference = (difference: number) => {
        if (difference > 0) {
            return <span>{formatDecimal(difference)}<i className="ppi ppi-arrow-up text-danger" /></span>
        } else if (difference < 0) {
            return <span>{formatDecimal(-difference)}<i className="ppi ppi-arrow-down text-success" /></span>
        } else {
            return <span>{formatDecimal(0)}<span>&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
        }
    };

    const handleExpandedData = (data: any, parentData: any) => {
        let payload: any = {};

        if (['costcenter', 'service'].includes(groupbyParam)) {
            payload[groupbyParam] = data.title.toUpperCase();
        }
        else if (['region', 'account', 'provider'].includes(groupbyParam)) {
            payload[groupbyParam] = data.title.split('/')[1];
        }
        else if (groupbyParam === 'service_costcenter') {
            const [initialParent, childParent] = groupbyParam.split("_");
            payload[initialParent] = data.inicialParent.toUpperCase();
            payload[childParent] = data.title.toUpperCase();
            payload.typeParam = groupbyParam;
        }
        else if (['costcenter_service', 'costcenter_resource', 'service_resource'].includes(groupbyParam)) {
            const [parentKey, childKey] = groupbyParam.split("_");
            payload[parentKey] = parentData.title.toUpperCase();
            payload[childKey] = data.title.toUpperCase();
            payload.typeParam = groupbyParam;
        }
        else if (['account_service', 'region_service', 'provider_service'].includes(groupbyParam)) {
            const [parent, child] = groupbyParam.split("_");
            payload[parent] = parentData.title.split('/')[1];
            payload[child] = data.title.toUpperCase();
            payload.typeParam = groupbyParam;
        }
        else {
            dispatch({ type: 'RESET_ANALYTICAL' });
            ConfimationModal && ConfimationModal(false);
            return;
        }
        dispatch({ type: 'ANALYTICAL_FILTER', payload });
        ConfimationModal && ConfimationModal(true);

        // switch (groupbyParam) {
        //     case "costcenter":
        //     case "service":
        //         payload[groupbyParam] = data.title.toUpperCase();
        //         break;
        //     case "region":
        //     case "account":
        //         payload[groupbyParam] = data.title.split('/')[1];
        //         break;

        //     case "service_costcenter":
        //         const [initialParent, childParent] = groupbyParam.split("_");
        //         payload[initialParent] = data.inicialParent.toUpperCase();
        //         payload[childParent] = data.title.toUpperCase();
        //         payload.typeParam = groupbyParam;
        //         break;
        //     case "costcenter_service":
        //     case "costcenter_resource":
        //     case "service_resource":
        //         const [parentKey, childKey] = groupbyParam.split("_");
        //         payload[parentKey] = parentData.title.toUpperCase();
        //         payload[childKey] = data.title.toUpperCase();
        //         payload.typeParam = groupbyParam;
        //         break;
        //     case "account_service":
        //     case "region_service":
        //         const [parent, child] = groupbyParam.split("_");
        //         payload[parent] = parentData.title.split('/')[1];
        //         payload[child] = data.title.toUpperCase();
        //         payload.typeParam = groupbyParam;
        //         break;
        //     default:
        //         dispatch({ type: 'RESET_ANALYTICAL' });
        //         ConfimationModal && ConfimationModal(false);
        //         return;
        // }
        // dispatch({ type: 'ANALYTICAL_FILTER', payload });
        // ConfimationModal && ConfimationModal(true);
    }

    const mountTreeViewFinOps = (data, top10 = false) => {
        return (
            <div key={data.length}>
                {data && data.length > 0 ?
                    data.filter(c => {
                        return (selectedCompareOption === '') ? true :
                            selectedCompareOption === 'greaterThan' ?
                                c.difference > 0 : selectedCompareOption === 'lesserThan' ? c.difference <= 0 : true
                    }).sort((a, b) => {
                        var sortReturn = 0;
                        sortReturn = (finopsDetailSort.col === 'last') ? -1 * (a.last - b.last) * finopsDetailSort.dir : 0;
                        sortReturn += (finopsDetailSort.col === 'value') ? -1 * (a.value - b.value) * finopsDetailSort.dir : 0;
                        sortReturn += (finopsDetailSort.col === 'difference') ? -1 * (a.difference - b.difference) * finopsDetailSort.dir : 0;
                        sortReturn += (finopsDetailSort.col === 'title') ? -1 * a.title.localeCompare(b.title) * finopsDetailSort.dir : 0;

                        return sortReturn;
                    }).map((d, i) => {
                        let _children = d.children && d.children.length > 0 ? d.children : undefined;
                        let _data = d.data && d.data.length > 0 ? d.data : undefined;
                        return (
                            <Fragment key={i} >
                                <div className='finops-row' style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        d.expanded = !d.expanded;
                                        setFinopsDetailData([...finopsDetailData]);
                                        (_children === undefined && _data === undefined) && handleExpandedData(d, finopsDetailData)
                                    }}
                                >
                                    <div style={{ marginLeft: '0' }}
                                        className="cell-descricao label ellipsis">
                                        {(_children || _data) &&
                                            <i className={`ppi ppi-chevron-${d.expanded ? 'down' : 'right'}`} />}
                                        {mountIcon(d.type)} {d.title}
                                    </div>

                                    <div className={`row values${d.expanded || data.find(x => x.expanded) ? ' text-darkerx' : ''}`}>
                                        {<span className='row w-90 right'>{formatDecimal(Number(d.last))}</span>}
                                        <span className='row w-90 right'>{formatDecimal(Number(d.value))}</span>
                                        {<span className='row w-90 right'>{calcDiference(Number(d.difference))}</span>}
                                    </div>
                                </div>
                                {d.expanded &&
                                    <div className='ml-6 striped'>
                                        {_data && _data.sort((a, b) => {
                                            var sortReturn = 0;
                                            sortReturn = (finopsDetailSort.col === 'last') ? -1 * (a.last - b.last) * finopsDetailSort.dir : 0;
                                            sortReturn += (finopsDetailSort.col === 'value') ? -1 * (a.value - b.value) * finopsDetailSort.dir : 0;
                                            sortReturn += (finopsDetailSort.col === 'difference') ? -1 * (a.difference - b.difference) * finopsDetailSort.dir : 0;
                                            sortReturn += (finopsDetailSort.col === 'title') ? -1 * a.title.localeCompare(b.title) * finopsDetailSort.dir : 0;

                                            return sortReturn;
                                        }).filter(c => {
                                            return (selectedCompareOption === '') ? true :
                                                selectedCompareOption === 'greaterThan' ?
                                                    c.difference > 0 : selectedCompareOption === 'lesserThan' ? c.difference <= 0 : true
                                        }).map((e, i) => (
                                            <div className='finops-row' style={{ cursor: 'pointer' }} key={i} onClick={() => handleExpandedData(e, d)}>
                                                <div className="cell-descricao label ellipsis">
                                                    {mountIcon(e.type)} {e.title}
                                                </div>
                                                <div className={`row values${d.children.expanded ? ' text-darker' : ''}`}>
                                                    {<span className='row w-100 right'>{formatDecimal(Number(e.last))}</span>}
                                                    <span className='row w-100 right'>{formatDecimal(Number(e.value))}</span>
                                                    {<span className='row w-100 right'>{calcDiference(Number(e.difference))}</span>}
                                                </div>
                                            </div>
                                        ))}
                                        {mountTreeViewFinOps(d.children, top10)}
                                    </div>
                                }
                            </Fragment>
                        );
                    })
                    :
                    <div style={{ marginTop: '3rem' }}>
                        <LcNoData size="small" label='Não há dados para o período selecionado.' />
                    </div>

                }
            </div>
        );
    };

    const mountIcon = (type: string) => {
        switch (type) {
            case 'costcenter_data':
                return <i className='lci lci-currency-dollar  ' title='Centro de Custo' />
            case 'costcenter':
                return <i className='lci lci-currency-dollar text-success' title='Centro de Custo' />
            case 'service':
                return <i className='ppi ppi-cloud-server text-primary' title='Serviço' />
            case 'resource':
                return <i className='ppi ppi-chip text-danger' title='Recurso' />
        }
    }

    return (
        <>
            {focused ? <div className="maiores-consumos-full">
                <div title="Maiores consumos" className="lc-title ellipsis titlefix">Maiores consumos</div>
                <div className='row mb-7'>
                    <PPDropDown
                        title="Agrupar por"
                        name="groupbyParam"
                        options={jsonFinOpsGroupBy.sort((a, b) => a.label.localeCompare(b.label))}
                        defaultValue={jsonFinOpsGroupBy.find(option => option.value === groupbyParam)}
                        value={jsonFinOpsGroupBy.find(option => option.value === groupbyParam)}
                        onChange={(event) => { setGroupbyParam(event.value); }}
                        disabled={finopsDetailDataLoading}
                        required
                        hideFilter
                        width='12vw'
                    />
                    <PPDropDown
                        title='Período'
                        name="date"
                        options={dateOptions}
                        defaultValue={dateOptions.find(option => option.value === tipoDePeriodo)}
                        value={dateOptions.find(option => option.value === tipoDePeriodo)}
                        onChange={event => {
                            setSelectedCompareOption('');
                            setTipoDePeriodoComponent(event.value);
                        }}
                        width='12vw'
                        required
                        hideFilter
                        disabled={finopsDetailDataLoading}
                    />
                    <PPDropDown
                        title="Contrato"
                        name="contracts"
                        options={contracts}
                        onChange={event => {
                            setSelectedContractComponent(parseInt(event.value))
                        }}
                        defaultValue={contracts.find(option => option.value === selectedContractComponent)}
                        value={contracts.find(option => option.value === selectedContractComponent)}

                        width="12vw"
                        required
                        disabled={finopsDetailDataLoading}
                    />
                    <PPDropDown
                        title="Centro de custo"
                        name="idCostCenter"
                        options={costCenters}

                        defaultValue={costCenters.find(option => option.value === selectedCostCenterComponent)}
                        value={costCenters.find(option => option.value === selectedCostCenterComponent)}

                        onChange={event => setSelectedCostCenterComponent(event.value)}

                        width='12vw'
                        required
                        disabled={finopsDetailDataLoading}
                    />
                    <PPDropDown
                        title="Mostrar apenas"
                        name="showOnly"
                        options={compareOptions}

                        defaultValue={compareOptions.find(option => option.value === selectedCompareOption)}
                        value={compareOptions.find(option => option.value === selectedCompareOption)}

                        onChange={event => { setSelectedCompareOption(event.value); }}
                        width='12vw'
                        required
                        hideFilter
                        disabled={
                            finopsDetailDataLoading ||
                            (tipoDePeriodoComponent === 'last90Days' || tipoDePeriodoComponent === 'last180Days' || tipoDePeriodoComponent === 'last365Days')
                        }
                    />
                    <PPInput
                        title="Filtros"
                        name="filters"
                        type='text'
                        placeholder='Serviço ou recurso'
                        width='12vw'
                        onKeyUp={event => { if (event.key === "Enter") { setFilterText(event.currentTarget.value); } }}
                        disabled={finopsDetailDataLoading}
                    />
                </div>
                <div className='finops-row-title mr-6'>
                    <div className=".cell-descricao label text-darker ellipsis" onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'title', dir: finopsDetailSort.col === 'title' ? (finopsDetailSort.dir * -1) : 1 })}>
                        Descrição
                        {finopsDetailSort.col === 'title' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir === 1 ? 'down' : 'up'} text-warning`} />}
                    </div>
                    <div className="row values">
                        <span className='row w-100 right text-darker' onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'last', dir: finopsDetailSort.col === 'last' ? (finopsDetailSort.dir * -1) : 1 })}>
                            Anterior
                            {finopsDetailSort.col === 'last' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir === 1 ? 'down' : 'up'} text-warning`} />}
                        </span>
                        <span className='row w-100 right text-darker' onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'value', dir: finopsDetailSort.col === 'value' ? (finopsDetailSort.dir * -1) : 1 })}>
                            Atual
                            {finopsDetailSort.col === 'value' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir === 1 ? 'down' : 'up'} text-warning`} />}
                        </span>
                        <span className='row w-100 right text-darker' onClick={() => setFinopsDetailSort({ ...{ ...finopsDetailSort, col: 'difference', dir: finopsDetailSort.col === 'difference' ? (finopsDetailSort.dir * -1) : 1 } })}>
                            Diferença
                            {finopsDetailSort.col === 'difference' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir !== 1 ? 'down' : 'up'} text-warning`} />}
                        </span>
                    </div>
                </div>
                <div className='scrollable-v' style={{ maxHeight: 'calc(100% - 5vh)' }}>
                    <LcLoading loading={finopsDetailDataLoading} label='Carregando dados...'>
                        {mountTreeViewFinOps(finopsDetailData
                            .sort((a, b) => {
                                var sortReturn = 0;
                                sortReturn = (finopsDetailSort.col === 'last') ? a.last - b.last * finopsDetailSort.dir : 0;
                                sortReturn += (finopsDetailSort.col === 'value') ? a.value - b.value * finopsDetailSort.dir : 0;
                                sortReturn += (finopsDetailSort.col === 'difference') ? a.difference - b.difference * finopsDetailSort.dir : 0;
                                sortReturn += (finopsDetailSort.col === 'title') ? a.title.localeCompare(b.title) * finopsDetailSort.dir : 0;

                                return sortReturn;
                            }))}
                    </LcLoading>
                </div>
            </div>
                :
                <>
                    <div className="titlefix">
                        <div title="Maiores consumos" className={'lc-title ellipsis'} >Maiores consumos</div>
                        <PPDropDown
                            name="groupbyParam"
                            options={jsonFinOpsGroupBy.sort((a, b) => a.label.localeCompare(b.label))}
                            defaultValue={jsonFinOpsGroupBy.find(option => option.value === groupbyParam)}
                            value={jsonFinOpsGroupBy.find(option => option.value === groupbyParam)}
                            onChange={(event) => { setGroupbyParam(event.value); }}
                            disabled={finopsDetailDataLoading}
                            width='190px'
                            hideFilter
                        />
                    </div>
                    <div className='finops-row-title mr-6' >
                        <div className="cell-descricao label text-darker ellipsis"
                            onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'title', dir: finopsDetailSort.col === 'title' ? (finopsDetailSort.dir * -1) : 1 })}>
                            Descrição{finopsDetailSort.col === 'title' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir === 1 ? 'down' : 'up'} text-warning`} />}
                        </div>
                        <div className="row values">
                            {<span className="row w-100 right text-darker"
                                onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'last', dir: finopsDetailSort.col === 'last' ? (finopsDetailSort.dir * -1) : 1 })}>
                                {periodText ? periodMonthLast : !tipoDePeriodoComponent.includes("last") ? 'Anterior' : 'Primeiro'}
                                {finopsDetailSort.col === 'last' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir === 1 ? 'down' : 'up'} text-warning`} />}
                            </span>}
                            <span className="row w-100 right text-darker"
                                onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'value', dir: finopsDetailSort.col === 'value' ? (finopsDetailSort.dir * -1) : 1 })}>
                                {periodText ? periodText : !tipoDePeriodoComponent.includes("last") ? 'Atual' : 'Último'}
                                {finopsDetailSort.col === 'value' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir === 1 ? 'down' : 'up'} text-warning`} />}
                            </span>
                            {<span className='row w-100 right text-darker'
                                onClick={() => setFinopsDetailSort({ ...finopsDetailSort, col: 'difference', dir: finopsDetailSort.col === 'difference' ? (finopsDetailSort.dir * -1) : 1 })}>
                                Diferença
                                {finopsDetailSort.col === 'difference' && <i className={`ppi ppi-arrow-${finopsDetailSort.dir !== 1 ? 'down' : 'up'} text-warning`} />}
                            </span>}
                        </div>
                    </div>
                    <LcLoading loading={finopsDetailDataLoading} label='Carregando dados...'>
                        <div className='scrollable-v'>
                            {mountTreeViewFinOps(finopsDetailData
                                .sort((a, b) => {
                                    var sortReturn = 0;
                                    sortReturn = (finopsDetailSort.col === 'last') ? a.last - b.last * finopsDetailSort.dir : 0;
                                    sortReturn += (finopsDetailSort.col === 'value') ? a.value - b.value * finopsDetailSort.dir : 0;
                                    sortReturn += (finopsDetailSort.col === 'difference') ? a.difference - b.difference * finopsDetailSort.dir : 0;
                                    sortReturn += (finopsDetailSort.col === 'title') ? a.title.localeCompare(b.title) * finopsDetailSort.dir : 0;

                                    return sortReturn;
                                }))}
                        </div>
                    </LcLoading>
                </>
            }
        </>
    );
});

export default FinOpsNestedCostCenterCosts;
