import React, { useEffect, useMemo, useRef, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import { useSelector } from 'react-redux';
interface DoughNutEChartProps {
    ArrayData: any;
    decimals?: number;
    showPercent?: boolean;
    totalNumber?: number;
    legendOrientation?: 'horizontal' | 'vertical';
    title?: string;
    onRef?: Function;
    offsetPie?: { left?: number; bottom?: number; }
    customColors?: string[]
    disebleSortWithValue?: boolean
}
function getLegendText(item, showPercent, decimals) {
    const label = item.label || item.name; // Use item.label se existir, senão use item.name
    return !showPercent ?
        `${(label.length > 20) ? label.substring(0, 20) + '...' : label} : ${((item.value / 100) * 100).toLocaleString('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals })}`
        :
        `${(label.length > 20) ? label.substring(0, 20) + '...' : label} : ${((item.value / 100) * 100).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`
        ;
}

const handleLegendChange = (params, legendTexts, absoluteValues, selectedLegends, decimals, chartRef) => {
    let newTotal = 0;
    const newSelectedLegends = { ...selectedLegends };

    legendTexts.forEach((legend, i) => {
        newSelectedLegends[legend] = params.selected[legend];
        if (newSelectedLegends[legend]) {
            newTotal += absoluteValues[i];
        }
    });

    if (chartRef.current) {
        const chartInstance = chartRef.current.getEchartsInstance();

        chartInstance.setOption({
            legend: {
                selected: newSelectedLegends
            },
            series: [{
                label: {
                    formatter: newTotal.toLocaleString('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
                }
            }]
        }, false, true);
    }
}

const getOptions = (title, legendOrientation, offsetPie, data, currentTotal, totalNumber, showPercent, decimals, colors) => {
    // Traduz cor "var" -> hex para corrigir gráfico "piscando" ao passar o mouse
    const getCSSVariableValue = (variable: string): string => {
        if (!variable.startsWith('var(--')) return variable;
        return getComputedStyle(document.documentElement)
            .getPropertyValue(variable.slice(4, -1))
            .trim();
    };

    let legendTexts = data.map(item => getLegendText(item, showPercent, decimals));
    let selectedLegends = Object.fromEntries(legendTexts.map(legend => [legend, true]));
    let absoluteValues = showPercent ? data.map(item => (item.value * totalNumber) / 100) : data.map(item => item.value);
    return {
        tooltip: {
            backgroundColor: '#f1f5fb',
            borderWidth: 0,
            textStyle: {
                fontSize: 11,
                fontFamily: 'Ubuntu',
                color: '#2B304F',
            },
            trigger: 'item',
            formatter: function (params) {
                const index = legendTexts.indexOf(params.name);
                const absoluteValue = absoluteValues[index];

                if (showPercent) {
                    return '<div>' + params.name + '</div>' +
                        ' <b>(' + absoluteValue.toLocaleString('pt-BR', { minimumFractionDigits: decimals, maximumFractionDigits: decimals }) + ')</b>';
                } else {
                    return '<div>' + params.name + '</div>' +
                        ' <b>(' + params.percent + '%)</b>';
                }
            }
        },
        title: {
            text: title,
            right: "0",
            top: "0",
            textStyle: {
                fontSize: 11,
                fontWeight: 'normal',
                color: '#8b90a3',
                fontFamily: 'Ubuntu',
            },
            backgroundColor: title ? '#ffffff90' : 'transparent',
            borderRadius: [10],
            padding: [4, 8, 4, 8],
            itemGap: 8
        },
        grid: {},
        legend: {
            inactiveColor: '#ededed',
            inactiveBorderColor: '#ededed',
            name: '',
            type: 'scroll',  // Enable scroll (pagination)
            orient: legendOrientation,
            height: legendOrientation === 'vertical' ? '100%' : 'auto',
            width: legendOrientation === 'vertical' ? '' : '100%',
            right: legendOrientation === 'vertical' ? '5%' : 'center',
            top: legendOrientation === 'vertical' ? 'middle' : 'bottom',
            textStyle: {
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                fontSize: window.innerWidth < 1400 ? '8px' : window.innerWidth < 1650 ? '10px' : '14px',
            },
            itemGap: window.innerWidth < 1650 ? 5 : 10,
            pageButtonItemGap: legendOrientation === 'vertical' ? 5 : 3,
            pageButtonGap: legendOrientation === 'vertical' ? 10 : 5,
            pageButtonPosition: 'end',
            pageFormatter: '{current}/{total}',  // You can customize this
            pageIconSize: 10,
            pageIconColor: '#273b85',
            pageIcons: {
                vertical: [
                    'path://M10 9L6 5l-4 4 1.4 1.4L6 7.8l2.6 2.6z',  // Chevron para baixo
                    'path://M6 9l-4-4 1.4-1.4L6 6.2l2.6-2.6L10 5z' // Chevron para cima                        
                ],
                horizontal: [
                    'path://M5 6l4-4 1.4 1.4L7.8 6l2.6 2.6L9 10z',  // Chevron para a direita
                    'path://M9 6l-4 4-1.4-1.4L6.2 6 3.6 3.4 5 2z' // Chevron para a esquerda                        
                ]
            },
            pageTextStyle: {
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
                fontSize: 10,
            },
            data: legendTexts,
            formatter: (name) => name,
            selected: selectedLegends
        },
        series: [
            //   offsetPie?: { left?: number; bottom?: number; }
            {
                type: 'pie',
                center: legendOrientation === 'vertical' ? [(offsetPie && offsetPie.left ? `${offsetPie.left}%` : '27%'),
                (offsetPie && offsetPie.bottom ? `${offsetPie.bottom}%` : '50%')] : ['50%', '50%'],
                radius: window.innerWidth < 1600 ?
                    legendOrientation === 'vertical' ? ['35%', '55%'] : ['30%', '45%']
                    :
                    legendOrientation === 'vertical' ? ['30%', '50%'] : ['25%', '40%'],
                avoidLabelOverlap: false,
                color: colors,
                data: data.map((item, index) => ({
                    value: item.value,
                    name: legendTexts[index],
                    itemStyle: {
                        color: item.itemStyle && item.itemStyle.color ?
                            getCSSVariableValue(item.itemStyle?.color) :
                            colors[index % colors.length]
                    }
                })),
                label: {
                    show: true,
                    position: 'center',
                    formatter: `${currentTotal}`,
                    fontWeight: 'normal',
                    overflow: 'truncate',
                    ellipsis: '...',
                    fontFamily: 'Ubuntu',
                    fontSize: window.innerWidth < 1600 ? '18px' : '24px',
                },
                itemStyle: {
                    borderRadius: 4,
                    borderColor: '#fff',
                    borderWidth: 2
                },
                emphasis: {
                    scale: false,
                    scaleSize: 0,
                    focus: 'self',
                    blurScope: 'none',
                    itemStyle: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(58, 79, 135, 0.4)',
                        borderColor: '#fff',
                        borderWidth: 2
                    }
                }
            }
        ]
    };
};

const calculateTotal = (showPercent, totalNumber, data) => {
    if (showPercent && totalNumber > 0) return totalNumber;
    if (!showPercent && data) {
        return data.reduce((total, { value }) => total + value, 0);
    }
    return showPercent ? 100 : 0;
};

const DoughNutEChart: React.FC<DoughNutEChartProps> = ({ ArrayData, decimals = 2, showPercent = false, totalNumber = 0, legendOrientation = 'vertical', title, onRef, offsetPie, customColors, disebleSortWithValue }) => {
    //console.log(ArrayData);
    const chartRef = useRef<ReactECharts>(null);
    const user = useSelector<RootState, UserState>(state => state.user);
    const previousDataRef = useRef<any[]>([]);
    const colors = useMemo(() => {
        return customColors ? customColors : (
            user.ClientGroupSelected === 152 ?
                ['#5470C6', '#73C0DE', '#4A91E3', '#2D5FA8', '#167ABC', '#1E4477', '#153E90', '#0A47A1', '#083D77'] :
                [
                    '#8C40E3',  // var(--color-primary-purple)
                    '#471FCC',  // var(--color-primary-blue)
                    '#34C38F',  // var(--color-secondary-green-light)
                    '#00DBFF',  // var(--color-secondary-cyan)
                    '#255A4B',  // var(--color-secondary-green-dark)
                    '#AB19E7',  // var(--color-primary-magenta)
                    '#DA43F2'   // var(--color-primary-pink)
                ]
        );
    }, [customColors, user.ClientGroupSelected]);

    const groupedData = useMemo(() => {
        if (!ArrayData?.length) return [];

        const newData = ArrayData.reduce((acc, item) => {
            const labelName = item.label || item.name || '';
            const existingItem = acc.find(x => x.label === labelName);
            if (existingItem) {
                existingItem.value += item.value;
            } else {
                acc.push({ ...item, label: labelName });
            }
            return acc;
        }, []);

        const prevArrayDataString = JSON.stringify(previousDataRef.current);
        const newArrayDataString = JSON.stringify(newData);

        const result = prevArrayDataString === newArrayDataString ? previousDataRef.current : newData;
        previousDataRef.current = result;

        return result;
    }, [ArrayData]);

    const data = useMemo(() => {
        if (!groupedData?.length) return [];
        return disebleSortWithValue ?
            groupedData :
            [...groupedData].sort((a, b) => b.value - a.value);
    }, [disebleSortWithValue, groupedData]);

    const legendTexts = useMemo(() => {
        if (!data?.length) return [];
        return data.map(item => getLegendText(item, showPercent, decimals));
    }, [data, showPercent, decimals]);

    const selectedLegends = useMemo(() => {
        if (!data?.length || !legendTexts?.length) return {};
        return Object.fromEntries(legendTexts.map(legend => [legend, true]));
    }, [data, legendTexts]);

    const total = useMemo(() => calculateTotal(showPercent, totalNumber, data).toLocaleString('pt-BR', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    }), [showPercent, totalNumber, data, decimals]);

    const absoluteValues = useMemo(() => {
        if (!data?.length) return [];
        return showPercent ? data.map(item => (item.value * totalNumber) / 100) : data.map(item => item.value);
    }, [data, showPercent, totalNumber]);


    useEffect(() => {
        let urlImg = chartRef.current?.getEchartsInstance().getDataURL({
            pixelRatio: 2,
            backgroundColor: '#fff'
        });
        onRef && onRef(urlImg)
    }, [onRef])

    const memoizedOptions = useMemo(() => getOptions(
        title,
        legendOrientation,
        offsetPie,
        data,
        total,
        totalNumber,
        showPercent,
        decimals,
        colors
    ), [title, legendOrientation, offsetPie, data, total, totalNumber, showPercent, decimals, colors]);

    if (!ArrayData) {
        return <div></div>;
    }

    return (
        <ReactECharts
            ref={chartRef}
            key={`doughnut-${JSON.stringify(data)}`}
            style={{
                width: '100%',
                height: '100%',
                minHeight: legendOrientation === 'horizontal' ? '160px' : ''
            }}
            opts={{ renderer: 'svg', locale: 'PT-br' }}
            option={memoizedOptions}
            notMerge={true}
            lazyUpdate={true}
            onEvents={{ 'legendselectchanged': (params: any) => handleLegendChange(params, legendTexts, absoluteValues, selectedLegends, decimals, chartRef) }}
        />
    )
}

export default React.memo(DoughNutEChart, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.ArrayData) === JSON.stringify(nextProps.ArrayData) &&
        prevProps.decimals === nextProps.decimals &&
        prevProps.showPercent === nextProps.showPercent &&
        prevProps.totalNumber === nextProps.totalNumber &&
        prevProps.legendOrientation === nextProps.legendOrientation &&
        prevProps.title === nextProps.title &&
        prevProps.offsetPie === nextProps.offsetPie &&
        JSON.stringify(prevProps.customColors) === JSON.stringify(nextProps.customColors) &&
        prevProps.disebleSortWithValue === nextProps.disebleSortWithValue;
});