import React, { useEffect, useState } from 'react';
import LcLoading from '../../../../components/Generic/LcLoading';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import moment from 'moment';
import LcMobileStepper from '../../../../components/Form/LcMobileStepper';
import AutomationsService from '../../../../services/automations/automationsService';
import { AutomationsScheduler } from '../../../../services/automations/automationsModel';
import { PiCalendarBlankLight } from 'react-icons/pi';
import './index.css';

interface NextScheduleAutomationProps {
    isHome?: boolean;
    isOnlyCMSCorporate?: boolean;
}

const NextScheduleAutomation: React.FC<NextScheduleAutomationProps> = ({
    isHome,
    isOnlyCMSCorporate
}) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const automationSvc = new AutomationsService({});
    const [loading, setLoading] = useState<boolean>(false);
    const [nextSchedules, setNextSchedules] = useState<AutomationsScheduler[]>()

    const getNextSchedule = async () => {
        try {
            setLoading(true)
            const response = await automationSvc.GetAutomations();
            let schedules = findNextSchedulers(response);

            if (schedules)
                setNextSchedules(schedules.slice(0, 3));

            setLoading(false)
        } catch (error) {
            console.error('Error:', error);
            setLoading(false)
        }
    }

    useEffect(() => { getNextSchedule() }, [user.ClientGroupSelected, user.refreshFlag])

    function findNextSchedulers(automations: AutomationsScheduler[]) {
        const now = moment();
        const daysMap = { 'SUN': 0, 'MON': 1, 'TUE': 2, 'WED': 3, 'THU': 4, 'FRI': 5, 'SAT': 6 };
        const diasDaSemanaPtBr = {
            dom: "Domingo",
            seg: "Segunda-feira",
            ter: "Terça-feira",
            qua: "Quarta-feira",
            qui: "Quinta-feira",
            sex: "Sexta-feira",
            sab: "Sábado"
        };

        const nextSchedule = (automation) => {
            const weekDays = automation.weekDaysScheduled.split('-').map(day => daysMap[day]);
            const scheduledTime = moment(automation.hourScheduled, 'HH:mm:ss');

            for (let i = 0; i <= 7; i++) {
                const day = (now.day() + i) % 7;
                if (weekDays.includes(day)) {
                    const nextDateTime = now.clone().add(i, 'days').set({
                        hour: scheduledTime.hour(),
                        minute: scheduledTime.minute(),
                        second: scheduledTime.second()
                    });
                    if (nextDateTime.isAfter(now)) {
                        return nextDateTime;
                    }
                }
            }
        }

        var nextAutomations = automations
            .map(automation => ({ ...automation, nextSchedule: nextSchedule(automation), translatedName: '' }))
            .sort((a, b) => (a.nextSchedule as any) - (b.nextSchedule as any));

        var automationTemplatesDicTrimmed = {};
        Object.keys(automationSvc.automationTemplatesDic).map(atd =>
            automationTemplatesDicTrimmed[atd.replace(/\s/g, '')] = automationSvc.automationTemplatesDic[atd]
        )

        if (nextAutomations.length > 0)
            nextAutomations.forEach(na => {
                let day = diasDaSemanaPtBr[na.nextSchedule?.format('ddd')!];
                na.nextSchedule = `${day} às ${na.nextSchedule?.format('HH')}h${na.nextSchedule?.format('mm')}` as any
                na.translatedName = automationSvc.automationDataDic[JSON.parse(na.data).Automation]
            });

        return nextAutomations;
    }

    return (
        <LcLoading loading={loading} >
            {
                nextSchedules && nextSchedules.length > 0 ?
                    <div className='card-big-number colorfull' style={{ height: isOnlyCMSCorporate? '-webkit-fill-available':''}}>
                        {isHome && <div className='title-schedule' style={{color: '#fff',padding: isHome ?  '.1rem .5rem': ''}}>Próximo agendamento</div>}
                        <div
                            style={{
                                height: isOnlyCMSCorporate? '-webkit-fill-available':'inherit', 
                                alignItems: isHome ? 'flex-start' : 'flex-end',
                                padding: isHome ?'.1rem .5rem' : '',
                                justifyContent: isHome ? 'space-between' : ''
                            }}
                            className='subtitle-dash ellipsis nextScheduleAutomation'
                            >
                            {!isHome && <PiCalendarBlankLight style={{ marginBottom: '5vh', }} size={32} />}
                            <LcMobileStepper steps={nextSchedules.map((ns, nsi) => {
                                return {
                                    content: 
                                    <div key={nsi} 
                                        className="slide-container"
                                        style={{height: isOnlyCMSCorporate ? '100%' : ''}}
                                        >
                                        <div 
                                            className="slide-item automation-content"
                                            style={{
                                                alignItems: isHome ? 'start' : 'end',
                                                height: isOnlyCMSCorporate? 'inherit' : '75px',
                                                margin: isHome ? '0' :  '0 0.1rem 0 0rem',
                                                justifyContent:isOnlyCMSCorporate? 'space-evenly' : isHome ? 'center' : 'flex-end'
                                            }}
                                        >
                                            <div title="Recurso envolvido" style={{color: '#fff'}} className={isHome ? 'automation-resource-home' : 'automation-resource'}>
                                                {JSON.parse(ns.data).ResourceId}
                                            </div>
                                            <div title="Nome da automação" className={isHome ? 'automation-name-home' : 'automation-name'} style={{ padding: isHome ? '2px 0' : '7px 0', color:'#fff' }}>
                                                {ns['translatedName']}
                                            </div>
                                            <div title="Próxima execução" className={'automation-schedule'} style={{ color: '#fff' }}>
                                                {ns['nextSchedule']}
                                            </div>
                                        </div>
                                    </div>

                                }
                            })}
                            autostep={15} 
                            hideSteppers 
                            dotsLeftMargin={isHome ? 0 : -36} 
                            dotsSize={isHome ? 'small' : 'large'}
                            />
                        </div>
                    </div>
                    :
                    <div style={{ color: '#fff', fontSize: '.7rem', padding:isHome ?  '.1rem .5rem' : ''  }}>
                        {isHome && <div className='title-schedule' style={{color: '#fff'}}>Próximo agendamento</div>}
                        Sem dados
                    </div>
            }
        </LcLoading>
    )
}

export default NextScheduleAutomation;