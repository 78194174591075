import React, { useEffect, useState } from 'react';
import LcInfiniteTable, { TableColumn } from '../../../components/Data/LcInfiniteTable';
import LcNoData from '../../../components/Generic/LcNoData';
import { groupByField } from '../resume/Hook/useMostTargetData';
import LcLoading from '../../../components/Generic/LcLoading';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

interface MostargeTableCheckpointProps {
    data: any[];
	hideBar: boolean

}
export const translateAttackSeverity = (status: string) => {
    const classficiation = {
        'Critical':'Crítico',
        'High':'Alto',
        'Medium':'Médio',
        'Low':'Baixo',
		'Informational': 'Informativo'
        

    }
    return classficiation[status] || "-"
}
export const MostTargeTableCheckpoint: React.FC<MostargeTableCheckpointProps> = (
	{
		data,
		hideBar
	}
) => {
	const [rows, setRows] = useState<any[]>([]);
    const [groupNames, setGroupNames] = useState<string[]>([]);
    const loadingAttacksChartData = useSelector((state: RootState) => state.xdrCheckpointResume.loadingStates.loadingAttacksChartData);
	const columns: TableColumn[] = [
	{
		field: 'src_machine_name', headerName:  'Dispositivo', width: '80%', align: 'left', fontSize: hideBar ? 'medium' : 'small' 
	},
	{
		field: 'count', headerName:  'Ataques', width: '10%', align:'left',fontSize: hideBar ? 'medium' : 'small' 
	}
];

	const onSortChange = (sortData: any) => {
		const { sort } = sortData;
		let _events = { ...rows[0] };
		Object.keys(_events).forEach(device => {
			_events[device] = _events[device].sort((a: any, b: any) => {
				if (sort.direction === 'asc') return (a[sort.column] > b[sort.column]) ? 1 : -1;
				else return (a[sort.column] > b[sort.column]) ? -1 : 1;
			});
		});
	};

	useEffect(() => {
		if(data && data.length === 0) return;
		const uniqueClassifications = [...new Set(data
		.map(item => translateAttackSeverity(item.severity)))]
		setGroupNames(uniqueClassifications as string[]);
		let translateData = data.map( i => ({...i, severity: translateAttackSeverity(i.severity)}))

		const groupBySeverityAndMachine = (array: any[]) => {
			return array.reduce((acc, object) => {
			const key = `${object.severity}-${object.src_machine_name}`;
			if (!acc[key]) {
				acc[key] = { severity: object.severity, src_machine_name: object.src_machine_name, count: 0 };
			}
			acc[key].count += 1;
			return acc;
			}, {} as Record<string, { severity: string; src_machine_name: string; count: number }>);
		};
		
		const groupedData: any[] = groupBySeverityAndMachine(translateData);
		
		const result = Object.values(groupedData).sort((a, b) => b.count - a.count)
		setRows([groupByField(result, 'severity')]);
		
	}, [data])
	return(
		<LcLoading loading={loadingAttacksChartData}>
            {
                data && data.length > 0 ?
				<LcInfiniteTable 
                    className={`mostTargetsTable ${hideBar ? 'hideBar' : ''}`}
                    height='90%'
                    rowAutoHeight
                    rows={rows} 
                    onSortChange={onSortChange}
                    columns={columns} 
                    groupName={groupNames} 
                    fontSize='medium'
                    collapsedGroups={true}
                    hidePagination
					hideHeader={!hideBar}
				/>
                :
                <LcNoData label='Não há dados disponiveis neste periodo.'/>
            }
        </LcLoading>
	);
}

;