import moment from 'moment';
import { useSnackbar } from 'notistack';
import { PPModal } from 'processor-plataform-ui';
import React, { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { PrefState } from '../../../store/reducers/prefReducer';
import { UserState } from '../../../store/reducers/userReducer';
import { setFilterActive } from '../../../store/reducers/filterReducer';

import LcInfiniteTable from '../../../components/Data/LcInfiniteTable';
import LcTable from '../../../components/Data/LcTable';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcIconLink from '../../../components/Generic/LcIconLink';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import { FilterLcInfiniteTable } from '../../../components/Data/Dashboard/LCDashboard/FilterLcInfiniteTable';

import { FinancialManagementService } from '../../../services/financialManagement/FinancialManagementService';

import './index.css';
import { PiFilePdf, PiFileTextLight } from 'react-icons/pi';
import { TbFileTypeXml } from 'react-icons/tb';

interface NotasFiscaisModel {
    rpsId: number;
    nfseId: string;
    clientId: number;
    contractId: number;
    salesOrderId: number;
    issuanceDate: Date;
    dueDate: Date;
    contractStartDate: Date | null;
    amount: number;
    status: string;
    razaoSocial: string;
    contractVendor: string;
    clientGroupId: number;
    contractName: any;
    typeNF: any;
    cnpj: string | null;
}

interface NFFilter {
    size: number,
    column: string,
    direction: string,
    contractId: number,
    contractName: string,
    status: string,
    rpsId: number,
    razaoSocial: string
    cnpj: string
}

const emptyNFFilter = {
    size: 20,
    column: "",
    direction: "asc",
    contractId: 0,
    contractName: "",
    status: "",
    rpsId: 0,
    razaoSocial: "",
    cnpj: ""
}

const formatBRL = (value: number) => {
    return `${Intl.NumberFormat('pt-BR', {
        style: 'currency', currency: 'BRL',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(value)}`
}

const formatLocalDate = (date: Date) => {
    return new Date(date).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' })
}

const getAllYears = () => {
    let allYears: { value: Number, name: string }[] = [];
    for (var index = 2018; index <= new Date().getFullYear(); index++) {
        let yearObject = {
            value: index,
            name: '' + index
        }
        allYears.push(yearObject);
    }
    return allYears.sort((a, b) => (b.value > a.value) ? 1 : -1);
}

const DuplicatesRemoverByField = (array: any, field: string) => {
    const uniqueMap = {};
    const result: any[] = [];

    for (const item of array) {
        const fieldValue = item[field];

        if (!uniqueMap[fieldValue]) {
            uniqueMap[fieldValue] = true;
            result.push(item);
        }
    }

    return result;
}

var scope = {
    splitterStyle: {
        minHeight: 'calc(7vw)',
        fontSize: '11px'
    },
    splitterStyleSmall: {
        minHeight: 'calc(7vw)',
        fontSize: '11px'
    }
};
const columnsOffer = [
    { field: 'chargeType', headerName: 'Tipo', width: "75%" },
    {
        field: 'chargeStartDate', headerName: 'Início', width: "15%", align: 'right',
        renderCell: (row: any) => {
            return <div style={{ textAlign: 'right' }}>
                {row.chargeStartDate ?
                    formatLocalDate(row.chargeStartDate)
                    :
                    'Sem informação'}
            </div>
        }
    },
    {
        field: 'chargeEndDate', headerName: 'Vigência estimada', width: "15%", align: 'right',
        renderCell: (row: any) => {
            return <div style={{ textAlign: 'right' }}>
                {row.chargeEndDate ?
                    formatLocalDate(row.chargeEndDate)
                    :
                    'Sem informação'}
            </div>
        }
    }
];
const columnsDetailsItensTable = [
    { field: 'id', headerName: 'Pn/SIP', width: "25%", sort: false },
    { field: 'description', headerName: 'Descrição', width: "35%", sort: false },
    { field: 'qtd', headerName: 'Quantidade', width: "10%", align: 'right', sort: false },
    {
        field: 'unitValue', headerName: 'Valor unitário', width: "15%", align: 'right', sort: false,
        renderCell: (row) => {
            return <div style={{ textAlign: 'right' }}>R$ {row.unitValue}</div>
        }
    },
    {
        field: 'totalValue', headerName: 'Valor total', width: "15%", align: 'right', sort: false,
        renderCell: (row) => {
            return <div style={{ textAlign: 'right' }}>R$ {row.totalValue}</div>
        }
    }
];
const getStatus = (row: any) => {
    return row.status === "CONCLUÍDO" ? "secondary" : 'danger';
}

const formatCNPJ = (cnpj: string | null): string => {
    if (!cnpj) return '---';
    const cleaned = cnpj.replace(/\D/g, '');
    if (cleaned.length !== 14) return cnpj;
    return cleaned.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        '$1.$2.$3/$4-$5'
    );
};
const NotasFiscais: React.FC = (props) => {
    const user = useSelector<RootState, UserState>(state => state.user)
    const service = new FinancialManagementService(props);
    const dispatch = useDispatch();
    const pref = useSelector<RootState, PrefState>(state => state.pref);
    const { enqueueSnackbar } = useSnackbar();
    const [filterVisible, setFilterVisible] = useState(false);
    const [rightSidePanelPinned, setRightSidePanelPinned] = useState(false);
    const [filter, setFilter] = useState<NFFilter>(emptyNFFilter);
    const [modalVisibleDetailsNF, setModalVisibleDetailsNF] = useState<boolean>(false);
    const [isLoadingModal, setIsLoadingModal] = useState(true);
    const [filteredNFDetailsItens, setFilteredNFDetailsItens] = useState([]);
    const [nfSelected, setNfSelected] = useState('');
    const NFDefault = {
        parcelNF: [],
        itens: [],
        licenses: [],
        client: {
            name: null,
            cnpj: null
        },
        company: {
            name: null,
            cnpj: null
        }
    }
    const [NFDetails, setNFDetails] = useState(NFDefault);
    const [filteredNFNFLicensesItens, setFilteredNFNFLicensesItens] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState<any>({});
    const [notasFiscais, setNotasFiscais] = useState<NotasFiscaisModel[]>([]);
    const [notasFiscaisFiltered, setNotasFiscaisFiltered] = useState<NotasFiscaisModel[]>([]);
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);

    function retrieveData(ano?: number) {
        let year: Number = moment().year();
        if (ano !== undefined) {
            year = ano
        }
        setNotasFiscaisFiltered([]);
        setLoading(true);
        service.GetInvoicesByClientGroupContractYear(year)
            .then(res => {
                if (res.length > 0) {
                    setNotasFiscais(DuplicatesRemoverByField(res, 'nfseId'));
                }
                else {
                    setNotasFiscais([]);
                }
                setLoading(false);
            })
            .catch(err => {
                setNotasFiscais([]);
                setLoading(false);
                console.warn(err);
            })
    }

    useEffect(() => {
        retrieveData();
    }, [user.ClientGroupSelected]);

    async function getDetailsInvoiceProduto(row: any) {
        setModalVisibleDetailsNF(true);
        setIsLoadingModal(true);
        setNFDetails(NFDefault);
        setFilteredNFDetailsItens([]);
        setNfSelected('');

        const ClientId = row.clientId;
        const RpsId = row.rpsId;
        const SalesOrderId = row.salesOrderId;
        service.GetCompleteInvoiceDetails(ClientId, RpsId, SalesOrderId).then((response) => {
            if (response) {
                console.log(response)
                setNfSelected(row.nfseId);
                setNFDetails(response);
                response.itens && setFilteredNFDetailsItens(response.itens);
                response.licenses && setFilteredNFNFLicensesItens(response.licenses);
                response.licenses && response.licenses.length > 0 &&
                    setSelectedProduct(response.licenses[0]);
            }

            setIsLoadingModal(false);
        });
    }

    async function getDetailsInvoicePDF(row: any) {

        setNFDetails(NFDefault);
        setFilteredNFDetailsItens([])

        const nfseId = row.nfseId;
        const RpsId = row.rpsId;

        service.GetInvoiceFilePDF(nfseId, RpsId).then((response) => {
            if (response?.includes("The specified resource does not exist")) {
                enqueueSnackbar(`A nota fiscal seleciona não foi encontrada para download`, {
                    variant: 'error',
                    preventDuplicate: true,
                    persist: false
                })
            }
        });
    }

    async function getDetailsInvoiceXML(row: any) {

        setNFDetails(NFDefault);
        setFilteredNFDetailsItens([])

        const nfseId = row.nfseId;
        const RpsId = row.rpsId;

        service.GetInvoiceFileXML(nfseId, RpsId);
    }

    const columns = [
        {
            field: "contractId", headerName: "Contrato", width: "8%", align: "left",
        },
        {
            field: "contractName", headerName: "Descrição", width: rightSidePanelPinned && filterVisible ? "10%" : "13%", align: "left",
            renderCell: (row: any) => {
                let text = (row.contractName && row.contractName.length > 0) ? row.contractName : row.contractVendor;
                return <span title={text} className='ellipsis'>
                    {text}
                </span>
            }
        },
        {
            field: "contractStartDate", headerName: "Início", width: rightSidePanelPinned && filterVisible ? "0%" : "10%", align: "left", hide: rightSidePanelPinned && filterVisible,
            renderCell: (row) => {
                if (row.contractStartDate) {
                    let text = new Date(row.contractStartDate).toLocaleDateString();
                    return text
                }
                return '--';
            }
        },
        {
            field: "rpsId", headerName: "RPS", width: "6%", align: "left",
            renderCell: (row: any) => {
                let text = row.rpsId;
                return <span className='cy-rpsId'>
                    {text}
                </span>
            }
        },
        {
            field: "nfseId", headerName: "NF-e", width: rightSidePanelPinned && filterVisible ? "10%" : "15%", align: "left", truncate: true,
        },
        {
            field: "issuanceDate", headerName: "Emissão", width: rightSidePanelPinned && filterVisible ? "0%" : "10%", align: "left", hide: rightSidePanelPinned && filterVisible,
            renderCell: (row) => {
                let text = new Date(row.issuanceDate).toLocaleDateString();
                return text
            }
        },
        { field: "status", headerName: "Status", width: rightSidePanelPinned && filterVisible ? "0%" : "10%", hide: rightSidePanelPinned && filterVisible },
        {
            field: "cnpj", headerName: "CNPJ", width: rightSidePanelPinned && filterVisible ? "0%" : "16%", hide: rightSidePanelPinned && filterVisible,
            renderCell: (row) => formatCNPJ(row.cnpj)
        },
        {
            field: "razaoSocial", headerName: "Razão social", width: rightSidePanelPinned && filterVisible ? "17%" : "20%", align: "left", truncate: true,
        },
        {
            field: "amount", headerName: "Valor total", width: rightSidePanelPinned && filterVisible ? "12%" : "15%", align: "right",
            renderCell: (row) => {
                let text = row.amount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                return text
            }
        },
        {
            field: "salesOrderId", headerName: "Pedido", width: "8%", align: "right",
        },
        {
            field: 'pdf', headerName: 'PDF', width: "5%", align: "center", overflow: "visible", sort: false,
            renderCell: (row, density) => {
                return <LcIconLink size='small' icon={<PiFilePdf color='var(--status-red)' />} tooltip="Baixar Nota Fiscal PDF"
                    onClick={() => getDetailsInvoicePDF(row)}
                    id={`pdf_icon_${row.id || row.invoiceNumber || 'default'}`}
                />
            }
        },
        {
            field: 'xml', headerName: 'XML', width: "5%", align: "center", overflow: "visible", sort: false,
            renderCell: (row, density) => {
                return <LcIconLink size='small' icon={<TbFileTypeXml color='var(--status-yellow)' />} tooltip="Baixar Nota Fiscal XML"
                    onClick={() => getDetailsInvoiceXML(row)}
                    id={`xml_icon_${row.id || row.invoiceNumber || 'default'}`}
                />
            }
        },
        {
            field: 'details', headerName: 'Detalhes', width: "7%", align: "center", overflow: "visible", sort: false,
            renderCell: (row, density) => {
                return <LcIconLink size='small' icon={<PiFileTextLight />} tooltip='Detalhes'
                    onClick={() => getDetailsInvoiceProduto(row)}
                    id={`details_icon_${row.id || row.invoiceNumber || 'default'}`}
                />
            }
        }
    ];

    const changeDensity = (density: string) => {
        dispatch({ type: 'PREF_UPDATE', payload: { name: "density", value: density } });
    }

    const columnsLicenseItensColumns = [{
        field: 'dueDade', headerName: '', width: "5%",
        renderCell: (row: any) => {
            return <LcIconLink
                icon={`${row == selectedProduct ? 'ppi ppi-radiobtn-on' : 'ppi ppi-radiobtn'}`}
                color='var(--color-secondary-lime)'
                size='small'
                onClick={() => {
                    setSelectedProduct(row);
                }}
            />
        }
    },
    {
        field: 'productDescription', headerName: 'Selecione item para detalhamento', width: "55%", sort: false,
        renderCell: (row) => {
            return <div
                style={{ cursor: 'pointer' }}
                onClick={() => {
                    setSelectedProduct(row);
                }} > {row.productDescription}</div>
        }
    },
    { field: 'qtd', headerName: 'Quantidade', width: "10%", align: 'right', sort: false },
    {
        field: 'unitValue', headerName: 'Valor unitário', width: "15%", align: 'right', sort: false,
        renderCell: (row: any) => { return <div style={{ textAlign: 'right' }}>{formatBRL(row.totalValue / row.qtd)}</div> }
    },
    {
        field: 'totalValue', headerName: 'Valor total', width: "15%", align: 'right', sort: false,
        renderCell: (row: any) => { return <div style={{ textAlign: 'right' }}>{formatBRL(row.totalValue)}</div> }
    }
    ];

    const layoutDots = (
        <>
            <div style={{ color: pref.data?.density === "high" ? "#abaeb9" : "#000" }} className={`item${pref.data?.density === "high" ? " disabled" : ""}`} onClick={() => changeDensity("high")}><i className='ppi ppi-view-list' /> Densidade alta</div>
            <div style={{ color: pref.data?.density === "medium" ? "#abaeb9" : "#000" }} className={`item${pref.data?.density === "medium" ? " disabled" : ""}`} onClick={() => changeDensity("medium")}><i className='ppi ppi-menu' /> Densidade média</div>
            <div style={{ color: pref.data?.density === "low" ? "#abaeb9" : "#000" }} className={`item${pref.data?.density === "low" ? " disabled" : ""}`} onClick={() => changeDensity("low")}><i className='ppi ppi-menu-alt-4' /> Densidade baixa</div>
        </>
    )

    const yearFilterSelector = (
        <LcDropDown
            element={<span>{getAllYears()[value].name} <i className="ppi ppi-chevron-down" /></span>}
            position="right"
            children={
                getAllYears().map((year: any, index) => {
                    return <div key={index} id={`rightToolbar_year_button_${index}`} className="item" onClick={() => { retrieveData(year.value); setValue(index) }}>{year.name}</div>
                })
            }
        />
    )

    const card = [
        {
            id: 1,
            type: 'Custom',
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loading={loading} label="Carregando dados...">
                        <LcInfiniteTable
                            loading={loading}
                            columns={columns}
                            rows={notasFiscaisFiltered}
                            filter={filterAdvanced}
                            size={notasFiscaisFiltered.length}
                            loadMore={() => { }}
                            onSortChange={onSortChange}
                            density={pref.data?.density || "high"}
                            status={getStatus}
                            disableFilterModal
                            tooltipContentColumn='status'
                        />
                    </LcLoading>
                )
            }
        }
    ]

    const onSortChange = (sortData: any) => {
        const { sort, size } = sortData;
        setFilter({ ...filter, column: sort.column, direction: sort.direction, size: size })
    };

    const filterAdvanced = {
        fields: [
            { label: "Contrato", name: "contractId", type: "text" },
            { label: "Descrição", name: "contractName", type: "text" },
            {
                label: "Status", name: "status", type: "select", options: [
                    { id: 'form_filtros_option1', label: 'TODOS', value: '' },
                    { id: 'form_filtros_option2', label: 'CONCLUÍDO', value: 'CONCLUÍDO' },
                    { id: 'form_filtros_option3', label: 'CANCELADO', value: 'CANCELADO' }
                ]
            },
            { label: "RPS", name: "rpsId", type: "text" },
            { label: "CNPJ", name: "cnpj", type: "text" },
            { label: "Razão social", name: "razaoSocial", type: "text", id: 'rzaosocial' },
        ],
        onChange: (filter: any, size: number) => setFilterVisible({ ...filter, size: size, term: filter[0].value.toLowerCase() }),
        visible: filterVisible,
        onChangeVisibility: () => setFilterVisible(!filterVisible)
    }

    const filterSystem = () => {
        return (
            <div className="filter-container">
                <FilterLcInfiniteTable
                    filter={filterAdvanced.fields}
                    onFilterChange={handleFilterChange}
                    size={10}
                />
            </div>
        );
    };

    const applyFilter = useCallback(() => {
        let isFilterActive = false;

        if (notasFiscais.length > 0) {
            const { contractId, rpsId, razaoSocial, contractName, status, cnpj } = filter;
            let filtered = [...notasFiscais].sort((a, b) => a.issuanceDate > b.issuanceDate ? 1 : -1);

            if (contractId > 0) {
                filtered = filtered.filter(nf => nf.contractId === contractId);
            }

            if (rpsId > 0) {
                filtered = filtered.filter(nf => nf.rpsId === rpsId);
            }

            if (razaoSocial) {
                filtered = filtered.filter(nf => nf.razaoSocial.toLowerCase().includes(razaoSocial));
            }

            if (status) {
                filtered = filtered.filter(nf => nf.status.toLowerCase().includes(status));
            }

            if (cnpj) {
                filtered = filtered.filter(nf => nf.cnpj && nf.cnpj.toLowerCase().includes(cnpj));
            }

            if (contractName) {
                filtered = filtered.filter(nf => {
                    const name = nf.contractName || nf.contractVendor; // Use contractName se disponível, senão contractVendor
                    return name.toLowerCase().includes(contractName);
                });
            }

            var sorted = filtered.sort((a: any, b: any) => {
                if (filter.direction === 'asc') return (a[filter.column] > b[filter.column]) ? 1 : -1;
                else return (a[filter.column] > b[filter.column]) ? -1 : 1;
            });

            isFilterActive = filtered.length !== notasFiscais.length;
            setNotasFiscaisFiltered(filtered);
            dispatch(setFilterActive(isFilterActive));
        }
    }, [filter, notasFiscais, dispatch]);

    useEffect(() => {
        applyFilter();
    }, [applyFilter]);

    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);

    const gridFilter = {
        toggleVisibility: () => {
            setFilterVisible(!filterVisible)
        },
        size: notasFiscaisFiltered.length
    }

    const handleFilterChange = (updatedFilters) => {
        const contractIdFilter = updatedFilters.find(f => f.name === 'contractId')?.value || '';
        const rpsIdFilter = updatedFilters.find(f => f.name === 'rpsId')?.value || '';
        const razaoSocialFilter = updatedFilters.find(f => f.name === 'razaoSocial')?.value || '';
        const contractNameFilter = updatedFilters.find(f => f.name === 'contractName')?.value || '';
        const statusFilter = updatedFilters.find(f => f.name === 'status')?.value || '';
        const cnpjFilter = updatedFilters.find(f => f.name === 'cnpj')?.value || '';

        // Ajuste para garantir que valores vazios sejam tratados como 0
        const contractId = contractIdFilter ? parseInt(contractIdFilter, 10) : 0;
        const rpsId = rpsIdFilter ? parseInt(rpsIdFilter, 10) : 0;

        setFilter({
            ...filter,
            contractId: contractId,
            rpsId: rpsId,
            razaoSocial: razaoSocialFilter.toLowerCase(),
            contractName: contractNameFilter.toLowerCase(),
            status: statusFilter.toLowerCase(),
            cnpj: cnpjFilter.toLowerCase()
        });
    };

    const toggleRightSidePanelPinned = () => {
        setRightSidePanelPinned(prevState => !prevState); // Atualiza com base no estado anterior        
    };

    const dashboardRightPanel = {
        title: 'Filtros',
        pinned: rightSidePanelPinned,
        togglePinned: toggleRightSidePanelPinned,
        show: filterVisible,
        content: filterSystem,
        close: (e) => { setFilterVisible(e); setRightSidePanelPinned(false) }
    }

    return (
        <Layout pageTitle='Notas fiscais' loading={loading} dots={layoutDots} gridFilter={gridFilter} row={yearFilterSelector} >

            <PPModal size='large' visible={modalVisibleDetailsNF} title={`Detalhes da nota fiscal ${nfSelected}`} onClose={() => setModalVisibleDetailsNF(false)}>
                {!isLoadingModal && <h4>{(NFDetails !== undefined)
                    ? NFDetails.company.name : "CANCELADO"}
                    {' '}{NFDetails !== undefined && NFDetails.company.cnpj !== "" ?
                        `| CNPJ: ${NFDetails.company.cnpj}` :
                        (NFDetails !== undefined ? NFDetails.company.cnpj : "CANCELADO")
                    }</h4>
                }

                <LcLoading label="Carregando dados.." loading={isLoadingModal} >
                    {
                        NFDetails && NFDetails.itens && NFDetails.itens.length > 0 &&
                        <div style={scope.splitterStyleSmall}>
                            <LcTable
                                columns={columnsDetailsItensTable}
                                data={filteredNFDetailsItens}
                                hidetopBar
                            />
                        </div>
                    }

                    <div style={scope.splitterStyle}>
                        {
                            NFDetails && NFDetails.licenses && NFDetails.licenses.length > 0 &&
                            <LcTable
                                columns={columnsLicenseItensColumns}
                                data={filteredNFNFLicensesItens}
                                hidetopBar
                            />
                        }
                    </div>
                    <div style={scope.splitterStyle}>
                        {
                            selectedProduct && selectedProduct.offers && selectedProduct.offers.length &&
                            <>
                                <h4 style={{ marginTop: '30px' }}>Detalhamento - {selectedProduct.productDescription}</h4>

                                <LcInfiniteTable
                                    rows={selectedProduct.offers}
                                    columns={columnsOffer}
                                    loadMore={() => { }}
                                    size={selectedProduct.offers.length}
                                    hidetopBar
                                />
                            </>
                        }
                    </div>
                </LcLoading>

            </PPModal>

            <LCDashboard cards={card} rightSidePanel={dashboardRightPanel} />

        </Layout >
    );
}
export default NotasFiscais;