import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import EndpointDetectionResponseService from '../../../services/endpointDetectionResponse/EndpointDetectionResponseService';
import LcLoading from '../../../components/Generic/LcLoading';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import { IoShieldOutline } from 'react-icons/io5';
import { TbFishHook } from 'react-icons/tb';
import moment from 'moment';
import { translateConfidenceLevel, translateAction } from './MaliciousFileCard';
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';

export const collumnZeroPhising = [
    { field: 'resource', headerName: 'URL acessada', width: "25%"},
    { field: 'src', headerName: 'Origem', width: "10%" },
    { field: 'user_name', headerName: 'Usuário', width: "20%", renderCell:(row: any) => row.user_name},
    { field: 'confidence_level', headerName: 'Confiança', width: "8%", renderCell: (row: any) => translateConfidenceLevel(row.confidence_level) },
    { field: 'action', headerName: 'Ação', width: "10%", renderCell: (row: any) => translateAction(row.action) },
    { field: 'time', headerName: 'Data', width: "12%", renderCell: (row: any) =>  moment(row.time).format('DD/MM/YYYY HH:mm:ss') },
    { field: 'description', headerName: 'Descrição', width: "40%", renderCell: (row: any) => {
        return(
            <Tooltip arrow title={row.description} leaveDelay={0} PopperProps={{style: {pointerEvents: 'none'}}} enterDelay={200} >
                <div style={{ width: '28vw' }} className="overflowEllipsis">{row.description}</div>
            </Tooltip>
        )
    }  },

]

export const tranlationsZeroPhising = {
    resource: {
        label: "URL acessado",
        type: "text",
    },
    user_name: {
        label: "Usuário",
        type: "text",
    },
    src: {
        label: "Origem",
        type: "text",
    },
    action: {
        label: "Ação",
        type: "multiSelect",
        values: {
            'Detect':'Detectado',
            "Prevent":'Prevenido',
        }
    },
}

export const ZeroPhisingCard: React.FC = () => {
    const dispatch = useDispatch();
    const period = useSelector((state: RootState) => state.xdrCheckpointResume.periodFilter);
    const user = useSelector<RootState, UserState>(state => state.user);
    const checkpointService = new EndpointDetectionResponseService({});
    const loading = useSelector((state: RootState) => state.xdrCheckpointResume.loadingStates.loadingZeroPhising);
    const { data: ZeroPhinsingData,  refetch,isLoading } = useQuery({
        queryKey: ["ZeroPhinsing",period, user.refreshFlag, user.ClientGroupSelected],
        queryFn: () => checkpointService.GetZeroFishing(period),
        refetchOnWindowFocus: false,
        onSuccess: (response) => {
            dispatch({ type: 'SET_ZERO_PHISING_DATA', payload: response });
        },
        onError: (error) => {
            console.error('Error fetching URL filtering data:', error);
        }
    });
    useEffect(() => {
        dispatch({ type: 'SET_LOADING', payload: { key: 'loadingZeroPhising', value: isLoading } });
    }, [dispatch, isLoading]);


    useEffect(() => {
        refetch();
    }, [user.ClientGroupSelected, user.refreshFlag, refetch]);
    return (
        <LcLoading loading={isLoading || loading}> 
            <IconValue 
                value={ZeroPhinsingData ? ZeroPhinsingData.recordCount : 0}
                icon={
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <IoShieldOutline   size={30} />
                    <TbFishHook   size={18} style={{ position: 'absolute', bottom:10 , zIndex: '100', left: 7, color: 'var(--color-primary-blue)' }} />
                </div>}
                color='var(--color-primary-blue)'
                description='Detectados'
            />

        </LcLoading>
    );
}
