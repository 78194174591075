import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LcLoading from '../../../components/Generic/LcLoading';
import LcNoData from '../../../components/Generic/LcNoData';
import ReactECharts from 'echarts-for-react';
import { Chart } from '../../endpointDetectionResponse/resumeEvent/Models';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { UserState } from '../../../store/reducers/userReducer';
import { SaasService } from '../../../services/saas/saasService';
import { useQuery } from 'react-query';

interface OneDriveUsageChartModel {
    userPeriod: number
}
const getOptionsChart =(oneDriveUsageChart: Chart) => {
    return {
        grid: {
            height: 'fit-content',
            top: '5%',
            left: '3%',
            right: '3%',
            bottom: '0%',
            containLabel: true
        },
        color: ['#3398DB', '#B4009E', '#BAD24B', '#00B7C3', '#117D3F', '#5C2D91', '#F16323'],
        tooltip: {
            backgroundColor: '#f1f5fb',
            borderWidth: 0,
            textStyle: {
                fontSize: 11,
                fontFamily: 'Ubuntu',
                color: '#2B304F',
            },
            trigger: 'axis',
            formatter: function (params) {
                // Adding color box using item.marker and then appending series name and value
                return params.map(item => `${item.marker} ${item.seriesName}: ${item.value}`).join('<br/>');
            }
        },
        xAxis: {
            type: 'category',
            boundaryGap: false,
            data: oneDriveUsageChart.labels,
            axisLabel: {
                fontSize: '10px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value}',
                fontSize: '10px',
                fontFamily: 'Ubuntu',
                color: '#8b90a3',
            },
            //interval: 150
        },
        series: oneDriveUsageChart.datasets,
    };
}

const  OneDriveUsageChartData = (response: any): Chart => {
    if (response.data !== null) {
        let oneDriveUsageChart = {
            labels: response.data.map((Item: any) => {
                let date = new Date(Item.reportDate);
                return date.getDate().toString().padStart(2, '0') + "/" + (date.getMonth() + 1).toString().padStart(2, '0');
            }),
            datasets: [
                {
                    name: "Total de contas",
                    data: response.data.map((Item: any) => {
                        return Item.total
                    }),
                    type: 'line',
                    smooth: true
                },
                {
                    name: "Contas ativas",
                    data: response.data.map((Item: any) => {
                        return Item.active
                    }),
                    type: 'line',
                    smooth: true
                }
            ]
        };
        return oneDriveUsageChart;
    }else {
        let oneDriveUsageChart = {
            labels: [],
            datasets: []
        };
        return oneDriveUsageChart;
    }
}

export const OneDrveUsageChart: React.FC<OneDriveUsageChartModel> = ({userPeriod}) => {
    const user = useSelector<RootState, UserState>(state => state.user);
    const {data: chartData, isLoading } = useQuery({
        queryKey: ['oneDriveUsageChart', userPeriod, user.ClientGroupSelected, user.refreshFlag],
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        queryFn: async () => {
            const saasService = new SaasService({});
            const response = await saasService.GetOneDriveUsageChart(userPeriod);
            const oneDriveUsageChart = OneDriveUsageChartData(response);
            return getOptionsChart(oneDriveUsageChart);
        },
        onError: (error) => {
            console.error('Erro ao carregar dados do gráfico:', error);
        }
    })
    return (
        <LcLoading loading={isLoading}>
        {chartData && Object.keys(chartData).length > 0 ?
            <ReactECharts
                className="chart-medium"
                style={{ width: '100%', height: '100%' }}
                opts={{ renderer: 'svg', locale: 'PT-br' }}
                option={chartData}
            />
            :
            <LcNoData size="small" label="Sem dados no período." />
        }
    </LcLoading>
    );
}
