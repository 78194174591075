import React, { useEffect, useMemo, useState } from 'react';
import './index.css'
import LcIconLink from '../../../../components/Generic/LcIconLink';
import LcLoading from '../../../../components/Generic/LcLoading';
import { logActivitiesModel, AwsEvent, Resource } from '../../../../services/cmsPremier/cmsPremierModel';
import { CmsPremierService } from '../../../../services/cmsPremier/cmsPremierService';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { UserState } from '../../../../store/reducers/userReducer';
import LcNoData from '../../../../components/Generic/LcNoData';
import { SiAmazonaws, SiMicrosoftazure } from 'react-icons/si';
import { PiCheckCircleLight, PiInfoLight, PiXCircleLight } from 'react-icons/pi';
import LcInfiniteTable, { Sort, TableColumn } from '../../../../components/Data/LcInfiniteTable';
interface ActivityLogProps {
    focus: boolean
}
// Helper function to safely parse JSON
const safeJsonParse = (jsonString: string) => {
    try {
        return JSON.parse(jsonString);
    } catch (error) {
        console.error("Failed to parse JSON string:", error);
        return null;
    }
};

// Function to convert JSON object to typed object with defensive programming
const convertToTypedObject = (jsonObject: AwsEvent): AwsEvent | null => {
    if (typeof jsonObject !== 'object' || jsonObject === null) {
        console.error("Invalid input: jsonObject is not an object");
        return null;
    }

    const { cloudTrailEvent, readOnly, resources, ...rest } = jsonObject;

    if (typeof cloudTrailEvent !== 'string') {
        console.error("Invalid input: cloudTrailEvent is not a string");
        return null;
    }

    const parsedCloudTrailEvent = safeJsonParse(cloudTrailEvent);
    if (!parsedCloudTrailEvent) {
        console.error("Invalid input: cloudTrailEvent could not be parsed");
        return null;
    }

    if (readOnly !== "true" && readOnly !== "false") {
        console.error("Invalid input: readOnly should be 'true' or 'false'");
        return null;
    }

    if (!Array.isArray(resources)) {
        console.error("Invalid input: resources should be an array");
        return null;
    }

    return {
        ...rest,
        cloudTrailEvent: parsedCloudTrailEvent,
        readOnly: readOnly === "true",
        resources: resources as Resource[],
    };
};
const ActivityLog: React.FC<ActivityLogProps> = (props) => {
    const [logActivitiesData, setLogActivitiesData] = useState<logActivitiesModel[]>()
    const [loading, setLoading] = useState<boolean>(false)
    const user = useSelector<RootState, UserState>(state => state.user);
    const [sortColumn, setSortColumn] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const toggleSort = (newColumn: Sort) => {
        if (sortColumn === newColumn.sort.column) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortColumn(newColumn.sort.column);
            setSortDirection('asc');
        }
    };
    const sortedActivities = useMemo(() => {
        if (!logActivitiesData || !sortColumn) {
            return logActivitiesData;
        }
        return [...logActivitiesData].sort((a, b) => {
            if (a[sortColumn] < b[sortColumn]) {
                return sortDirection === 'asc' ? -1 : 1;
            }
            if (a[sortColumn] > b[sortColumn]) {
                return sortDirection === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }, [logActivitiesData, sortColumn, sortDirection]);

    function formatDate(dateString?: string | null): string {
        if (dateString === null || dateString === undefined) {
            return '-';
        }

        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');

        return `${hours}:${minutes} ${day}/${month}/${year}`;
    }


    useEffect(() => {
        const getLogActivites = async () => {
            try {
                setLoading(true)
                const CmsService = new CmsPremierService({});
                const activities = await CmsService.getLogActivities();
                const activitiesMapped: logActivitiesModel[] = activities.azureLog.map(item => ({
                    id: item.id,
                    provider: 'Azure',
                    resource: item.operationName.localizedValue,
                    resourceId: item.resourceId,
                    status: item.status.localizedValue,
                    eventTime: item.eventTimestamp,
                    subscriptionId: item.subscriptionId,
                    descricao: item.resourceProviderName.localizedValue + ' ' + item.resourceType.localizedValue,
                    level: item.level,
                }));
                activities.awsLog.map(item => {
                    var objectAwsEvent = convertToTypedObject(item);
                    if (!objectAwsEvent) {
                        return item;
                    }
                    var resourcesString = '';
                    if (objectAwsEvent.resources && objectAwsEvent.resources.length > 0) {
                        resourcesString = objectAwsEvent.resources
                            .map(resource => resource.resourceName)
                            .join(', ');
                    }
    
                    activitiesMapped.push({
                        id: item.id,
                        provider: 'AWS',
                        resource: resourcesString ? objectAwsEvent.resources[0].resourceName : objectAwsEvent.cloudTrailEvent.resources && objectAwsEvent.cloudTrailEvent.resources.length > 0 ? objectAwsEvent.cloudTrailEvent.resources[0].ARN : objectAwsEvent.eventSource,
                        resourceId: resourcesString ? objectAwsEvent.resources[0].resourceName : objectAwsEvent.cloudTrailEvent.resources && objectAwsEvent.cloudTrailEvent.resources.length > 0 ? objectAwsEvent.cloudTrailEvent.resources[0].ARN : objectAwsEvent.eventSource,
                        status: 'warning',
                        eventTime: objectAwsEvent.eventTime,
                        subscriptionId: objectAwsEvent.cloudTrailEvent.resources && objectAwsEvent.cloudTrailEvent.resources.length > 0 ? objectAwsEvent.cloudTrailEvent.resources[0].accountId : objectAwsEvent.eventSource,
                        descricao: objectAwsEvent.cloudTrailEvent.awsRegion + ' - ' + objectAwsEvent.cloudTrailEvent.eventCategory + ' - ' + objectAwsEvent.cloudTrailEvent.eventType + ' - ' + objectAwsEvent.eventName,
                        level: objectAwsEvent.cloudTrailEvent.eventType,
                    });
                    return item;
                });
    
                const sortedActivities = activitiesMapped.sort((a, b) => {
                    return new Date(b.eventTime).getTime() - new Date(a.eventTime).getTime();
                });
    
                setLogActivitiesData(sortedActivities);
                setLoading(false)
            } catch (error) {
                console.error('Error:', error);
                setLoading(false)
            }
        }
        getLogActivites()
    }, [user.ClientGroupSelected, user.refreshFlag])

    const columns: TableColumn[] = [
        { field: 'eventTime', headerName: 'Data', width: props.focus? '15%': "20%", align: 'left', renderCell: (atv: any) => formatDate(atv.eventTime) },
        { field: 'resource', headerName: 'Recurso', width: props.focus ? "20%" : "65%", truncate: true }, 
        { field: 'subscriptionId', headerName: 'Assinatura', width: "25%", hide: !props.focus },
        { field: 'descricao', headerName: 'Detalhes', width: "40%" , hide: !props.focus,  truncate: true },
        { field: 'provider', headerName: 'Cloud', width: "10%",align: 'center', renderCell: (atv: any) =>{
            return <div className={props.focus ? 'cloud_focus':'cloud'} >
                    {
                    atv.provider.localeCompare('AWS', 'pt-BR') === 0
                        ? <div id={`cloud_${atv.id}_row_${atv.id}` } style={{ color: '#2E1956', textAlign: 'center' }} ><SiAmazonaws title="AWS" size='18' /></div>
                        : atv.provider.localeCompare('Azure', 'pt-BR') === 0
                            ? <div style={{ color: '#2E1956', textAlign: 'center' }}><SiMicrosoftazure title="Microsoft Azure" /></div>
                            : <div  style={{ color: 'grey' }}>N/A</div>
                }
            </div>
        } },
        { field: 'status', headerName: 'Status', width: "10%",align: 'center', overflow: 'visible', renderCell: (atv) => {
            return  <div className={props.focus ? 'status_focus':'status'}>
                    {
                    (atv.status.localeCompare('Succeeded', 'pt-BR') === 0
                        || atv.status.localeCompare('Accepted', 'pt-BR') === 0
                        || atv.status.localeCompare('Updated', 'pt-BR') === 0) ?
                        <LcIconLink   tooltipPosition="inline-left" tooltip={atv.status} icon={<PiCheckCircleLight/>} color='#2CA02C' size='small' />
                        : atv.status.localeCompare('Started', 'pt-BR') === 0 ?
                            <LcIconLink  tooltipPosition="inline-left" tooltip={atv.status} icon={<PiInfoLight/>} color='#53a8dd' size='small' />
                            : atv.status.localeCompare('warning', 'pt-BR') === 0 ? <LcIconLink tooltipPosition="inline-left" tooltip={atv.status} icon={<PiInfoLight/>} color='#53a8dd' size='small' /> :
                                <LcIconLink  id={`status${atv.id}_row_${atv.id}` }tooltipPosition="inline-left" tooltip={atv.status} icon={<PiXCircleLight/>} color='#d35555' size='small' />
                }
            </div>
        } },
    ]

    return (
        <LcLoading loading={loading}>
            {
                sortedActivities && sortedActivities?.length > 0 ?
                <LcInfiniteTable
                    columns={columns}
                    rows={sortedActivities}
                    size={sortedActivities.length}
                    density='high'
                    onSortChange={toggleSort}
                    hidePagination
                    
                    height='calc(100% - 50px)'
                />
                    // <>
                    //     <div className="headerLog">
                    //         {(
                    //             <div className={`${props.focus ? 'eventTime_focus' : 'eventTime'}`} onClick={() => toggleSort('eventTime')} style={{ cursor: 'pointer' }}>
                    //                 Data
                    //                 {sortColumn === 'eventTime' && (sortDirection === 'asc' ? <i className="ppi ppi-arrow-up"></i> : <i className="ppi ppi-arrow-down"></i>)}
                    //             </div>
                    //         )}
                    //         <div className={!props.focus ? 'resources' : 'resources_focus'} onClick={() => toggleSort('resource')} style={{ cursor: 'pointer' }}>
                    //             Recurso
                    //             {sortColumn === 'resource' && (sortDirection === 'asc' ? <i className="ppi ppi-arrow-up"></i> : <i className="ppi ppi-arrow-down"></i>)}
                    //         </div>
                    //         {props.focus && (
                    //             <div className='resources_focus' onClick={() => toggleSort('subscriptionId')} style={{ cursor: 'pointer' }}>
                    //                 Assinatura
                    //                 {sortColumn === 'subscriptionId' && (sortDirection === 'asc' ? <i className="ppi ppi-arrow-up"></i> : <i className="ppi ppi-arrow-down"></i>)}
                    //             </div>
                    //         )}
                    //         {props.focus && (
                    //             <div className='resources_focus' onClick={() => toggleSort('descricao')} style={{ cursor: 'pointer' }}>
                    //                 Detalhes
                    //                 {sortColumn === 'descricao' && (sortDirection === 'asc' ? <i className="ppi ppi-arrow-up"></i> : <i className="ppi ppi-arrow-down"></i>)}
                    //             </div>
                    //         )}
                    //         <div className={props.focus ? 'cloud_focus':'cloud'} onClick={() => toggleSort('provider')} style={{ cursor: 'pointer' }}>
                    //             Cloud
                    //             {sortColumn === 'provider' && (sortDirection === 'asc' ? <i className="ppi ppi-arrow-up"></i> : <i className="ppi ppi-arrow-down"></i>)}
                    //         </div>
                    //         <div  className={props.focus ? 'status_focus':'status'} onClick={() => toggleSort('status')} style={{ cursor: 'pointer' }}>
                    //             Status
                    //             {sortColumn === 'status' && (sortDirection === 'asc' ? <i className="ppi ppi-arrow-up"></i> : <i className="ppi ppi-arrow-down"></i>)}
                    //         </div>
                    //     </div>

                    //     <div className="bodyLog scrollable-v">
                    //         {
                    //             sortedActivities.map((atv, index) => {
                    //                 return (
                    //                     <div id={`row_${index}`} className='row' key={index}>
                    //                         <div  id={`date_${index}_row_${index}` } className={props.focus ?'eventTime_focus': 'eventTime'} title={formatDate(atv.eventTime)}> {formatDate(atv.eventTime)}</div>
                    //                         <div id={`resources_${index}_row_${index}` } className={props.focus ? 'resources_focus': 'resources' } title={atv.resourceId} > {atv.resource}</div>
                    //                         {props.focus && <div  id={`resources_focus${index}_row_${index}` } className='resources_focus' > {atv.subscriptionId} </div>}
                    //                         {props.focus && <div  id={`resources_focus${index}_row_${index}` } className='resources_focus' > {atv.descricao} </div>}
                    //                         {
                    //                             <div className={props.focus ? 'cloud_focus':'cloud'} >
                    //                                 {
                    //                                     atv.provider.localeCompare('AWS', 'pt-BR') === 0
                    //                                         ? <div id={`cloud_${index}_row_${index}` } style={{ color: '#2E1956', textAlign: 'center' }} ><SiAmazonaws title="AWS" size='18' /></div>
                    //                                         : atv.provider.localeCompare('Azure', 'pt-BR') === 0
                    //                                             ? <div style={{ color: '#2E1956', textAlign: 'center' }}><SiMicrosoftazure title="Microsoft Azure" /></div>
                    //                                             : <div  style={{ color: 'grey' }}>N/A</div>
                    //                                 }

                    //                             </div>
                    //                         }
                    //                         {
                    //                             <div className={props.focus ? 'status_focus':'status'}>
                    //                                 {
                    //                                     (atv.status.localeCompare('Succeeded', 'pt-BR') === 0
                    //                                         || atv.status.localeCompare('Accepted', 'pt-BR') === 0
                    //                                         || atv.status.localeCompare('Updated', 'pt-BR') === 0) ?
                    //                                         <LcIconLink   tooltipPosition="inline-left" tooltip={atv.status} icon={<PiCheckCircleLight/>} color='#2CA02C' size='small' />
                    //                                         : atv.status.localeCompare('Started', 'pt-BR') === 0 ?
                    //                                             <LcIconLink  tooltipPosition="inline-left" tooltip={atv.status} icon={<PiInfoLight/>} color='#53a8dd' size='small' />
                    //                                             : atv.status.localeCompare('warning', 'pt-BR') === 0 ? <LcIconLink tooltipPosition="inline-left" tooltip={atv.status} icon={<PiInfoLight/>} color='#53a8dd' size='small' /> :
                    //                                                 <LcIconLink  id={`status${index}_row_${index}` }tooltipPosition="inline-left" tooltip={atv.status} icon={<PiXCircleLight/>} color='#d35555' size='small' />
                    //                                 }
                    //                             </div>
                    //                         }
                    //                     </div>
                    //                 )
                    //             })
                    //         }
                    //     </div>
                    // </>
                    :
                    <LcNoData size='small' />
            }
        </LcLoading>
    );
}

export default ActivityLog;