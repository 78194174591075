import React, { useCallback, useEffect, useState } from 'react';
import Layout from '../../../components/Layout/Layout';
import './index.css'
import { useSelector } from 'react-redux';
import { UserState } from '../../../store/reducers/userReducer';
import { RootState } from '../../../store/index';
import LcLoading from '../../../components/Generic/LcLoading';
import { SaasService } from '../../../services/saas/saasService';
import LcNoData from '../../../components/Generic/LcNoData';
import LCDashboard, { Card } from '../../../components/Data/Dashboard/LCDashboard';
import IconValue from '../../../components/Layout/Dashboard/IconValue';
import { PiArrowSquareOutLight, PiCubeBold, PiCubeLight, PiLightbulbLight, PiUsersBold, PiUsersLight } from 'react-icons/pi';
import { LCTechService } from '../../../services/LCTech/LCTechService';
import LcIconLink from '../../../components/Generic/LcIconLink';
import moment from 'moment';
import EventsGraph from '../../endpointDetectionResponse/resumeEvent/EventsGraph';
import { Chart } from '../../endpointDetectionResponse/resumeEvent/Models';
import SoftwareAccountsByMonth from './SoftwareAccountsByMonth';
import PivotTableWebix from '../../../components/webix';
import { SaasUserService } from '../../../services/SaasUserService';
import { SubscriptionData, ActivationChartModel, SoftawareAcoountByMonthModel, SuggestionsSubscriptionModel, ActiveInfoModel, DomainInfoModel } from '../../../services/saas/SaasModel';
import { getIconAndColor, sCdateToJsDate } from '../../CSP/resume';
import { SecureScore } from '../../../services/LCTech/LCTechModel';
import { ProgressBarWithLabel } from '../../../components/Layout/Dashboard/ProgressBarWithLabel';
import { useHistory } from 'react-router-dom';
import { HealthCheckCard } from '../../../components/Layout/Cards/HealthCheckCard';
import { HealthCheckTable } from '../../../components/Layout/Cards/HealthCheckTable';
import { AreaChart } from '../../../components/Data/Dashboard/AreaChart';
import AIxChatComponent from '../../../components/aix/ChatClient/AIxChatComponent';
import MessageService from '../../../services/AIx/messages/MessageService';
import { SubscritionChart } from './SubscriptionsChart';
import { ActivationsByDeviceCard } from './ActivationsByDeviceCard';
//import AIxChatComponent from '../../LiveWatch/Insights/AIxChatComponent';

export const month = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"]

const Insights: React.FC = (props) => {

    const user = useSelector<RootState, UserState>(state => state.user);
    const saasService = new SaasService(props);
    const lcTechService = new LCTechService(props);
    const SaaS_ServiceUSer = new SaasUserService(props);
    const [activeAcounts, setActiveAcounts] = useState<number>(0);
    const [distinctSoftwares, setDistinctSoftwares] = useState<number>(0);
    const [quantityAccountsLabel, setQuantityAccountsLabel] = useState<boolean>(true);
    const [activationsLabel, setActivationsLabel] = useState<boolean>(false);
    const [subiscriptionsLabel, setSubiscriptionsLabel] = useState<boolean>(false);
    const [optionLicensesContratadasLoading, setOptionLicensesContratadasLoading] = useState<boolean>(true);
    const [softawareAccountByMonthData, setSoftwareAccountByMonthData] = useState<SoftawareAcoountByMonthModel[]>([])
    const [formattedData, setFormattedData] = useState<any[]>();
    const [pivotlicensesFocus, setPivotlicensesFocus] = useState<boolean>(false);
    const [pivotlicensesFocusContasAtivas, setPivotlicensesFocusContasAtivas] = useState<boolean>(false);
    const [monthPeak, setMonthPeak] = useState<number>(Number(moment().month()))
    const [fraudEventsReportCount, setFraudEventsReportCount] = useState<any>();
    const [secureScore, setSecureScore] = useState<SecureScore>();
    const [resourceUsagePivotRecords, setResourceUsagePivotRecords] = useState<any[]>([]);
    const [resourceUsagePivotRecordsLoading, setResourceUsagePivotRecordsLoading] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingScore, setLoadingScore] = useState<boolean>(false);
    const [loadingUsers, setLoadingUsers] = useState<boolean>(false);
    const [cloudMensageError, setCloudMessageError] = useState<string>('Não há dados disponíveis de consumo.')
    const history2 = useHistory();
    const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false);
    const [pinnedFilterPanel, setPinnedFilterPanel] = useState<boolean>(false);
    const [assistantid, setAssistantid] = useState<string>("");
    useEffect(() => {
        setLoading(true);
        const alPromisses = [
            getActiveCountPerDays(90),
            loadUsers(),
            getFraudAlert(),
            getSecuretyScore(),
            getRetrieveUsage(),
            getAssitantData()
        ];
        Promise.all(alPromisses)
            .catch(error => console.error(error))
            .finally(() => setLoading(false))
    }, [user.refreshFlag, user.ClientGroupSelected])

    const getAssitantData = useCallback(async () => {
        // setAssistantid("");
        if (user && user.ClientGroupSelected) {
            const messageService = new MessageService({});
            var assistantid = await messageService.getAssistantId("9", user.ClientGroupSelected?.toString(), "1");
            setAssistantid(assistantid);
        }
    }, [user])

    const getFraudAlert = async () => {
        try {
            const response = await lcTechService.GetFraudEventsReportByClientGroupId()
            setFraudEventsReportCount(response.data);
        } catch (error) {
            console.error(error);
        };
    }

    const getSecuretyScore = async () => {
        setLoadingScore(true);
        try {
            const response = await lcTechService.GetSecureScores();
            setSecureScore(response);
        } catch (error) {
            console.error(error)
        } finally {
            setLoadingScore(false);
        }
    }


    const getActiveCountPerDays = async (days: number) => {
        setOptionLicensesContratadasLoading(true)
        await saasService.GetUsersChart(days).then(response => {
            if (response) {
                setSoftwareAccountByMonthData(response)
            }
        }).catch((error) => console.error(error))
            .finally(() => setOptionLicensesContratadasLoading(false))

    }

    const loadUsers = async () => {
        setLoadingUsers(true)
        try {
            var data = await SaaS_ServiceUSer.GetUserServices();
            //const originalData = resultUsers && resultUsers.data;
            setActiveAcounts(data.userCount);
            setDistinctSoftwares(data.softwares);
            var expandedDataLocal: any[] = [];
            expandedDataLocal = data.pivot;
            setFormattedData(expandedDataLocal);
        } catch (e) {
            console.error(e);
        } finally {
            setLoadingUsers(false)
        }
    }

    const getRetrieveUsage = async () => {
        setResourceUsagePivotRecordsLoading(true);
        try {
            const response = await lcTechService.RetrieveUsage(1, true, moment().format('M'), moment().format('YYYY'));

            if (response?.data?.resourceUsageRecords?.length > 0) {
                const trintaDiasAtras = moment().subtract(30, 'days').startOf('day').toDate();
                const totalPorData = {};

                // Filtrar, mapear e reduzir os registros de uso em um único loop
                response.data.resourceUsageRecords.forEach(resourceUsage => {
                    try {
                        const usageDate = sCdateToJsDate(resourceUsage.usageDate);

                        if (usageDate > trintaDiasAtras && usageDate < new Date()) {
                            const formattedDate = `${usageDate.getFullYear()}-${String(usageDate.getMonth() + 1).padStart(2, '0')}-${String(usageDate.getDate()).padStart(2, '0')}`;
                            const totalCost = parseFloat(resourceUsage.totalCost?.toFixed(6)) || 0;

                            // Acumular total por data
                            totalPorData[formattedDate] = (totalPorData[formattedDate] || 0) + totalCost;
                        }
                    } catch (e) {
                        console.error('Erro ao processar registro de uso:', e);
                    }
                });

                // Converter o objeto em array e ordenar por data
                const arrayFinal = Object.entries(totalPorData)
                    .map(([data, total]) => ({
                        data: new Date(data),
                        total
                    }))
                    .sort((a, b) => b.data.getTime() - a.data.getTime());

                setResourceUsagePivotRecords(arrayFinal);
            }
        } catch (e) {
            console.error('Erro ao buscar dados de uso:', e);
            setCloudMessageError('Não foram encontrados dados de cloud pública.');
        } finally {
            setResourceUsagePivotRecordsLoading(false);
        }
    };
    const cards: Card[] = [
        //Contas ativas
        {
            id: 1,
            type: 'Custom',
            position: { ColSpan: 2, RowSpan: 2, row: 1, col: 1 },
            showFocusButton: true,
            title: () => "Contas ativas",
            customContentRender: (focused?: boolean) => {
                setPivotlicensesFocusContasAtivas(focused == true);
                if (focused) {
                    var fieldBase = [
                        { id: 'disName', value: "Usuario", type: "text" },
                        { id: 'userPrincipalName', value: "Email", type: "text" },
                        { id: 'jobTitle', value: "Cargo", type: "text" },
                        { id: 'lncs', value: "Subscrição", type: "text" },
                        { id: 'sku', value: "SKU", type: "text" },
                        { id: 'count', value: "Atribuída", type: "number" },
                        { id: 'avali', value: "Contratada", type: "number" }
                    ];
                    return (
                        <LcLoading loading={loading}>
                            {
                                formattedData && formattedData.length > 0 ?
                                    <PivotTableWebix
                                        focusedStyleVision
                                        idView={"Contas ativas"}
                                        showAllNumbersAsInteger={true}
                                        maxY={false}
                                        minY={false}
                                        maxX={false}
                                        minX={false}
                                        footer={true}
                                        key={"Contas ativas"}
                                        data={formattedData}
                                        fields={fieldBase}
                                        mode="tree"
                                        chart={{ scale: 'logarithmic', yAxis: { start: 0 } }}
                                        style={{ marginTop: '-10px' }}
                                        structure={{
                                            rows: ["disName"],
                                            columns: ["lncs"],
                                            values: [{ name: "count", operation: ["count"] }, { name: "avali", operation: ["max"] }],
                                            filters: [{ name: "lncs" }, { name: "disName" }]
                                        }}
                                    />
                                    : <LcNoData />
                            }
                        </LcLoading>
                    )
                } else {
                    setPivotlicensesFocus(false);
                    return (
                        <LcLoading loading={loadingUsers}>
                            {
                                activeAcounts ?
                                    <IconValue icon={<PiUsersLight size={32} />} value={activeAcounts} color='#2E1956' />
                                    :
                                    <LcNoData size='small' />
                            }
                        </LcLoading>

                    )


                }
            }
        },
        ////Subscrições distintas
        {
            id: 3,
            type: 'Custom',
            position: { ColSpan: 2, RowSpan: 2, row: 1, col: 3 },
            showFocusButton: true,
            title: () =>
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Subscrições distintas</div>
                </div>,
            customContentRender: (focused?: boolean) => {
                setPivotlicensesFocus(focused == true);
                if (focused) {
                    var fieldBase = [
                        { id: 'disName', value: "Usuario", type: "text" },
                        { id: 'userPrincipalName', value: "Email", type: "text" },
                        { id: 'jobTitle', value: "Cargo", type: "text" },
                        { id: 'lncs', value: "Subscrição", type: "text" },
                        { id: 'sku', value: "SKU", type: "text" },
                        { id: 'count', value: "Atribuída", type: "number" },
                        { id: 'avali', value: "Contratada", type: "number" }
                    ];
                    //disName: user.displayName.trim(),
                    //      userPrincipalName: user.userPrincipalName,
                    //      jobTitle: user.jobTitle,
                    //      lncs: licence.trim(),
                    //      sku: skusArray[index].trim(),
                    //      avali: Number(skuPartPrepaidUnitsArray[index].trim()),
                    //      count: 1,
                    return <><LcLoading loading={loading}>{formattedData && formattedData.length > 0 ?
                        <PivotTableWebix
                            focusedStyleVision
                            idView={"Softwares distintos"}
                            showAllNumbersAsInteger={true}
                            maxY={false}
                            minY={false}
                            maxX={false}
                            minX={false}
                            footer={false}
                            key={"Softwares distintos"}
                            data={formattedData}
                            fields={fieldBase}
                            mode="tree"
                            chart={{ scale: 'logarithmic', yAxis: { start: 0 } }}
                            style={{ marginTop: '-10px' }}
                            structure={{
                                rows: ["lncs"],
                                values: [{ name: "count", operation: ["count"] }, { name: "avali", operation: ["max"] }],
                                filters: [{ name: "lncs" }, { name: "disName" }]
                            }}
                        /> : <LcNoData />
                    }</LcLoading></>
                } else {
                    return (
                        <LcLoading loading={loadingUsers}>
                            {
                                distinctSoftwares ?
                                    <IconValue icon={<PiCubeLight size={32} />} value={distinctSoftwares} color='#2E1956' />
                                    :
                                    <LcNoData size='small' />
                            }
                        </LcLoading>
                    )
                }
            }
        },
        ////Score Segurança
        {
            id: 2,
            type: 'Custom',
            position: { ColSpan: 3, RowSpan: 1, row: 7, col: 4 },
            showFocusButton: false,
            ticketButton: () => {
                return <PiArrowSquareOutLight onClick={() => history2.push('/SaaS/SecurityScore')} />;
            },
            ticketButtonLabel: 'Ir para segurança',
            title: () => "Score segurança",
            infoContent: () => 'Dados disponibilizados pela Microsoft.',
            customContentRender: (focused?: boolean) => {
                let textTooltip = (secureScore) ? `${secureScore.currentScore} pontos de ${secureScore.maxScore} possíveis` : `0 pontos de 0 possíveis`
                return (
                    <LcLoading loading={loadingScore}>
                        {
                            secureScore ?
                                <ProgressBarWithLabel
                                    atualPercentScore={secureScore.currentScore}
                                    maxPercentScore={secureScore.maxScore}
                                    textTooltip={textTooltip}
                                    label='Meu score'
                                    size='small'
                                />
                                :
                                <LcNoData size='small' />
                        }
                    </LcLoading>
                )
            }
        },
        ////Alertas de fraude
        {
            id: 4,
            type: 'Custom',
            position: { ColSpan: 2, RowSpan: 1, row: 7, col: 7 },
            // ticketButton: () => <LcIconLink backgroundClickble='#F0F3FF' icon={'ppi ppi-lightning-bolt'} onClick={() => setDomainLabel(!domainLabel)} clickble={domainLabel} degrade tooltip='LiveCloud AI' tooltipPosition='right' />,
            showFocusButton: false,
            centeredContent: false,
            infoContent: () => <div>Dados disponibilizados pela Microsoft.</div>,
            title: () => <div className="lc-title ellipsis">Alertas de fraude</div>,
            customContentRender: (focused?: boolean) => {
                return (
                    <LcLoading loading={loading}>
                        <div className='fraudDiv'>
                            <IconValue
                                title={"Microsoft 365 suite"}
                                icon={getIconAndColor((fraudEventsReportCount ? fraudEventsReportCount : 0), "").icon}
                                value={fraudEventsReportCount ? fraudEventsReportCount : 0}
                                color={getIconAndColor((fraudEventsReportCount ? fraudEventsReportCount : 0), "").color}
                                unity=""
                                maximumFractionDigits={0}
                                minimumFractionDigits={0}
                                noDataLabel="Sem fraudes no período."
                                formatValue={true}
                                colorValue='#2E1956'
                            />
                            <p className='textInfo'>{fraudEventsReportCount === 0 ? 'Não existem alertas no momento' : 'Enviamos os detalhes por e-mail'}</p>
                        </div>
                    </LcLoading>
                )
            }
        },
        ////Subscrições
        {
            id: 5,
            type: 'Custom',
            position: { RowSpan: 4, ColSpan: 5, row: 1, col: 9 },
            title: () =>
                <div className="lc-segment-title-row-resume">
                    <div className="lc-title ellipsis">Subscrições</div>
                </div>,
            ticketButton: () => <LcIconLink icon={<PiLightbulbLight />} backgroundClickble='#F0F3FF'
                onClick={() => setSubiscriptionsLabel(!subiscriptionsLabel)} clickble={subiscriptionsLabel} tooltip='Insights' tooltipPosition='right' />,
            customContentRender: (focused?: boolean) => <SubscritionChart pinnedFilterPanel={pinnedFilterPanel} subiscriptionsLabel={subiscriptionsLabel} />
        },
        ////Quantidade de contas por principais softwares
        {
            id: 6,
            type: 'Custom',
            position: { RowSpan: 4, ColSpan: 8, row: 3, col: 1 },
            title: () => `Quantidade de contas por principais softwares em ${month[monthPeak]}`,
            ticketButton: () => <LcIconLink backgroundClickble='#F0F3FF' icon={<PiLightbulbLight />} onClick={() => setQuantityAccountsLabel(!quantityAccountsLabel)}
                tooltip='Insights' tooltipPosition='right' clickble={quantityAccountsLabel} />,
            customContentRender: (focused?: boolean) =>
                <LcLoading loading={optionLicensesContratadasLoading}>
                    {
                        softawareAccountByMonthData && softawareAccountByMonthData.length > 0 ?
                            <SoftwareAccountsByMonth
                                data={softawareAccountByMonthData}
                                showInfo={quantityAccountsLabel}
                                showMonthlyPeek={false}
                                pinnedFilterPanel={pinnedFilterPanel}
                            />
                            :
                            <LcNoData />
                    }
                </LcLoading>
        },
        ////Ativações
        {
            id: 7,
            type: 'Custom',
            position: { RowSpan: 3, ColSpan: 5, row: 5, col: 9 },
            title: () => <div className="lc-segment-title-row-resume">
                <div className="lc-title ellipsis">Ativações por dispositivo</div>
            </div>,
            ticketButton: () => <LcIconLink icon={<PiLightbulbLight />} onClick={() => setActivationsLabel(!activationsLabel)} clickble={activationsLabel}
                backgroundClickble='#F0F3FF' tooltip='Insights' tooltipPosition='right' />,
            customContentRender: (focused?: boolean) =>
                <ActivationsByDeviceCard activationsLabel={activationsLabel} pinnedFilterPanel={pinnedFilterPanel} />
        },
        ////Monitoramento dos serviços Office
        {
            id: 8,
            type: 'Custom',
            ticketButtonLabel: "Ver detalhes",
            position: { RowSpan: 1, ColSpan: 3, row: 7, col: 1 },
            // ticketButton: () => {
            //     return <PiArrowSquareOutLight onClick={() => history2.push('/ManageOfficeStatus')} />;
            // },
            showFocusButton: true,
            infoContent: () => 'Status de serviços disponibilizados pela Microsoft.',
            title: () => <div className="lc-title ellipsis">HealthCheck de serviços</div>,
            customContentRender: (focused: boolean) => {
                if (focused) {
                    return <HealthCheckTable />
                } else {
                    return <HealthCheckCard isHome={false} />
                }
            }
        },
        {
            //consumo Cloud
            id: 9,
            type: 'Custom',
            showFocusButton: true,
            position: { ColSpan: 4, RowSpan: 2, row: 1, col: 5 },
            title: () => <div className="lc-title ellipsis">Consumo cloud (R$)</div>,
            customContentRender: (focused: boolean) => (
                // <div style={{ width: '100%', height: '100%' }} >
                <LcLoading loading={resourceUsagePivotRecordsLoading}>
                    {resourceUsagePivotRecords && resourceUsagePivotRecords.length > 0 ?
                        <AreaChart data={resourceUsagePivotRecords} focused={focused} />
                        :
                        <LcNoData size="small" label={cloudMensageError} />
                    }
                </LcLoading>
                // </div>
            )
        },
    ];
    var assitantToolBaricon = [{
        icon: <LcIconLink icon='ppi ppi-lightning-bolt'
            id='aiLCChamados'
            size='small'
            degrade
            tooltip={"Saas AI"}
            tooltipPosition='right' />,
        onClick: () => {
            setShowFilterPanel(!showFilterPanel)
        }, tooltip: `Saas AI`
    }];
    const questions = [
        "Licenças disponíveis e em uso?",
        "Usuários com recursos sem licença?",
        "Licenças atribuídas a inativos?",
        "Recomendações para otimizar custos?"
    ];

    return (
        <Layout
            pageTitle="Insights"
            pivot={pivotlicensesFocus || pivotlicensesFocusContasAtivas}
            functionsGeneric={assistantid && assistantid.length > 0 ? assitantToolBaricon : []}
        // row={
        //     <div className={`item fixedMenu`}>Ativos de Software</div>
        //     // <LcDropDown
        //     //     element={<span>{dashSelected.name} <i className="ppi ppi-chevron-down" /></span>}
        //     //     position="rigth"
        //     // >
        //     //     {
        //     //         dashs.map((d, i) => {
        //     //         })
        //     //     }
        //     // </LcDropDown>
        // }
        >
            <LCDashboard grid={[7, 12]} cards={cards} rightSidePanel={{
            title: 'LiveCloud AI',
            titleClass: 'ai-gradient-text',
            focusable: showFilterPanel,
            pinned: false,
            show: ((showFilterPanel)),
            togglePinned() {
                setPinnedFilterPanel(!pinnedFilterPanel);
            },
            close: (filterPanelShow) => { setShowFilterPanel(filterPanelShow); },
            content: () => {
                return <>{
                    <div style={{ alignSelf: 'center' }}>
                        <AIxChatComponent questions={questions} eventChat={(_event) => { }} assistantId={assistantid} assistantName={"Saas"} />
                    </div>
                }
                </>;
            }
        }} />
        </Layout>
    );
}

export default Insights;