import Moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';

//Redux
import { useDispatch, useSelector } from 'react-redux';
import { setFilterActive } from '../../../store/reducers/filterReducer';
import LcCheckList from '../../../components/Form/LcCheckList';
import LcDropDown from '../../../components/Generic/LcDropDown';
import LcLoading from '../../../components/Generic/LcLoading';
import Layout from '../../../components/Layout/Layout';
import { BackupGoldService } from '../../../services/backupGold/backupGoldService';
import { RootState } from '../../../store/index';
import { UserState } from '../../../store/reducers/userReducer';
import PivotTableWebix from '../../../components/webix';
import LCDashboard from '../../../components/Data/Dashboard/LCDashboard';
import PPExpand from '../../../components/Generic/PPExpand';
import LcNoData from '../../../components/Generic/LcNoData';
import { PiInfoLight } from 'react-icons/pi';
import { VscCollapseAll, VscExpandAll } from "react-icons/vsc";

const BackupGoldHistoricoTree: React.FC = (props) => {
    const [filteredSize, setFilteredSize] = useState(0);
    const dispatch = useDispatch();

    // function getStatePivot() {
    //     try {
    //         var stringsessionStorageGet = sessionStorage.getItem("statePivotTableUIProps");
    //         var objstatePivotTableUIProps = JSON.parse(stringsessionStorageGet ? stringsessionStorageGet : "") as object;
    //         if (objstatePivotTableUIProps) {
    //             return objstatePivotTableUIProps;
    //         }
    //     } catch (e) {

    //     }

    //     return undefined as unknown as object;
    // }

    const backupGoldService = new BackupGoldService(props);
    const local = document.location.pathname;
    const user = useSelector<RootState, UserState>(state => state.user);
    const userPeriodSelected = user.PeriodSelection ? user.PeriodSelection[local] : undefined;
    const [jobs, setJobs] = useState<any[]>([]);
    const [rows, setRows] = useState<string[]>(['Situação', 'Agente', 'Tipo', 'SubCliente']);//
    const [cols, setCols] = useState<string[]>([]);//
    const [servers, setServers] = useState<any[]>([]);
    const [agentTypes, setAgentTypes] = useState<any[]>([]);
    const [backupTypes, setBackupTypes] = useState<any[]>([]);
    const [month, setMonth] = useState<string>(userPeriodSelected ? userPeriodSelected.fields[0].value.split('-')[1] : undefined);
    const [year, setYear] = useState<string>(userPeriodSelected ? userPeriodSelected.fields[0].value.split('-')[0] : undefined);
    const [isLoadingJobs, setIsLoadingJobs] = useState(true);
    const [refresh, setRefresh] = useState<boolean>(false);
    const [modeView, setModeView] = useState<string>("Arvore");
    const [modalVisible, setModalVisible] = useState<boolean>(true);
    const [dataPivot, setDataPivot] = useState<Array<{ [K: string]: string }>>({} as Array<{ [K: string]: string }>);
    const [expanded, setExpanded] = useState(false);
    const [expandAllActive, setExpandAllActive] = useState<boolean>(false);
    const [statePivotTableUIProps, setStatePivotTableUIProps] = useState<object>();
    const [dataTree, setDataTree] = useState<any>();
    const [dataTreeView, setDataTreeView] = useState<any>();
    const gridRef = useRef();
    const [columnDefs, setColumnDefs] = useState([
        { field: 'Situação', filter: true },
        { field: 'Agente', filter: true },
        { field: 'SubCliente', filter: true },
        { field: 'Tipo', filter: true }
    ]);

    async function retrieveDataFromBackupDynamo() {
        sessionStorage.setItem("statePivotTableUIProps", JSON.stringify({}));

        sessionStorage.setItem("changedCols", JSON.stringify([]));
        sessionStorage.setItem("changedRows", JSON.stringify([]));
        try {
            var currentMonth = (new Date().getMonth() + 1).toString().padStart(2, '0');
            var currentYear = new Date().getFullYear().toString();
            setIsLoadingJobs(true);

            if (month) {
                currentMonth = month
            }
            if (year) {
                currentYear = year
            }
            if (!month && !year) {
                setMonth(currentMonth);
                setYear(currentYear);
            }

            if (user.ClientGroupSelected && currentMonth && currentYear) {
                const startDate = [currentYear, currentMonth, '01'].join('-');
                const endDate = [currentYear, currentMonth, new Date(parseInt(currentYear ? currentYear :
                    new Date().getFullYear().toString()), parseInt(currentMonth ? currentMonth :
                        (new Date().getMonth() + 1).toString().padStart(2, '0')), 0).getDate().toString()].join('-');
                var response = (await backupGoldService.GetHistory(startDate, endDate));
                let jobsResponse = response ? response.data : [];
                let attListNackup = [];

                var jobsLocal = jobsResponse
                    .map((Job: {
                        id: string,
                        situacao: string,
                        systemName: string,
                        taskName: string,
                        backupType: string,
                        backupDateTime: any,
                        size: number,
                        instanceName: string,
                        vmNames: string,
                        content: string,
                        subClientName: string
                        year: number,
                        month: number,
                        day: number,
                        hour: number,
                        minute: number,
                    }, index: number) => {

                        return {
                            id: Job.id,
                            Situacao: Job.situacao,
                            SystemName: Job.systemName,
                            TaskName: Job.taskName,
                            BackupType: Job.backupType ? Job.backupType.toLowerCase() : 'N/A',
                            BackupDateTime: Job.backupDateTime ? Moment(Job.backupDateTime).format('DD/MM/YYYY') : '-',
                            Size: Job.size,
                            InstanceName: Job.instanceName,
                            SubClientName: Job.subClientName,
                            VMNames: Job.vmNames,
                            content: Job.content,
                            year: Job.year,
                            month: Job.month,
                            day: Job.day,
                            hour: Job.hour,
                            minute: Job.minute,
                        }
                    });

                setJobs(jobsLocal);

                var localjobsDatatoTree = [...new Set(jobsLocal.map(x => x["SystemName"]))]
                    .map((item: any) => {
                        var recordItemServidor = {};
                        recordItemServidor["Servidor"] = item;
                        try {
                            var arrayAgents = jobsLocal.filter(c => c.SystemName == item
                            );
                            //Agent
                            var arrayAgentsGroupByProcessed = [...new Set(arrayAgents.map(x => x["TaskName"]))]
                                .map((itemAgent: any) => {
                                    var recordItemAgent = {};
                                    recordItemAgent["Agent"] = itemAgent;
                                    var arrayInstances = jobsLocal.filter(c =>
                                        c.SystemName == item &&
                                        c.TaskName == itemAgent);
                                    //Instancia

                                    var arrayInstanceGroupByProcessed = [...new Set(arrayInstances.map(x => x["InstanceName"]))]
                                        .map((itemInstance: any) => {
                                            var recordItemInstancia = {};
                                            recordItemInstancia["Instancia"] = itemInstance;

                                            var arraySubClientes = jobsLocal.filter(c =>
                                                c.SystemName == item &&
                                                c.InstanceName == itemInstance &&
                                                c.TaskName == itemAgent);
                                            //SubClientes
                                            var arraySubClientesGroupByProcessed =
                                                [...new Set(arraySubClientes.map(x => x["SubClientName"]))]
                                                    .map((itemSubCliente: any) => {
                                                        var recordItemSubCliente = {};
                                                        recordItemSubCliente["SubCliente"] = itemSubCliente;
                                                        // Tipo: item.BackupType,
                                                        var arrayBackupType = jobsLocal
                                                            .filter(c =>
                                                                c.SystemName == item &&
                                                                c.InstanceName == itemInstance &&
                                                                c.SubClientName == itemSubCliente &&
                                                                c.TaskName == itemAgent);
                                                        //Tipo
                                                        var arrayBackupTypeGroupByProcessed =
                                                            [...new Set(arrayBackupType.map(x => x["BackupType"]))]
                                                                .map((itemTipo: any) => {
                                                                    var recordItemBackupType = {};
                                                                    recordItemBackupType["Tipo"] = itemTipo;
                                                                    // Tipo: item.BackupType,
                                                                    var ContentTypes = jobsLocal
                                                                        .filter(c =>
                                                                            c.SystemName == item &&
                                                                            c.InstanceName == itemInstance &&
                                                                            c.SubClientName == itemSubCliente &&
                                                                            c.BackupType == itemTipo &&
                                                                            c.TaskName == itemAgent
                                                                        );

                                                                    var arrayVMsGroupByProcessed = [] as string[];
                                                                    [...new Set(ContentTypes.map(x => x["VMNames"]))]
                                                                        .map((itemVM: any) => {
                                                                            if (itemVM != null) {
                                                                                if (itemVM.includes(',')) {
                                                                                    itemVM.split(',').map(vmItem => arrayVMsGroupByProcessed.push(vmItem));
                                                                                }
                                                                                else { arrayVMsGroupByProcessed.push(itemVM) }
                                                                            }
                                                                            return itemVM;
                                                                        });

                                                                    if (arrayVMsGroupByProcessed != undefined && arrayVMsGroupByProcessed && arrayVMsGroupByProcessed.length > 0)
                                                                        recordItemBackupType["VMs"] = arrayVMsGroupByProcessed.filter((elemento, indice) => {
                                                                            return arrayVMsGroupByProcessed.indexOf(elemento) == indice;
                                                                        });

                                                                    var arrayContentsGroupByProcessed = [] as string[];
                                                                    [...new Set(ContentTypes.map(x => x["content"]))]
                                                                        .map((content: any) => {
                                                                            if (content != null) {
                                                                                if (content.includes(';')) {
                                                                                    content.split(';').map(conItem => arrayContentsGroupByProcessed.push(conItem));
                                                                                }
                                                                                else { arrayContentsGroupByProcessed.push(content); }
                                                                            }
                                                                            return content;
                                                                        });

                                                                    if (arrayContentsGroupByProcessed != undefined && arrayContentsGroupByProcessed && arrayContentsGroupByProcessed.length > 0)
                                                                        recordItemBackupType["Contents"] = arrayContentsGroupByProcessed;
                                                                    return recordItemBackupType;
                                                                });

                                                        if (arrayBackupTypeGroupByProcessed != undefined)
                                                            recordItemSubCliente["Tipos"] = arrayBackupTypeGroupByProcessed;
                                                        return recordItemSubCliente;
                                                    });
                                            if (arraySubClientesGroupByProcessed != undefined) {
                                                recordItemInstancia["SubClientes"] = arraySubClientesGroupByProcessed;
                                            }

                                            return recordItemInstancia;
                                        });

                                    if (arrayInstanceGroupByProcessed != undefined) {
                                        recordItemAgent["Instancias"] = arrayInstanceGroupByProcessed;
                                    }

                                    return recordItemAgent;
                                })

                            recordItemServidor["Agentes"] = arrayAgentsGroupByProcessed;
                        } catch (error) {
                            console.log(error);
                        };

                        return recordItemServidor;
                    });
                setDataTree(localjobsDatatoTree);
                setDataTreeView(localjobsDatatoTree)
                setIsLoadingJobs(false);
            }
        } catch (error) {
            console.log(error);
        };

    }

    useEffect(() => {
        retrieveDataFromBackupDynamo();
        setFilteredSize(servers.length);
        dispatch(setFilterActive(false));

    }, [month, user.ClientGroupSelected, year, user.refreshFlag, refresh, dispatch, servers.length]);

    useEffect(() => {
        let _server: { value: string, selected: boolean }[] = [];
        let _agentType: { value: string, selected: boolean }[] = [];
        let _backupType: { value: string, selected: boolean }[] = [];

        jobs.map(job => {
            if (_server.findIndex(c => c.value == job.SystemName) == -1) {
                _server.push({ value: job.SystemName, selected: true });
            }
            if (_agentType.findIndex(c => c.value == job.TaskName) == -1) {
                _agentType.push({ value: job.TaskName, selected: true });
            }
            if (_backupType.findIndex(c => c.value == job.BackupType) == -1) {
                _backupType.push({ value: job.BackupType, selected: true });
            }
        })

        setServers(_server.sort((a, b) => a.value > b.value ? 1 : -1));
        setAgentTypes(_agentType.sort((a, b) => a.value > b.value ? 1 : -1));
        setBackupTypes(_backupType.sort((a, b) => a.value > b.value ? 1 : -1));

        setFilteredSize(servers.length);

    }, [jobs]);

    useEffect(() => {
        setExpanded(expandAllActive)
    }, [expandAllActive]);

    const onChagePeriod = (selected: any) => {
        let date = selected.fields[0].value;
        setMonth(date.split('-')[1]);
        setYear(date.split('-')[0]);
    };
    const periodSelection = {
        periods: [
            {
                label: 'Este mês',
                text: 'Este mês',
                fields: [
                    { name: 'month', value: Moment().format('YYYY-MM') }
                ],
                default: true
            },
            {
                label: 'Mês anterior',
                text: 'Mês anterior',
                fields: [
                    { name: 'month', value: Moment().add(-1, 'M').format('YYYY-MM') }
                ]
            },
            {
                label: 'Dois meses atrás',
                text: 'Dois meses atrás',
                fields: [
                    { name: 'month', value: Moment().add(-2, 'M').format('YYYY-MM') },
                ]
            }
        ],
        customPeriod: {
            label: 'Personalizado',
            text: 'personalizado',
            fields: [
                { name: 'month', label: 'Mês', value: '' }
            ]
        },
        customPeriodType: 'month',
        onChange: onChagePeriod
    };


    // UseEffect para limpar o filtro ativo quando o componente é desmontado
    useEffect(() => {
        return () => {
            dispatch(setFilterActive(false));
        };
    }, [dispatch]);


    // const handleFilter = useCallback((server) => {
    //     if (modeView === 'Arvore') {
    //         let selectedServe = server.filter(serv => serv.selected);
    //         let treeFilter = dataTreeView.filter(tree =>
    //             selectedServe.some(serv => serv.value.toLowerCase() === tree.Servidor.toLowerCase())
    //         );
    //         setDataTree(treeFilter);
    //         dispatch(setFilterActive(selectedServe.length < servers.length));
    //         setFilteredSize(treeFilter.length); // Atualiza o tamanho dos itens filtrados
    //     } else {
    //         debugger;
    //         setServers(server);
    //         const isActive = server.some(serv => serv.selected);
    //         dispatch(setFilterActive(isActive && server.length < servers.length));
    //         setFilteredSize(isActive ? server.filter(serv => serv.selected).length : servers.length);
    //     }
    // }, [modeView, dataTreeView, servers, dispatch]);

    const handleFilter = (server) => {
        if (modeView === 'Arvore') {
            let selectedServe = server.filter(serv => serv.selected);
            let treeFilter = dataTreeView.filter(tree =>
                selectedServe.some(serv => serv.value.toLowerCase() === tree.Servidor.toLowerCase())
            );
            setDataTree(treeFilter);
            dispatch(setFilterActive(selectedServe.length < servers.length));
            setFilteredSize(treeFilter.length); // Atualiza o tamanho dos itens filtrados
        } else {
            const isActive = server.some(serv => serv.selected);
            dispatch(setFilterActive(isActive && server.length < servers.length));
            setFilteredSize(isActive ? server.filter(serv => serv.selected).length : servers.length);
        }
        setServers(server);
    };

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.substring(1);
    }

    const card = [
        {
            id: 1,
            type: 'Custom',
            title: () => {
                return modeView == "Arvore" && dataTree ? (
                    <div className={'lc-segment-title-row mb-2'}>
                        <div className={'lc-title'}>
                            Servidores
                        </div>
                    </div>
                ) : null;
            },
            bgColor: 'rgba(255,255,255,.7)',
            position: { ColSpan: 12, RowSpan: 12, row: 1, col: 1 },
            customContentRender: () => {
                return (
                    <LcLoading loadingType='Helix' loading={isLoadingJobs} >
                        {
                            jobs && jobs.length === 0 &&
                            <LcNoData />
                            // <Grid container direction="row" justify="center" alignItems="center" spacing={2}>
                            //     <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            //         <small><i className="lci lci-exclamation text-warning" /> Não há informações no período</small>
                            //     </Grid>
                            // </Grid>
                        }
                        {
                            jobs && jobs.length > 0 && modeView == "Grade" &&
                            <div className="lc-pivot-segments" >
                                {
                                    servers && agentTypes && backupTypes &&
                                    servers.map((server, serverIndex) => {
                                        return (
                                            server.selected &&
                                            <div key={serverIndex} className="lc-segment primary">
                                                {console.log(server)}
                                                <span className="title">{server.value}</span>
                                                {
                                                    agentTypes.map((agent, agentIndex) => {
                                                        return (
                                                            agent.selected && jobs && server && agent
                                                            && jobs.filter(l => l.SystemName == server.value && l.TaskName == agent.value).length > 0 &&
                                                            <div key={agentIndex} className="secondary">
                                                                <div className="left">
                                                                    <span className="title">{agent.value}</span>
                                                                    {
                                                                        backupTypes.map((type, typeIndex) => {
                                                                            return (
                                                                                type.selected && jobs && server && agent && type &&
                                                                                jobs.filter(l => l.SystemName == server.value && l.TaskName == agent.value && l.BackupType == type.value).length > 0 &&
                                                                                <div key={typeIndex} className="tertiary">
                                                                                    <div className="left">
                                                                                        <span className="title">{type.value}</span>
                                                                                        {(() => {
                                                                                            const currentJobs = jobs.filter(l => l.SystemName == server.value && l.TaskName == agent.value && l.BackupType == type.value);
                                                                                            if (currentJobs.length > 0) {
                                                                                                const job = jobs.filter(l => l.SystemName == server.value && l.TaskName == agent.value && l.BackupType == type.value)[(currentJobs.length - 1)];
                                                                                                if (job)
                                                                                                    return (<span className={`info ${job.Situacao.includes("failed") ? 'text-danger' : 'text-success'}`} style={{ width: '300%' }}>{`${job.BackupDateTime}`}</span>);
                                                                                            }
                                                                                        })()}
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </div >
                        }
                        {
                            jobs && jobs.length > 0 && modeView == "Tabela" &&
                            <>

                                <div style={{ maxWidth: "100%", maxHeight: "calc(100% - 78px)" }}>
                                    {jobs && jobs.length > 0 && <PivotTableWebix
                                        idView={'dataPivot'}
                                        key={'dataPivot'} data={jobs}
                                        fields={[
                                            { id: 'id', value: "Id", type: "text" },
                                            { id: 'Situacao', value: "Situacao", type: "text" },
                                            { id: 'SystemName', value: "Servidor", type: "text" },
                                            { id: 'TaskName', value: "Agente", type: "text" },
                                            { id: 'BackupType', value: "Tipo", type: "text" },
                                            { id: 'BackupDateTime', value: "Data", type: "text" },
                                            { id: 'Size', value: "Tamanho(GB)", type: "text", prepare: value => value ? (((((value / 1024) / 1024)) / 1024)) : 0.00 },
                                            { id: 'InstanceName', value: "Instancia", type: "text" },
                                            { id: 'SubClientName', value: "SubCliente", type: "text" },
                                            { id: 'VMNames', value: "VMs", type: "text" },
                                            { id: 'content', value: "Content", type: "text" },
                                            { id: 'day', value: "Dia", type: "number" },
                                            { id: 'hour', value: "Hora", type: "number" },
                                            { id: 'minute', value: "Minuto", type: "number" },

                                        ]}
                                        structure={{
                                            cols: cols,
                                            rows: rows,
                                            values: [{ name: 'Size', operation: ["Sum"] }]
                                        }}
                                    />}
                                </div>
                            </>
                        }
                        {
                            modeView == "Arvore" && dataTree && dataTree.length > 0 &&
                            <div className='chart-container'>
                                <div className="chart-content scrollable-v" style={{ height: '100%' }}>
                                    <PPExpand hover expanded={expanded} title={capitalizeFirstLetter(String(user.ClientGroupSelectedDescription))} >
                                        {dataTree
                                            .sort((a, b) => a.Servidor && a.Servidor.localeCompare(b.Servidor, 'pt-BR'))
                                            .map((servidor) => {
                                                return (<PPExpand hover expanded={expanded} title={capitalizeFirstLetter(servidor["Servidor"])}  >
                                                    {servidor["Agentes"] && (servidor["Agentes"] as any)
                                                        .sort((a, b) => a.Agent && a.Agent.localeCompare(b.Agent, 'pt-BR'))
                                                        .map((agent) => {
                                                            return (
                                                                <PPExpand hover expanded={expanded} title={'Agent: ' + agent.Agent}   >
                                                                    {agent["Instancias"] && (agent["Instancias"] as any)
                                                                        .sort((a, b) => a.Instancia && a.Instancia.localeCompare(b.Instancia, 'pt-BR'))
                                                                        .map((instance) => {
                                                                            return (
                                                                                <PPExpand hover expanded={expanded} title={'Instancia: ' + instance.Instancia}  >
                                                                                    {instance && instance["SubClientes"] && (instance["SubClientes"] as any)
                                                                                        .sort((a, b) => a.SubCliente && a.SubCliente.localeCompare(b.SubCliente, 'pt-BR'))
                                                                                        .map((SubCliente) => {
                                                                                            return (
                                                                                                <PPExpand hover expanded={expanded} title={'SubCliente: ' + SubCliente.SubCliente}   >
                                                                                                    {SubCliente["Tipos"] && (SubCliente["Tipos"] as any)
                                                                                                        .sort((a, b) => a.Tipo && a.Tipo.localeCompare(b.Tipo, 'pt-BR'))
                                                                                                        .map((Tipo) => {
                                                                                                            return (
                                                                                                                <PPExpand hover expanded={expanded} title={'Tipo Backup: ' + (Tipo.Tipo != 'N/A' ? Tipo.Tipo : 'synthetic full')}  >
                                                                                                                    {Tipo["Contents"] && (Tipo["Contents"] as any)
                                                                                                                        .sort((a, b) => a && a.localeCompare(b, 'pt-BR'))
                                                                                                                        .map((Content) => {
                                                                                                                            return (
                                                                                                                                <div className="bg-hover" style={{ padding: '5px', color: 'var(--text-default)', marginLeft: '30px', display: "flex", alignItems: "center" }}>Content:{Content}</div>
                                                                                                                            )
                                                                                                                        })}
                                                                                                                    {Tipo["VMs"] && (Tipo["VMs"] as any)
                                                                                                                        .sort((a, b) => a.localeCompare(b, 'pt-BR'))
                                                                                                                        .map((VM) => {
                                                                                                                            return (<div className="bg-hover" style={{ padding: '5px', color: 'var(--text-default)', marginLeft: '30px', display: "flex", alignItems: "center" }}>VM:{VM}</div>)
                                                                                                                        })}
                                                                                                                </PPExpand>)
                                                                                                        })}
                                                                                                </PPExpand>)
                                                                                        })}
                                                                                </PPExpand>)
                                                                        })}
                                                                </PPExpand>)
                                                        })}
                                                </PPExpand>)
                                            })}
                                    </PPExpand>
                                </div>
                            </div>
                        }
                    </LcLoading >
                )
            }
        }
    ]

    const filterSystem = () => {
        return (
            servers && servers.length > 0 &&
            <LcCheckList cssClass={'lc-checklist no-margin'} list={servers} toggle={true} onChange={handleFilter} search={true} searchPlaceHolder="(Procure por servidores)" title="Servidores" />
        );
    };

    return (
        <Layout
            pageTitle="Histórico"
            periodSelection={periodSelection}
            functionsGeneric={
                [
                    {
                        icon: <PiInfoLight />,
                        tooltip: () => {
                            return <div className="">
                                Abaixo é apresentado o detalhamento de todos os jobs executados no mês
                            </div>
                        },
                        onClick: () => null
                    },
                    //{ icon: `ppi ppi-filter ${filterIconClass}`, onClick: () => setModalVisible(!modalVisible), tooltip: "Filtro" }
                    {
                        icon: expandAllActive ? <VscCollapseAll color='var(--color-secondary-lime)' /> : <VscExpandAll />,
                        tooltip: () => expandAllActive ? 'Recolher tudo' : 'Expandir tudo',
                        onClick: () => {
                            setExpandAllActive(!expandAllActive)
                            setExpanded(expandAllActive)
                        }
                    },
                ]
            }
            gridFilter={{
                toggleVisibility: () => {
                    setModalVisible(!modalVisible)
                },
                size: filteredSize
            }}
            row={
                <LcDropDown
                    element={<span>{modeView} <i className="ppi ppi-chevron-down" /></span>}
                    position="right"
                >
                    <div className={`item`} onClick={() => { setModeView("Arvore"); }}>Arvore</div>
                </LcDropDown>
            }
        >
            <LCDashboard
                cards={card}
                rightSidePanel={{
                    title: 'Filtros',
                    pinned: true,
                    show: modalVisible,
                    content: filterSystem,
                }}
            />
        </Layout>
    );
};

export default BackupGoldHistoricoTree;